export const statusColorMapper = (status) => {
  if (status?.color && status?.statusId) {
    const colorMap = {
      1: '#d32f2f',
      2: '#4caf50',
      3: '#d32f2f',
      4: '#ed6c02',
      5: '#1976d2',
    };
    return colorMap[status.statusId] || status.color;
  }
  return 'primary';
};
