import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Stack, TextField, Autocomplete } from '@mui/material';

const dayOptions = [...Array(31).keys()];
const hourOptions = [...Array(24).keys()];
const minuteOptions = [...Array(60).keys()];

const DurationSelector = ({ days, setDays, hours, setHours, minutes, setMinutes }) => {
  const { t } = useTranslation();

  const handleChange = (value, setter, type) => {
    const parsedValue = Number(value);

    const minLimit = 0;
    let maxLimit = 999999;

    if (type === 'hours') {
      maxLimit = 23;
    } else if (type === 'minutes') {
      maxLimit = 59;
    }

    // Check if the parsed value is a valid number and within the allowed range
    if (
      !Number.isNaN(parsedValue) &&
      parsedValue >= minLimit &&
      parsedValue <= maxLimit
    ) {
      setter(parsedValue); // Update the state with the valid parsed value
    } else {
      setter('');
    }
  };

  return (
    <Stack direction="row" spacing={2} mb={2} mt={2}>
      {/* Days Selector */}
      <Autocomplete
        freeSolo
        options={dayOptions}
        value={days}
        getOptionLabel={(option) => option.toString()}
        onChange={(_event, newValue) => handleChange(newValue, setDays, 'days')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${t('pages.formPage.duration')} ${t('pages.formPage.inDays')}`}
            onChange={(e) => handleChange(e.target.value, setDays, 'days')}
            inputProps={{
              ...params.inputProps,
              type: 'number',
              min: 0,
              max: 999999,
              step: 1,
            }}
          />
        )}
        sx={{ flex: 1 }}
      />

      {/* Hours Selector */}
      <Autocomplete
        freeSolo
        options={hourOptions}
        value={hours}
        getOptionLabel={(option) => option.toString()}
        onChange={(_event, newValue) => handleChange(newValue, setHours, 'hours')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${t('pages.formPage.duration')} ${t('pages.formPage.inHours')}`}
            onChange={(e) => handleChange(e.target.value, setHours, 'hours')}
            inputProps={{
              ...params.inputProps,
              type: 'number',
              min: 0,
              max: 23,
              step: 1,
            }}
          />
        )}
        sx={{ flex: 1 }}
      />

      {/* Minutes Selector */}
      <Autocomplete
        freeSolo
        options={minuteOptions}
        value={minutes}
        getOptionLabel={(option) => option.toString()}
        onChange={(_event, newValue) => handleChange(newValue, setMinutes, 'minutes')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${t('pages.formPage.duration')} ${t('pages.formPage.inMinutes')}`}
            onChange={(e) => handleChange(e.target.value, setMinutes, 'minutes')}
            inputProps={{
              ...params.inputProps,
              type: 'number',
              min: 0,
              max: 59,
              step: 1,
            }}
          />
        )}
        sx={{ flex: 1 }}
      />
    </Stack>
  );
};

DurationSelector.propTypes = {
  days: PropTypes.number,
  setDays: PropTypes.func,
  hours: PropTypes.number,
  setHours: PropTypes.func,
  minutes: PropTypes.number,
  setMinutes: PropTypes.func,
};

export default DurationSelector;
