import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Backdrop, Container, Typography } from '@mui/material';

import Loader from 'src/components/loaders/Loader';
import DashboardToolbar from 'src/components/ToolBars/DashboardToolbar';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { fetchStatuses } from '../../services/status.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { getDepartmentById } from '../../services/department.service';
import DepartmentSelect from '../../components/departmentSelect/DepartmentSelect';
import { BarChart, AppWidgetSummary, AppCurrentSubject } from '../../components/charts';

// ----------------------------------------------------------------------

export default function TicketManagerDashboard() {
  const theme = useTheme();
  const { id: currentUserId, username, departmentId } = useAuth();
  const [ticketsData, setTicketData] = useState([]);
  const [depTicketsData, setDepTicketsData] = useState([]);
  const [departmentData, setDepartmentData] = useState({});
  const [statusesList, setStatusesList] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    fetchStatuses().then((res) => {
      setStatusesList(
        res.filter((status) => status.departmentId === depId || status.departmentId === 0)
      );
    });
  };
  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);

    const tickets = await fetchTicketsByDate({
      startDate: start,
      endDate: end,
      attributeName,
    });

    const updatedTickets = tickets.map((ticket) => {
      const statusObject = statusesList.find(
        (status) => status.statusId === ticket.status_id
      );
      return { ...ticket, statusObject };
    });

    const filteredTickets = updatedTickets.reduce(
      (acc, ticket) => {
        if (ticket.assignedTo === currentUserId) {
          acc.userTickets.push(ticket);
        }
        if (ticket.departmentId === departmentId) {
          acc.depTickets.push(ticket);
        }
        return acc;
      },
      { userTickets: [], depTickets: [] }
    );

    // Set state in one go after filtering
    setTicketData(filteredTickets.userTickets);
    setDepTicketsData(filteredTickets.depTickets);
    setIsLoading(false);
  };

  const fetchDepartment = () => {
    getDepartmentById(departmentId)
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() =>
        showToast({
          message: t('errorMessages.couldntLoadDep'),
          severoty: 'error',
          props: { hideToast },
        })
      );
  };
  const { ticketStatusCount, expiredTickets, expiringTickets, ticketCount } =
    useMemo(() => {
      const ticketStatusCount = {
        open: 0,
        onhold: 0,
        resolved: 0,
        closed: 0,
      };
      let expiredTickets = 0;
      let expiringTickets = 0;

      const now = new Date().getTime();

      ticketsData.forEach((ticket) => {
        const status = ticket.statusObject?.systemMappedStatus;
        const estimatedEndTime = new Date(ticket.estimatedEndTime).getTime();
        const estimatedStartTime = new Date(ticket.estimatedStartTime).getTime();

        // Count ticket statuses
        if (status === 'OPEN') ticketStatusCount.open += 1;
        if (status === 'ONHOLD') ticketStatusCount.onhold += 1;
        if (status === 'RESOLVED') ticketStatusCount.resolved += 1;
        if (status === 'CLOSED') ticketStatusCount.closed += 1;

        // Count expired tickets
        if (estimatedEndTime < now && status !== 'RESOLVED' && status !== 'CLOSED') {
          expiredTickets += 1;
        }

        // Count expiring tickets
        if (
          status !== 'RESOLVED' &&
          status !== 'CLOSED' &&
          estimatedEndTime > now &&
          estimatedEndTime - now <= 0.1 * (estimatedEndTime - estimatedStartTime)
        ) {
          expiringTickets += 1;
        }
      });

      return {
        ticketStatusCount,
        expiredTickets,
        expiringTickets,
        ticketCount: ticketsData.length,
      };
    }, [ticketsData]);

  useEffect(() => {
    setIsLoading(true);
    if (statusesList.length > 0)
      getTicketsByDate({
        startDate: start,
        endDate: end,
        attributeName: 'created',
      });
    fetchDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);

  useEffect(() => {
    getStatuses(departmentId);
  }, [departmentId]);

  const ticketsByAgent = useMemo(
    () =>
      depTicketsData.reduce((accumulator, ticket) => {
        const { agentName: agent, assignedTo: agentId } = ticket;

        // Only process tickets with a valid agentName
        if (agent && agent !== 'none') {
          // Initialize the accumulator for this agentId if not already done
          if (!accumulator[agentId]) {
            accumulator[agentId] = { agent, agentId, tickets: [] };
          }

          // Add the ticket to the agent's tickets array
          accumulator[agentId].tickets.push(ticket);
        }

        return accumulator;
      }, []), // Directly return an array to avoid Object.values call
    [depTicketsData]
  );

  const ticketCategoryByStatus = useMemo(() => {
    const ticketsByCategory = ticketsData.reduce((accumulator, ticket) => {
      const { category } = ticket;

      if (!accumulator[category]) {
        accumulator[category] = { category, tickets: [] };
      }

      accumulator[category].tickets.push(ticket);
      return accumulator;
    }, {});

    const ticketCategoryByStat = Object.keys(ticketsByCategory).map((key) => {
      const category = ticketsByCategory[key];
      const statusCount = statusesList.reduce((counts, status) => {
        const count = category.tickets.filter(
          (ticket) => ticket.statusObject?.statusId === status.statusId
        ).length;
        counts[status.statusId] = count;
        return counts;
      }, {});

      return {
        name: key,
        data: statusesList.map((status) => statusCount[status.statusId] || 0),
      };
    });

    return ticketCategoryByStat;
  }, [ticketsData, statusesList]);

  useEffect(() => {
    setStatusNames(
      statusesList.map((status) =>
        status.statusId < 6 ? t(`status.${status.name}`) : status.name
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Backdrop
          sx={{
            bgcolor: 'rgb(121 121 121 / 32%)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Loader size={175} hideText />
          </Box>
        </Backdrop>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || 'user'}!
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item xs={10}>
            <DashboardToolbar
              getTicketsByDate={getTicketsByDate}
              start={start}
              end={end}
              setIsLoading={setIsLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <DepartmentSelect
              options={[{ name: departmentData.name, id: departmentData.id }]}
            />
          </Grid>
        </Stack>
        <Grid container columns={15} spacing={3}>
          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.expiringTickets')}
              color="warning"
              total={expiringTickets || 0}
              icon="uil:clock-ten"
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.expiredTickets')}
              total={expiredTickets || 0}
              color="error"
              icon="uil:clock-two"
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.onHoldTickets')}
              total={ticketStatusCount.onhold}
              icon="ph:ticket"
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closureRate')}
              total={(ticketStatusCount.resolved / ticketCount) * 100 || 0}
              color="success"
              icon="material-symbols:percent"
            />
          </Grid>
          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.openingRate')}
              total={
                ((ticketStatusCount.onhold + ticketStatusCount.open) / ticketCount) *
                  100 || 0
              }
              color="info"
              icon="material-symbols:percent"
            />
          </Grid>

          <Grid item xs={15} md={7.5} lg={10}>
            <BarChart
              title={t('dashboard.bestPerAgents')}
              subheader=""
              chartData={ticketsByAgent
                .filter((agent) =>
                  agent.tickets.some(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  )
                )
                .sort(
                  (a, b) =>
                    b.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length -
                    a.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length
                )
                .map((agent) => ({
                  label: agent.agent,
                  value: agent.tickets.filter(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  ).length,
                }))}
            />
          </Grid>

          <Grid item xs={15} md={7.5} lg={5}>
            <AppCurrentSubject
              title={t('dashboard.mostReccuringSubjects')}
              chartLabels={statusNames}
              chartData={ticketCategoryByStatus}
              chartColors={[...Array(statusNames.length)].map(
                () => theme.palette.text.secondary
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
