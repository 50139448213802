import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover, MenuItem } from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';

import Iconify from 'src/components/iconify/Iconify';

import { checkDelete } from '../../../services/user.service';

OptionsPopover.propTypes = {
  open: PropTypes.object,
  handleCloseMenu: PropTypes.func,
  setModifyUserModal: PropTypes.func,
  selectedRow: PropTypes.object,
  removeUser: PropTypes.func,
  showDelete: PropTypes.bool,
  unarchive: PropTypes.func,
};

export default function OptionsPopover({
  open,
  handleCloseMenu,
  setModifyUserModal,
  selectedRow,
  removeUser,
  showDelete,
  unarchive,
}) {
  const { t } = useTranslation();

  const { showConfirmModal: showConfirmArchiveModal } = useConfirmModal(
    t('pages.usersPage.form.confirmArchive'),
    t('pages.usersPage.form.confirmArchiveMessage'),
    async () => removeUser(selectedRow.id, true)
  );
  const { showConfirmModal: showConfirmDeleteModal } = useConfirmModal(
    t('pages.usersPage.form.confirmDelete'),
    `${t('pages.usersPage.form.confirmDelete')} ${selectedRow?.fullName}`,
    async () => removeUser(selectedRow.id)
  );

  const handleDelete = async () => {
    try {
      const isLinked = await checkDelete(selectedRow.id);

      if (isLinked) {
        showConfirmArchiveModal();
      } else {
        showConfirmDeleteModal();
      }
    } catch (error) {
      console.error('Error checking delete:', error);
    } finally {
      handleCloseMenu();
    }
  };

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem
        id="modify-user-btn"
        onClick={() => {
          setModifyUserModal(true);
          handleCloseMenu();
        }}
      >
        <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
        {t('popover.edit')}
      </MenuItem>
      {showDelete && !selectedRow?.archived && (
        <MenuItem
          sx={{ color: 'error.main' }}
          id="delete-user-btn"
          onClick={handleDelete}
        >
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          {t('popover.delete')}
        </MenuItem>
      )}
      {selectedRow?.archived && (
        <MenuItem
          id="unarchive-user-btn"
          sx={{ color: 'success.dark' }}
          onClick={() => {
            unarchive(selectedRow.id);
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:archive-outline" sx={{ mr: 2 }} />
          {t('popover.unarchive')}
        </MenuItem>
      )}
    </Popover>
  );
}
