import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import {
  deleteUser,
  unarchiveUser,
  multipleUsersDelete,
} from 'src/services/user.service';

import TableLoader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import TableToolbar from 'src/components/ToolBars/TableToolbar';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';
import TablePaginations from 'src/components/table-pagination/TablePaginations';

import UsersTableBody from './tableBody';
import FilterPopover from '../popovers/filterPopover';
import OptionsPopover from '../popovers/optionsPopover';
import { TABLE_HEAD_ADMIN, TABLE_HEAD_DEPMANAGER } from '../tableHeads';

UsersTable.propTypes = {
  fullFilteredData: PropTypes.array,
  role: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  noArchivedUsers: PropTypes.bool,
  noActiveUsers: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
  setPage: PropTypes.func,
  title: PropTypes.string,
  departmentsData: PropTypes.array,
  setRowsPerPage: PropTypes.func,
  setSelectedRow: PropTypes.func,
  selectedRow: PropTypes.object,
  setFilterName: PropTypes.func,
  setModifyUserModal: PropTypes.func,
  setFilterBy: PropTypes.func,
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
  activeTab: PropTypes.number,
  usersData: PropTypes.array,
};

export default function UsersTable({
  filterName,
  fullFilteredData,
  role,
  setRowsPerPage,
  selected,
  setSelected,
  order,
  setOrder,
  setOrderBy,
  orderBy,
  rowsPerPage,
  page,
  setPage,
  noArchivedUsers,
  noActiveUsers,
  searchIsNotFound,
  title,
  departmentsData,
  setSelectedRow,
  selectedRow,
  setFilterName,
  setModifyUserModal,
  setFilterBy,
  fetchData,
  isLoading,
  activeTab,
  usersData,
}) {
  const [searchBy, setSearchBy] = useState('byName');
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const [filterPopover, setFilterPopover] = useState(null);
  const [open, setOpen] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [showDelete, setShowDelete] = useState(true);

  const [subFilterPopover, setSubFilterPopover] = useState({
    department: null,
    status: null,
    role: null,
  });

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id: currentUserId } = useAuth();
  const { showToast, hideToast } = useToast();

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenMenu = (event, row) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
    setSelectedRow(row);
    if (role === 'ROLE_ADMIN') {
      setShowDelete(row.id !== currentUserId);
    } else {
      setShowDelete(false);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClick = (event, email, id) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];
    let newSelectedId = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
      newSelectedId = newSelectedId.concat(selectedId, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedId = newSelectedId.concat(selectedId.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedId = newSelectedId.concat(
        selectedId.slice(0, selectedIndex),
        selectedId.slice(selectedIndex + 1)
      );
    }
    setSelectedId(newSelectedId);
    setSelected(newSelected);
  };

  const removeUser = async (id, archive) => {
    if (id !== currentUserId) {
      genericApiToaster(
        deleteUser,
        id,
        archive
          ? t('successMessages.successUserArchived')
          : t('successMessages.successUserDelete'),
        t('errorMessages.couldntDeleteUser'),
        showToast,
        hideToast,
        t,
        () => {
          fetchData();
        }
      );
    } else {
      showToast({
        message: t('errorMessages.cannotDeleteUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const unarchive = async (id) => {
    if (id !== currentUserId) {
      genericApiToaster(
        unarchiveUser,
        id,
        t('successMessages.successUserUnarchive'),
        t('errorMessages.couldntUnarchiveUser'),
        showToast,
        hideToast,
        t,
        () => {
          fetchData();
        }
      );
    } else {
      showToast({
        message: t('errorMessages.cannotUnarchiveUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const deleteMultipleUsers = async (usersIDs) => {
    if (!usersIDs.includes(currentUserId)) {
      genericApiToaster(
        multipleUsersDelete,
        [usersIDs],
        '',
        t('errorMessages.couldntDeleteUser'),
        showToast,
        hideToast,
        t,
        (res) => {
          showToast({
            message: t('successMessages.successMultipleUsersDelete', {
              usersDeleted: res.deletedCount,
              totalUsers: res.totalIds,
              usersArchived: res.archivedCount,
            }),
            severity: 'success',
            props: { hideToast },
          });
          setSelectedId([]);
          setSelected([]);
          fetchData();
        }
      );
    } else {
      showToast({
        message: t('errorMessages.cannotDeleteUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageData.map((n) => n.email);
    const newSelectdsId = currentPageData.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      // remove selected ids and email of this page if all are already selected
      setSelectedId(selectedId.filter((id) => !newSelectdsId.includes(id)));
      setSelected(selected.filter((email) => !newSelecteds.includes(email)));
      return;
    }
    // else select ids and emails of this page and add it to the selected list
    setSelected([...new Set([...selected, ...newSelecteds])]);
    setSelectedId([...new Set([...selectedId, ...newSelectdsId])]);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRemoveSelected = () => {
    setSelected([]);
    setSelectedId([]);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleClickRow = (row) => {
    setSelectedRow(row);
    setModifyUserModal(true);
  };

  const filteredData = useMemo(
    () =>
      activeTab === 0
        ? fullFilteredData.filter((user) => !user.archived)
        : fullFilteredData.filter((user) => user.archived),
    [activeTab, fullFilteredData]
  );

  const currentPageData = useMemo(
    () => filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [filteredData, page, rowsPerPage]
  );

  useEffect(() => {
    if (currentPageData?.length > 0) {
      const newSelectdsId = currentPageData.map((n) => n.id);
      if (newSelectdsId.every((id) => selectedId.includes(id))) {
        setIndeterminateCheckbox(true);
      } else setIndeterminateCheckbox(false);
    }
  }, [page, currentPageData, selectedId]);

  return (
    <>
      <Card>
        <TableToolbar
          numSelected={activeTab === 0 ? selected.length : 0}
          filterName={filterName}
          setFilterName={setFilterName}
          filterBy={searchBy}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByName}
          selected={selectedId}
          handleRemoveSelected={handleRemoveSelected}
          deleteFunc={deleteMultipleUsers}
          model={t(`nav.${title}`)}
          data={usersData}
        />

        <Scrollbar>
          <TableContainer>
            <Table size={isSmallScreen ? 'small' : 'medium'}>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={
                  role === 'ROLE_ADMIN' ? TABLE_HEAD_ADMIN : TABLE_HEAD_DEPMANAGER
                }
                showCheckBox={activeTab === 0}
                rowCount={filteredData.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                indeterminate={indeterminateCheckbox}
              />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={isSmallScreen ? 5 : 8}
                      sx={{ textAlign: 'center' }}
                    >
                      <TableLoader size={isSmallScreen ? 50 : 150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <UsersTableBody
                    currentPageData={currentPageData || []}
                    role={role}
                    handleClick={handleClick}
                    fullFilteredData={filteredData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    selected={selected}
                    handleOpenMenu={handleOpenMenu}
                    handleClickRow={handleClickRow}
                    activeTab={activeTab}
                  />
                  {((noArchivedUsers && activeTab === 1) ||
                    (noActiveUsers && activeTab === 0)) && (
                    <EmptyTable
                      filterName={filterName}
                      searchIsNotFound={searchIsNotFound}
                      colSpan={isSmallScreen ? 5 : 8}
                    />
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePaginations
          filteredEntity={filteredData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <OptionsPopover
        open={open}
        handleCloseMenu={handleCloseMenu}
        setModifyUserModal={setModifyUserModal}
        selectedRow={selectedRow}
        removeUser={removeUser}
        showDelete={showDelete}
        unarchive={unarchive}
      />
      <FilterPopover
        setSubFilterPopover={setSubFilterPopover}
        subFilterPopover={subFilterPopover}
        handleCloseSubFilter={handleCloseSubFilter}
        setFilterBy={setFilterBy}
        setFilterName={setFilterName}
        setSearchBy={setSearchBy}
        setPage={setPage}
        departmentsData={departmentsData}
        handleCloseFilter={handleCloseFilter}
        filterPopover={filterPopover}
      />
    </>
  );
}
