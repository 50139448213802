import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Paper, Pagination } from '@mui/material';

import Loader from 'src/components/loaders/Loader';
import EmptyGrid from 'src/components/emptyGrid/emptyGrid';

import StatusCard from './statusCard';

StatusGrid.propTypes = {
  statusData: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
  isLoading: PropTypes.bool,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};
export default function StatusGrid({
  statusData,
  itemsPerPage,
  page,
  handleChangePage,
  handleDetailsClick,
  deleteStatus,
  isLoading,
  searchIsNotFound,
  isNotFound,
  filterName,
}) {
  const totalPages = useMemo(
    () => Math.ceil(statusData.length / itemsPerPage),
    [statusData, itemsPerPage]
  );

  return (
    <>
      <Grid container columns={15} spacing={3}>
        {!isLoading ? (
          <>
            {statusData
              .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              .map((status, idx) => (
                <Grid item key={idx} xs={12} sm={6} md={3}>
                  <StatusCard
                    status={status}
                    handleDetailsClick={handleDetailsClick}
                    deleteStatus={deleteStatus}
                  />
                </Grid>
              ))}
            {isNotFound && (
              <EmptyGrid searchIsNotFound={searchIsNotFound} filterName={filterName} />
            )}
          </>
        ) : (
          <Paper
            sx={{
              width: '100%',
              py: 5,
              my: 5,
              textAlign: 'center',
            }}
          >
            <Loader size={175} />
          </Paper>
        )}
      </Grid>
      {statusData.length > 0 && (
        <Stack alignItems="center" sx={{ mt: 5 }}>
          <Pagination
            component="div"
            color="primary"
            count={totalPages}
            size="large"
            page={page + 1}
            onChange={handleChangePage}
          />
        </Stack>
      )}
    </>
  );
}
