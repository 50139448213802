import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Card, CardHeader, Typography, CardContent } from '@mui/material';

import { useFormContext } from '../../../hooks/useForm';
import { reorderArray } from '../../../utils/filterData';
import FormInput from '../../../components/FormInputs/FormInput';

QuestionView.propTypes = {
  question: PropTypes.object,
  sectionsList: PropTypes.array,
  page: PropTypes.number,
  setSections: PropTypes.func,
  setPage: PropTypes.func,
  setHasSelectedSection: PropTypes.func,
  section: PropTypes.object,
};

export default function QuestionView({
  question,
  sectionsList,
  page,
  setSections,
  setPage,
  setHasSelectedSection,
  section,
}) {
  const [input, setInput] = useState(question);
  const { getQuestionOptions, modifyQuestion, addFiles, removeFiles } = useFormContext();
  const { t } = useTranslation();
  const [selectedOpt, setSelectedOpt] = useState('');
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (section.nextSection === undefined) {
      setHasSelectedSection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setSelectedOpt(e.target.value);
    modifyQuestion(question.id, { ...question, inputValue: e.target.value });
  };
  const setSelectedVals = (selectedVals) => {
    modifyQuestion(question.id, { ...question, inputValue: selectedVals });
  };

  const setFormData = (formData) => {
    addFiles(formData);
  };

  const removeAttachements = (attachementId) => {
    removeFiles(attachementId);
  };

  const handleSelect = (value) => {
    setSelectedOpt(value);
    modifyQuestion(question.id, { ...question, inputValue: value });
  };

  useEffect(() => {
    setInput(question);
    const optionsList = getQuestionOptions(question.id);
    setOptions(optionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id]);

  const getOptionByValue = (valueToFind) => {
    const option = options?.find((option) => option.value === valueToFind);
    return option || null;
  };
  const getSectionIndexById = (sectionId) => {
    const sectionIndex = sectionsList.findIndex((section) => section.id === sectionId);
    setHasSelectedSection(true);
    return sectionIndex;
  };

  useEffect(() => {
    if (question.redirectOnAnswer && selectedOpt) {
      const option = getOptionByValue(selectedOpt);
      const sectionId = option?.redirectTo;

      if (sectionId !== undefined) {
        const index = getSectionIndexById(sectionId);
        const newSectionOrder = reorderArray(sectionsList, page, index);
        setSections(newSectionOrder.array);
        setPage(newSectionOrder.currentIndex);
        setSelectedOpt(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpt, sectionsList]);

  const isCheckboxEmpty = useMemo(
    () =>
      question.type === 'CheckboxInput' &&
      Array.isArray(question.inputValue) &&
      question.inputValue.length === 0,
    [question.type, question.inputValue]
  );

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={question.text}
        sx={{
          color:
            question.isRequired && (!question.inputValue || isCheckboxEmpty)
              ? 'red'
              : 'initial',
        }}
      />
      <CardContent>
        <FormInput
          input={input}
          options={options}
          sx={{ mt: '2vh' }}
          handleSelect={handleSelect}
          onChange={onChange}
          setSelectedVals={setSelectedVals}
          setFormData={setFormData}
          removeAttachements={removeAttachements}
          setHasSelectedSection={setHasSelectedSection}
        />
        {(!question.inputValue || isCheckboxEmpty) && question.isRequired && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {t('formControl.fieldRequired')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
