import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Grid, Stack, Container, Typography, Pagination } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useModal } from 'src/hooks/useModal';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';
import {
  createGroup,
  updateGroup,
  deleteGroup,
  fetchAllGroups,
} from 'src/services/group.users.service';

import TableToolbar from 'src/components/ToolBars/TableToolbar';
import ActionButton from 'src/components/action-button/action-button';

import GroupUsersList from './card/GroupUsersList';
import AddUpdateGroupUsers from './modal/AddUpdateGroupUsers';

const itemsPerPage = 8;

function GroupUsersView() {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal } = useModal();
  const { showToast, hideToast } = useToast();
  const { id } = useAuth();
  const [gridPage, setGridPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [groupUsers, setGroupUsers] = useState([]);

  const getGroupUsers = async () => {
    setIsLoading(true);
    genericApiToaster(
      fetchAllGroups,
      id,
      '',
      t('errorMessages.errorFetchGroupUsers'),
      showToast,
      hideToast,
      t,
      setGroupUsers,
      undefined,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    getGroupUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addGroupUsers = async (newGroupUsers) => {
    genericApiToaster(
      createGroup,
      [
        {
          name: newGroupUsers.name,
          users: newGroupUsers.users,
          image: newGroupUsers.image,
          createdBy: id,
        },
      ],
      t('successMessages.successAddGroupUsers'),
      t('errorMessages.errorAddGroupUsers'),
      showToast,
      hideToast,
      t,
      getGroupUsers
    );
  };

  const updateGroupUsers = async (updatedGroupUsers) => {
    genericApiToaster(
      updateGroup,
      [
        {
          id: updatedGroupUsers.id,
          name: updatedGroupUsers.name,
          users: updatedGroupUsers.users,
          image: updatedGroupUsers.image,
          createdBy: updatedGroupUsers.createdBy,
        },
      ],
      t('successMessages.successUpdateGroupUsers'),
      t('errorMessages.errorUpdateGroupUsers'),
      showToast,
      hideToast,
      t,
      getGroupUsers
    );
  };

  const deleteGroupUsers = async (groupId) => {
    genericApiToaster(
      deleteGroup,
      groupId,
      t('successMessages.successDeleteGroupUsers'),
      t('errorMessages.errorDeleteGroupUsers'),
      showToast,
      hideToast,
      t,
      getGroupUsers
    );
  };

  const filteredGroupUsers = useMemo(() => {
    const lowerCaseFilterName = filterName.toLowerCase();
    return groupUsers.filter((group) =>
      group.name.toLowerCase().includes(lowerCaseFilterName)
    );
  }, [groupUsers, filterName]);

  const totalPages = useMemo(
    () => Math.ceil(filteredGroupUsers.length / itemsPerPage),
    [filteredGroupUsers]
  );

  const { isNotFound, searchIsNotFound } = useMemo(() => {
    const isNotFound = !filteredGroupUsers.length;
    const searchIsNotFound = !filteredGroupUsers.length && !!filterName;
    return { isNotFound, searchIsNotFound };
  }, [filteredGroupUsers, filterName]);

  const handleChangeGridPage = (event, newPage) => {
    setGridPage(newPage - 1);
  };

  const handleFilterByName = (event) => {
    setGridPage(0);
    setFilterName(event.target.value);
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={isSmallScreen ? 2 : 3}
      >
        <Typography
          style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
          mx={1}
        >
          {t('nav.MyGroups')}
        </Typography>
        <Grid>
          <ActionButton
            id="add-group-users-btn"
            onClick={() =>
              showModal({
                title: t('pages.groupUsersPage.addNewGroupUsers'),
                Component: AddUpdateGroupUsers,
                props: {
                  hideModal,
                  addGroupUsers,
                },
              })
            }
            icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
            text={t('buttons.addNewGroupUsers')}
            title={t('buttons.addNewGroupUsers')}
          />
        </Grid>
      </Stack>
      <TableToolbar
        numSelected={0}
        filterName={filterName}
        setFilterName={setFilterName}
        onFilterName={handleFilterByName}
        model={t('nav.MyGroups')}
      />
      <GroupUsersList
        groupUsers={filteredGroupUsers}
        onEditGroupUsers={updateGroupUsers}
        handleDelete={deleteGroupUsers}
        itemsPerPage={itemsPerPage}
        gridPage={gridPage}
        page={gridPage}
        isLoading={isLoading}
        isNotFound={isNotFound}
        searchIsNotFound={searchIsNotFound}
        filterName={filterName}
      />
      {filteredGroupUsers.length > 0 && (
        <Stack alignItems="center" sx={{ mt: 5 }}>
          <Pagination
            component="div"
            color="primary"
            count={totalPages}
            size={isSmallScreen ? 'medium' : 'large'}
            page={gridPage + 1}
            onChange={handleChangeGridPage}
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: isSmallScreen && '0.75rem',
              },
            }}
          />
        </Stack>
      )}
    </Container>
  );
}

export default GroupUsersView;
