import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Table,
  Popover,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import TablePaginations from 'src/components/table-pagination/TablePaginations';

import StatusTableRow from './statusTableRow';
import Scrollbar from '../../../components/scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import { useConfirmModal } from '../../../hooks/useModal';
import TableHeaders from '../../../components/tableHeaders/TableHeaders';

export const TABLE_HEAD = [
  { id: 'name', name: 'name', label: 'status.name', alignCenter: true },
  {
    id: 'description',
    name: 'description',
    label: 'status.description',
    alignRight: false,
  },
  {
    id: 'systemState',
    name: 'systemState',
    label: 'status.systemState',
    alignRight: false,
  },
  {
    id: 'department',
    name: 'departement',
    label: 'status.department',
    alignRight: false,
  },
  { id: 'color', name: 'color', label: 'status.color', alignCenter: false },
  {
    id: 'correspondances',
    name: 'correspondances',
    label: 'status.correspondances',
    alignCenter: true,
  },
  { id: 'options', label: 'ticket.options', sticky: true },
];

StatusTable.propTypes = {
  statusData: PropTypes.array.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterName: PropTypes.string,
};
export default function StatusTable({
  statusData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRequestSort,
  order,
  orderBy,
  handleDetailsClick,
  deleteStatus,
  isLoading,
  searchIsNotFound,
  isNotFound,
  filterName,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState([]);
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };
  const { showConfirmModal } = useConfirmModal(
    t('pages.statusPage.confirmDelete'),
    `${t('pages.statusPage.areYouSure')} ${selected?.name}`,
    async () => deleteStatus(selected.statusId)
  );
  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer>
            <Table size={isSmallScreen ? 'small' : 'medium'}>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={statusData.length}
                numSelected={0}
                onRequestSort={handleRequestSort}
              />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                      <Loader size={150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {isNotFound ? (
                    <EmptyTable
                      filterName={filterName}
                      colSpan={7}
                      searchIsNotFound={searchIsNotFound}
                    />
                  ) : (
                    <TableBody>
                      {statusData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, idx) => (
                          <StatusTableRow
                            key={idx}
                            row={row}
                            handleDetailsClick={handleDetailsClick}
                            setSelected={setSelected}
                            handleOpenMenu={handleOpenMenu}
                          />
                        ))}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePaginations
          filteredEntity={statusData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[6, 12, 30]}
        />
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: isSmallScreen ? 110 : 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleDetailsClick(selected);
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:edit-fill"
            sx={{
              mr: isSmallScreen ? 1.5 : 2,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.edit')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            showConfirmModal();
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:trash-2-outline"
            sx={{
              mr: isSmallScreen ? 1.5 : 2,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.delete')}
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
