import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, IconButton, InputAdornment } from '@mui/material';

import Iconify from '../../../components/iconify';
import { useFormContext } from '../../../hooks/useForm';
import TextInput from '../../../components/FormInputs/TextInput';
import SelectInput from '../../../components/FormInputs/SelectInput';

QuestionOption.propTypes = {
  option: PropTypes.object,
  selectedInput: PropTypes.string,
  questionId: PropTypes.string,
  selectedType: PropTypes.string,
};

export default function QuestionOption({
  option,
  selectedInput,
  questionId,
  selectedType,
}) {
  const { modifyOption, deleteOption, sections } = useFormContext();
  const [updatedOption, setUpdatedOption] = useState(option || {});
  const [selectedRedirectValue, setSelectedRedirectValue] = useState(
    option.redirectTo || '0'
  );

  const { t } = useTranslation();

  const handleOptionChange = () => {
    modifyOption(option.id, { ...option, ...updatedOption });
  };

  const handleOptionRedirect = (value) => {
    setSelectedRedirectValue(value);
    modifyOption(option.id, { ...option, redirectTo: value });
  };

  const sectionsOptions = Object.values(sections).map((section) => ({
    text: `${t('pages.formPage.goToSection')} ${section.name}`,
    value: section.id,
  }));

  sectionsOptions.unshift({ text: t('pages.formPage.chooseSection'), value: '0' });

  return (
    <Grid container mt={2} mb={2}>
      <Grid item xs={6}>
        <TextInput
          name="option"
          label={t('pages.formPage.optionLabel')}
          variant="standard"
          color="info"
          defaultValue={updatedOption.text || ''}
          onChange={(e) =>
            setUpdatedOption({ ...option, text: e.target.value, value: e.target.value })
          }
          onBlur={handleOptionChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => deleteOption(questionId, option.id)}
                  edge="end"
                >
                  <Iconify icon="ph:x-bold" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {(selectedInput === 'SelectInput' || selectedInput === 'RadioInput') &&
        selectedType === t('pages.formPage.goToSectionBaseOn') && (
          <Grid item xs={5} sx={{ textAlign: 'end' }}>
            <SelectInput
              labelValue={t('pages.formPage.action')}
              name="Type selector"
              defaultValue={selectedRedirectValue}
              options={sectionsOptions}
              setSelectedInput={handleOptionRedirect}
            />
          </Grid>
        )}
    </Grid>
  );
}
