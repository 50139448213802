import { t } from 'i18next';
import * as React from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

SelectInput.propTypes = {
  defaultValue: PropTypes.node,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
};

export default function SelectInput({
  defaultValue,
  labelValue,
  options,
  setSelectedInput,
  ...other
}) {
  const [value, setValue] = React.useState(defaultValue || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedInput(event.target.value);
  };

  return (
    <FormControl {...other}>
      <InputLabel id="select-label">{labelValue}</InputLabel>
      <Select
        labelId="select"
        id="select"
        value={value}
        label={labelValue}
        onChange={handleChange}
      >
        {options?.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{
                color: option.value === '' ? 'grey' : 'inherit',
              }}
            >
              {option.text}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            {t('infoMessages.noOptions')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
