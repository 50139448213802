import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Popper, MenuItem, Typography } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';

import { TABLE_HEAD_ADMIN } from '../tableHeads';

FilterPopover.propTypes = {
  setFilterBy: PropTypes.func,
  setFilterName: PropTypes.func,
  setSearchBy: PropTypes.func,
  setPage: PropTypes.func,
  departmentsData: PropTypes.array,
  handleCloseFilter: PropTypes.func,
  filterPopover: PropTypes.object,
};
export default function FilterPopover({
  setFilterBy,
  setFilterName,
  setSearchBy,
  setPage,
  departmentsData,
  handleCloseFilter,
  filterPopover,
}) {
  const [selectedFilter, setSelectedFilter] = useState('fullName');

  const [subFilterPopover, setSubFilterPopover] = useState({
    department: null,
    status: null,
    role: null,
  });

  const { t } = useTranslation();

  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'role') {
      setSubFilterPopover({ role: event.currentTarget });
    }
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  return (
    <>
      {/* main popper */}
      <Popper
        open={Boolean(filterPopover)}
        anchorEl={filterPopover}
        placement="left-start"
        onClose={handleCloseFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {TABLE_HEAD_ADMIN.map(({ id, name }) => (
            <div key={id}>
              {name === 'status' ? (
                <MenuItem
                  selected={selectedFilter === 'status'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'status')}
                  onClick={(e) => handleOpenSubFilter(e, 'status')}
                >
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2">{t('filters.byStatus')}</Typography>
                </MenuItem>
              ) : null}
              {name === 'fullName' ? (
                <MenuItem
                  selected={selectedFilter === name}
                  onMouseEnter={handleCloseSubFilter}
                  onClick={() => {
                    setFilterName('');
                    setFilterBy(name);
                    setSearchBy(`byName`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                  onTouchStart={() => {
                    setFilterName('');
                    setFilterBy(name);
                    setSearchBy(`byName`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                >
                  <Typography variant="body2">Name</Typography>
                </MenuItem>
              ) : null}
              {name === 'departmentName' ? (
                <MenuItem
                  selected={selectedFilter === 'department'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'department')}
                  onClick={(e) => handleOpenSubFilter(e, 'department')}
                >
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2">{t('filters.byDepartment')}</Typography>
                </MenuItem>
              ) : null}
              {name === 'appUserRole' ? (
                <MenuItem
                  selected={selectedFilter === 'appUserRole'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'role')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'role')}
                  onClick={(e) => handleOpenSubFilter(e, 'role')}
                >
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2">{t('filters.byRole')}</Typography>
                </MenuItem>
              ) : null}
              {!['status', 'departmentName', 'appUserRole', 'fullName'].includes(
                name
              ) && (
                <MenuItem
                  selected={selectedFilter === name}
                  onMouseEnter={handleCloseSubFilter}
                  onClick={() => {
                    setFilterName('');
                    setFilterBy(name);
                    setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                  onTouchStart={() => {
                    setFilterName('');
                    setFilterBy(name);
                    setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                >
                  <Typography variant="body2">
                    {name && name.charAt(0).toUpperCase() + name.slice(1)}
                  </Typography>
                </MenuItem>
              )}
            </div>
          ))}
        </Card>
      </Popper>
      {/* sub poppers */}
      <Popper
        open={Boolean(subFilterPopover.status)}
        anchorEl={subFilterPopover.status}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {['true', 'false'].map((status, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('status');
                setSearchBy('byStatus');
                setSelectedFilter('status');
                setFilterName(status);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
              onTouchStart={() => {
                setFilterBy('status');
                setSearchBy('byStatus');
                setSelectedFilter('status');
                setFilterName(status);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
            >
              {status === 'true' ? (
                <Typography>{t('userStatus.active')}</Typography>
              ) : (
                <Typography>{t('userStatus.inactive')}</Typography>
              )}
            </MenuItem>
          ))}
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.role)}
        anchorEl={subFilterPopover.role}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {[
            { value: 'ROLE_CUSTOMER', show: t('roles.ROLE_CUSTOMER') },
            { value: 'ROLE_ADMIN', show: t('roles.ROLE_ADMIN') },
            { value: 'ROLE_DEPARTMENTMANAGER', show: t('roles.ROLE_DEPARTMENTMANAGER') },
            { value: 'ROLE_AGENT', show: t('roles.ROLE_AGENT') },
          ].map((roleOption, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('appUserRole');
                setSearchBy('byRole');
                setSelectedFilter('appUserRole');
                setFilterName(roleOption.value);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
              onTouchStart={() => {
                setFilterBy('appUserRole');
                setSearchBy('byRole');
                setSelectedFilter('appUserRole');
                setFilterName(roleOption.value);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
            >
              {roleOption.show}
            </MenuItem>
          ))}
        </Card>
      </Popper>
      <Popper
        open={Boolean(subFilterPopover.department)}
        anchorEl={subFilterPopover.department}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {departmentsData.map((department) => (
            <MenuItem
              key={department.id}
              onClick={() => {
                setFilterBy('departmentName');
                setSearchBy('byDepartment');
                setSelectedFilter('department');
                setFilterName(department.name);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
              onTouchStart={() => {
                setFilterBy('departmentName');
                setSearchBy('byDepartment');
                setSelectedFilter('department');
                setFilterName(department.name);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
            >
              {department.name}
            </MenuItem>
          ))}
        </Card>
      </Popper>
    </>
  );
}
