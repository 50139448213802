import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { Divider, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

CheckboxInput.propTypes = {
  defaultValue: PropTypes.array,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
  setSelectedVals: PropTypes.func,
};

export default function CheckboxInput({
  defaultValue,
  options,
  setSelectedVals,
  ...other
}) {
  const [checkedValues, setCheckedValues] = useState([]);
  const { t } = useTranslation();

  const selectAll = useMemo(() => {
    if (Array.isArray(options) && Array.isArray(checkedValues)) {
      return options.length === checkedValues.length;
    }
    return false;
  }, [options, checkedValues]);

  const setValues = (values) => {
    setCheckedValues(values);
    setSelectedVals(values);
  };

  const handleSelect = (checkedValue) => {
    let newValues = [checkedValue];
    if (Array.isArray(checkedValues) && checkedValues.length > 0) {
      newValues = checkedValues.includes(checkedValue)
        ? checkedValues.filter((name) => name !== checkedValue)
        : [...checkedValues, checkedValue];
    }
    setValues(newValues);
  };

  const handleSelectAll = () => {
    if (checkedValues.length !== options.length) {
      setValues(options.map((option) => option.value));
    } else {
      setValues([]);
    }
  };

  useEffect(() => {
    // When defaultValue changes, update checkedValues
    if (defaultValue && Array.isArray(defaultValue)) {
      setValues(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <FormGroup {...other}>
      <FormControlLabel
        key="selectAll$"
        onChange={handleSelectAll}
        control={<Checkbox />}
        label={t('pages.formPage.selectAll')}
        checked={selectAll}
      />
      <Divider />
      {options?.map((option, index) => (
        <FormControlLabel
          key={`check${index}`}
          checked={
            Array.isArray(checkedValues) ? checkedValues.includes(option?.value) : false
          }
          onChange={() => handleSelect(option.value)}
          control={<Checkbox />}
          label={option.text}
          value={option.value}
        />
      ))}
    </FormGroup>
  );
}
