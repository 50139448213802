import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';

import Loader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';

import DepartmentRow from './DepartmentRow';
import MenuPopover from '../popovers/menuPopover';
import Scrollbar from '../../../components/scrollbar';
import { DEPARTMENT_TABLE_HEAD } from './tableHeaders';
import DepartmentFormModal from '../modal/DepartmentFormModal';

DepartmentTable.propTypes = {
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  departments: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  editDepartment: PropTypes.func,
  isLoading: PropTypes.bool,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};

export default function DepartmentTable({
  editDepartment,
  handleDelete,
  departments,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRequestSort,
  isNotFound,
  searchIsNotFound,
  filterName,
  order,
  isLoading,
  orderBy,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState({
    id: 0,
    name: '',
    description: '',
    image: '',
    createdBy: 0,
  });

  const [openModifyDepartment, setOpenModifyDepartment] = useState(false);

  const handleOpenMenu = useCallback((e) => {
    setOpen(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const { showConfirmModal } = useConfirmModal(
    t('pages.departmentPage.details.confirmDelete'),
    `${t('pages.departmentPage.details.areYouSure')} ${selected?.name}`,
    async () => handleDelete(selected?.id)
  );

  const handleDeleteClick = useCallback(async () => {
    showConfirmModal();
  }, [showConfirmModal]);

  return (
    <>
      <DepartmentFormModal
        key={`dep-${selected?.id}`}
        open={openModifyDepartment}
        setOpen={setOpenModifyDepartment}
        department={selected}
        apiCall={editDepartment}
      />
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={DEPARTMENT_TABLE_HEAD}
                rowCount={departments.length}
                numSelected={0}
                onRequestSort={handleRequestSort}
              />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13} sx={{ textAlign: 'center' }}>
                      <Loader size={150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {isNotFound ? (
                    <EmptyTable
                      filterName={filterName}
                      colSpan={6}
                      searchIsNotFound={searchIsNotFound}
                    />
                  ) : (
                    <TableBody>
                      {departments
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, idx) => (
                          <DepartmentRow
                            key={idx}
                            row={row}
                            handleOpenMenu={handleOpenMenu}
                            setSelected={setSelected}
                          />
                        ))}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[6, 12, 30]}
          component="div"
          labelRowsPerPage={t('filters.rowsPerPage')}
          count={departments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <MenuPopover
        open={open}
        setOpenModifyDepartment={setOpenModifyDepartment}
        handleCloseMenu={handleCloseMenu}
        handleDeleteClick={handleDeleteClick}
      />
    </>
  );
}
