import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';

import QuestionOption from './QuestionOption';
import Iconify from '../../../components/iconify';
import { useFormContext } from '../../../hooks/useForm';

export default function OptionSelector({ question, selectedInput, selectedType }) {
  const { addOption, getQuestionOptions } = useFormContext();
  const [optionsList, setOptionsList] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const options = getQuestionOptions(question.id);
    setOptionsList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionOptions]);

  const handleAddOption = () => {
    addOption(question.id, {});
  };

  return (
    <>
      {optionsList?.map((option) => (
        <QuestionOption
          key={`optionList${option.id}`}
          option={option}
          selectedInput={selectedInput}
          questionId={question.id}
          selectedType={selectedType}
        />
      ))}
      <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAddOption}>
        {t('pages.formPage.addOption')}
      </Button>
    </>
  );
}

OptionSelector.propTypes = {
  question: PropTypes.object,
  selectedType: PropTypes.string,
  selectedInput: PropTypes.string,
};
