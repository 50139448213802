import jwtDecode from 'jwt-decode';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

import { useConfirmModal } from './useModal';
import authService from '../services/auth.service';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const IdleLogout = ({ children }) => {
  let timer;
  const confirmedRef = useRef(false);
  const { t } = useTranslation();

  // this function sets the timer that logs out the user after 1 hour
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 3600000); // 3600000ms = 1hour. Duration until timeout
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 1 hour of inactivity resets.
  // However, if none of the event is triggered within 1 hour, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // logs out user by clearing out auth token in localStorage and redirecting url to /login page.
  const { showConfirmModal } = useConfirmModal(
    t('timeOut.sessionTimeOutHeader'),
    t('timeOut.sessionTimeOutBody'),
    () => {
      confirmedRef.current = true;
      resetTimer();
    }
  );
  const logoutAction = () => {
    if (window.location.pathname !== '/login') {
      showConfirmModal();
      setTimeout(() => {
        if (!confirmedRef.current) {
          const token = secureLocalStorage.getItem('user');
          const user = jwtDecode(token);
          authService
            .logout(user.id)
            .then(() => {
              window.location.pathname = '/login';
            })
            .catch(() => {});
        }
      }, 30000);
      confirmedRef.current = false;
    }
  };

  return children;
};

export default IdleLogout;
