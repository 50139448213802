import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import {
  Card,
  List,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import UserListPermission from 'src/components/ticketPermissions/UserListPermission';
import AddUsersPermissionsModal from 'src/components/ticketPermissions/modal/AddUsersPermissionsModal';

export default function TicketCreationPermissions({
  users,
  groups,
  selectedDepartment,
  setSelectedWriteIDs,
  setSelectedReadIDs,
}) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [permissionUsers, setPermissionUsers] = useState([]);
  const { currentUserId } = useAuth();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const handleShowAddUserPermissionModal = () => {
    showModal({
      title: t('pages.ticketDetails.addPermission'),
      Component: AddUsersPermissionsModal,
      props: {
        isModal: true,
        groupList: groups,
        permissionUsers,
        setPermissionUsers,
        hideModal,
        usersList: users,
        ticketUsersIds: permissionUsers.map((user) => user.id),
      },
    });
  };

  const handleReadWriteExtraction = (usersList) => {
    const readPermissionUserIds = usersList
      .filter(
        (user) =>
          user.permissions[0] === true &&
          user.permissions[1] === false &&
          user.default === false
      )
      .map((user) => user.id);

    // Extract IDs of writePermissionUsers
    const writePermissionUserIds = usersList
      .filter(
        (user) =>
          user.permissions[0] === true &&
          user.permissions[1] === true &&
          user.default === false
      )
      .map((user) => user.id);
    return { readPermissionUserIds, writePermissionUserIds };
  };
  useEffect(() => {
    const { readPermissionUserIds, writePermissionUserIds } =
      handleReadWriteExtraction(permissionUsers);
    setSelectedReadIDs(readPermissionUserIds);
    setSelectedWriteIDs(writePermissionUserIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionUsers]);

  useEffect(() => {
    const filteredUsers = users.filter(
      (user) =>
        user.appUserRole === 'ADMIN' ||
        user.departmentId === selectedDepartment ||
        user.id === currentUserId
    );

    // Add the new attributes to each filtered user
    const modifiedUsers = filteredUsers.map((user) => ({
      ...user,
      permissions: [true, true],
      default: true,
    }));

    setPermissionUsers(modifiedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedDepartment]);

  const sortedUserPermissions = useMemo(
    () => permissionUsers.sort((a, b) => a.default - b.default),
    [permissionUsers]
  );

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: '90%',
          alignSelf: 'center',
          mt: isSmallScreen && 10,
          fontSize: isSmallScreen && '0.7rem',
          ml: isSmallScreen && 1,
          whiteSpace: 'nowrap',
        }}
        onClick={() => setIsAccordionOpen((state) => !state)}
        size={isSmallScreen ? 'small' : 'medium'}
      >
        {t('buttons.managePermissions')}
      </Button>
      <Card sx={{ mt: '10px', width: '90%', alignSelf: 'center' }}>
        <Accordion expanded={isAccordionOpen}>
          <AccordionSummary sx={{ display: 'none' }}>{}</AccordionSummary>
          <AccordionDetails>
            <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
              {sortedUserPermissions.map((user, index) => (
                <UserListPermission
                  key={index}
                  user={user}
                  setPermissionUsers={setPermissionUsers}
                  isLocalUpdate
                />
              ))}
            </List>

            <Button
              variant="contained"
              size={isSmallScreen ? 'small' : 'medium'}
              sx={{
                mt: 3,
                fontSize: isSmallScreen ? '0.7rem' : '1rem',
              }}
              onClick={handleShowAddUserPermissionModal}
              style={{ width: '100%' }}
            >
              {t('pages.ticketDetails.addUserPermission')}
            </Button>
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
}

TicketCreationPermissions.propTypes = {
  users: PropTypes.array,
  groups: PropTypes.array,
  selectedDepartment: PropTypes.number,
  setSelectedReadIDs: PropTypes.func,
  setSelectedWriteIDs: PropTypes.func,
};
