import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Stack, TableRow, TableCell, Typography, IconButton } from '@mui/material';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

StatusTableRow.propTypes = {
  row: PropTypes.object,
  handleDetailsClick: PropTypes.func,
  setSelected: PropTypes.func,
  handleOpenMenu: PropTypes.func,
};

export default function StatusTableRow({
  row,
  handleDetailsClick,
  setSelected,
  handleOpenMenu,
}) {
  const {
    statusId,
    statusColor,
    name,
    color,
    correspondences,
    description,
    department,
    systemMappedStatus,
    departmentId,
  } = row;
  const { t } = useTranslation();
  const { statusDescription } = useMemo(() => {
    const statusDescription =
      statusId <= 5 ? t(`statusDescription.${systemMappedStatus}`) : description;
    return { statusDescription };
  }, [description, systemMappedStatus, statusId, t]);

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={() => handleDetailsClick(row)}>
      <TableCell component="th" scope="row">
        <Stack alignItems="center">
          <Label color={color}>{name}</Label>
        </Stack>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">{statusDescription}</Typography>
      </TableCell>
      <TableCell align="left">{t(`status.${systemMappedStatus}`)}</TableCell>
      <TableCell align="left">
        {statusId <= 5
          ? t('pages.statusPage.globalStatus')
          : departmentId === 0
          ? t('pages.statusPage.noDepartment')
          : department}
      </TableCell>
      <TableCell align="left">
        <Stack alignItems="center" justifyContent="center">
          <Box
            height={25}
            width={25}
            bgcolor={statusId <= 5 ? statusColor : color}
            borderRadius={1}
          />
        </Stack>
      </TableCell>
      <TableCell align="left">
        {statusId > 5 && <Stack alignItems="center">{correspondences.length}</Stack>}
      </TableCell>
      <TableCell align="center">
        {statusId > 5 && (
          <IconButton
            size="large"
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenMenu(event, row);
              setSelected(row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
