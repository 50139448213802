import Slider from 'react-slick';

import { Box, useTheme, useMediaQuery } from '@mui/material';

import './slick-theme.min.css';
import './slick.min.css';
import { marketingImageBox, marketingImageBoxMobile } from './styles';

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 750,
  autoplaySpeed: 5000,
  cssEase: 'linear',
  pauseOnHover: true,
};

export default function Carousel() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box style={{ width: '100%' }}>
      <Slider {...settings} style={{ borderRadius: '50px' }}>
        {[1, 2, 3].map((im, key) => (
          <Box
            key={key}
            sx={
              isSmallScreen
                ? {
                    ...marketingImageBoxMobile,
                    backgroundImage: `url('assets/images/covers/cover_${im}.jpg')`,
                  }
                : {
                    ...marketingImageBox,
                    backgroundImage: `url('assets/images/covers/cover_${im}.jpg')`,
                  }
            }
          />
        ))}
      </Slider>
    </Box>
  );
}
