import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Stack,
  Avatar,
  Tooltip,
  ListItem,
  Checkbox,
  Accordion,
  Typography,
  ListItemIcon,
  ListItemAvatar,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { listItemTicketPermissionModalStyle } from '../../styles';

GroupListItem.propTypes = {
  group: PropTypes.object,
  setSelectedGroups: PropTypes.func,
  selectedGroups: PropTypes.array,
  availableUserIds: PropTypes.object,
};

export default function GroupListItem({
  group,
  setSelectedGroups,
  selectedGroups,
  availableUserIds,
}) {
  const { t } = useTranslation();
  const { id, name, image, users } = group;
  const { isSmallScreen } = useResponsiveScreen();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleGroupSelect = (e, group) => {
    e.stopPropagation();
    setSelectedGroups((prev) => {
      const isSelected = prev.some((item) => item.id === group.id);
      if (isSelected) {
        return prev.filter((item) => item.id !== group.id);
      }

      return [...prev, group];
    });
  };

  return (
    <ListItem
      divider={!expanded}
      sx={{
        padding: 0,
        marginY: 1,
        borderRadius: '20px',
      }}
      alignItems="flex-start"
    >
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          borderRadius: '20px',
          width: '100%',
        }}
      >
        <AccordionSummary
          expandIcon={<Iconify icon="ooui:expand" width={isSmallScreen ? 10 : 15} />}
          aria-controls={`panel${id}-content`}
          id={`panel${id}-header`}
          sx={{
            transition: 'border-radius 0.4s ease, box-shadow 0.7s ease',
            borderRadius: expanded ? '20px 20px 0 0' : '20px',
            ...listItemTicketPermissionModalStyle(selectedGroups, group),
            width: '100%',
            padding: 0,
            '& .MuiAccordionSummary-expandIconWrapper': {
              marginRight: '10px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              px: 1,
            }}
          >
            <ListItemIcon sx={{ marginTop: 0 }}>
              <Checkbox
                edge="end"
                checked={selectedGroups.some((item) => item.id === group.id)}
                tabIndex={-1}
                onClick={(e) => handleGroupSelect(e, group)}
                disableRipple
              />
            </ListItemIcon>
            <ListItemAvatar sx={{ marginTop: 0 }}>
              <Avatar
                alt=""
                src={image}
                sx={{
                  width: isSmallScreen ? 30 : 40,
                  height: isSmallScreen ? 30 : 40,
                }}
              />
            </ListItemAvatar>
            <Typography component="span">{name}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 1,
            border:
              expanded &&
              selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)
                ? '2px solid #b4d5fe'
                : '2px solid transparent',
            borderRadius: expanded && '0 0 20px 20px',
            transition: expanded && 'border-color 0.4s ease, border-width 0.4s ease',
            transitionDelay: expanded ? '0.2s' : '0s',
          }}
        >
          {users.map((user) => (
            <Stack
              direction="row"
              key={user.id}
              sx={{ marginBottom: '5px', pl: 1, py: 1 }}
            >
              <ListItemAvatar>
                <Avatar
                  alt=""
                  src={user.avatar}
                  sx={{
                    width: isSmallScreen ? 30 : 40,
                    height: isSmallScreen ? 30 : 40,
                  }}
                />
              </ListItemAvatar>
              <Stack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                width="100%"
              >
                <Stack spacing={0.5}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.65rem' : '0.85rem' }}
                  >
                    <b>{user.username}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.65rem' : '0.85rem' }}
                  >
                    <b>{t('attributes.email')}: </b> {user.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
                    }}
                  >
                    <b>{t('tableHeads.user.role')}: </b>
                    {t(`roles.${user.appUserRole}`)}
                  </Typography>
                  {user.departmentName && user.departmentName !== 'none' && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
                      }}
                    >
                      <b>{t('attributes.department')}: </b>
                      {user.departmentName}
                    </Typography>
                  )}
                </Stack>
                <ListItemIcon>
                  {!availableUserIds.has(user.id) ? (
                    <Tooltip title={t('pages.ticketDetails.hasPermission')}>
                      <Iconify
                        icon="eva:checkmark-outline"
                        width={20}
                        color={(theme) => theme.palette.success.dark}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('pages.ticketDetails.DoesntHavePermission')}>
                      <Iconify
                        icon="eva:close-outline"
                        width={20}
                        color={(theme) => theme.palette.error.main}
                      />
                    </Tooltip>
                  )}
                </ListItemIcon>
              </Stack>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}
