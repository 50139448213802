import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from './TextInput';
import FileInput from './FileInput';
import RadioInput from './RadioInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';
import CheckboxInput from './CheckboxInput';
import DateTimePickerInput from './DateTimePickerInput';

const InputComponents = {
  TextInput,
  DateTimePickerInput,
  SelectInput,
  TextAreaInput,
  CheckboxInput,
  RadioInput,
  FileInput,
};

FormInput.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  onChange: PropTypes.func,
  setSelectedVals: PropTypes.func,
  setFormData: PropTypes.func,
  removeAttachements: PropTypes.func,
  setHasSelectedSection: PropTypes.func,
};

export default function FormInput({
  input,
  options,
  handleSelect,
  onChange,
  setSelectedVals,
  setFormData,
  removeAttachements,
  setHasSelectedSection,
}) {
  const { t } = useTranslation();
  const Input = InputComponents[input.type];

  const validOptions = Array.isArray(options) ? options : [];

  const updatedOptions = [
    { value: '', text: t('pages.formPage.selectAnOption') },
    ...validOptions,
  ];

  // Assign props dynamically depending on input type
  const props = useMemo(() => {
    switch (input.type) {
      case 'TextInput':
        return {
          type: 'text',
          defaultValue: input.inputValue || '',
          label: input.text,
          fullWidth: true,
          onChange,
        };
      case 'DateTimePickerInput':
        return {
          type: 'datetime',
          fullWidth: true,
          setSelectedVals,
          defaultValue: input.inputValue || '',
        };
      case 'SelectInput':
        return {
          type: 'select',
          options: updatedOptions,
          setSelectedInput: (value) => {
            if (value === '') {
              handleSelect(null);
            } else {
              handleSelect(value);
            }
          },
          fullWidth: true,
          defaultValue: input.inputValue || '',
          labelValue: input.text,
        };
      case 'TextAreaInput':
        return {
          type: 'textarea',
          fullWidth: true,
          defaultValue: input.inputValue || '',
          onChange,
        };
      case 'CheckboxInput':
        return {
          type: 'Checkbox',
          options,
          defaultValue: input.inputValue || [],
          setSelectedVals,
        };
      case 'RadioInput':
        return {
          type: 'radio',
          options,
          defaultValue: input.inputValue || '',
          setSelectedInput: onChange,
          isRequired: input.isRequired,
          setHasSelectedSection,
        };
      case 'FileInput':
        return {
          type: 'fileInput',
          setFilesList: setSelectedVals,
          setFormData,
          removeAttachements,
        };
      default:
        return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, options, t]);

  return <Input {...props} required={input.isRequired} />;
}
