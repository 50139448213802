import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabPanel } from '@mui/lab';
import { Box, Stack, Button, Typography } from '@mui/material';

import { useToast } from '../../../../hooks/useToast';
import { linkBoxStyle, TypographyStyle } from './style';

LinkFormSection.propTypes = {
  token: PropTypes.string,
  handleRemoveToken: PropTypes.func,
  handlePopoverClose: PropTypes.func,
};

function LinkFormSection({ token, handleRemoveToken, handlePopoverClose }) {
  const { t } = useTranslation();

  const { showToast, hideToast } = useToast();

  const link = useMemo(
    () => `${process.env.REACT_APP_DOMAIN_URL}/create-ticket?form=${token}`,
    [token]
  );

  const handleCopyLink = async () => {
    // Logic to copy the link to clipboard
    navigator.clipboard.writeText(link).then(() => {
      showToast({
        message: t('successMessages.successLinkCopied'),
        severity: 'success',
        props: { hideToast },
      });
    });
    handlePopoverClose();
  };

  return (
    <TabPanel value="0">
      {token ? (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.formPage.link')}
          </Typography>
          <Box sx={linkBoxStyle}>
            <Typography variant="body1" sx={TypographyStyle} title={link}>
              {link}
            </Typography>
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
            <Button
              onClick={() => handleRemoveToken()}
              variant="outlined"
              color="secondary"
            >
              {t('buttons.cancel')}
            </Button>
            <Button onClick={handleCopyLink} variant="contained" color="primary">
              {t('buttons.copyLink')}
            </Button>
          </Stack>
        </>
      ) : (
        <Typography variant="h6" gutterBottom mt={2}>
          {t('pages.formPage.linkNotAvailable')}
        </Typography>
      )}
    </TabPanel>
  );
}

export default LinkFormSection;
