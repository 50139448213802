import { Outlet } from 'react-router-dom';

// Pages
import CreateTicketPage from '../pages/Common/CreateTicketPage';
import FormUnavailable from '../pages/Common/FormUnavailable';
import FormSharableSubmitted from '../pages/Common/FormSharableSubmitted';

const CommonRoutes = {
  path: '*',
  element: <Outlet />,
  children: [
    {
      path: 'create-ticket',
      element: <CreateTicketPage />,
    },
    {
      path: 'form-sharable-submitted',
      element: <FormSharableSubmitted />,
    },
    {
      path: 'form-unavailable',
      element: <FormUnavailable />,
    },
  ],
};

export default CommonRoutes;
