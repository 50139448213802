import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Stack, TableRow, TableCell, Typography, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';

import DepartmentDetails from 'src/sections/department/modal/DepartmentDetailsModal';

import DataCell from './DataCell';
import { useToast } from '../../../hooks/useToast';
import { getUsersByDepartment } from '../../../services/user.service';
import { getTicketsByDepartment } from '../../../services/ticket.service';

DepartmentRow.propTypes = {
  row: PropTypes.object,
  handleOpenMenu: PropTypes.func,
  setSelected: PropTypes.func,
};

export default function DepartmentRow({ row, handleOpenMenu, setSelected }) {
  const { t } = useTranslation();
  const { id, name, description } = row;
  const [openDepartmentDetails, setOpenDepartmentDetails] = useState(false);
  const [users, setUsersData] = useState([]);
  const [tickets, setTicketsData] = useState([]);
  const { showToast } = useToast();

  // Fetch tickets by department
  const fetchTickets = async (departmentId) => {
    try {
      const res = await getTicketsByDepartment(departmentId);
      setTicketsData(res);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDepTickets'),
        severity: 'error',
      });
    }
  };

  // Fetch users by department
  const fetchUsers = async (departmentId) => {
    try {
      const res = await getUsersByDepartment(departmentId);
      setUsersData(res.filter((user) => user.appUserRole === 'ROLE_AGENT'));
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDepAgents'),
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchTickets(id);
    fetchUsers(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <DepartmentDetails
        open={openDepartmentDetails}
        setOpen={setOpenDepartmentDetails}
        department={row}
        tickets={tickets.length}
        users={users.length}
      />
      <TableRow
        hover
        sx={{ cursor: 'pointer' }}
        onClick={() => setOpenDepartmentDetails(true)}
      >
        <TableCell component="th" scope="row">
          <Stack alignItems="center">
            <Typography variant="body2">{id}</Typography>
          </Stack>
        </TableCell>
        <DataCell value={name} />
        <DataCell value={description} />
        <DataCell value={users.length} />
        <DataCell value={tickets.length} />

        <TableCell align="center">
          <IconButton
            size="large"
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenMenu(event, row);
              setSelected(row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
