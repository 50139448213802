import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Link, Stack, TextField, Typography } from '@mui/material';

import { EMAIL_REGEX } from '../../../utils/regex';
import { useToast } from '../../../hooks/useToast';
import { inputStyles, labelStyles } from './styles';
import { emailStyles, signInStyles } from '../login/styles';
import { generatePasswordUsingToken } from '../../../services/user.service';

ForgotPassword.propTypes = {
  toggleForgotPassword: PropTypes.func,
};
export default function ForgotPassword({ toggleForgotPassword }) {
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const [loading, setLoading] = useState(false);

  const HoverableText = styled('p')({
    cursor: 'pointer',
  });

  const handleReturnToLogin = () => {
    toggleForgotPassword();
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    generatePasswordUsingToken(data.email)
      .then(() => {
        showToast({
          message: t('successMessages.successSendingPasswordResetEmail'),
          severity: 'success',
          props: { hideToast },
        });
        handleReturnToLogin();
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.resetPasswordError'),
          severity: 'error',
          props: { hideToast },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography alignSelf="center" sx={signInStyles}>
        {t('pages.login.resetPassword')}
      </Typography>
      <Typography alignSelf="center" sx={emailStyles}>
        {t('pages.login.EnterEmailToReset')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <br />

          <TextField
            name="email"
            label={t('pages.usersPage.form.emailAdress')}
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message || ''}
            {...register('email', {
              required: t('formControl.enterEmail'),
              pattern: {
                value: EMAIL_REGEX,
                message: t('formControl.invalidEmail'),
              },
            })}
            InputLabelProps={{
              sx: labelStyles,
            }}
            InputProps={{
              sx: inputStyles,
            }}
          />
        </Stack>
        <Link variant="subtitle2">
          <HoverableText
            sx={{ width: 'fit-content' }}
            underline="hover"
            onClick={handleReturnToLogin}
          >
            {t('pages.login.backToLogin')}
          </HoverableText>
        </Link>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={loading}
          >
            {t('pages.login.resetPassword')}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
