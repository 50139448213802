export const LANGS = [
  {
    value: 'it',
    label: 'Italian',
    icon: '/assets/icons/ic_flag_it.webp',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'ar',
    label: 'Arabic',
    icon: '/assets/icons/ic_flag_tn.webp',
  },
];
