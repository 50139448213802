import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;
const url = `${API_URL}/ticket-permission`;

export async function addMultipleUsersPermissions({
  ticketId,
  userIds,
  isReadPermission,
  isWritePermission,
}) {
  const permissions = [isReadPermission, isWritePermission];
  const response = await axios.post(
    `${url}/${ticketId}/multiple`,
    { userIds, permissions },
    {
      headers: getAuthorizationHeader(),
    }
  );
  return response.data;
}

export async function deleteUserPermission({ ticketId, userId }) {
  const response = axios.delete(`${url}/${ticketId}/users/${userId}`, {
    headers: getAuthorizationHeader(),
  });
  return response.data;
}

export async function updateUserPermissions(
  ticketId,
  userId,
  isReadPermission,
  isWritePermission
) {
  if (isWritePermission) {
    isReadPermission = true;
  }
  const newPermissions = [isReadPermission, isWritePermission];
  const response = await axios.put(`${url}/${ticketId}/users/${userId}`, newPermissions, {
    headers: getAuthorizationHeader(),
  });
  return response.data;
}

export async function fetchUsersWithPermissions(ticketId) {
  const response = await axios.get(`${url}/${ticketId}/users`, {
    headers: getAuthorizationHeader(),
  });
  return response.data;
}

export async function fetchUserPermissions(ticketId, userId) {
  const response = await axios.get(`${url}/${ticketId}/users/${userId}`, {
    headers: getAuthorizationHeader(),
  });
  return response.data;
}

// tagged tickets
export const getTaggedTickets = async (userId) => {
  const result = await axios.get(`${url}/permission/user/${userId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
