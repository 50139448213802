import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

// @mui
import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import authService from 'src/services/auth.service';

import TicketsTable from './table/table';
// components
import { useAuth } from '../../hooks/useAuth';
import Iconify from '../../components/iconify';
import { useToast } from '../../hooks/useToast';
import ModifyTicket from './modals/ModifyTicket';
import { fetchStatuses } from '../../services/status.service';
import { getUsersByDepartment } from '../../services/user.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { fetchDepartments } from '../../services/department.service';
import { getComparator, applySortFilter } from '../../utils/filterData';
import DashboardToolbar from '../../components/ToolBars/DashboardToolbar';
// ----------------------------------------------------------------------

TicketTab.propTypes = {
  title: PropTypes.string,
};

export default function TicketTab({ title }) {
  const [ticketsData, setTicketsData] = useState([]);
  const [openModifyTicket, setOpenModifyTicket] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderBy, setOrderBy] = useState('id');
  const [filterTitle, setFilterTitle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentData, setDepartmentData] = useState([]);
  const [filterBy, setFilterBy] = useState('id');
  const [dataInCSV, setDataInCSV] = useState('');
  const [statusesList, setStatusesList] = useState([]);
  const [ticketsWithStatus, setTicketsWithStatus] = useState([]);
  const [currentPageTickets, setCurrentPageTickets] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [searchIsNotFound, setSearchIsNotFound] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [filterByData, setFilterByData] = useState([]);
  const [isDownloadDebouncing, setIsDownloadDebouncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id: currentUserId, departmentId } = useAuth();
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));
  const currentUserRole = authService.verifyUserRole();

  const fetchTickets = async (startDate, endDate, attributeName) => {
    const handleError = () => {
      showToast({
        message: t('errorMessages.couldntLoadTicket'),
        severity: 'error',
        props: { hideToast },
      });
    };
    setIsLoading(true);
    try {
      const res = await fetchTicketsByDate(startDate, endDate, attributeName);
      setTicketsData(res);
      setPage(0);
    } catch {
      handleError();
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };
  const getTicketsByDateRange = () => {
    fetchTickets({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
  };
  useSubscription(`/notification/sendNotif-${currentUserId}`, (message) => {
    const notif = JSON.parse(message.body);
    if (notif?.type === 'TICKET') {
      getTicketsByDateRange();
    }
  });

  // fetch departments
  const fetchDepartmentData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.couldntLoadDep'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };

  const handleDownloadClick = () => {
    if (!isDownloadDebouncing) {
      setIsDownloadDebouncing(true);
      setTimeout(() => {
        setIsDownloadDebouncing(false);
      }, 1500);
    }
  };

  useEffect(() => {
    getTicketsByDateRange();
    fetchDepartmentData();
    getStatuses(0, setStatusesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatuses = (depId, setState) => {
    if (depId === 0) {
      fetchStatuses().then((res) => {
        setState(res);
      });
    } else {
      fetchStatuses().then((res) => {
        setState(
          res.filter(
            (status) => status.departmentId === depId || status.departmentId === 0
          )
        );
      });
    }
  };

  useEffect(() => {
    // Function to update items with status
    const updateItemsWithStatus = () => {
      const updatedTickets = ticketsData.map((ticket) => {
        const statusObject = statusesList.find(
          (status) => status.statusId === ticket.status_id
        );
        const statusName = statusObject?.name || '';
        return { ...ticket, statusObject, status: statusName };
      });
      let filteredTickets = updatedTickets;

      const tabFilters = {
        [t('ticketTabs.allTickets')]: () => {
          switch (currentUserRole) {
            case 'ROLE_AGENT':
              filteredTickets = updatedTickets.filter(
                (ticket) =>
                  ticket.assignedTo === currentUserId ||
                  ticket.createdBy === currentUserId
              );
              break;
            case 'ROLE_CUSTOMER':
              filteredTickets = updatedTickets.filter(
                (ticket) => ticket.createdBy === currentUserId
              );
              break;
            case 'ROLE_DEPARTMENTMANAGER':
              filteredTickets = updatedTickets.filter(
                (ticket) => ticket.departmentId === departmentId
              );
              break;
            default:
              break;
          }
        },
        [t('ticketTabs.unassignedTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) =>
              ticket.statusObject?.systemMappedStatus === 'UNASSIGNED' &&
              ticket.departmentId === departmentId
          );
        },
        [t('ticketTabs.myTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) => ticket.createdBy === currentUserId
          );
        },
        [t('ticketTabs.activeTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) =>
              ticket.assignedTo === currentUserId &&
              ticket.statusObject?.systemMappedStatus === 'OPEN'
          );
        },
        [t('ticketTabs.onHoldTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) =>
              ticket.assignedTo === currentUserId &&
              ticket.statusObject?.systemMappedStatus === 'ONHOLD'
          );
        },
        [t('ticketTabs.othersTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) => ticket.createdBy !== currentUserId
          );
        },
        [t('ticketTabs.departmentTickets')]: () => {
          filteredTickets = updatedTickets.filter(
            (ticket) => ticket.departmentId === departmentId
          );
        },
      };
      const filterFunction = tabFilters[title];
      if (filterFunction) {
        filterFunction();
        setTicketsWithStatus(filteredTickets);
        setFilterByData(filteredTickets);
      }
    };
    updateItemsWithStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsData, statusesList, title, t]);

  const filteredTickets = useMemo(() => {
    const filtered = applySortFilter(
      filterByData,
      getComparator(order, orderBy),
      filterTitle,
      filterBy
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSearchIsNotFound(!filtered.length && !!filterTitle);
    setIsNotFound(!filtered.length);
    return filtered;
  }, [filterBy, filterTitle, order, orderBy, filterByData]);

  const generateCSVData = (tickets) => {
    // Gather all unique question texts
    const allQuestionTexts = new Set(
      tickets.flatMap((ticket) =>
        (ticket.data || []).flatMap((section) =>
          section.questions.map((question) => question.text)
        )
      )
    );

    // Extract ticket attributes (excluding 'data')
    const ticketAttributes = [];
    Object.keys(tickets[0] || []).forEach((key) => {
      if (key !== 'data') {
        ticketAttributes.push(key);
      }
    });

    // Create CSV data
    return Papa.unparse({
      fields: [...ticketAttributes, ...allQuestionTexts],
      data: tickets.map((ticket) => {
        const rowData = {};
        // Add ticket attributes
        Object.keys(ticket).forEach((key) => {
          if (key !== 'data') {
            rowData[key] = ticket[key];
          }
        });
        // Add questions and answers as columns
        allQuestionTexts.forEach((questionText) => {
          rowData[questionText] = ''; // Initialize with empty string
        });
        (ticket.data || []).forEach((section) => {
          section.questions.forEach((question) => {
            rowData[question.text] = question.inputValue;
          });
        });
        return rowData;
      }),
    });
  };

  useEffect(() => {
    const csvData = generateCSVData(filteredTickets);
    setDataInCSV(csvData);
  }, [filteredTickets]);

  useEffect(() => {
    setCurrentPageTickets(
      filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [filteredTickets, page, rowsPerPage]);

  const fetchDepAgents = (departmentId) => {
    getUsersByDepartment(departmentId)
      .then((res) => {
        setDepartmentUsers(
          res.filter((user) => user.appUserRole === 'ROLE_AGENT' && !user.archived)
        );
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.couldntLoadDepUsers'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };

  useEffect(() => {
    if (selectedItem) fetchDepAgents(selectedItem.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      {selectedItem && (
        <ModifyTicket
          open={openModifyTicket}
          setOpen={setOpenModifyTicket}
          row={selectedItem}
          fetchTicketData={getTicketsByDateRange}
          departmentData={departmentData}
          getStatuses={getStatuses}
          statusesList={statusesList}
          currentRole={currentUserRole}
          currentUserId={currentUserId}
          departmentUsers={departmentUsers}
        />
      )}

      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Grid>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => navigate('/dashboard/newTicket')}
              sx={{ marginRight: '1vh' }}
            >
              {t('buttons.newTicket')}
            </Button>

            <Button
              disabled={isDownloadDebouncing}
              href={`data:text/csv;charset=utf-8,${dataInCSV}`}
              download="tickets.csv"
              onClick={handleDownloadClick}
              variant="contained"
              startIcon={<Iconify icon="material-symbols:download" />}
            >
              {t('buttons.download')}
            </Button>
          </Grid>
        </Stack>
        <Box sx={{ display: 'flex' }}>
          <DashboardToolbar getTicketsByDate={fetchTickets} start={start} end={end} />
          <Button
            variant="outlined"
            sx={{ minWidth: 'unset', height: '37px', mt: '29px', ml: '-15px' }}
            onClick={() => setIsAccordionOpen((state) => !state)}
          >
            {t('buttons.openFilter')}
          </Button>
        </Box>
        <TicketsTable
          isAccordionOpen={isAccordionOpen}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          searchIsNotFound={searchIsNotFound}
          isNotFound={isNotFound}
          filterTitle={filterTitle}
          setFilterTitle={setFilterTitle}
          page={page}
          currentPageTickets={currentPageTickets}
          filteredTickets={filteredTickets}
          ticketsData={ticketsData}
          ticketsWithStatus={ticketsWithStatus}
          setPage={setPage}
          setFilterByData={setFilterByData}
          departmentData={departmentData}
          statusesList={statusesList}
          setFilterBy={setFilterBy}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpenModifyTicket={setOpenModifyTicket}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          getTicketsByDateRange={getTicketsByDateRange}
          isLoading={isLoading}
          generateCSVData={generateCSVData}
        />
      </Box>
    </>
  );
}
