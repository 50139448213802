export const avatarColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5'];

export const componentPropsStyle = {
  bgcolor: 'white',
  color: 'black',
  boxShadow: 15,
};

export const cartStyle = {
  cursor: 'pointer',
  mb: 2,
  boxShadow: 5,
};

export const avatarStyle = {
  width: 24,
  height: 24,
  fontSize: 12,
};

export const avatarDetailsStyle = {
  width: 32,
  height: 32,
  fontSize: 16,
  mr: 2,
};

export const paperStyle = {
  width: '100%',
  py: 5,
  my: 5,
  textAlign: 'center',
};

export const imageBoxStyle = {
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '5%',
  margin: '0 auto',
  mt: 1,
};

export const typographyTitleStyle = {
  fontWeight: 'bold',
  color: 'primary.main',
  mt: 2,
};

export const gridBoxStyle = {
  mx: 1,
  mb: 1,
  p: 2,
  backgroundColor: 'white',
  borderRadius: 2,
  boxShadow: 5,
  display: 'flex',
  alignItems: 'center',
};
