import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Dialog, Typography, IconButton } from '@mui/material';

import InfoRow from './InfoRow';
import Iconify from '../../../components/iconify';

DepartmentDetails.propTypes = {
  setOpen: PropTypes.func,
  department: PropTypes.object,
  open: PropTypes.bool,
  tickets: PropTypes.number,
  users: PropTypes.number,
};

export default function DepartmentDetails({ setOpen, open, department, tickets, users }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      anchor="right"
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '80vh',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: '2px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <IconButton onClick={handleClose}>
          <Iconify icon="flowbite:close-circle-solid" style={{ color: 'dc0404' }} />
        </IconButton>
      </Stack>
      <img
        src={department.image || '/assets/images/departments/defaultDepartment.png'}
        alt="Selected"
        style={{
          borderRadius: '10px',
          objectFit: 'cover',
          maxHeight: '180px',
          display: 'block',
          marginLeft: '18px',
          marginRight: '18px',
        }}
      />

      <Stack sx={{ px: 2, py: 3 }}>
        <Box backgroundColor="#F4F4F4" borderRadius={3} sx={{ px: 2, py: 2 }}>
          <Stack spacing={2}>
            <InfoRow
              icon="fluent-emoji-high-contrast:department-store"
              label={`${t('attributes.name')} :`}
              value={department.name}
            />
            <InfoRow icon="tabler:id" label="ID :" value={department.id} />
            <InfoRow
              icon="hugeicons:manager"
              label={`${t('pages.departmentPage.details.numAgents')} :`}
              value={users}
            />
            <InfoRow
              icon="emojione-monotone:admission-tickets"
              label={`${t('pages.departmentPage.details.numTickets')} :`}
              value={tickets}
            />

            <Stack>
              <Box display="flex" alignItems="center">
                <Iconify
                  icon="fluent:text-description-16-filled"
                  style={{ color: '#4f4f4f', marginRight: '15px' }}
                />
                <Typography>{t('attributes.description')} : </Typography>
              </Box>
              <Box pl={4.5}>
                <Typography variant="body1">{department.description}</Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Dialog>
  );
}
