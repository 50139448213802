export const phoneNumberSplit = (number) => {
  const defaultCountryCode = '+39';
  const regex = /\(([^)]+)\) (.+)/;
  const match = number?.match(regex);

  if (match) {
    return { partOne: match[1], partTwo: match[2] };
  }

  return { partOne: defaultCountryCode, partTwo: number };
};
