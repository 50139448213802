// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
export const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },

  {
    title: 'tickets',
    path: '/dashboard/tickets',
    icon: icon('ic_ticket'),
  },
  {
    title: 'departments',
    path: '/dashboard/departments',
    icon: icon('ic_department'),
  },
  {
    title: 'forms',
    path: '/dashboard/forms',
    icon: icon('ic_form'),
  },
  {
    title: 'Status',
    path: '/dashboard/Status',
    icon: icon('ic_flow'),
  },
  {
    title: 'ShareableForms',
    path: '/dashboard/shareableForms',
    icon: icon('ic_shareable_forms'),
  },
  {
    title: 'clientCredentials',
    path: '/dashboard/clientCredentials',
    icon: icon('ic_client_credentials'),
  },
  {
    title: 'MyGroups',
    path: '/dashboard/myGroups',
    icon: icon('my_groups'),
  },
];

export const agentNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'tickets',
    path: '/dashboard/tickets',
    icon: icon('ic_ticket'),
  },
  {
    title: 'calendar',
    path: '/dashboard/calendar',
    icon: icon('ic_calendar'),
  },
  {
    title: 'MyGroups',
    path: '/dashboard/myGroups',
    icon: icon('my_groups'),
  },
];

export const customerNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'tickets',
    path: '/dashboard/tickets',
    icon: icon('ic_ticket'),
  },
  {
    title: 'MyGroups',
    path: '/dashboard/myGroups',
    icon: icon('my_groups'),
  },
];

export const depManagerNavConfig = [
  {
    title: 'myDepartment',
    path: '/dashboard/app',
    icon: icon('ic_department'),
  },
  {
    title: 'agents',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'tickets',
    path: '/dashboard/tickets',
    icon: icon('ic_ticket'),
  },
  {
    title: 'forms',
    path: '/dashboard/forms',
    icon: icon('ic_form'),
  },
  {
    title: 'calendar',
    path: '/dashboard/calendar',
    icon: icon('ic_calendar'),
  },
  {
    title: 'Status',
    path: '/dashboard/Status',
    icon: icon('ic_flow'),
  },
  {
    title: 'ShareableForms',
    path: '/dashboard/shareableForms',
    icon: icon('ic_shareable_forms'),
  },
  {
    title: 'MyGroups',
    path: '/dashboard/myGroups',
    icon: icon('my_groups'),
  },
];

export const commonNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
];
