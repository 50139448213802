import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Checkbox, TableRow, TableCell, IconButton } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { convertLocalTime } from 'src/utils/convertTime';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

function ClientCredentialsRow({
  clientCredentials,
  isItemSelected,
  handleClick,
  handleOpenMenu,
  onRowClick,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();

  const handleCopyClientId = (clientId) => {
    navigator.clipboard.writeText(clientId).then(() => {
      showToast({
        message: t('pages.clientCredentialsPage.clientIdCopied'),
        severity: 'success',
        props: { hideToast },
      });
    });
  };

  const handleCopyClientSecret = (clientSecret) => {
    navigator.clipboard.writeText(clientSecret).then(() => {
      showToast({
        message: t('pages.clientCredentialsPage.clientSecretCopied'),
        severity: 'success',
        props: { hideToast },
      });
    });
  };

  return (
    <TableRow
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
      hover
      onClick={() => onRowClick(clientCredentials)}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleClick(event, clientCredentials.id)}
          sx={{
            '.MuiSvgIcon-root': {
              width: isSmallScreen ? 18 : 24,
              height: isSmallScreen ? 18 : 24,
            },
          }}
        />
      </TableCell>
      <TableCell
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
      >
        #{clientCredentials.id}
      </TableCell>
      <TableCell
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
      >
        {clientCredentials.clientName}
      </TableCell>
      <TableCell
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
        align={clientCredentials.clientDomain.length > 10 ? 'left' : 'center'}
      >
        {clientCredentials.clientDomain}
      </TableCell>
      <TableCell align="left">
        <Label
          color={(clientCredentials.enabled === false && 'error') || 'success'}
          sx={{
            fontSize: isSmallScreen && '0.65rem',
          }}
        >
          {clientCredentials.enabled === true
            ? t('userStatus.active')
            : t('userStatus.inactive')}
        </Label>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleCopyClientId(clientCredentials.clientId);
          }}
        >
          <Iconify
            icon="heroicons:clipboard-document-list"
            width={isSmallScreen ? 20 : 28}
            height={isSmallScreen ? 16 : 20}
            style={{ color: '#76B0F1' }}
          />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleCopyClientSecret(clientCredentials.clientSecret);
          }}
        >
          <Iconify
            icon="heroicons:clipboard-document-list"
            width={isSmallScreen ? 20 : 28}
            height={isSmallScreen ? 16 : 20}
            style={{ color: '#76B0F1' }}
          />
        </IconButton>
      </TableCell>
      <TableCell
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
        align="center"
      >
        {format(
          convertLocalTime(new Date(clientCredentials.createdAt)),
          'dd/MM/yyyy HH:mm:ss'
        )}
      </TableCell>
      <TableCell
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
        align="center"
      >
        {clientCredentials.updatedAt
          ? format(
              convertLocalTime(new Date(clientCredentials.updatedAt)),
              'dd/MM/yyyy HH:mm:ss'
            )
          : '-'}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          position: 'sticky',
          zIndex: '100',
          right: 0,
          backgroundColor: 'white',
        }}
      >
        <IconButton
          size={isSmallScreen ? 'small' : 'large'}
          color="inherit"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenMenu(event, clientCredentials);
          }}
        >
          <Iconify
            icon="eva:more-vertical-fill"
            sx={{
              width: 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ClientCredentialsRow.propTypes = {
  clientCredentials: PropTypes.object,
  isItemSelected: PropTypes.bool,
  handleClick: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default ClientCredentialsRow;
