import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function PopOverMenu(props) {
  const { options, setAnchorEl, anchorEl, selectedIndex, setSelectedIndex } = props;
  const open = Boolean(anchorEl);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
PopOverMenu.propTypes = {
  options: PropTypes.array,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
};
