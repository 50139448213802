import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

import { Box, Stack, Typography } from '@mui/material';

import Logo from 'src/components/logo/Logo';
import Iconify from 'src/components/iconify';
import LanguagePopover from 'src/components/LanguagePopover';

import ChangePassword from '../../components/changePassword/ChangePassword';

export default function FirstLogin() {
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const { userId, oldPassword } = state || {};

  const { t } = useTranslation();

  if (!state) {
    secureLocalStorage.removeItem('user');
    secureLocalStorage.removeItem('user_data');
  }

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System</title>
      </Helmet>

      <Stack bgcolor="#FFFFFF" height="100%" width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Logo sx={{ width: '36vh', height: '18vh', pl: '1' }} alignSelf="left" />
          <Box sx={{ pr: { md: 15, sm: 10, xs: 5 }, pt: 5 }}>
            <LanguagePopover />
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Stack>
            <Iconify
              style={{ color: '2b1fd1' }}
              icon="mdi:password-check-outline"
              alignSelf="center"
              sx={{
                width: { xs: '15vh', sm: '18vh', md: '20vh' },
                height: { xs: '6vh', sm: '7vh', md: '8vh' },
              }}
            />
            <Typography
              variant="h4"
              alignSelf="center"
              sx={{
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              }}
            >
              {t('pages.firstLogin.chooseNewPWD')}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              width={{ xs: '90%', sm: '75vh' }}
              variant="subtitle1"
              color="rgb(80 85 127)"
              alignSelf="center"
            >
              {t('pages.firstLogin.pleaseNewPWD')}
            </Typography>
            <Stack alignItems="center" justifyContent="center">
              <ChangePassword userId={userId} oldPass={oldPassword} token={token} />
            </Stack>
          </Stack>
          <Typography
            color="black"
            textAlign="center"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {t('pages.login.poweredBy')}
            <img
              src="/assets/icons/AlgorLeapLogo.svg"
              style={{
                maxWidth: '80%',
                height: 'auto',
                marginTop: '10px',
                width: '140px',
              }}
              alt="Algor"
            />
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
