export const nonSortableHeaders = [
  'options',
  'actions',
  'users',
  'tickets',
  'correspondances',
  'token',
  'iframe',
  'color',
];
