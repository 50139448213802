// @mui
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Link,
  Card,
  Stack,
  Button,
  Avatar,
  Divider,
  Tooltip,
  Skeleton,
  Typography,
  CardHeader,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import stringAvatar from 'src/utils/stringToAvatar';

import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { encrypt } from '../../utils/hashCode';
import { fToNow } from '../../utils/formatTime';
import { convertLocalTime } from '../../utils/convertTime';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  onViewAllClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default function AppNewsUpdate({
  title,
  subheader,
  list,
  onViewAllClick,
  isLoading,
  ...other
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <Card {...other} sx={{ height: '100%' }}>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          '& .MuiCardHeader-title': { fontSize: isSmallScreen && '0.8rem' },
          '& .MuiCardHeader-subheader': { fontSize: isSmallScreen && '0.7rem' },
        }}
      />
      <Stack height="90%" justifyContent="space-between">
        <Scrollbar>
          <Stack spacing={1} sx={{ p: 1, pr: 0 }}>
            {isLoading ? (
              [...Array(3)].map((_, index) => (
                <Stack direction="row" alignItems="center" spacing={2} key={index}>
                  <Skeleton variant="circular" width="45px" height="45px" />
                  <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                    <Skeleton variant="text" width="100px" />
                    <Stack direction="row" spacing={1}>
                      <Skeleton variant="text" width="70px" />
                      <Skeleton variant="text" width="90px" />
                    </Stack>
                    <Skeleton variant="text" width="200px" />
                  </Box>
                  <Stack
                    sx={{
                      pr: 3,
                      flexShrink: 0,
                      color: 'text.secondary',
                      alignItems: 'end',
                    }}
                  >
                    <Skeleton variant="text" width="100px" />
                    <Skeleton variant="text" width="75px" />
                  </Stack>
                </Stack>
              ))
            ) : list.length === 0 ? (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    fontSize: isSmallScreen && '0.8rem',
                  }}
                  paragraph
                >
                  {t('filters.notFound')}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {t('dashboard.noUpdates')}
                </Typography>
              </Box>
            ) : (
              list.map((news) => <NewsItem key={news.id} news={news} />)
            )}
          </Stack>
        </Scrollbar>
        <Divider />
        <Box sx={{ p: isSmallScreen ? 1 : 2, textAlign: 'right' }}>
          <Button
            size="small"
            color="inherit"
            onClick={onViewAllClick}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
            sx={{ fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('buttons.viewAll')}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    postedAt: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    avatar: PropTypes.string,
    createdBy: PropTypes.string,
    department: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { id, createdBy, avatar, department, title, description, postedAt } = news;
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar
        src={avatar}
        {...stringAvatar(createdBy)}
        sx={{
          width: isSmallScreen ? 30 : 45,
          height: isSmallScreen ? 30 : 45,
          ...stringAvatar(createdBy).sx,
        }}
      />

      <Box sx={{ minWidth: isSmallScreen ? 140 : 240, flexGrow: 1 }}>
        <Tooltip
          title={
            <>
              {`ID: ${id}`} <br />
              {`${t('attributes.title')}: ${title}`}
            </>
          }
        >
          <Link
            href={`/tickets/${encrypt(id)}`}
            color="inherit"
            variant="subtitle2"
            underline="hover"
          >
            <Typography
              noWrap
              sx={{
                fontSize: isSmallScreen ? '0.8rem' : '1rem',
              }}
            >
              <b>{title}</b>
            </Typography>
          </Link>
        </Tooltip>
        <Typography
          variant="body2"
          color="text.secondary"
          noWrap
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          <b>{t('dashboard.openedBy')}</b> {createdBy}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', fontSize: isSmallScreen && '0.7rem' }}
          noWrap
        >
          {description}
        </Typography>
      </Box>

      <Stack sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        <Typography
          textAlign="right"
          variant="body2"
          sx={{ color: 'text.secondary', fontSize: isSmallScreen && '0.7rem' }}
          noWrap
        >
          {department}
        </Typography>
        <Typography
          textAlign="right"
          variant="caption"
          sx={{
            fontSize: isSmallScreen && '0.6rem',
          }}
        >
          {fToNow(Date.parse(convertLocalTime(postedAt)))}
        </Typography>
      </Stack>
    </Stack>
  );
}
