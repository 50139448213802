import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Link, Stack, TextField, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';

import { EMAIL_REGEX } from '../../../utils/regex';
import { useToast } from '../../../hooks/useToast';
import { inputStyles, labelStyles } from './styles';
import { emailStyles, signInStyles } from '../login/styles';
import { generatePasswordUsingToken } from '../../../services/user.service';

ForgotPassword.propTypes = {
  toggleForgotPassword: PropTypes.func,
};
export default function ForgotPassword({ toggleForgotPassword }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [loading, setLoading] = useState(false);

  const HoverableText = styled('p')({
    cursor: 'pointer',
  });

  const handleReturnToLogin = () => {
    toggleForgotPassword();
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    genericApiToaster(
      generatePasswordUsingToken,
      data.email,
      t('successMessages.successSendingPasswordResetEmail'),
      t('errorMessages.resetPasswordError'),
      showToast,
      hideToast,
      t,
      handleReturnToLogin,
      undefined,
      setLoading(false)
    );
  };

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography
          alignSelf="center"
          sx={{
            ...signInStyles,
            mt: isSmallScreen && 5,
            fontSize: isSmallScreen ? '1.5rem' : '2rem',
          }}
        >
          {t('pages.login.resetPassword')}
        </Typography>
        <Typography
          alignSelf="center"
          sx={{ ...emailStyles, fontSize: isSmallScreen ? '0.7rem' : '0.9rem' }}
        >
          {t('pages.login.EnterEmailToReset')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} sx={{ mt: 3, p: isSmallScreen && 3 }}>
            <TextField
              name="email"
              label={t('pages.usersPage.form.emailAdress')}
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email?.message || ''}
              {...register('email', {
                required: t('formControl.enterEmail'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('formControl.invalidEmail'),
                },
              })}
              InputLabelProps={{
                sx: labelStyles,
              }}
              InputProps={{
                sx: inputStyles,
              }}
              size={isSmallScreen ? 'small' : 'medium'}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: isSmallScreen ? '0.8rem' : '1rem',
                },
                '& .MuiFormLabel-root': {
                  fontSize: isSmallScreen ? '0.8rem' : '1rem',
                },
                '& .MuiFormHelperText-root': {
                  fontSize: isSmallScreen ? '0.675rem' : '0.8rem',
                },
              }}
            />
            <Link
              variant="subtitle2"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              <HoverableText
                sx={{ width: 'fit-content' }}
                underline="hover"
                onClick={handleReturnToLogin}
              >
                {t('pages.login.backToLogin')}
              </HoverableText>
            </Link>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={loading}
              sx={{ fontSize: isSmallScreen && '0.7rem' }}
              size={isSmallScreen ? 'small' : 'medium'}
            >
              {t('pages.login.resetPassword')}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}
