import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Avatar, Divider, Typography, SwipeableDrawer } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Scrollbar from 'src/components/scrollbar/Scrollbar';

import {
  avatarColors,
  gridBoxStyle,
  imageBoxStyle,
  avatarDetailsStyle,
  typographyTitleStyle,
} from '../style';

function GroupUsersDetails({ open, onClose, onOpen, group }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: isSmallScreen ? '75%' : 400,
          padding: 3,
          backgroundColor: '#f4f6f8',
        },
      }}
    >
      <Box sx={{ textAlign: 'center', mb: isSmallScreen ? 2 : 3 }}>
        <Box
          component="img"
          src={group.image || '/assets/images/group-users/group-users.jpg'}
          alt={group.name}
          sx={imageBoxStyle}
        />
        <Typography
          variant="h6"
          sx={{ ...typographyTitleStyle, fontSize: isSmallScreen && '0.8rem' }}
        >
          {t('pages.groupUsersPage.details.groupName')}: {group.name}
        </Typography>
      </Box>

      <Divider sx={{ mb: isSmallScreen ? 2 : 3 }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.groupUsersPage.details.numberOfUsers')}
            </Typography>
            <Typography
              variant="h6"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {group.users.length}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: isSmallScreen && '0.8rem',
              mb: 2,
            }}
          >
            {t('pages.groupUsersPage.details.listOfUsers')} :
          </Typography>
          <Scrollbar sx={{ maxHeight: 450 }}>
            <Grid container spacing={1}>
              {group.users.map((user, index) => (
                <Grid item xs={12} sm={12} key={user.id}>
                  <Box sx={gridBoxStyle}>
                    <Avatar
                      src={user.avatar || ''}
                      sx={{
                        ...avatarDetailsStyle,
                        backgroundColor: user.avatar
                          ? 'transparent'
                          : avatarColors[index % avatarColors.length],
                      }}
                    >
                      {!user.image && user.username.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          fontSize: isSmallScreen && '0.65rem',
                        }}
                      >
                        {user.username}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontSize: isSmallScreen && '0.65rem',
                        }}
                      >
                        {user.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Scrollbar>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

GroupUsersDetails.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  group: PropTypes.object,
};

export default GroupUsersDetails;
