import axios from 'axios';
import jwtDecode from 'jwt-decode';
import secureLocalStorage from 'react-secure-storage';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/users/`;

// user login
const login = async ({ email, password }) =>
  axios.post(`${url}login`, { email, password }).then((res) => {
    if (typeof res.data !== 'undefined') {
      const start = new Date();
      const end = new Date();
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
      start.setDate(start.getDate() - ((start.getDay() + 6) % 7));
      secureLocalStorage.setItem('user', res.data.authenticationToken);
      secureLocalStorage.setItem('ticketStart', start);
      secureLocalStorage.setItem('ticketEnd', end);
      secureLocalStorage.setItem('sortby', 'thisWeek');
    }
    return res.data;
  });

// user logout
const logout = (id) => {
  secureLocalStorage.removeItem('user_data');
  secureLocalStorage.removeItem('ticketStart');
  secureLocalStorage.removeItem('ticketEnd');
  secureLocalStorage.removeItem('sortby');

  const res = axios.post(`${url}logout/${id}`, {}, { headers: getAuthorizationHeader() });
  secureLocalStorage.removeItem('user');
  return res;
};
const verifyUserRole = () => {
  const token = secureLocalStorage.getItem('user');
  if (token) {
    const user = jwtDecode(token);
    return user.auth.authority;
  }
  return '';
};

const getUser = () => {
  const token = localStorage.getItem('user');
  if (token) {
    const user = jwtDecode(token);
    return user;
  }
  return '';
};
const authVerify = () => {
  const user = secureLocalStorage.getItem('user');
  if (user) {
    if (jwtDecode(user).exp * 1000 < Date.now()) {
      secureLocalStorage.clear();
      return false;
    }
    return true;
  }
  return false;
};
const authService = {
  login,
  logout,
  authVerify,
  verifyUserRole,
  getUser,
};
export default authService;
