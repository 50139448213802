import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';
import Papa from 'papaparse';
import secureLocalStorage from 'react-secure-storage';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { format } from 'date-fns';
import { convertLocalTime } from '../../utils/convertTime';
import AdvancedFilter from './AdvancedFilter';
import { useToast } from '../../hooks/useToast';
// components
import Label from '../label';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import ModifyTicket from '../ticket/ModifyTicket';

// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { useConfirmModal } from '../../hooks/useModal';
import { getUsersByDepartment } from '../../services/user.service';
import { getComparator, applySortFilter } from '../../utils/filterData';
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';
import { deleteTicket, updateTicket, fetchTicketsByDate } from '../../services/ticket.service';
import { fetchStatuses } from '../../services/status.service';
import { fetchDepartments } from '../../services/department.service';
import { useAuth } from '../../hooks/useAuth';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', name: 'id', label: 'ticket.id', alignRight: false },
  { id: 'title', name: 'title', label: 'ticket.title', alignRight: false },
  { id: 'department', name: 'department', label: 'ticket.department', alignRight: false },
  { id: 'priority', name: 'priority', label: 'ticket.priority', alignRight: false },
  { id: 'status', name: 'status', label: 'ticket.status', alignRight: false },
  { id: 'category', name: 'category', label: 'ticket.category', alignRight: false },
  { id: 'createdBy', name: 'customerName', label: 'ticket.createdBy', alignRight: false },
  { id: 'createdAt', name: 'createdAt', label: 'ticket.createdAt', alignRight: false },
  { id: 'updatedAt', name: 'updatedAt', label: 'ticket.updatedAt', alignCenter: true },
  { id: 'agentName', name: 'agentName', label: 'ticket.agent', alignRight: false },
  { id: 'formName', name: 'formName', label: 'ticket.formName', alignRight: false },
  { id: 'options', label: 'ticket.options' },
];

TicketsPage.propTypes = {
  title: PropTypes.string,
};
export default function TicketsPage({ title }) {
  const { id: currentUserId, departmentId, role } = useAuth();
  const [ticketsData, setTicketsData] = useState([]);
  const { showToast, hideToast } = useToast();
  const [openModifyTicket, setOpenModifyTicket] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [filterPopover, setFilterPopover] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('title');
  const [page, setPage] = useState(0);
  const [subFilterPopover, setSubFilterPopover] = useState({ department: null, status: null, priority: null });
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderBy, setOrderBy] = useState('title');
  const [filterTitle, setFilterTitle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentData, setDepartmentData] = useState([]);
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState('title');
  const [searchBy, setSearchBy] = useState('byTitle');
  const [showDelete, setShowDelete] = useState(true);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const [dataInCSV, setDataInCSV] = useState('');
  const [statusesList, setStatusesList] = useState([]);
  const [ticketsWithStatus, setTicketsWithStatus] = useState([]);
  const [currentPageTickets, setCurrentPageTickets] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [filterByData, setFilterByData] = useState([]);
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const handleOpenMenu = (event, row) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedItem(row);
    if (role.authority === 'ROLE_AGENT' && row.createdBy !== currentUserId) setShowDelete(false);
    else setShowDelete(true);
  };
  const handleCloseMenu = () => {
    setPopoverAnchorEl(null);
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };
  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'priority') {
      setSubFilterPopover({ priority: event.currentTarget });
    }
  };
  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };
  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmDelete'),
    `${t('pages.ticketDetails.areYouSure')} ${selectedItem?.title}`,
    async () => RemoveTicket(selectedItem.id)
  );

  // Adds error handling and toast on success/error
  const genericApiToaster = (apiCall, args, successMessage, errorMessage, successCallback, errorCallback) => {
    const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

    apiCall(...apiArguments)
      .then((res) => {
        showToast({ message: successMessage, severity: 'success', props: { hideToast } });
        if (successCallback) {
          successCallback(res);
        }
      })
      .catch((e) => {
        showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
        if (errorCallback) {
          errorCallback(e);
        }
      });
  };
  const fetchTickets = (startDate, endDate, attributeName) => {
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    const handleError = () => {
      showToast({ message: t('errorMessages.couldntLoadTicket'), severity: 'error', props: { hideToast } });
    };

    fetchTicketsByDate(startDate, endDate, attributeName)
      .then((res) => {
        let filteredTickets = res;

        switch (title) {
          case t('ticketTabs.allTickets'):
            switch (role.authority) {
              case 'ROLE_ADMIN':
                break;
              case 'ROLE_AGENT':
                filteredTickets = res.filter(
                  (ticket) => ticket.assignedTo === currentUserId || ticket.createdBy === currentUserId
                );
                break;
              case 'ROLE_CUSTOMER':
                filteredTickets = res.filter((ticket) => ticket.createdBy === currentUserId);
                break;
              case 'ROLE_DEPARTMENTMANAGER':
                filteredTickets = res.filter((ticket) => ticket.departmentId === departmentId);
                break;
              default:
                break;
            }
            break;
          case t('ticketTabs.unassignedTickets'):
            filteredTickets = res.filter(
              (ticket) => ticket.status === 'UNASSIGNED' && ticket.departmentId === departmentId
            );
            break;
          case t('ticketTabs.myTickets'):
            filteredTickets = res.filter((ticket) => ticket.createdBy === currentUserId);
            break;
          case t('ticketTabs.othersTickets'):
            filteredTickets = res.filter((ticket) => ticket.createdBy !== currentUserId);
            break;
          case t('ticketTabs.departmentTickets'):
            filteredTickets = res.filter((ticket) => departmentId === ticket.departmentId);
            break;
          default:
            break;
        }
        setTicketsData(filteredTickets);
        setPage(0);
      })
      .catch(handleError);
  };

  const getTicketsByDateRange = () => {
    fetchTickets({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
  };
  useSubscription(`/notification/sendNotif-${currentUserId}`, (message) => {
    const notif = JSON.parse(message.body);
    if (notif?.type === 'TICKET') {
      getTicketsByDateRange();
    }
  });

  // fetch departments
  const fetchDepartmentData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
      });
  };
  useEffect(() => {
    getTicketsByDateRange();
    fetchDepartmentData();
    getStatuses(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => {
        setStatusesList(res);
      });
    } else {
      fetchStatuses().then((res) => {
        setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0));
      });
    }
  };
  // edit a ticket

  const modifyTicket = async (newTicketData) => {
    genericApiToaster(
      updateTicket,
      newTicketData,
      t('successMessages.successTicketUpdate'),
      t('errorMessages.errorUpdatingTicket'),
      (res) => {
        getTicketsByDateRange();
        setSelectedItem(res);
      }
    );
  };

  // delete a ticket
  const RemoveTicket = async (id) => {
    genericApiToaster(
      deleteTicket,
      id,
      t('successMessages.successTicketRemove'),
      t('errorMessages.errorDeletingTicket'),
      () => {
        getTicketsByDateRange();
        setSelected([]);
      },
      () => {}
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleClickRow = (row) => {
    setSelectedItem(row);
    navigate(`/tickets/${row.id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByTitle = (event) => {
    setPage(0);
    setFilterTitle(event.target.value);
  };

  useEffect(() => {
    // Function to update items with status
    const updateItemsWithStatus = () => {
      const updatedTickets = ticketsData.map((ticket) => {
        const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
        const statusName = statusObject ? statusObject.name : '';
        return { ...ticket, statusObject, status: statusName };
      });
      setTicketsWithStatus(updatedTickets);
      setFilterByData(updatedTickets);
    };
    updateItemsWithStatus();
  }, [ticketsData, statusesList]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ticketsData.length) : 0;

  const filteredTickets = useMemo(() => {
    const filtered = applySortFilter(filterByData, getComparator(order, orderBy), filterTitle, filterBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsNotFound(!filtered.length && !!filterTitle);
    return filtered;
  }, [filterBy, filterTitle, order, orderBy, filterByData]);

  useEffect(() => {
    // Gather all unique question texts
    const allQuestionTexts = new Set(
      filteredTickets.flatMap((ticket) =>
        (ticket.data || []).flatMap((section) => section.questions.map((question) => question.text))
      )
    );
    const ticketAttributes = [];
    Object.keys(filteredTickets[0] || [])?.forEach((key) => {
      if (key !== 'data') {
        ticketAttributes.push(key);
      }
    });

    // Create CSV data
    const csvData = Papa.unparse({
      fields: [...ticketAttributes, ...allQuestionTexts],
      data: filteredTickets.map((ticket) => {
        const rowData = {};
        // Add ticket attributes
        Object.keys(ticket).forEach((key) => {
          if (key !== 'data') {
            rowData[key] = ticket[key];
          }
        });
        // Add questions and answers as columns
        allQuestionTexts.forEach((questionText) => {
          rowData[questionText] = ''; // Initialize with empty string
        });
        (ticket.data || []).forEach((section) => {
          section.questions.forEach((question) => {
            rowData[question.text] = question.inputValue;
          });
        });
        return rowData;
      }),
    });

    setDataInCSV(csvData);
  }, [filteredTickets]);

  useEffect(() => {
    setCurrentPageTickets(filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [filteredTickets, page, rowsPerPage]);

  useEffect(() => {
    const newSelectdsId = currentPageTickets.map((n) => n.id);
    if (newSelectdsId.every((id) => selected.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageTickets.map((n) => n.id);
    if (newSelecteds.every((id) => selected.includes(id))) {
      setSelected(selected.filter((id) => !newSelecteds.includes(id)));
      return;
    }
    setSelected([...new Set([...selected, ...newSelecteds])]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    const newSelectds = currentPageTickets.map((n) => n.id);
    if (newSelectds.every((id) => selected.includes(id))) {
      // if all items of this page are selected then the checkbox state is set to indeterminate ( checked but with - icon )
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPageTickets]);

  const handleRemoveSelected = () => {
    setSelected([]);
  };

  const fetchDepAgents = (departmentId) => {
    getUsersByDepartment(departmentId)
      .then((res) => {
        setDepartmentUsers(res.filter((user) => user.appUserRole === 'ROLE_AGENT'));
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDepUsers'), severity: 'error', props: { hideToast } });
      });
  };

  useEffect(() => {
    if (selectedItem) fetchDepAgents(selectedItem.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      {selectedItem ? (
        <ModifyTicket
          open={openModifyTicket}
          setOpen={setOpenModifyTicket}
          row={selectedItem}
          modifyTicket={modifyTicket}
          departmentData={departmentData}
          getStatuses={getStatuses}
          statusesList={statusesList}
          currentRole={role.authority}
          currentUserId={currentUserId}
          departmentUsers={departmentUsers}
        />
      ) : null}

      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Grid>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => navigate('/dashboard/newTicket')}
              sx={{ marginRight: '1vh' }}
            >
              {t('buttons.newTicket')}
            </Button>
            <a href={`data:text/csv;charset=utf-8,${dataInCSV}`} download="tickets.csv">
              <Button variant="contained" startIcon={<Iconify icon="material-symbols:download" />}>
                {t('buttons.download')}
              </Button>
            </a>
          </Grid>
        </Stack>
        <Box sx={{ display: 'flex' }}>
          <DashboardToolbar getTicketsByDate={fetchTickets} start={start} end={end} />
          <Button
            variant="outlined"
            sx={{ minWidth: 'unset', height: '37px', mt: '29px', ml: '-15px' }}
            onClick={() => setIsAccordionOpen((state) => !state)}
          >
            {t('buttons.openFilter')}
          </Button>
        </Box>
        <Card sx={{ mt: '10px' }}>
          <Accordion expanded={isAccordionOpen}>
            <AccordionSummary sx={{ display: 'none' }}>{}</AccordionSummary>
            <AccordionDetails>
              <AdvancedFilter ticketsWithStatus={ticketsWithStatus} setFilterByData={setFilterByData} />
            </AccordionDetails>
          </Accordion>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterTitle}
            filterBy={searchBy}
            deleteFunc={RemoveTicket}
            selected={selected}
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            onFilterName={handleFilterByTitle}
            handleRemoveSelected={handleRemoveSelected}
            model="Ticket"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredTickets.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  indeterminate={indeterminateCheckbox}
                  onSelectAllClick={handleSelectAllClick}
                  showCheckBox={role.authority !== 'ROLE_AGENT'}
                />
                <TableBody>
                  {currentPageTickets.map((row) => {
                    const {
                      id,
                      title,
                      category,
                      priority,
                      createdAt,
                      updatedAt,
                      department,
                      customerName,
                      statusObject,
                      agentName,
                      formName,
                      createdBy,
                      assignedTo,
                    } = row;
                    let statusName = '';
                    if (statusObject) {
                      statusName = statusObject.statusId < 6 ? t(`status.${statusObject.name}`) : statusObject.name;
                    }
                    const selectedTicket = selected.indexOf(id) !== -1;
                    const createdAtStr = format(convertLocalTime(new Date(createdAt)), 'dd/MM/yyyy HH:mm:ss');
                    const updatedAtStr = updatedAt
                      ? format(convertLocalTime(new Date(updatedAt)), 'dd/MM/yyyy HH:mm:ss')
                      : '';
                    return (
                      <TableRow
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClickRow(row)}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedTicket}
                      >
                        {role.authority !== 'ROLE_AGENT' && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              name="checkBox"
                              checked={selectedTicket}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClick(event, id);
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell align="left">#{id}</TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{department}</TableCell>
                        <TableCell align="left">{t(`priority.${priority}`)}</TableCell>
                        <TableCell align="left">
                          <Label color={statusObject?.color || 'info'}>{statusName}</Label>
                        </TableCell>
                        <TableCell align="left">{category}</TableCell>
                        <TableCell align="left">{customerName}</TableCell>
                        <TableCell align="left">{createdAtStr}</TableCell>
                        <TableCell align="center">{updatedAtStr}</TableCell>
                        <TableCell align="left">{agentName || t('ticket.unknown')}</TableCell>
                        <TableCell align="center">{formName || '-'}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            disabled={
                              role.authority === 'ROLE_AGENT' &&
                              currentUserId !== assignedTo &&
                              currentUserId !== createdBy
                            }
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenMenu(event, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ color: (theme) => theme.palette.grey[500] }}>
                            {t('filters.notFound')}
                          </Typography>

                          <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[500] }}>
                            {t('filters.noResultsFound', { filterName: filterTitle })}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage={t('filters.rowsPerPage')}
            count={filteredTickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
      {/* main filter popover */}
      <Popper open={Boolean(filterPopover)} anchorEl={filterPopover} placement="left-start" onClose={handleCloseFilter}>
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {TABLE_HEAD.filter(({ id }) => id !== 'options').map(({ id, name }) =>
            name === 'status' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'status'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2"> {t('filters.byStatus')}</Typography>
                </Stack>
              </MenuItem>
            ) : name === 'department' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'department'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2">{t('filters.byDepartment')}</Typography>
                </Stack>
              </MenuItem>
            ) : name === 'priority' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'priority'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'priority')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2"> {t('filters.byPriority')}</Typography>
                </Stack>
              </MenuItem>
            ) : (
              <MenuItem
                key={id}
                selected={selectedFilter === name}
                onClick={() => {
                  setFilterTitle('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                }}
              >
                <Typography variant="body2">
                  {t(`filters.by${name && name.charAt(0).toUpperCase() + name.slice(1)}`)}
                </Typography>
              </MenuItem>
            )
          )}
        </Card>
      </Popper>
      {/* sub filter popover */}
      <Popper
        open={Boolean(subFilterPopover.department)}
        anchorEl={subFilterPopover.department}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {departmentData.map((department) => (
            <MenuItem
              key={department.id}
              onClick={() => {
                setFilterBy('department');
                setSearchBy('byDepartment');
                setSelectedFilter('department');
                setFilterTitle(department.name);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {department.name}
            </MenuItem>
          ))}
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.status)}
        anchorEl={subFilterPopover.status}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {statusesList.map((status, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('status');
                setSearchBy('byStatus');
                setSelectedFilter('status');
                setFilterTitle(status.name);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {status.statusId <= 5 ? t(`status.${status.name}`) : status.name}
            </MenuItem>
          ))}
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.priority)}
        anchorEl={subFilterPopover.priority}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {[
            { value: 'LOW', display: t('priority.LOW') },
            { value: 'MEDIUM', display: t('priority.MEDIUM') },
            { value: 'HIGH', display: t('priority.HIGH') },
          ].map((priority, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('priority');
                setSearchBy('byPriority');
                setSelectedFilter('priority');
                setFilterTitle(priority.value);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {priority.display}
            </MenuItem>
          ))}
        </Card>
      </Popper>

      {/* menu popover */}
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModifyTicket(true);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>
        {showDelete && (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              showConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('popover.delete')}
          </MenuItem>
        )}
      </Popover>
    </>
  );
}
