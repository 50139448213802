// component
import dayjs from 'dayjs';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

// @mui
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Box,
  Stack,
  Button,
  Toolbar,
  Tooltip,
  Popover,
  MenuItem,
  Typography,
} from '@mui/material';

import Iconify from '../iconify';

// ----------------------------------------------------------------------
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'start',
  padding: theme.spacing(0, 1, 0, 3),
  whiteSpace: 'pre',
}));

// ----------------------------------------------------------------------
DashboardToolbar.propTypes = {
  end: PropTypes.instanceOf(Date),
  start: PropTypes.instanceOf(Date),
  getTicketsByDate: PropTypes.func,
  setIsLoading: PropTypes.func,
};
export default function DashboardToolbar({ end, start, getTicketsByDate, setIsLoading }) {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(false);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(t(secureLocalStorage.getItem('sortby')));

  const handleStartDateChange = (date) => {
    secureLocalStorage.setItem('ticketStart', date);
    setStartDate(new Date(secureLocalStorage.getItem('ticketStart')));
  };

  const handleEndDateChange = (date) => {
    secureLocalStorage.setItem('ticketEnd', date);
    setEndDate(new Date(secureLocalStorage.getItem('ticketEnd')));
  };

  const handleClickMenu = (event) => {
    if (popoverAnchorEl) {
      setPopoverAnchorEl(null);
    } else {
      setPopoverAnchorEl(event.currentTarget);
    }
  };

  const handleApply = async (startingDate, endingDate) => {
    if (setIsLoading) setIsLoading(true);
    await getTicketsByDate({
      startDate: startingDate.toISOString(),
      endDate: endingDate.toISOString(),
      attributeName: 'created',
    });
    if (setIsLoading) setIsLoading(false);
  };
  const handleMenuClick = async (sortBy) => {
    const theStart = new Date();
    const theEnd = new Date();
    const dateAdjustments = {
      thisMonth: () => theStart.setDate(1),
      last7: () => theStart.setDate(theStart.getDate() - 7),
      last30: () => theStart.setDate(theStart.getDate() - 30),
      last6Month: () => theStart.setMonth(theStart.getMonth() - 6),
      thisYear: () => {
        theStart.setMonth(0);
        theStart.setDate(1);
      },
      thisWeek: () => {
        const dayOfWeek = (theStart.getDay() + 6) % 7;
        theStart.setDate(theStart.getDate() - dayOfWeek);
      },
    };

    if (dateAdjustments[sortBy]) {
      dateAdjustments[sortBy]();
    }

    theStart.setHours(0, 0, 0);
    theEnd.setHours(23, 59, 59);

    handleApply(theStart, theEnd);
    handleStartDateChange(theStart);
    handleEndDateChange(theEnd);
    setSortBy(sortBy);
    secureLocalStorage.setItem('sortby', sortBy);
    handleClickMenu();
  };

  return (
    <StyledRoot>
      <Stack direction="row" alignItems="center" spacing={2} key="1">
        <Box key="12">
          <Tooltip title="Filter by Date" sx={{ marginRight: '2vh' }}>
            <Button color="inherit" onClick={handleClickMenu}>
              <Iconify icon="subway:paragraph-2" />
              <Typography ml={2} variant="body1" key="sortBy">
                {t(`filters.dates.${sortBy}`)}
              </Typography>
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              value={dayjs(startDate)}
              onChange={handleStartDateChange}
              label={t('filters.dates.from')}
            />
          </LocalizationProvider>
        </Box>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              value={dayjs(endDate)}
              onChange={handleEndDateChange}
              label={t('filters.dates.to')}
            />
          </LocalizationProvider>
        </Box>

        <Button
          variant="outlined"
          onClick={() => {
            handleApply(startDate, endDate);
          }}
        >
          {t('buttons.apply')}
        </Button>
      </Stack>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClickMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('today');
          }}
        >
          {t('filters.dates.today')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClick('thisWeek');
          }}
        >
          {t('filters.dates.thisWeek')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('last7');
          }}
        >
          {t('filters.dates.last7')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('thisMonth');
          }}
        >
          {t('filters.dates.thisMonth')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClick('last30');
          }}
        >
          {t('filters.dates.last30')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('last6Month');
          }}
        >
          {t('filters.dates.last6Month')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('thisYear');
          }}
        >
          {t('filters.dates.thisYear')}
        </MenuItem>
      </Popover>
    </StyledRoot>
  );
}
