import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStompClient, useSubscription } from 'react-stomp-hooks';

import {
  Tab,
  Box,
  Tabs,
  List,
  Badge,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from './loader';
import NotificationItem from './notificationItem';
import { useAuth } from '../../../../hooks/useAuth';
import Iconify from '../../../../components/iconify';
import { encrypt } from '../../../../utils/hashCode';
import { useToast } from '../../../../hooks/useToast';
import {
  markAllAsReadById,
  markNotificationReadById,
  fetchReadNotificationsByUserId,
  fetchUnreadNotificationsByUserId,
} from '../../../../services/notification.service';

export default function NotificationsPopover() {
  const { id } = useAuth();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const { showToast, hideToast } = useToast();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [hasMoreUnread, setHasMoreUnread] = useState(true);
  const [hasMoreRead, setHasMoreRead] = useState(true);
  const [totalUnread, setTotalUnread] = useState(0);
  const [totalRead, setTotalRead] = useState(0);
  const [unreadPage, setUnreadPage] = useState(0);
  const [readPage, setReadPage] = useState(0);
  const [loadingUnread, setLoadingUnread] = useState(false);
  const [loadingRead, setLoadingRead] = useState(false);

  const fetchUnreadNotifications = async (page) => {
    setLoadingUnread(true);
    try {
      const res = await fetchUnreadNotificationsByUserId(id, page, 10);
      setTotalUnread(res.notificationPageable.totalElements);

      if (res.notificationPageable.content.length < 10) {
        setHasMoreUnread(false);
      }

      setUnreadNotifications((prev) =>
        [
          ...prev,
          ...res.notificationPageable.content.filter(
            (newNotif) => !prev.some((notif) => notif.id === newNotif.id)
          ),
        ].sort((a, b) => {
          const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return bTime - aTime;
        })
      );
    } finally {
      setLoadingUnread(false);
    }
  };

  const fetchReadNotifications = async (page) => {
    setLoadingRead(true);
    try {
      const res = await fetchReadNotificationsByUserId(id, page, 10);
      setTotalRead(res.notificationPageable.totalElements);

      if (res.notificationPageable.content.length < 10) {
        setHasMoreRead(false);
      }

      setReadNotifications((prev) =>
        [
          ...prev,
          ...res.notificationPageable.content.filter(
            (newNotif) => !prev.some((notif) => notif.id === newNotif.id)
          ),
        ].sort((a, b) => {
          const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return bTime - aTime;
        })
      );
    } finally {
      setLoadingRead(false);
    }
  };

  const fetchAllNotifications = async () => {
    await fetchUnreadNotifications(unreadPage);
    await fetchReadNotifications(readPage);
  };

  useSubscription(`/notification/sendNotif-${id}`, (message) => {
    showToast({
      message: JSON.parse(message.body).subject,
      severity: 'info',
      verticalPos: 'bottom',
      horizontalPos: 'right',
      props: { hideToast },
    });
    fetchAllNotifications();
  });

  const stompClient = useStompClient();
  window.addEventListener('beforeunload', () => {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    const lastEntry = navigationEntries[navigationEntries.length - 1];

    const pageAccessedByReload =
      (window.performance.navigation && window.performance.navigation.type === 1) ||
      lastEntry.type === 'reload';
    if (!pageAccessedByReload && stompClient) {
      stompClient.publish({
        destination: `/app/user-activity-${id}-False`,
      });
    }
  });

  if (stompClient) {
    stompClient.publish({
      destination: `/app/user-activity-${id}-True`,
    });
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async (id) => {
    await markAllAsReadById(id);

    setReadNotifications((prev) =>
      [...prev, ...unreadNotifications].sort((a, b) => {
        const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return bTime - aTime;
      })
    );

    setUnreadNotifications([]);

    setTotalUnread(0);
    setTotalRead((prevTotal) => prevTotal + unreadNotifications.length);
  };

  const handleNotificationClick = async (id, ticketId, triggeredBy, type) => {
    setUnreadNotifications((prev) =>
      prev
        .map((notification) =>
          notification.id === id ? { ...notification, isRead: true } : notification
        )
        .sort((a, b) => {
          const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return bTime - aTime;
        })
    );

    await markNotificationReadById(id);

    if (
      !['DELETE', 'UNTAGGED'].includes(triggeredBy) &&
      ticketId !== 0 &&
      ['STATUS', 'TICKET', 'MESSAGE'].includes(type)
    ) {
      navigate(`/tickets/${encrypt(ticketId)}`);
    }
  };
  useEffect(() => {
    fetchAllNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleNotificationView = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setHasMoreUnread(true);
    } else {
      setHasMoreUnread(true);
    }
  };

  const fetchMoreNotifications = () => {
    if (activeTab === 0) {
      const nextUnreadPage = unreadPage + 1;
      setUnreadPage(nextUnreadPage);
      fetchUnreadNotifications(nextUnreadPage);
    } else {
      const nextReadPage = readPage + 1;
      setReadPage(nextReadPage);
      fetchReadNotifications(nextReadPage);
    }
  };
  const notifications = useMemo(
    () => (activeTab === 0 ? unreadNotifications : readNotifications),
    [activeTab, unreadNotifications, readNotifications]
  );

  return (
    <>
      <Tooltip title={t('navbar.notifications')}>
        <IconButton
          color={open ? 'primary' : 'default'}
          onClick={handleOpen}
          sx={{ width: 40, height: 40 }}
        >
          <Badge
            badgeContent={totalUnread}
            color="error"
            sx={{
              '& .MuiBadge-badge': {
                fontSize: isSmallScreen ? 10 : 12,
              },
            }}
          >
            <Iconify icon="eva:bell-fill" />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: isSmallScreen ? 240 : 360,
            height: notifications.length < 3 ? 'auto' : isSmallScreen ? '60%' : '75%',
            overflow: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: isSmallScreen ? 1.5 : 2,
            px: 2.5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}>
              {t('notifications.notifications')}
            </Typography>{' '}
            <Typography
              variant={isSmallScreen ? 'caption' : 'body2'}
              sx={{ color: 'text.secondary' }}
            >
              {t('notifications.unreadMessages', { count: totalUnread })}
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title={t('notifications.markAllAsRead')}>
              <IconButton color="primary" onClick={() => handleMarkAllAsRead(id)}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Tabs
          value={activeTab}
          onChange={toggleNotificationView}
          aria-label="notification tabs"
          sx={{
            '& .MuiTab-root': {
              fontSize: isSmallScreen ? '12px' : '14px',
            },
          }}
        >
          <Tab label={`${t('notifications.unread')} (${totalUnread})`} />
          <Tab label={`${t('notifications.read')} (${totalRead})`} />
        </Tabs>

        <List
          id="scrollableTarget"
          style={{
            overflow: 'auto',
            height: '79%',
          }}
        >
          {notifications.length === 0 ? (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                p: 2,
                fontSize: isSmallScreen ? '12px' : '15px',
              }}
            >
              {t('notifications.noMoreNotifications')}
            </Typography>
          ) : (
            <InfiniteScroll
              dataLength={notifications.length}
              next={fetchMoreNotifications}
              hasMore={activeTab === 0 ? hasMoreUnread : hasMoreRead}
              loader={
                (activeTab === 0 && loadingUnread) || (activeTab === 1 && loadingRead) ? (
                  <Loader />
                ) : null
              }
              scrollableTarget="scrollableTarget"
            >
              {notifications.map((notification) => (
                <NotificationItem
                  key={`notif${notification.id}`}
                  notification={notification}
                  handleNotificationClick={handleNotificationClick}
                />
              ))}
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  p: 2,
                  fontSize: isSmallScreen ? '12px' : '15px',
                }}
              >
                {t('notifications.noMoreNotifications')}
              </Typography>
            </InfiniteScroll>
          )}
        </List>
      </Popover>
    </>
  );
}
