import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField, IconButton, InputAdornment } from '@mui/material';

import Iconify from '../iconify';

PasswordTextInput.propTypes = {
  showPassword: PropTypes.bool,
  name: PropTypes.string,
  handleShowPassword: PropTypes.func,
  errors: PropTypes.object,
  register: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string,
};

export default function PasswordTextInput({
  showPassword,
  name = '',
  handleShowPassword,
  errors,
  register,
  validations,
  value,
}) {
  const { t } = useTranslation();

  return (
    <TextField
      name={name}
      label={t(`pages.usersPage.form.${name}`)}
      autoComplete={name}
      sx={{
        width: {
          xs: '90%',
          sm: '80%',
          md: '75vh',
        },
        maxWidth: '100%',
      }}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleShowPassword(name)} edge="end">
              <Iconify icon={showPassword[name] ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!errors[name]}
      helperText={errors[name]?.message || ''}
      value={value}
      {...register(name, validations)}
    />
  );
}
