import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;
const url = `${API_URL}/favorites`;

// Create a new favorite
export const createFavorite = async ({ type, typeId, userId }) => {
  const result = await axios.post(
    url,
    { type, typeId, userId },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// Delete a favorite by ID
export const deleteFavorite = async (id) => {
  const result = await axios.delete(`${url}/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get favorites by user ID
export const fetchFavoritesByUserId = async (userId) => {
  const result = await axios.get(`${url}/user/${userId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get favorites by type and typeId
export const fetchFavoritesByTypeAndTypeId = async ({ type, typeId }) => {
  const result = await axios.get(`${url}/type/${type}/id/${typeId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
