import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import {
  Avatar,
  Tooltip,
  ListItem,
  IconButton,
  Typography,
  ListItemAvatar,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import { useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { updateUserPermissions } from 'src/services/ticket.permissions.service';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

UserListPermission.propTypes = {
  user: PropTypes.object.isRequired,
  ticketId: PropTypes.string,
  deletePermission: PropTypes.func,
  setPermissionUsers: PropTypes.func.isRequired,
  hasWritePermission: PropTypes.bool,
  isLocalUpdate: PropTypes.bool,
};

function UserListPermission({
  user,
  ticketId,
  deletePermission,
  setPermissionUsers,
  hasWritePermission = true,
  isLocalUpdate = false,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(user?.permissions || [false, false]);
  const [toggled, setToggled] = useState(false);
  const { showToast, hideToast } = useToast();
  const { id: currentUserId } = useAuth();

  useEffect(() => {
    if (user.permissions && user.permissions.length === 2) {
      setPermissions(user.permissions);
    }
  }, [user.permissions]);

  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmDelete'),
    `${t('pages.ticketDetails.areYouSure')} ${user?.givenName}`,
    async () => {
      if (isLocalUpdate) {
        handleLocalPermissionUserDelete(user.id);
      } else {
        const errorCallback = () => {
          setToggled(false);
          setPermissions(user.permissions);
        };
        deletePermission(ticketId, user?.id, errorCallback);
      }
    }
  );

  const handleLocalPermissionUserDelete = (userId) => {
    setPermissionUsers((prev) => prev.filter((user) => user.id !== userId));
  };

  const handleUpdatePermissions = async () => {
    if (!permissions[0] && permissions[1]) {
      setPermissions([true, permissions[1]]);
      setToggled(false);
      showToast({
        message: t('errorMessages.cannotRevokeReadPermission'),
        severity: 'warning',
        props: { hideToast },
      });
      return;
    }

    if (!permissions[0] && !permissions[1]) {
      showConfirmModal();
      return;
    }

    setToggled(false);

    if (isLocalUpdate) {
      // Local state update for temporary/draft changes
      setPermissionUsers((prev) =>
        prev.map((u) => (u.id === user.id ? { ...u, permissions } : u))
      );
    } else {
      // API update for persistent changes
      genericApiToaster(
        updateUserPermissions,
        {
          ticketId,
          userId: user.id,
          isReadPermission: permissions[0],
          isWritePermission: permissions[1],
        },
        t('successMessages.userPermissionsUpdated'),
        t('errorMessages.errorUpdatingPermissions'),
        showToast,
        hideToast,
        t,
        undefined,
        () => setPermissions(user?.permissions)
      );
    }
  };

  const toggleReadPermission = () => {
    setPermissions([!permissions[0], permissions[1]]);
    setToggled(true);
  };

  const toggleWritePermission = () => {
    setPermissions([true, !permissions[1]]);
    setToggled(true);
  };

  const cancelUpdate = () => {
    setPermissions(user.permissions || [false, false]);
    setToggled(false);
  };

  const handleDelete = () => {
    if (isLocalUpdate) {
      handleLocalPermissionUserDelete(user.id);
    } else {
      showConfirmModal();
    }
  };

  const canModifyPermissions =
    hasWritePermission && !user.default && currentUserId !== user.id;

  return (
    <ListItem divider alignItems="flex-start">
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <ListItemAvatar>
            <Avatar
              alt={user.givenName}
              src={user.avatarUrl || user.avatar || ''}
              sx={{
                width: isSmallScreen ? 30 : 40,
                height: isSmallScreen ? 30 : 40,
              }}
            />
          </ListItemAvatar>
          <Stack spacing={0.5}>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{`${user.givenName} ${user.familyName}`}</b>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{t('attributes.email')}: </b>
              {user.email}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{t('tableHeads.user.role')}: </b>
              {t(`roles.${user.appUserRole}`)}
            </Typography>
            {user.departmentName && user.departmentName !== 'none' && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
                }}
              >
                <b>{t('attributes.department')}: </b>
                {user.departmentName}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.25}>
          {/* Permissions */}
          <Stack direction="row" spacing={0.5}>
            <Tooltip title={t('pages.ticketDetails.read')}>
              <Label
                color={permissions[0] ? 'success' : 'error'}
                onClick={canModifyPermissions ? toggleReadPermission : undefined}
                sx={{
                  fontSize: isSmallScreen ? '0.55rem' : '0.65rem',
                  cursor: canModifyPermissions ? 'pointer' : 'not-allowed',
                }}
              >
                {t('pages.ticketDetails.r')}
              </Label>
            </Tooltip>
            <Tooltip title={t('pages.ticketDetails.modify')}>
              <Label
                color={permissions[1] ? 'success' : 'error'}
                onClick={canModifyPermissions ? toggleWritePermission : undefined}
                sx={{
                  fontSize: isSmallScreen ? '0.55rem' : '0.65rem',
                  cursor: canModifyPermissions ? 'pointer' : 'not-allowed',
                }}
              >
                {t('pages.ticketDetails.m')}
              </Label>
            </Tooltip>
          </Stack>

          {toggled && (
            <>
              {/* Update Icon */}
              <Tooltip title={t('pages.ticketDetails.confirm')}>
                <IconButton
                  color="primary"
                  onClick={handleUpdatePermissions}
                  disabled={!canModifyPermissions}
                >
                  <Iconify icon="eva:checkmark-outline" sx={{ width: 17, height: 17 }} />
                </IconButton>
              </Tooltip>
              {/* Cancel Icon */}
              <Tooltip title={t('pages.ticketDetails.cancel')}>
                <IconButton color="warning" onClick={cancelUpdate}>
                  <Iconify icon="eva:close-outline" sx={{ width: 17, height: 17 }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {/* Delete Icon */}
          {!user.default ? (
            <Tooltip title={t('pages.ticketDetails.delete')}>
              <IconButton
                color="error"
                disabled={
                  !isLocalUpdate && (!hasWritePermission || currentUserId === user.id)
                }
                onClick={handleDelete}
              >
                <Iconify icon="eva:trash-2-outline" sx={{ width: 17, height: 17 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Label
              variant="outlined"
              sx={{ fontSize: isSmallScreen ? '0.55rem' : '0.65rem' }}
            >
              {t('pages.ticketDetails.default')}
            </Label>
          )}
        </Stack>
      </Stack>
    </ListItem>
  );
}

export default UserListPermission;
