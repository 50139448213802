import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardContent } from '@mui/material';

FormCard.propTypes = {
  children: PropTypes.node,
};

export default function FormCard({ children }) {
  return (
    <Card sx={{ minWidth: 275, mt: 3, mb: 3 }} raised>
      <CardContent>{children}</CardContent>
    </Card>
  );
}
