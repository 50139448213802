import primary from 'src/theme/palette';

export const marketingImageBox = {
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '15px',
  paddingLeft: '64px',
  paddingTop: '50%',
  height: '96vh',
  display: 'flex',
  alignItems: 'center',
};

export const marketingImageBoxMobile = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  paddingLeft: '30px',
  paddingTop: '20%',
  borderRadius: '30px',
  height: '30vh',
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const subtitleStyleMobile = {
  margin: '20px',
  width: '80%',
  textAlign: 'center',
  fontWeight: 'regular',
  color: primary.main,
};

export const subtitleStyle = {
  marginTop: '20px',
  fontSize: '15px',
  fontWeight: 'regular',
  color: primary.main,
};
