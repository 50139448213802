export const genericApiToaster = async (
  apiCall,
  args,
  successMessage,
  errorMessage,
  showToast,
  hideToast,
  successCallback,
  errorCallback
) => {
  const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

  try {
    const res = await apiCall(...apiArguments);
    showToast({ message: successMessage, severity: 'success', props: { hideToast } });
    if (successCallback) {
      successCallback(res);
    }
  } catch (e) {
    showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
    if (errorCallback) {
      errorCallback(e);
    }
  }
};
