import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Tab, Grid, Stack, Container, Typography } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';

import ActionButton from 'src/components/action-button/action-button';

import StatusGrid from 'src/sections/statuses/grid/statusGrid';

import AddStatus from './modals/AddStatus';
import EditStatus from './modals/EditStatus';
import { useAuth } from '../../hooks/useAuth';
import StatusTable from './table/statusTable';
import Iconify from '../../components/iconify';
import { useModal } from '../../hooks/useModal';
import FilterPopover from './popovers/filterPopover';
import { statusColorMapper } from '../../utils/statusColorMapper';
import { getUsersByDepartment } from '../../services/user.service';
import UserListToolbar from '../../components/ToolBars/FormToolbar';
import { fetchDepartments } from '../../services/department.service';
import { getComparator, applySortFilter } from '../../utils/filterData';
import {
  modifyStatus,
  createStatus,
  deleteStatus,
  fetchStatuses,
} from '../../services/status.service';

const itemsPerPage = 10;

export default function StatusView() {
  const [departmentList, setDepartmentList] = useState([]);
  const { role, departmentId } = useAuth();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(departmentId);
  const [filteredStatusData, setFilteredStatusData] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const [filterName, setFilterName] = useState('');
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const [gridPage, setGridPage] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('statusId');
  const [isLoading, setIsLoading] = useState(false);
  const [filterPopover, setFilterPopover] = useState(null);
  const [searchBy, setSearchBy] = useState('byName');
  const [filterBy, setFilterBy] = useState('name');

  const [subFilterPopover, setSubFilterPopover] = useState({
    department: null,
    systemState: null,
  });

  const { showToast, hideToast } = useToast();
  const { showModal, hideModal } = useModal();
  const { isSmallScreen } = useResponsiveScreen();

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ department: null, systemState: null });
  };

  // add status
  const addStatus = (newStatusData) => {
    genericApiToaster(
      createStatus,
      newStatusData,
      t('successMessages.successCreatingStatus'),
      t('errorMessages.couldntCreateStatus'),
      showToast,
      hideToast,
      t,
      getStatuses
    );
  };

  // edit status
  const editStatus = (editedStatusData) => {
    genericApiToaster(
      modifyStatus,
      editedStatusData,
      t('successMessages.successModifyingStatus'),
      t('errorMessages.couldntModifyStatus'),
      showToast,
      hideToast,
      t,
      getStatuses
    );
  };

  // delete status
  const delStatus = (id) => {
    genericApiToaster(
      deleteStatus,
      id,
      t('successMessages.successDeletingStatus'),
      t('errorMessages.couldntDeleteStatus'),
      showToast,
      hideToast,
      t,
      getStatuses
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getDepartMents = async () => {
    genericApiToaster(
      fetchDepartments,
      [],
      '',
      t('errorMessages.couldntLoadDep'),
      showToast,
      hideToast,
      t,
      setDepartmentList
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setTablePage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeTablePage = (event, newPage) => {
    setTablePage(newPage);
  };

  useEffect(() => {
    if (role.authority === 'ROLE_ADMIN') getDepartMents();
    else setSelectedDepartmentId(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetailsClick = (status) => {
    showModal({
      title: t('pages.statusPage.editStatusForm'),
      Component: EditStatus,
      props: {
        hideModal,
        status,
        role: role.authority,
        departmentList,
        emailsList,
        editStatus,
      },
    });
  };

  const getStatusSuccesCallback = (res) => {
    const updatedStatuses = res.map((status) => {
      const statusColor = statusColorMapper(status);
      if (status.statusId <= 5) {
        return {
          ...status,
          statusColor,
        };
      }
      return status;
    });
    setFilteredStatusData(
      selectedDepartmentId === 0
        ? updatedStatuses
        : updatedStatuses.filter((status) =>
            role.authority === 'ROLE_ADMIN'
              ? status.departmentId === selectedDepartmentId
              : status.departmentId === selectedDepartmentId || status.statusId < 6
          )
    );
    setTablePage(0);
    setGridPage(0);
    if (selectedDepartmentId === 0) {
      setEmailsList([]);
    } else {
      getUsersByDepartment(selectedDepartmentId).then((res) => {
        setEmailsList(res.filter((user) => !user.archived).map((user) => user.email));
      });
    }
  };

  const getStatuses = () => {
    setIsLoading(true);
    genericApiToaster(
      fetchStatuses,
      [],
      '',
      t('errorMessages.couldntLoadStatuses'),
      showToast,
      hideToast,
      t,
      getStatusSuccesCallback,
      undefined,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    getStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentId]);

  const filteredStatus = useMemo(() => {
    const mappedData = filteredStatusData.map((status) => ({
      ...status,
      name: (() => {
        switch (status.name) {
          case 'OPEN':
            return t('status.OPEN');
          case 'ONHOLD':
            return t('status.ONHOLD');
          case 'CLOSED':
            return t('status.CLOSED');
          case 'UNASSIGNED':
            return t('status.UNASSIGNED');
          case 'RESOLVED':
            return t('status.RESOLVED');
          default:
            return status.name;
        }
      })(),
    }));

    return applySortFilter(
      mappedData,
      getComparator(order, orderBy),
      filterName,
      filterBy
    );
  }, [filteredStatusData, order, orderBy, filterName, filterBy, t]);

  const { isNotFound, searchIsNotFound } = useMemo(() => {
    const isNotFound = !filteredStatus.length;
    const searchIsNotFound = !filteredStatus.length && !!filterName;
    return { isNotFound, searchIsNotFound };
  }, [filteredStatus, filterName]);

  const handleFilterByName = (event) => {
    setGridPage(0);
    setTablePage(0);
    setFilterName(event.target.value);
  };

  const handleChangePageGrid = (event, newPage) => {
    setGridPage(newPage - 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  return (
    <>
      <Helmet>
        <title>{t('pages.statusPage.statusSingle')}</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={isSmallScreen ? 2 : 5}
        >
          <Typography
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
            mx={1}
          >
            {t('pages.statusPage.statusPlural')}
          </Typography>
          <Grid>
            <ActionButton
              id="add-status-btn"
              onClick={() =>
                showModal({
                  title: t('pages.statusPage.addStatusForm'),
                  Component: AddStatus,
                  props: {
                    hideModal,
                    emailsList,
                    departmentList,
                    role: role.authority,
                    addStatus,
                    currentUserDepartment: departmentId,
                  },
                })
              }
              icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
              text={t('pages.statusPage.newStatus')}
              title={t('pages.statusPage.newStatus')}
            />
          </Grid>
        </Stack>

        <Box mb={isSmallScreen ? 0 : 1} mt={isSmallScreen ? 0 : 1}>
          <UserListToolbar
            numSelected={0}
            model={t('pages.statusPage.statusSingle')}
            onFilterName={handleFilterByName}
            filterName={filterName}
            setFilterName={setFilterName}
            filterBy={searchBy}
            handleOpenFilter={handleOpenFilter}
            handleCloseFilter={handleCloseFilter}
          />
        </Box>
        <TabContext value={tabValue}>
          <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <TabList onChange={handleTabChange} aria-label="status-tabs">
              <Tab
                onClick={() => setOrderBy('statusId')}
                icon={<Iconify icon="gridicons:grid" />}
                value="1"
              />
              <Tab icon={<Iconify icon="gridicons:list-unordered" />} value="2" />
            </TabList>
          </Stack>
          <TabPanel value="1" sx={{ marginY: 3, padding: 0 }}>
            <StatusGrid
              statusData={filteredStatus}
              itemsPerPage={itemsPerPage}
              page={gridPage}
              handleChangePage={handleChangePageGrid}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              filterName={filterName}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ marginY: 3, padding: 0 }}>
            <StatusTable
              statusData={filteredStatus}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangeTablePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              filterName={filterName}
              isLoading={isLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>
      <FilterPopover
        setSubFilterPopover={setSubFilterPopover}
        subFilterPopover={subFilterPopover}
        handleCloseSubFilter={handleCloseSubFilter}
        setSearchBy={setSearchBy}
        handleCloseFilter={handleCloseFilter}
        filterPopover={filterPopover}
        setFilterTitle={setFilterName}
        setFilterBy={setFilterBy}
        departmentData={departmentList}
        setPage={setTablePage}
      />
    </>
  );
}
