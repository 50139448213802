import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Link, Stack, Divider, Typography } from '@mui/material';

import Logo from '../../components/logo';
import { boxStyleFormFooter } from './style';

const FormFooter = ({ show = false }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ ...boxStyleFormFooter, mt: show ? 4 : 2 }}>
      <Logo
        disabledLink
        alignSelf="center"
        sx={{
          width: show ? '25vh' : '20vh',
          height: show ? '15vh' : '10vh',
        }}
      />

      {/* Detailed privacy message */}
      {show && (
        <>
          <Typography variant="body2" paragraph sx={{ px: 3 }}>
            {t('pages.formPage.detailedPrivacyMessage')}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Links section */}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ flexWrap: 'wrap' }}
          >
            <Link href="#" underline="hover" variant="body2">
              {t('pages.formPage.privacyPolicy')}
            </Link>
            <Link href="#" underline="hover" variant="body2">
              {t('pages.formPage.termsOfService')}
            </Link>
            <Link href="#" underline="hover" variant="body2">
              {t('pages.formPage.contactUs')}
            </Link>
          </Stack>
        </>
      )}

      <Typography variant="caption" sx={{ mt: show ? 2 : 1, display: 'block' }}>
        {t('pages.formPage.poweredBy')}
        <Link href="https://www.algorleap.com" target="_blank" rel="noopener noreferrer">
          Algor Leap Solutions
        </Link>
        . © 2024 OryxOne Ticketing System. {t('pages.formPage.allRightsReserved')}
      </Typography>
    </Box>
  );
};

FormFooter.propTypes = {
  show: PropTypes.bool,
};

export default FormFooter;
