import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Popover, Tooltip, MenuItem, IconButton } from '@mui/material';

import useResponsiveScreen from '../../hooks/useResponsiveScreen';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'it',
    label: 'Italian',
    icon: '/assets/icons/ic_flag_it.webp',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'ar',
    label: 'Arabic',
    icon: '/assets/icons/ic_flag_tn.webp',
  },
];

// ----------------------------------------------------------------------
export default function LanguagePopover() {
  const {
    i18n: { changeLanguage },
    t,
  } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const [open, setOpen] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(LANGS[0]);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  useEffect(() => {
    const lang = JSON.parse(secureLocalStorage.getItem('language'));
    if (lang) {
      setSelectedLanguage(lang);
      changeLanguage(lang.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    secureLocalStorage.setItem('language', JSON.stringify(lang));
    changeLanguage(lang.value);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t('navbar.languages')}>
        <IconButton
          onClick={handleOpen}
          id="change-language"
          sx={{
            padding: 0,
            width: isSmallScreen ? 35 : 44,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <img
            src={selectedLanguage.icon}
            alt={selectedLanguage.label}
            width={isSmallScreen ? 23 : 28}
          />
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: isSmallScreen ? 140 : 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={isSmallScreen ? 0 : 0.75}>
          {LANGS.map((option) => (
            <MenuItem
              id="choose-english"
              key={option.value}
              selected={option.value === selectedLanguage.value}
              onClick={() => handleLanguageChange(option)}
            >
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{ width: isSmallScreen ? 23 : 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
