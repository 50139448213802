import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
// @mui
import {
  Box,
  Card,
  Link,
  Stack,
  Popover,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import DepartmentDetails from 'src/sections/department/modal/DepartmentDetailsModal';

import Iconify from '../../../components/iconify/Iconify';
import { useConfirmModal } from '../../../hooks/useModal';
import DepartmentFormModal from '../modal/DepartmentFormModal';
// services
// ----------------------------------------------------------------------

const StyledDepartmenttImg = styled('img')({
  top: 0,
  width: '100%',
  height: '90%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

DepartmentCard.propTypes = {
  department: PropTypes.object,
  handleDelete: PropTypes.func,
  editDepartment: PropTypes.func,
  fetchTickets: PropTypes.func,
  fetchUsers: PropTypes.func,
};

export default function DepartmentCard({
  department,
  handleDelete,
  editDepartment,
  fetchTickets,
  fetchUsers,
}) {
  const { name, image, description } = department;
  const [open, setOpen] = useState(null);
  const [openModifyDepartment, setOpenModifyDepartment] = useState(false);
  const [openDepartmentDetails, setOpenDepartmentDetails] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };
  const handleDeleteClick = async () => {
    showConfirmModal();
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.departmentPage.details.confirmDelete'),
    `${t('pages.departmentPage.details.areYouSure')} ${department.name}`,
    async () => handleDelete(department.id)
  );

  useEffect(() => {
    fetchTickets(department.id, (res) => setTicketsData(res));
    fetchUsers(department.id, (res) =>
      setUsersData(res.filter((user) => user.appUserRole === 'ROLE_AGENT'))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DepartmentFormModal
        key={`dep-${department.id}`}
        open={openModifyDepartment}
        setOpen={setOpenModifyDepartment}
        department={department}
        apiCall={editDepartment}
      />
      <DepartmentDetails
        open={openDepartmentDetails}
        setOpen={setOpenDepartmentDetails}
        department={department}
        tickets={ticketsData.length}
        users={usersData.length}
      />

      <Card sx={{ cursor: 'pointer' }} onClick={() => setOpenDepartmentDetails(true)}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <StyledDepartmenttImg
            alt={name}
            src={image || '/assets/images/departments/defaultDepartment.png'}
          />
        </Box>
        <Stack spacing={1} sx={{ pt: 0, pb: 1, px: 2 }}>
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Link
              color="inherit"
              underline="hover"
              sx={{ maxWidth: 'calc(100% - 40px)' }}
            >
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: isSmallScreen ? '0.8rem' : '1rem',
                }}
              >
                {name}
              </Typography>
            </Link>
            <IconButton
              sx={{
                p: 1,
                width: isSmallScreen ? 30 : 40,
                height: isSmallScreen ? 30 : 40,
              }}
              size={isSmallScreen ? 'small' : 'medium'}
              color="inherit"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMenu(e, department);
              }}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
          <Typography
            variant="body1"
            noWrap
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: isSmallScreen ? '0.7rem' : '0.9rem',
            }}
          >
            {description}
          </Typography>
        </Stack>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: isSmallScreen ? 120 : 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDepartmentDetails(true);
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:plus-circle-outline"
            sx={{
              mr: 1,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.moreDetails')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenModifyDepartment(true);
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:edit-fill"
            sx={{
              mr: 1,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.edit')}
          </Typography>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClick}>
          <Iconify
            icon="eva:trash-2-outline"
            sx={{
              mr: 1,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.delete')}
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
