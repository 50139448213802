import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import ChangePassword from 'src/components/changePassword/ChangePassword';

import { styles } from '../styles';

export default function ChangePasswordSection() {
  const { t } = useTranslation();

  return (
    <Stack mt={1} spacing={1}>
      <Stack sx={styles.iconBox}>
        <Iconify icon="eva:refresh-outline" sx={{ mr: 1 }} />
        <Typography variant="h6">{t('pages.profilePage.changePassword')}:</Typography>
      </Stack>
      <Card sx={styles.formCard}>
        <ChangePassword />
      </Card>
    </Stack>
  );
}
