import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

import { Tab, Box } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import authService from '../../services/auth.service';
import TicketTab from '../../sections/ticketTabs/ticketTab';

export default function TabTicketsPage() {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [tabValue, setTabValue] = useState(
    secureLocalStorage.getItem('activeTicketTab') || '1'
  );
  const role = authService.verifyUserRole();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    secureLocalStorage.setItem('activeTicketTab', newValue);
  };

  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleTabChange}
          aria-label="tickets tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t('ticketTabs.allTickets')}
            value="1"
            sx={{
              fontSize: isSmallScreen && '0.675rem',
            }}
          />
          {role === 'ROLE_AGENT' && (
            <Tab
              label={t('ticketTabs.departmentTickets')}
              value="7"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
          {role !== 'ROLE_ADMIN' && (
            <Tab
              label={t('ticketTabs.taggedTickets')}
              value="8"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
          {role !== 'ROLE_CUSTOMER' && (
            <Tab
              label={t('ticketTabs.myTickets')}
              value="5"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
          {role === 'ROLE_AGENT' && (
            <Tab
              label={t('ticketTabs.activeTickets')}
              value="2"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
          {role === 'ROLE_AGENT' && (
            <Tab
              label={t('ticketTabs.onHoldTickets')}
              value="3"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
          {role === 'ROLE_ADMIN' && (
            <Tab
              label={t('ticketTabs.othersTickets')}
              value="4"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}

          {role === 'ROLE_DEPARTMENTMANAGER' && (
            <Tab
              label={t('ticketTabs.unassignedTickets')}
              value="6"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            />
          )}
        </TabList>
      </Box>
      <TabPanel value="1">
        <TicketTab title={t('ticketTabs.allTickets')} />
      </TabPanel>
      <TabPanel value="2">
        <TicketTab title={t('ticketTabs.activeTickets')} />
      </TabPanel>
      <TabPanel value="3">
        <TicketTab title={t('ticketTabs.onHoldTickets')} />
      </TabPanel>
      <TabPanel value="4">
        <TicketTab title={t('ticketTabs.othersTickets')} />
      </TabPanel>
      <TabPanel value="5">
        <TicketTab title={t('ticketTabs.myTickets')} />
      </TabPanel>
      <TabPanel value="6">
        <TicketTab title={t('ticketTabs.unassignedTickets')} />
      </TabPanel>
      <TabPanel value="7">
        <TicketTab title={t('ticketTabs.departmentTickets')} />
      </TabPanel>
      <TabPanel value="8">
        <TicketTab title={t('ticketTabs.taggedTickets')} />
      </TabPanel>
    </TabContext>
  );
}
