import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Select,
  Dialog,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { updateTicket } from 'src/services/ticket.service';
import { fetchDepartments } from 'src/services/department.service';

import Iconify from 'src/components/iconify';

ModifyTicket.propTypes = {
  row: PropTypes.object,
  setOpen: PropTypes.func,
  fetchTicketData: PropTypes.func,
  open: PropTypes.bool,
  getStatuses: PropTypes.func,
  departmentUsers: PropTypes.array,
};

export default function ModifyTicket({
  row,
  setOpen,
  open,
  getStatuses,
  departmentUsers,
  fetchTicketData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: row?.status_id || 0,
    },
  });
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [departmentsData, setDepartmentsData] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const { id: currentUserId, role: currentUserRole } = useAuth();
  const [initialStatusObject, setInitialStatusObject] = useState({});
  const [selectedStatusObject, setSelectedStatusObject] = useState();
  const [showAgents, setShowAgents] = useState(false);

  const selectedDepartment = watch('department_id');
  const selectedStatusId = watch('status_id');

  useMemo(() => {
    const defaultValues = {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: row?.status_id || 0,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    if (selectedStatusObject.systemMappedStatus === 'UNASSIGNED') {
      data.assignedTo = -1;
    }
    modifyTicket(data);
    handleClose();
  };

  const fetchDepartmentsData = async () => {
    genericApiToaster(
      fetchDepartments,
      [],
      '',
      t('errorMessages.couldntLoadDep'),
      showToast,
      hideToast,
      t,
      setDepartmentsData
    );
  };

  useEffect(() => {
    fetchDepartmentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedObject = statusesList.find(
      (status) => status.statusId === selectedStatusId
    );
    setSelectedStatusObject(selectedObject);
    if (
      initialStatusObject?.systemMappedStatus === 'UNASSIGNED' &&
      selectedObject?.systemMappedStatus !== 'UNASSIGNED'
    ) {
      if (currentUserRole.authority === 'ROLE_AGENT') {
        setShowAgents(false);
        setValue('assignedTo', currentUserId);
      } else {
        setShowAgents(true);
      }
    } else {
      setShowAgents(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusId, statusesList]);

  useEffect(() => {
    setInitialStatusObject(
      statusesList.find((status) => status.statusId === row?.status_id)
    );
  }, [row, statusesList]);

  useEffect(() => {
    getStatuses(selectedDepartment, setStatusesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment]);

  const modifyTicket = async (newTicketData) => {
    genericApiToaster(
      updateTicket,
      newTicketData,
      t('successMessages.successTicketUpdate'),
      t('errorMessages.errorUpdatingTicket'),
      showToast,
      hideToast,
      t,
      () => {
        handleClose();
        fetchTicketData(row.id);
      }
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="modal--title" variant="h6" component="h2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen && '0.875rem',
              }}
            >
              {t('pages.ticketDetails.modifyTicket')}
            </Typography>
            <IconButton onClick={() => handleClose()} sx={{ mr: -1.5 }}>
              <Iconify
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: '#B72136',
                  },
                }}
                width={isSmallScreen ? 25 : 30}
                icon="eva:close-square-fill"
              />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={isSmallScreen ? 2 : 3}>
              <Box />
              <FormControl fullWidth error={!!errors.department}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                >
                  {t('attributes.department')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="department"
                  defaultValue={row?.department}
                  disabled
                  label={t('attributes.department')}
                  {...register('department', {
                    required: t('formControl.selectDepartment'),
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                >
                  {departmentsData?.length > 0 ? (
                    departmentsData.map((department) => {
                      const { id, name } = department;
                      return (
                        <MenuItem key={id} value={name}>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                          >
                            {name}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled value="">
                      {t('infoMessages.noOptions')}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText>{errors.department?.message}</FormHelperText>
              </FormControl>

              <TextField
                name="title"
                label={t('attributes.title')}
                error={!!errors.title}
                helperText={errors.title?.message}
                {...register('title', {
                  required: t('formControl.enterTitle'),
                  maxLength: {
                    value: 50,
                    message: t('formControl.maxTitle'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                  },
                }}
              />

              <TextField
                multiline
                rows={4}
                name="description"
                label={t('attributes.description')}
                error={!!errors.description}
                helperText={errors.description?.message || ''}
                {...register('description', {
                  maxLength: {
                    value: 255,
                    message: t('formControl.maxDescription'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                  },
                }}
              />

              {currentUserRole.authority !== 'ROLE_CUSTOMER' &&
                (currentUserRole.authority !== 'ROLE_AGENT' ||
                  row?.createdBy !== currentUserId) && (
                  <>
                    <FormControl fullWidth error={!!errors.status_id}>
                      <InputLabel
                        id="ticket-status-select-label"
                        sx={{
                          fontSize: isSmallScreen ? '0.775rem' : '1rem',
                        }}
                      >
                        {t('attributes.status')}
                      </InputLabel>
                      <Select
                        labelId="ticket-status-select-label"
                        id="status-select"
                        value={watch('status_id')}
                        name="status_id"
                        defaultValue={row?.status_id ?? ''}
                        label={t('attributes.status')}
                        {...register('status_id')}
                        size={isSmallScreen ? 'small' : 'medium'}
                      >
                        {statusesList?.length > 0 ? (
                          statusesList.map((status) => {
                            const { statusId, name } = status;
                            let tName = name;
                            if (statusId <= 5) tName = t(`status.${name}`);
                            return (
                              <MenuItem key={statusId} value={statusId}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                                >
                                  {tName}
                                </Typography>
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem disabled value="">
                            <Typography
                              variant="body2"
                              sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                            >
                              {t('infoMessages.noOptions')}
                            </Typography>
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText
                        sx={{
                          fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                        }}
                      >
                        {errors.status?.message}
                      </FormHelperText>
                    </FormControl>
                    {showAgents && (
                      <FormControl fullWidth error={!!errors.status_id}>
                        <InputLabel id="ticket-assignedTo-select-label">
                          <Typography
                            variant="body2"
                            sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                          >
                            {t('pages.ticketDetails.assignTo')}
                          </Typography>
                        </InputLabel>
                        <Select
                          labelId="ticket-status-select-label"
                          id="status-select"
                          name="status_id"
                          defaultValue={row?.assignedTo ?? -1}
                          label={t('pages.ticketDetails.assignTo')}
                          {...register('assignedTo', {
                            required: t('formControl.selectTicketManager'),
                            validate: { assigned: (v) => parseInt(v, 10) !== -1 },
                          })}
                          size={isSmallScreen ? 'small' : 'medium'}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          <MenuItem key={-1} value={-1}>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                            >
                              {t('pages.ticketDetails.selectTicketManager')}
                            </Typography>
                          </MenuItem>

                          {departmentUsers?.length > 0 ? (
                            departmentUsers.map((user) => {
                              const { id, username } = user;
                              return (
                                <MenuItem key={id} value={id}>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                                  >
                                    {username}
                                  </Typography>
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem disabled value="">
                              <Typography
                                variant="body2"
                                sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                              >
                                {t('infoMessages.noOptions')}
                              </Typography>
                            </MenuItem>
                          )}
                        </Select>
                        <FormHelperText
                          sx={{
                            fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                          }}
                        >
                          {errors.status?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </>
                )}
              <FormControl fullWidth error={!!errors.priority}>
                <InputLabel id="demo-simple-select-label">
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                  >
                    {t('attributes.priority')}
                  </Typography>
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="priority"
                  value={watch('priority')}
                  defaultValue={row?.priority ?? ''}
                  label={t('attributes.priority')}
                  {...register('priority')}
                  size={isSmallScreen ? 'small' : 'medium'}
                >
                  <MenuItem key={1} value="HIGH">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                    >
                      {t('priority.HIGH')}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={2} value="MEDIUM">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                    >
                      {t('priority.MEDIUM')}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={3} value="LOW">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                    >
                      {t('priority.LOW')}
                    </Typography>
                  </MenuItem>
                </Select>
                <FormHelperText
                  sx={{
                    fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                  }}
                >
                  {errors.priority?.message}
                </FormHelperText>
              </FormControl>

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  sx={{
                    backgroundColor: '#d21426',
                    color: '#fff',
                    fontSize: isSmallScreen ? '0.775rem' : '1rem',
                  }}
                  fullWidth
                  size={isSmallScreen ? 'small' : 'medium'}
                  variant="contained"
                  color="error"
                  disabled={!isDirty}
                  onClick={() => {
                    setValue('status_id', row?.status_id);

                    reset();
                  }}
                >
                  {t('buttons.cancelChanges')}
                </LoadingButton>
                <LoadingButton
                  sx={{
                    backgroundColor: 'success.dark',
                    color: '#fff',
                    ':hover': {
                      backgroundColor: 'success.darker',
                    },
                    fontSize: isSmallScreen ? '0.775rem' : '1rem',
                  }}
                  fullWidth
                  size={isSmallScreen ? 'small' : 'medium'}
                  type="submit"
                  variant="contained"
                  disabled={!isValid || !isDirty}
                >
                  {t('pages.ticketDetails.modifyTicket')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
