import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Tab, Stack, Button, Container, Typography } from '@mui/material';

import { genericApiToaster } from 'src/services/utils';

import StatusGrid from 'src/sections/statuses/grid/statusGrid';

import AddStatus from './modals/AddStatus';
import EditStatus from './modals/EditStatus';
import { useAuth } from '../../hooks/useAuth';
import StatusTable from './table/statusTable';
import Iconify from '../../components/iconify';
import { useModal } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast';
import FilterPopover from './popovers/filterPopover';
import { statusColorMapper } from '../../utils/statusColorMapper';
import { getUsersByDepartment } from '../../services/user.service';
import UserListToolbar from '../../components/ToolBars/FormToolbar';
import { fetchDepartments } from '../../services/department.service';
import { getComparator, applySortFilter } from '../../utils/filterData';
import {
  modifyStatus,
  createStatus,
  deleteStatus,
  fetchStatuses,
} from '../../services/status.service';

const itemsPerPage = 10;

export default function StatusView() {
  const [departmentList, setDepartmentList] = useState([]);
  const { role, departmentId } = useAuth();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(departmentId);
  const [filteredStatusData, setFilteredStatusData] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const [filterName, setFilterName] = useState('');
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const [gridPage, setGridPage] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('statusId');
  const [isLoading, setIsLoading] = useState(false);
  const [filterPopover, setFilterPopover] = useState(null);
  const [searchBy, setSearchBy] = useState('byName');
  const [filterBy, setFilterBy] = useState('name');

  const { showToast, hideToast } = useToast();
  const { showModal, hideModal } = useModal();

  // add status
  const addStatus = (newStatusData) => {
    genericApiToaster(
      createStatus,
      newStatusData,
      t('successMessages.successCreatingStatus'),
      t('errorMessages.couldntCreateStatus'),
      showToast,
      hideToast,
      () => getStatuses()
    );
  };

  // edit status
  const editStatus = (editedStatusData) => {
    genericApiToaster(
      modifyStatus,
      editedStatusData,
      t('successMessages.successModifyingStatus'),
      t('errorMessages.couldntModifyStatus'),
      showToast,
      hideToast,
      () => getStatuses()
    );
  };

  // delete status
  const delStatus = (id) => {
    genericApiToaster(
      deleteStatus,
      id,
      t('successMessages.successDeletingStatus'),
      t('errorMessages.couldntDeleteStatus'),
      showToast,
      hideToast,
      () => getStatuses()
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getDepartMents = async () => {
    const departments = await fetchDepartments();
    setDepartmentList(departments);
  };
  const handleChangeRowsPerPage = (event) => {
    setTablePage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleChangeTablePage = (event, newPage) => {
    setTablePage(newPage);
  };

  useEffect(() => {
    if (role.authority === 'ROLE_ADMIN') getDepartMents();
    else setSelectedDepartmentId(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetailsClick = (status) => {
    showModal({
      title: t('pages.statusPage.editStatusForm'),
      Component: EditStatus,
      props: {
        hideModal,
        status,
        role: role.authority,
        departmentList,
        emailsList,
        editStatus,
      },
    });
  };

  const getStatuses = () => {
    const handleError = () => {
      showToast({
        message: t('errorMessages.couldntLoadStatuses'),
        severity: 'error',
        props: { hideToast },
      });
    };
    if (selectedDepartmentId === 0) {
      setIsLoading(true);
      fetchStatuses()
        .then((res) => {
          const updatedStatuses = res.map((status) => {
            const statusColor = statusColorMapper(status);

            if (status.statusId <= 5) {
              return {
                ...status,
                statusColor,
              };
            }
            return status;
          });
          setFilteredStatusData(updatedStatuses);
          setTablePage(0);
          setGridPage(0);
          setEmailsList([]);
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      fetchStatuses()
        .then((res) => {
          const updatedStatuses = res.map((status) => {
            const statusColor = statusColorMapper(status);
            if (status.statusId <= 5) {
              return {
                ...status,
                statusColor,
              };
            }
            return status;
          });
          setFilteredStatusData(
            updatedStatuses.filter((status) =>
              role.authority === 'ROLE_ADMIN'
                ? status.departmentId === selectedDepartmentId
                : status.departmentId === selectedDepartmentId || status.statusId < 6
            )
          );
          setTablePage(0);
          setGridPage(0);
          getUsersByDepartment(selectedDepartmentId).then((res) => {
            setEmailsList(res.filter((user) => !user.archived).map((user) => user.email));
          });
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentId]);

  const filteredStatus = useMemo(() => {
    const mappedData = filteredStatusData.map((status) => ({
      ...status,
      name: (() => {
        switch (status.name) {
          case 'OPEN':
            return t('status.OPEN');
          case 'ONHOLD':
            return t('status.ONHOLD');
          case 'CLOSED':
            return t('status.CLOSED');
          case 'UNASSIGNED':
            return t('status.UNASSIGNED');
          case 'RESOLVED':
            return t('status.RESOLVED');
          default:
            return status.name;
        }
      })(),
    }));

    return applySortFilter(
      mappedData,
      getComparator(order, orderBy),
      filterName,
      filterBy
    );
  }, [filteredStatusData, order, orderBy, filterName, filterBy, t]);

  const { isNotFound, searchIsNotFound } = useMemo(() => {
    const isNotFound = !filteredStatus.length;
    const searchIsNotFound = !filteredStatus.length && !!filterName;
    return { isNotFound, searchIsNotFound };
  }, [filteredStatus, filterName]);

  const handleFilterByName = (event) => {
    setGridPage(0);
    setTablePage(0);
    setFilterName(event.target.value);
  };

  const handleChangePageGrid = (event, newPage) => {
    setGridPage(newPage - 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterPopover(null);
  };

  return (
    <>
      <Helmet>
        <title>{t('pages.statusPage.statusSingle')}</title>
      </Helmet>
      <Container>
        <Stack px={3} direction="row" justifyContent="space-between">
          <Typography variant="h4"> {t('pages.statusPage.statusPlural')} </Typography>
          <Button
            variant="contained"
            id="add-status-btn"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() =>
              showModal({
                title: t('pages.statusPage.addStatusForm'),
                Component: AddStatus,
                props: {
                  hideModal,
                  emailsList,
                  departmentList,
                  role: role.authority,
                  addStatus,
                  currentUserDepartment: departmentId,
                },
              })
            }
            sx={{ marginRight: '1vh' }}
          >
            {t('pages.statusPage.newStatus')}
          </Button>
        </Stack>

        <Box mb={1} mt={1}>
          <UserListToolbar
            numSelected={0}
            model={t('pages.statusPage.statusSingle')}
            onFilterName={handleFilterByName}
            filterName={filterName}
            setFilterName={setFilterName}
            filterBy={searchBy}
            handleOpenFilter={handleOpenFilter}
            handleCloseFilter={handleCloseFilter}
          />
        </Box>
        <TabContext value={tabValue}>
          <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <TabList onChange={handleTabChange} aria-label="status-tabs">
              <Tab
                onClick={() => setOrderBy('statusId')}
                icon={<Iconify icon="gridicons:grid" />}
                value="1"
              />
              <Tab icon={<Iconify icon="gridicons:list-unordered" />} value="2" />
            </TabList>
          </Stack>
          <TabPanel value="1">
            <StatusGrid
              statusData={filteredStatus}
              itemsPerPage={itemsPerPage}
              page={gridPage}
              handleChangePage={handleChangePageGrid}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              filterName={filterName}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value="2">
            <StatusTable
              statusData={filteredStatus}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangeTablePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              filterName={filterName}
              isLoading={isLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>
      <FilterPopover
        setSearchBy={setSearchBy}
        handleCloseFilter={handleCloseFilter}
        filterPopover={filterPopover}
        setFilterTitle={setFilterName}
        setFilterBy={setFilterBy}
        departmentData={departmentList}
        setPage={setTablePage}
      />
    </>
  );
}
