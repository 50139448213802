import PropTypes from 'prop-types';

import { TableCell, Typography } from '@mui/material';

DataCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default function DataCell({ value }) {
  return (
    <TableCell align="left">
      <Typography variant="body2">{value}</Typography>
    </TableCell>
  );
}
