import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TablePagination } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

function TablePaginations({
  filteredEntity,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={filteredEntity.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={t('filters.rowsPerPage')}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: isSmallScreen && '0.65rem',
              },
              '& .MuiList-root': {
                padding: isSmallScreen && 0,
              },
            },
          },
        },
      }}
      sx={{
        '& .MuiTablePagination-toolbar': {
          fontSize: isSmallScreen && '0.65rem',
          minHeight: isSmallScreen ? '32px' : '50px',
        },
        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
          fontSize: isSmallScreen && '0.65rem',
        },
        '& .MuiTablePagination-select': {
          fontSize: isSmallScreen && '0.65rem',
        },
        '& .MuiTablePagination-actions': {
          '& button': {
            '& .MuiSvgIcon-root': {
              fontSize: isSmallScreen && '1rem',
            },
          },
        },
      }}
    />
  );
}

TablePaginations.propTypes = {
  filteredEntity: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

export default TablePaginations;
