import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Popper, MenuItem, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

function SubFilterPopper({
  subFilterPopover,
  handleCloseSubFilter,
  setFilterBy,
  setSearchBy,
  setSelectedFilter,
  setFilterName,
  handleCloseFilter,
  setPage,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <Popper
      open={Boolean(subFilterPopover.status)}
      anchorEl={subFilterPopover.status}
      onClose={handleCloseSubFilter}
      placement="left-start"
    >
      <Card
        sx={{
          p: 1,
          minwidth: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            boxShadow: '0.5px 0.5px 2 px',
          },
        }}
      >
        {['true', 'false'].map((enabled, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setFilterBy('enabled');
              setSearchBy('byStatus');
              setSelectedFilter('enabled');
              setFilterName(enabled);
              handleCloseFilter();
              handleCloseSubFilter();
              setPage(0);
            }}
            onTouchStart={() => {
              setFilterBy('enabled');
              setSearchBy('byStatus');
              setSelectedFilter('enabled');
              setFilterName(enabled);
              handleCloseFilter();
              handleCloseSubFilter();
              setPage(0);
            }}
          >
            <Typography
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {enabled === 'true' ? t('userStatus.active') : t('userStatus.inactive')}
            </Typography>
          </MenuItem>
        ))}
      </Card>
    </Popper>
  );
}

SubFilterPopper.propTypes = {
  subFilterPopover: PropTypes.object,
  handleCloseSubFilter: PropTypes.func,
  setFilterBy: PropTypes.func,
  setSearchBy: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  setFilterName: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  setPage: PropTypes.func,
};

export default SubFilterPopper;
