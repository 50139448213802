import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Box } from '@mui/system';
import {
  Grid,
  Card,
  Stack,
  Paper,
  Table,
  TableRow,
  Container,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { getComparator, applySortFilter } from 'src/utils/filterData';

import { genericApiToaster } from 'src/services/utils';
import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';
import {
  fetchAllClientCredentials,
  deleteMultipleClientCredentials,
} from 'src/services/client.credentials.service';

import Loader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import TableToolbar from 'src/components/ToolBars/TableToolbar';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';
import ActionButton from 'src/components/action-button/action-button';
import TablePaginations from 'src/components/table-pagination/TablePaginations';

import MenuPopover from './popovers/MenuPopover';
import SubFilterPopper from './popovers/SubFilterPopper';
import MainFilterPopper from './popovers/MainFilterPopper';
import ClientCredentialsRow from './table/ClientCredentialsRow';
import ClientCredentialsDetails from './modal/ClientCredentialsDetails';
import AddUpdateClientCredentials from './modal/AddUpdateClientCredentials';
import { TABLE_HEAD_CLIENT_CREDENTIALS } from './table/ClientCredentialsTableHeaders';

function ClientCredentialsView() {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [clientCredentials, setClientCredentials] = useState([]);
  const [selectedClientCredentials, setSelectedClientCredentials] = useState([]);
  const [currentPageClientCredentials, setCurrentPageClientCredentials] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const [openClientCredentialsDetails, setOpenClientCredentialsDetails] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('id');
  const [subFilterPopover, setSubFilterPopover] = useState({ status: null });
  const [searchBy, setSearchBy] = useState('byId');
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterBy, setFilterBy] = useState('id');
  const { showToast, hideToast } = useToast();
  const { showModal, hideModal } = useModal();
  const [isLoading, setIsloading] = useState(false);
  const [filterPopover, setFilterPopover] = useState(null);

  const isSelected = (id) => selectedClientCredentials.indexOf(id) !== -1;

  const handleFetchClientCredentials = async () => {
    setIsloading(true);
    genericApiToaster(
      fetchAllClientCredentials,
      [],
      '',
      t('errorMessages.couldntLoadClientCredentials'),
      showToast,
      hideToast,
      t,
      setClientCredentials,
      undefined,
      () => setIsloading(false)
    );
  };

  useEffect(() => {
    handleFetchClientCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredClientCredentials = useMemo(() => {
    const filteredData = applySortFilter(
      clientCredentials,
      getComparator(order, orderBy),
      filterName,
      filterBy
    );

    return filteredData;
  }, [filterName, order, orderBy, filterBy, clientCredentials]);

  useEffect(() => {
    // Update current page forms shareable when page, rowsPerPage, or formsShareable changes
    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    setCurrentPageClientCredentials(filteredClientCredentials.slice(startIdx, endIdx));
  }, [page, rowsPerPage, filteredClientCredentials]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 whenever rows per page changes
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageClientCredentials.map((n) => n.id);
    if (newSelecteds.every((id) => selectedClientCredentials.includes(id))) {
      setSelectedClientCredentials(
        selectedClientCredentials.filter((id) => !newSelecteds.includes(id))
      );
      return;
    }
    setSelectedClientCredentials([
      ...new Set([...selectedClientCredentials, ...newSelecteds]),
    ]);
  };

  useEffect(() => {
    const newSelectdsId = currentPageClientCredentials.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedClientCredentials.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageClientCredentials, page, selectedClientCredentials]);

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterPopover(null);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const { isNotFound, searchIsNotFound } = useMemo(() => {
    const isNotFound = !filteredClientCredentials.length;
    const searchIsNotFound = !filteredClientCredentials.length && !!filterName;
    return { isNotFound, searchIsNotFound };
  }, [filteredClientCredentials, filterName]);

  const handleClick = (event, id) => {
    setSelectedClientCredentials((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(id);

      if (selectedIndex === -1) {
        return [...prevSelected, id]; // Add new ID
      }

      return prevSelected.filter((item) => item !== id); // Remove existing ID
    });
  };

  const handleOpenClientCredentialsDetails = (row) => {
    setSelectedItem(row);
    setOpenClientCredentialsDetails(true);
  };

  const handleRemoveSelected = () => {
    setSelectedClientCredentials([]);
  };

  const handleOpenMenu = (event, row) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedItem(row);
  };

  const handleCloseMenu = () => {
    setPopoverAnchorEl(null);
  };

  const multipleDeleteSuccessCallback = (res, selected) => {
    showToast({
      message: t('successMessages.deleteMultipleClientCredentialsSuccess', {
        clientCredentialsDeleted: res.deletedCount,
        totalClientCredentials: selected.length,
      }),
      severity: 'success',
      props: { hideToast },
    });
    handleFetchClientCredentials();
    setSelectedClientCredentials([]);
    setPage(0);
  };

  const multipleClientCredentialsDelete = async (selected) => {
    genericApiToaster(
      deleteMultipleClientCredentials,
      [selected],
      '',
      t('errorMessages.deleteClientCredentialsError'),
      showToast,
      hideToast,
      t,
      (res) => multipleDeleteSuccessCallback(res, selected)
    );
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({
      status: null,
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={isSmallScreen ? 2 : 5}
      >
        <Typography
          style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
          mx={1}
        >
          {t('nav.clientCredentials')}
        </Typography>
        <Grid>
          <ActionButton
            id="add-client-cred-btn"
            onClick={() =>
              showModal({
                title: t('pages.clientCredentialsPage.addNewClientCredentials'),
                Component: AddUpdateClientCredentials,
                props: {
                  hideModal,
                  handleFetchClientCredentials,
                },
              })
            }
            icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
            text={t('buttons.addNewClientCredentials')}
            title={t('buttons.addNewClientCredentials')}
          />
        </Grid>
      </Stack>

      <Card>
        <TableToolbar
          numSelected={selectedClientCredentials.length}
          filterName={filterName}
          filterBy={searchBy}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByName}
          selected={selectedClientCredentials}
          handleRemoveSelected={handleRemoveSelected}
          deleteFunc={multipleClientCredentialsDelete}
          model={t(`nav.clientCredentials`)}
          setFilterName={setFilterName}
        />

        <ClientCredentialsDetails
          open={openClientCredentialsDetails}
          setOpen={setOpenClientCredentialsDetails}
          selectedRow={selectedItem}
        />

        <Box
          sx={{
            overflowX: 'auto',
            maxWidth: '100%',
          }}
        >
          <TableContainer component={Paper}>
            <Table size={isSmallScreen ? 'small' : 'medium'}>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD_CLIENT_CREDENTIALS}
                rowCount={filteredClientCredentials.length}
                numSelected={selectedClientCredentials.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                showCheckBox
                indeterminate={indeterminateCheckbox}
              />

              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13} sx={{ textAlign: 'center' }}>
                      <Loader size={150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {isNotFound ? (
                    <EmptyTable
                      filterName={filterName}
                      colSpan={12}
                      searchIsNotFound={searchIsNotFound}
                    />
                  ) : (
                    <TableBody>
                      {filteredClientCredentials
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((clientCredentials, idx) => {
                          const isItemSelected = isSelected(clientCredentials.id);
                          return (
                            <ClientCredentialsRow
                              key={idx}
                              clientCredentials={clientCredentials}
                              idx={idx}
                              isItemSelected={isItemSelected}
                              handleClick={handleClick}
                              handleOpenMenu={handleOpenMenu}
                              onRowClick={handleOpenClientCredentialsDetails}
                            />
                          );
                        })}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Box>

        {/* Table Pagination */}
        <TablePaginations
          filteredEntity={filteredClientCredentials}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />

        {/* menu popover */}
        <MenuPopover
          popoverAnchorEl={popoverAnchorEl}
          handleCloseMenu={handleCloseMenu}
          selectedItem={selectedItem}
          handleFetchClientCredentials={handleFetchClientCredentials}
          AddUpdateClientCredentials={AddUpdateClientCredentials}
          setSelectedClientCredentials={setSelectedClientCredentials}
          handleOpenClientCredentialsDetails={handleOpenClientCredentialsDetails}
        />

        {/* main popper */}
        <MainFilterPopper
          filterPopover={filterPopover}
          setFilterName={setFilterName}
          setFilterBy={setFilterBy}
          setSearchBy={setSearchBy}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          handleCloseFilter={handleCloseFilter}
          handleCloseSubFilter={handleCloseSubFilter}
          setSubFilterPopover={setSubFilterPopover}
          setPage={setPage}
        />

        {/* sub popper */}
        <SubFilterPopper
          subFilterPopover={subFilterPopover}
          handleCloseSubFilter={handleCloseSubFilter}
          setFilterBy={setFilterBy}
          setSearchBy={setSearchBy}
          setSelectedFilter={setSelectedFilter}
          setFilterName={setFilterName}
          handleCloseFilter={handleCloseFilter}
          setPage={setPage}
        />
      </Card>
    </Container>
  );
}

export default ClientCredentialsView;
