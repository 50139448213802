import { genericApiToaster } from 'src/services/utils';
import { getTaggedTickets } from 'src/services/ticket.permissions.service';

export default async function filterByTab(
  title,
  tickets,
  userId,
  currentUserRole,
  departmentId,
  setTicketsWithStatus,
  setFilterByData,
  showToast,
  hideToast,
  t
) {
  // Define filters based on the selected tab
  const tabFilters = {
    [t('ticketTabs.allTickets')]: () => {
      switch (currentUserRole) {
        case 'ROLE_AGENT':
          return tickets.filter(
            (ticket) => ticket.assignedTo === userId || ticket.createdBy === userId
          );
        case 'ROLE_CUSTOMER':
          return tickets.filter((ticket) => ticket.createdBy === userId);
        case 'ROLE_DEPARTMENTMANAGER':
          return tickets.filter((ticket) => ticket.departmentId === departmentId);
        default:
          return tickets;
      }
    },
    [t('ticketTabs.unassignedTickets')]: () =>
      tickets.filter(
        (ticket) =>
          ticket.statusObject?.systemMappedStatus === 'UNASSIGNED' &&
          ticket.departmentId === departmentId
      ),
    [t('ticketTabs.myTickets')]: () =>
      tickets.filter((ticket) => ticket.createdBy === userId),
    [t('ticketTabs.activeTickets')]: () =>
      tickets.filter(
        (ticket) =>
          ticket.assignedTo === userId &&
          ticket.statusObject?.systemMappedStatus === 'OPEN'
      ),
    [t('ticketTabs.onHoldTickets')]: () =>
      tickets.filter(
        (ticket) =>
          ticket.assignedTo === userId &&
          ticket.statusObject?.systemMappedStatus === 'ONHOLD'
      ),
    [t('ticketTabs.othersTickets')]: () =>
      tickets.filter((ticket) => ticket.createdBy !== userId),
    [t('ticketTabs.departmentTickets')]: () =>
      tickets.filter((ticket) => ticket.departmentId === departmentId),
    [t('ticketTabs.taggedTickets')]: async () => {
      await genericApiToaster(
        getTaggedTickets,
        userId,
        '',
        t('errorMessages.couldntLoadTicket'),
        showToast,
        hideToast,
        t,
        (res) => {
          const taggedTickets =
            res.length > 0
              ? tickets.filter((ticket) =>
                  res.some((taggedTicket) => taggedTicket.id === ticket.id)
                )
              : [];
          setTicketsWithStatus(taggedTickets);
          setFilterByData(taggedTickets);
        }
      );
    },
  };
  if (tabFilters[title]) {
    const filteredTickets = await tabFilters[title]();

    if (title !== t('ticketTabs.taggedTickets')) {
      setTicketsWithStatus(filteredTickets);
      setFilterByData(filteredTickets);
    }
  }
}
