export const cardTicketModalStyle = {
  boxShadow: '0.5px 0.5px 2px',

  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const listTicketModalStyle = {
  width: '100%',
  bgcolor: 'background.paper',
};

export const listItemTicketPermissionModalStyle = (selectedUsers, user) => ({
  '&:hover': {
    backgroundColor: selectedUsers.some((item) => item.id === user.id)
      ? '#b4d5fe'
      : '#F5F5F5',
  },
  backgroundColor: selectedUsers.some((item) => item.id === user.id)
    ? '#b4d5fe'
    : 'transparent',
  cursor: 'pointer',
});
