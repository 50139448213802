export default function updatePermissionListAfterAdding({
  users,
  permission,
  availableUserIds,
  permissionUsers,
}) {
  // create the list of users to add
  const usersToAdd = users
    .filter((user) =>
      permission === 'read'
        ? availableUserIds.has(user.id) // if permission is read no need to change permission for users who are already in the ticket
        : !permissionUsers.some(
            (permissionUser) => permissionUser.id === user.id && permissionUser.default // if permission is write then excluding the users with default permissons
          )
    )
    .map((user) => ({
      ...user,
      permissions: [
        permission === 'read' || permission === 'write',
        permission === 'write',
      ],
      default: false,
      avatarUrl: user.avatar, // adding missing attributes
    }));

  const mergedUsers = [
    ...usersToAdd,
    ...permissionUsers.filter((user) => !usersToAdd.some((u) => u.id === user.id)),
  ]; // setting new users list with the added users at top whil removing duplicates ( if a user's permission was modified then keep the one from the new list)
  return mergedUsers;
}
