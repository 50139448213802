import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/api/email-settings`;

export const getSettings = async () => {
  const res = await axios.get(url, { headers: getAuthorizationHeader() });
  return res;
};

export const updateSettings = async (data) => {
  const res = await axios.put(
    `${url}/update`,
    { ...data },
    { headers: getAuthorizationHeader() }
  );
  return res;
};
