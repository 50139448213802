import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { useToast } from 'src/hooks/useToast';

import { fetchUsers } from 'src/services/user.service';
import { genericApiToaster } from 'src/services/utils';
import { fetchAllGroups } from 'src/services/group.users.service';

import TicketForm from 'src/sections/ticketCreation/TicketForm';

import { useAuth } from '../../hooks/useAuth';
import { fetchFormById } from '../../services/form.service';
import { fetchDepartments } from '../../services/department.service';

export default function CreateTicketPage() {
  const [departments, setDepartments] = useState();
  const [favoriteFormData, setFavoriteFormData] = useState();
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const { state } = useLocation();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();

  const fetchDerpartmentsData = async () => {
    genericApiToaster(
      fetchDepartments,
      [],
      '',
      t('errorMessages.couldntLoadDep'),
      showToast,
      hideToast,
      t,
      setDepartments
    );
  };

  const fetchFavoriteFormData = async (id) => {
    genericApiToaster(
      fetchFormById,
      id,
      '',
      t('errorMessages.errorLoadingFormData'),
      showToast,
      hideToast,
      t,
      setFavoriteFormData
    );
  };

  const fetchAllUsers = async () => {
    const res = await fetchUsers();
    setUsers(res.filter((user) => !user.archived));
  };

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useAuth();
  const fetchGroups = async () => {
    const res = await fetchAllGroups(id);
    setGroups(res);
  };

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchDerpartmentsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.formId) {
      fetchFavoriteFormData(state.formId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <TicketForm
      departments={departments}
      favoriteForm={favoriteFormData}
      users={users}
      groups={groups}
    />
  );
}
