import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

import Copyright from 'src/components/copyright/CopyRight';
import LanguagePopover from 'src/components/LanguagePopover';

import Carousel from './carousel/carousel';
import Logo from '../../components/logo/Logo';
import {
  logoStyles,
  carouselStyles,
  childrenStyles,
  containerStyles,
  topContainerStyles,
  boxContainerStyles,
} from './styles';

export function LoginLayout({ children }) {
  return (
    <Grid container sx={{ minHeight: '100vh' }}>
      <Grid item xs={12} md={6} bgcolor="white">
        <Box sx={boxContainerStyles}>
          <Box sx={topContainerStyles}>
            <Logo sx={logoStyles} />
            <LanguagePopover />
          </Box>
          <Box sx={containerStyles}>
            <Box sx={childrenStyles}>{children}</Box>
            <br />
            <Copyright />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} bgcolor="white">
        <Box sx={carouselStyles}>
          <Carousel />
        </Box>
      </Grid>
    </Grid>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node,
};
