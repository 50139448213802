import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

// @mui
import {
  Card,
  Table,
  TableRow,
  Accordion,
  TableCell,
  TableBody,
  TableContainer,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import authService from 'src/services/auth.service';
import { genericApiToaster } from 'src/services/utils';
import { deleteTicket, multipleTicketDelete } from 'src/services/ticket.service';

import TableLoader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import TableToolbar from 'src/components/ToolBars/TableToolbar';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';
import TablePaginations from 'src/components/table-pagination/TablePaginations';

import TicketsTableBody from './tableBody';
import AdvancedFilter from './advancedFilter';
import { encrypt } from '../../../utils/hashCode';
import { ALL_TICKET_TABLE_HEAD } from '../tableHeads';
import FilterPopover from '../popovers/filterPopover';
import OptionsPopover from '../popovers/optionsPopover';

TicketsTable.propTypes = {
  isAccordionOpen: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterTitle: PropTypes.string,
  setFilterTitle: PropTypes.func,
  page: PropTypes.number,
  currentPageTickets: PropTypes.array,
  filteredTickets: PropTypes.array,
  ticketsData: PropTypes.array,
  getTicketsByDateRange: PropTypes.func,
  setSelectedItem: PropTypes.func,
  ticketsWithStatus: PropTypes.array,
  setPage: PropTypes.func,
  setFilterByData: PropTypes.func,
  departmentData: PropTypes.array,
  statusesList: PropTypes.array,
  setFilterBy: PropTypes.func,
  selectedItem: PropTypes.object,
  setOpenModifyTicket: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  isLoading: PropTypes.bool,
  generateCSVData: PropTypes.func,
};

export default function TicketsTable({
  isAccordionOpen,
  rowsPerPage,
  setRowsPerPage,
  departmentData,
  statusesList,
  setOpenModifyTicket,
  selectedItem,
  setFilterBy,
  order,
  setFilterTitle,
  isNotFound,
  searchIsNotFound,
  filterTitle,
  page,
  isLoading,
  orderBy,
  setOrder,
  setOrderBy,
  currentPageTickets,
  filteredTickets,
  ticketsData,
  getTicketsByDateRange,
  setSelectedItem,
  ticketsWithStatus,
  setPage,
  setFilterByData,
  generateCSVData,
}) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [showDelete, setShowDelete] = useState(true);
  const [selected, setSelected] = useState([]);
  const [searchBy, setSearchBy] = useState('byId');
  const [filterPopover, setFilterPopover] = useState(null);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);

  const [subFilterPopover, setSubFilterPopover] = useState({
    department: null,
    status: null,
    priority: null,
  });

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id: currentUserId } = useAuth();
  const currentUserRole = authService.verifyUserRole();
  const { showToast, hideToast } = useToast();
  const navigate = useNavigate();

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageTickets.map((n) => n.id);
    if (newSelecteds.every((id) => selected.includes(id))) {
      setSelected(selected.filter((id) => !newSelecteds.includes(id)));
      return;
    }
    setSelected([...new Set([...selected, ...newSelecteds])]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByTitle = (event) => {
    setPage(0);
    setFilterTitle(event.target.value);
  };

  const handleRemoveSelected = () => {
    setSelected([]);
  };

  const handleOpenMenu = (event, row) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedItem(row);
    if (currentUserRole === 'ROLE_AGENT' && row.createdBy !== currentUserId)
      setShowDelete(false);
    else setShowDelete(true);
  };

  const handleCloseMenu = () => {
    setPopoverAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // delete one ticket
  const removeTicket = async (id) => {
    genericApiToaster(
      deleteTicket,
      id,
      t('successMessages.successTicketRemove'),
      t('errorMessages.errorDeletingTicket'),
      showToast,
      hideToast,
      t,
      () => {
        getTicketsByDateRange();
        setSelected([]);
      }
    );
  };

  // Multiple delete tickets
  const deleteMultipleTicketsSuccessCallback = (res, ticketIds) => {
    showToast({
      message: t('successMessages.succesMultipleTicketsDelte', {
        ticketsDeleted: res.deletedCount,
        totalTickets: ticketIds.length,
      }),
      severity: 'success',
      props: { hideToast },
    });

    getTicketsByDateRange();
    setSelected([]);
  };

  const deleteMultipleTickets = async (ticketIds) => {
    genericApiToaster(
      multipleTicketDelete,
      [ticketIds],
      '',
      t('errorMessages.errorDeletingTicket'),
      showToast,
      hideToast,
      t,
      (res) => deleteMultipleTicketsSuccessCallback(res, ticketIds)
    );
  };

  const handleClickRow = (row) => {
    setSelectedItem(row);
    navigate(`/tickets/${encrypt(row.id)}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ticketsData.length) : 0),
    [page, rowsPerPage, ticketsData]
  );

  useEffect(() => {
    const newSelectds = currentPageTickets.map((n) => n.id);
    if (newSelectds.every((id) => selected.includes(id))) {
      // if all items of this page are selected then the checkbox state is set to indeterminate ( checked but with - icon )
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPageTickets]);

  useEffect(() => {
    const newSelectdsId = currentPageTickets.map((n) => n.id);
    if (newSelectdsId.every((id) => selected.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <Card sx={{ mt: '10px' }}>
        <Accordion expanded={isAccordionOpen}>
          <AccordionSummary sx={{ display: 'none' }}>{}</AccordionSummary>
          <AccordionDetails>
            <AdvancedFilter
              ticketsWithStatus={ticketsWithStatus}
              setFilterByData={setFilterByData}
            />
          </AccordionDetails>
        </Accordion>
        <TableToolbar
          numSelected={selected.length}
          filterName={filterTitle}
          setFilterName={setFilterTitle}
          filterBy={searchBy}
          deleteFunc={deleteMultipleTickets}
          selected={selected}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByTitle}
          handleRemoveSelected={handleRemoveSelected}
          model="Ticket"
          generateCSVData={generateCSVData}
          data={ticketsData}
        />

        <Scrollbar>
          <TableContainer>
            <Table size={isSmallScreen ? 'small' : 'medium'}>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={ALL_TICKET_TABLE_HEAD}
                rowCount={filteredTickets.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                indeterminate={indeterminateCheckbox}
                onSelectAllClick={handleSelectAllClick}
                showCheckBox={currentUserRole !== 'ROLE_AGENT'}
              />

              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={isSmallScreen ? 6 : 13}
                      sx={{ textAlign: 'center' }}
                    >
                      <TableLoader size={isSmallScreen ? 50 : 150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TicketsTableBody
                    currentPageTickets={currentPageTickets}
                    emptyRows={emptyRows}
                    handleOpenMenu={handleOpenMenu}
                    handleClick={handleClick}
                    handleClickRow={handleClickRow}
                    selected={selected}
                  />
                  {isNotFound && (
                    <EmptyTable
                      filterName={filterTitle}
                      searchIsNotFound={searchIsNotFound}
                      colSpan={isSmallScreen ? 6 : 12}
                    />
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginations
          filteredEntity={filteredTickets}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <FilterPopover
        setSubFilterPopover={setSubFilterPopover}
        subFilterPopover={subFilterPopover}
        handleCloseSubFilter={handleCloseSubFilter}
        setSearchBy={setSearchBy}
        setPage={setPage}
        departmentData={departmentData}
        handleCloseFilter={handleCloseFilter}
        filterPopover={filterPopover}
        setFilterTitle={setFilterTitle}
        setFilterBy={setFilterBy}
        statusesList={statusesList}
      />

      <OptionsPopover
        removeTicket={removeTicket}
        selectedItem={selectedItem}
        popoverAnchorEl={popoverAnchorEl}
        handleCloseMenu={handleCloseMenu}
        setOpenModifyTicket={setOpenModifyTicket}
        showDelete={showDelete}
      />
    </>
  );
}
