import React from 'react';
import { Helmet } from 'react-helmet-async';

import ProfileView from 'src/sections/profile/index';

export default function ProfilePage() {
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System Log in Page </title>
      </Helmet>
      <ProfileView />
    </>
  );
}
