import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/users/`;

// Fetch users

export const fetchUsers = async () => {
  const result = await axios.get(`${url}all`, { headers: getAuthorizationHeader() });
  return result.data;
};
// Update user
export const updateUser = async ({
  id,
  givenName,
  familyName,
  username,
  appUserRole,
  email,
  phoneNumber,
  departmentId,
  avatar,
}) => {
  let fullName = '';
  if (!username) fullName = `${givenName} ${familyName}`;
  else fullName = username;
  const result = await axios.put(
    `${url}${id}`,
    {
      givenName,
      familyName,
      appUserRole,
      email,
      phoneNumber,
      username: fullName,
      avatar,
      departmentId,
    },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// Create User
export const createUser = async ({
  givenName,
  familyName,
  appUserRole,
  email,
  phoneNumber,
  departmentId,
}) => {
  const result = await axios.post(
    `${url}create-account`,
    {
      givenName,
      familyName,
      appUserRole,
      email,
      phoneNumber,
      username: `${givenName} ${familyName}`,
      departmentId,
    },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// create users from csv
export const uploadUsers = async (formData) => {
  const res = await axios.post(`${API_URL}/csv/upload`, formData, {
    headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

// Delete user
export const deleteUser = async (id) => {
  const result = await axios.delete(`${url}${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// Unarchive user
export const unarchiveUser = async (id) => {
  const result = await axios.put(
    `${url}unarchive/${id}`,
    {},
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// check delete
export const checkDelete = async (id) => {
  const result = await axios.get(`${url}check/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// delete multiple users
export const multipleUsersDelete = async (IDsList) => {
  const queryString = IDsList.map((id) => `ids=${id}`).join('&');
  const fullUrl = `${url}multiple?${queryString}`;
  const result = await axios.delete(fullUrl, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get user by ID
export const getUserById = async (id) => {
  const res = await axios.get(`${url}${id}`, { headers: getAuthorizationHeader() });
  return res.data;
};

// get users by department
export const getUsersByDepartment = async (depId) => {
  const res = await axios.get(`${url}by-department/${depId}`, {
    headers: getAuthorizationHeader(),
  });
  return res.data;
};

// change password
export const changePassword = async ({ id, oldPassword, newPassword }) => {
  const res = await axios.post(
    `${url}change-password/${id}`,
    { oldPassword, newPassword },
    { headers: getAuthorizationHeader() }
  );
  return res.data;
};

// reset password
export const resetPasswordUsingToken = async (resetToken, newPassword) => {
  const res = await axios.post(
    `${url}reset-password`,
    { resetToken, newPassword },
    { headers: { Authorization: `Bearer ${resetToken}` } }
  );
  return res.data;
};

// generate password reset token
export const generatePasswordUsingToken = async (email) => {
  const res = await axios.post(`${url}generate-reset-token`, { email });
  return res.data;
};
// Download users as csv

export const downloadUsers = async () => {
  const result = await axios.get(`${API_URL}/csv/download/users`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
}; // unused

// ## ADMIN Dashboard Services ##

export const getUserCountByRole = async (role) => {
  const result = await axios.get(`${url}count-by-role`, {
    headers: getAuthorizationHeader(),
    params: {
      role,
    },
  });
  return result.data;
};
