import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';

import Iconify from 'src/components/iconify';

import { styles } from '../styles';

export default function UserDetails({ department, startDate }) {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Stack spacing={1}>
      <Stack sx={styles.iconBox}>
        <Iconify icon="eva:question-mark-circle-outline" sx={{ mr: 1 }} />
        <Typography variant="h6">{t('pages.profilePage.userInfo')}</Typography>
      </Stack>
      <Card sx={styles.userInfoCard}>
        <Stack spacing={1}>
          <Typography>
            <b>{t('pages.profilePage.role')}: </b>
            {t(`roles.${user.appUserRole}`)}
          </Typography>
          {department && (
            <Typography>
              <b>{t('attributes.department')} : </b>
              {department}
            </Typography>
          )}
          <Typography>
            <b>{t('pages.profilePage.startedAt')}: </b>
            {startDate}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
}

UserDetails.propTypes = {
  department: PropTypes.string,
  startDate: PropTypes.string,
};
