import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Stack,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';

import { phoneNumberSplit } from 'src/utils/phoneNumberSplit';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from 'src/utils/regex';

import Iconify from 'src/components/iconify';

import { styles } from '../styles';
import countries from '../../../locale/phoneCode';

export default function UserInfoForm({ modifyUser }) {
  const { i18n, t } = useTranslation();
  const { user } = useAuth();
  const { showToast, hideToast } = useToast();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: user.id,
      givenName: user.givenName,
      familyName: user.familyName,
      username: user.username,
      countryCode: phoneNumberSplit(user?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(user?.phoneNumber).partTwo,
      email: user.email,
      avatar: user.avatar,
    },
  });

  useMemo(() => {
    const defaultValues = {
      id: user.id,
      givenName: user.givenName,
      familyName: user.familyName,
      username: user.username,
      countryCode: phoneNumberSplit(user?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(user?.phoneNumber).partTwo,
      email: user.email,
      avatar: user.avatar,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    countries.sort((a, b) => a[i18n.language].localeCompare(b[i18n.language]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelChanges = () => {
    reset();
  };

  const onSubmit = async (data) => {
    data.phoneNumber = `(${data.countryCode}) ${data.phoneNumber}`;

    setLoading(true);
    try {
      await modifyUser(data);
      showToast({
        message: t('successMessages.successUpdate'),
        severity: 'success',
        props: { hideToast },
      });
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntUpdateInfo'),
        severity: 'error',
        props: { hideToast },
      });
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <Box sx={styles.infoBox}>
      <Box>
        <Stack spacing={1}>
          <Stack sx={styles.iconBox}>
            <Iconify icon="eva:edit-2-outline" sx={{ mr: 1 }} />
            <Typography variant="h6">{t('pages.profilePage.editInfoHere')}:</Typography>
          </Stack>
          <Card sx={styles.formCard}>
            <form onSubmit={handleSubmit(onSubmit)} key={user.id}>
              <Stack mb={3} direction="row" spacing={3}>
                <TextField
                  fullWidth
                  name="givenName"
                  label={t('attributes.firstName')}
                  defaultValue={user.givenName}
                  error={!!errors.givenName}
                  helperText={errors.givenName?.message || ''}
                  {...register('givenName', {
                    required: t('formControl.enterName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minName'),
                    },
                  })}
                />
                <TextField
                  fullWidth
                  name="familyName"
                  label={t('attributes.lastName')}
                  autoComplete="family-name"
                  error={!!errors.familyName}
                  helperText={errors.familyName?.message || ''}
                  {...register('familyName', {
                    required: t('formControl.enterLastName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minLastName'),
                    },
                  })}
                />
              </Stack>
              <Stack spacing={3}>
                <TextField
                  name="username"
                  label={t('attributes.userName')}
                  autoComplete="username"
                  error={!!errors.username}
                  helperText={errors.username?.message || ''}
                  {...register('username', {
                    required: t('formControl.enterName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minName'),
                    },
                  })}
                />
                <Stack direction="row" spacing={1}>
                  <FormControl sx={{ width: '20%' }}>
                    <InputLabel id="country-code-label">
                      {t('attributes.country')}
                    </InputLabel>
                    <Select
                      labelId="country-code-label"
                      label={t('attributes.country')}
                      id="countryCode"
                      name="countryCode"
                      defaultValue={phoneNumberSplit(user?.phoneNumber).partOne}
                      value={watch('countryCode') || '+39'} // controlled Select
                      renderValue={(countryCode) => `(${countryCode})`}
                      {...register('countryCode', {
                        required: t('formControl.selectCountryCode'),
                      })}
                    >
                      {countries?.length > 0 ? (
                        countries.map((country, index) => (
                          <MenuItem key={index} value={country.code}>
                            {`${country[i18n.language]}  (${country.code})`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled value="">
                          {t('infoMessages.noOptions')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    name="phoneNumber"
                    type="number"
                    label={t('attributes.phoneNumber')}
                    autoComplete="tel-local"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message || ''}
                    {...register('phoneNumber', {
                      required: t('formControl.enterPhoneNumber'),
                      pattern: {
                        value: PHONENUMBER_REGEX,
                        message: t('formControl.invalidPhoneNumber'),
                      },
                    })}
                  />
                </Stack>
                <TextField
                  disabled
                  name="email"
                  label={t('pages.usersPage.form.emailAdress')}
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email?.message || ''}
                  {...register('email', {
                    required: t('formControl.enterEmail'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('formControl.enterEmail'),
                    },
                  })}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{ backgroundColor: '#d21426', color: '#fff' }}
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={handleCancelChanges}
                    disabled={!isDirty}
                  >
                    {t('buttons.cancelChanges')}
                  </LoadingButton>
                  <LoadingButton
                    sx={{
                      backgroundColor: 'success.dark',
                      color: '#fff',
                      ':hover': {
                        backgroundColor: 'success.darker',
                      },
                    }}
                    fullWidth
                    loading={loading}
                    type="submit"
                    id="update-info"
                    variant="contained"
                    disabled={!isValid || !isDirty}
                  >
                    {t('buttons.update')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Stack>
      </Box>
    </Box>
  );
}

UserInfoForm.propTypes = {
  modifyUser: PropTypes.func.isRequired,
};
