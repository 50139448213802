export default function generateUpdateMessage({
  message,
  statusesList,
  username,
  t,
  usersList,
}) {
  const messageObject = JSON.parse(message);
  const getStatusName = (statusId) => {
    const status = statusesList.find((status) => status.statusId === statusId);
    return parseInt(statusId, 10) <= 5 ? t(`status.${status?.name}`) : status?.name;
  };
  const updatesList = Object.keys(messageObject).map((key) => {
    const entry = messageObject[key];
    let prevVal = entry.previousValue;
    let newVal = entry.newValue;

    switch (key) {
      case 'Status':
        prevVal = getStatusName(prevVal);
        newVal = getStatusName(newVal);
        if (prevVal === 'status.undefined' || newVal === 'status.undefined') {
          return undefined;
        }
        break;
      case 'Priority':
        prevVal = t(`priority.${prevVal}`);
        newVal = t(`priority.${newVal}`);
        break;
      case 'Description':
        return t('pages.ticketDetails.ticketUpdateDescriptionLog', {
          user: username || '',
        });
      case 'Creation':
        return t('pages.ticketDetails.ticketCreatedLog', {
          user: username || '',
        });

      case 'Tagged':
        return t('pages.ticketDetails.userTaggedLog', {
          username,
          username2: entry.userName,
          permission: entry.write
            ? t('pages.ticketDetails.readAndModify')
            : t('pages.ticketDetails.onlyRead'),
        });

      case 'Untagged':
        return t('pages.ticketDetails.userUntaggedLog', {
          username,
          username2: entry.userName,
        });

      case 'updatePerm':
        return t('pages.ticketDetails.updatePermLog', {
          username,
          username2: entry.userName,
          permission: entry.write
            ? t('pages.ticketDetails.readAndModify')
            : t('pages.ticketDetails.onlyRead'),
        });

      case 'Assigned To': {
        const prevUser =
          usersList.find((user) => user.id === parseInt(prevVal, 10))?.username ||
          prevVal;
        const newUser =
          usersList.find((user) => user.id === parseInt(newVal, 10))?.username || newVal;

        return t('pages.ticketDetails.ticketReassignedLog', {
          user: username || '',
          previousAgent: prevUser,
          newAgent: newUser,
        });
      }

      default:
        break;
    }

    return t('pages.ticketDetails.ticketUpdateLog', {
      user: username || '',
      attribute: t(`attributes.${key.toLowerCase()}`),
      prevValue: prevVal,
      newValue: newVal,
    });
  });
  return updatesList;
}
