export const TABLE_HEAD_ADMIN = [
  { id: 'givenName', name: 'fullName', label: 'user.name', alignRight: false },
  { id: 'email', name: 'email', label: 'user.email', alignRight: false },
  {
    id: 'departmentName',
    name: 'departmentName',
    label: 'user.department',
    alignRight: false,
  },
  { id: 'appUserRole', name: 'appUserRole', label: 'user.role', alignRight: false },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'user.phoneNumber',
    alignRight: false,
  },
  { id: 'status', name: 'status', label: 'user.status', alignRight: false },
  { id: 'options', label: 'user.options', sticky: true },
];

export const TABLE_HEAD_DEPMANAGER = [
  { id: 'givenName', name: 'name', label: 'user.name', alignRight: false },
  { id: 'email', name: 'email', label: 'user.email', alignRight: false },
  { id: 'appUserRole', name: 'role', label: 'user.role', alignRight: false },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'user.phoneNumber',
    alignRight: false,
  },
  { id: 'status', name: 'status', label: 'user.status', alignRight: false },
  { id: 'options', label: 'user.options', sticky: true },
];
