import { Outlet, Navigate } from 'react-router-dom';

// layouts

import Page404 from '../pages/404/Page404';
import LoginPage from '../pages/Login/loginPage';
import FirstLogin from '../pages/firstLogin/FirstLogin';
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute';
// ----------------------------------------------------------------------

const FirstLoginRoutes = {
  path: '/',
  element: <Outlet />,
  children: [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/firstLogin',
      element: (
        <ProtectedRoute>
          <FirstLogin />
        </ProtectedRoute>
      ),
    },
    { path: '404', element: <Page404 firstLogin /> },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ],
};

export default FirstLoginRoutes;
