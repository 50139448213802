import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/status/`;

// get all statuses
export const fetchStatuses = async () => {
  const result = await axios.get(url, { headers: getAuthorizationHeader() });
  return result.data;
};

// get status by ID
export const fetchStatusByID = async ({ id }) => {
  const result = await axios.get(`${url}${id}`, { headers: getAuthorizationHeader() });
  return result.data;
}; // unused but may be useful in the future

// create status
export const createStatus = async ({
  name,
  color,
  description,
  correspondences,
  departmentId,
  systemMappedStatus,
}) => {
  const result = await axios.post(
    url,
    {
      name,
      color,
      description,
      correspondences,
      departmentId,
      systemMappedStatus,
    },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// modify status
export const modifyStatus = async ({
  id,
  name,
  color,
  description,
  correspondences,
  departmentId,
  systemMappedStatus,
}) => {
  const result = await axios.put(
    `${url}${id}`,
    {
      name,
      color,
      description,
      correspondences,
      departmentId,
      systemMappedStatus,
    },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// delete status
export const deleteStatus = async (id) => {
  const result = await axios.delete(`${url}${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};
