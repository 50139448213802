import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tooltip } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import AddUsersPermissionsForm from './UsersPermissionsTab';
import AddGroupsPermissionsForm from './groupsPermission/GroupsPermissionsTab';

AddUsersPermissionsModal.propTypes = {
  usersList: PropTypes.array,
  groupList: PropTypes.array,
  ticketId: PropTypes.string,
  ticketUsersIds: PropTypes.array,
  ticketGroupsIds: PropTypes.array,
  setPermissionUsers: PropTypes.func,
  permissionUsers: PropTypes.array,
  addMultiplePermissions: PropTypes.func,
};
export default function AddUsersPermissionsModal({
  usersList,
  ticketId,
  ticketUsersIds,
  groupList,
  ticketGroupsIds,
  setPermissionUsers,
  permissionUsers,
  addMultiplePermissions,
}) {
  const [tabsValue, setTabsValue] = useState('1');
  const { isSmallScreen } = useResponsiveScreen();

  const availableUserIds = useMemo(
    () =>
      new Set(
        usersList
          .filter((user) => !ticketUsersIds.includes(user.id))
          .map((user) => user.id)
      ),
    [usersList, ticketUsersIds]
  );

  const handleTabsChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const { t } = useTranslation();

  return (
    <TabContext value={tabsValue}>
      <Box
        sx={{
          marginLeft: '30px',
          marginRight: isSmallScreen ? '10px' : '30px',
          borderBottom: 1,
          borderColor: 'divider',
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TabList
          onChange={handleTabsChange}
          aria-label="ticket details tabs permission "
          sx={{ width: '100%' }}
        >
          <Tooltip title={t('pages.ticketDetails.AddUser')}>
            <Tab
              sx={{ width: '50%' }}
              icon={
                <Iconify width={isSmallScreen ? 20 : 30} icon="flowbite:user-outline" />
              }
              style={{ color: '#121212' }}
              iconPosition="bottom"
              value="1"
            />
          </Tooltip>
          <Tooltip title={t('pages.ticketDetails.AddGroup')}>
            <Tab
              sx={{ width: '50%' }}
              icon={
                <Iconify
                  width={isSmallScreen ? 22 : 30}
                  icon="flowbite:users-group-outline"
                />
              }
              style={{ color: '#121212' }}
              iconPosition="bottom"
              value="2"
            />
          </Tooltip>
        </TabList>
      </Box>
      <TabPanel value="1" xs={5} sm={5} md={2} sx={{ padding: '0px 24px' }}>
        <AddUsersPermissionsForm
          usersList={usersList}
          ticketId={ticketId}
          ticketUsersIds={ticketUsersIds}
          availableUserIds={availableUserIds}
          setPermissionUsers={setPermissionUsers}
          permissionUsers={permissionUsers}
          addMultiplePermissions={addMultiplePermissions}
        />
      </TabPanel>
      <TabPanel value="2" sx={{ padding: '0px 24px' }}>
        <AddGroupsPermissionsForm
          groupList={groupList.filter((group) =>
            group.users.some((user) => availableUserIds.has(user.id))
          )}
          ticketGroupsIds={ticketGroupsIds}
          availableUserIds={availableUserIds}
          ticketId={ticketId}
          setPermissionUsers={setPermissionUsers}
          permissionUsers={permissionUsers}
          addMultiplePermissions={addMultiplePermissions}
        />
      </TabPanel>
    </TabContext>
  );
}
