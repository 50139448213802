export const TABLE_HEAD_CLIENT_CREDENTIALS = [
  { id: 'id', name: 'id', label: 'clientCredentials.id', alignRight: false },
  {
    id: 'clientName',
    name: 'clientName',
    label: 'clientCredentials.clientName',
    alignRight: false,
  },
  {
    id: 'clientDomain',
    name: 'clientDomain',
    label: 'clientCredentials.clientDomain',
    alignRight: false,
  },
  {
    id: 'enabled',
    name: 'enabled',
    label: 'clientCredentials.status',
    alignCenter: true,
  },
  {
    id: 'clientId',
    name: 'clientId',
    label: 'clientCredentials.clientId',
    alignCenter: true,
  },
  {
    id: 'clientSecret',
    name: 'clientSecret',
    label: 'clientCredentials.clientSecret',
    alignCenter: true,
  },

  {
    id: 'createdAt',
    name: 'createdAt',
    label: 'clientCredentials.createdAt',
    alignCenter: true,
  },
  {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'clientCredentials.updatedAt',
    alignCenter: true,
  },
  { id: 'actions', name: 'actions', label: 'clientCredentials.actions', sticky: true },
];
