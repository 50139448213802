import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabPanel } from '@mui/lab';
import { Box, Stack, Button, Typography } from '@mui/material';

import { useToast } from '../../../../hooks/useToast';
import { linkBoxStyle, TypographyStyle } from './style';

function IFrameSection({ token, handleRemoveToken, handlePopoverClose }) {
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();

  // Use useMemo to generate the iframe HTML code.
  const iframeCode = useMemo(
    () =>
      `<iframe title="iframe form" width="100%" height="680px" src="${process.env.REACT_APP_DOMAIN_URL}/create-ticket?form=${token}"  allowFullScreen style="border: none;" ></iframe>`,
    [token]
  );

  const handleCopyIframe = async () => {
    await navigator.clipboard.writeText(iframeCode);
    showToast({
      message: t('successMessages.successIFrameCopied'),
      severity: 'success',
      props: { hideToast },
    });
    handlePopoverClose();
  };

  return (
    <TabPanel value="1">
      {token ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            {t('pages.formPage.iframePreview')}
          </Typography>

          <Box sx={linkBoxStyle}>
            <Typography variant="body1" sx={TypographyStyle} title={iframeCode}>
              {iframeCode}
            </Typography>
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
            <Button
              onClick={() => handleRemoveToken()}
              variant="outlined"
              color="secondary"
            >
              {t('buttons.cancel')}
            </Button>
            <Button onClick={handleCopyIframe} variant="contained" color="primary">
              {t('buttons.copyIframe')}
            </Button>
          </Stack>
        </>
      ) : (
        <Typography variant="h6" gutterBottom mt={2}>
          {t('pages.formPage.iFrameNotAvailable')}
        </Typography>
      )}
    </TabPanel>
  );
}

IFrameSection.propTypes = {
  token: PropTypes.string,
  handleRemoveToken: PropTypes.func,
  handlePopoverClose: PropTypes.func,
};

export default IFrameSection;
