import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;
const url = `${API_URL}/favorites`;

// Get all favorites
export const fetchAllFavorites = async () => {
  const result = await axios.get(url, { headers: getAuthorizationHeader() });
  return result.data;
};

// Get favorite by ID
export const fetchFavoriteById = async (id) => {
  const result = await axios.get(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// Create a new favorite
export const createFavorite = async ({ type, typeId, userId }) => {
  const result = await axios.post(
    url,
    { type, typeId, userId },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// Update an existing favorite by ID
export const updateFavorite = async ({ id, type, typeId, userId }) => {
  const result = await axios.put(
    `${url}/${id}`,
    { type, typeId, userId },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// Delete a favorite by ID
export const deleteFavorite = async (id) => {
  const result = await axios.delete(`${url}/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get favorites by user ID
export const fetchFavoritesByUserId = async (userId) => {
  const result = await axios.get(`${url}/user/${userId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get favorites by type
export const fetchFavoritesByType = async (type) => {
  const result = await axios.get(`${url}/type/${type}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// Get favorites by type and typeId
export const fetchFavoritesByTypeAndTypeId = async ({ type, typeId }) => {
  const result = await axios.get(`${url}/type/${type}/id/${typeId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
