import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import { Checkbox, TableRow, TableBody, TableCell, IconButton } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { convertLocalTime } from 'src/utils/convertTime';

import authService from 'src/services/auth.service';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

TicketsTableBody.propTypes = {
  currentPageTickets: PropTypes.array,
  emptyRows: PropTypes.number,
  handleOpenMenu: PropTypes.func,
  handleClick: PropTypes.func,
  handleClickRow: PropTypes.func,
  selected: PropTypes.array,
};

export default function TicketsTableBody({
  currentPageTickets,
  emptyRows,
  handleOpenMenu,
  handleClickRow,
  handleClick,
  selected,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id: currentUserId } = useAuth();
  const currentUserRole = authService.verifyUserRole();
  return (
    <TableBody>
      {currentPageTickets.map((row) => {
        const {
          id,
          title,
          category,
          priority,
          createdAt,
          updatedAt,
          department,
          customerName,
          statusObject,
          agentName,
          formName,
          createdBy,
          assignedTo,
        } = row;
        let statusName = '';
        if (statusObject) {
          statusName =
            statusObject.statusId < 6
              ? t(`status.${statusObject.name}`)
              : statusObject.name;
        }
        const selectedTicket = selected.indexOf(id) !== -1;
        const createdAtStr = format(
          convertLocalTime(new Date(createdAt)),
          'dd/MM/yyyy HH:mm:ss'
        );
        const updatedAtStr = updatedAt
          ? format(convertLocalTime(new Date(updatedAt)), 'dd/MM/yyyy HH:mm:ss')
          : '-';
        return (
          <TableRow
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClickRow(row)}
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={selectedTicket}
          >
            {currentUserRole !== 'ROLE_AGENT' && (
              <TableCell padding="checkbox">
                <Checkbox
                  name="checkBox"
                  checked={selectedTicket}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, id);
                  }}
                  sx={{
                    '.MuiSvgIcon-root': {
                      width: isSmallScreen ? 18 : 24,
                      height: isSmallScreen ? 18 : 24,
                    },
                  }}
                />
              </TableCell>
            )}
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              #{id}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {title}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {department}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {t(`priority.${priority}`)}
            </TableCell>
            <TableCell align="left">
              <Label
                color={statusObject?.color || 'info'}
                sx={{
                  fontSize: isSmallScreen && '0.65rem',
                }}
              >
                {statusName}
              </Label>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {category}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {customerName !== 'none'
                ? customerName || 'customer'
                : t('pages.ticketDetails.anonymous')}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {createdAtStr}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {updatedAtStr}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {agentName || t('ticket.unknown')}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {formName || '-'}
            </TableCell>

            <TableCell
              align="center"
              sx={{
                position: 'sticky',
                zIndex: '100',
                right: 0,
                backgroundColor: 'white',
              }}
            >
              <IconButton
                disabled={
                  currentUserRole !== 'ROLE_ADMIN' &&
                  currentUserId !== assignedTo &&
                  currentUserId !== createdBy
                }
                size={isSmallScreen ? 'small' : 'large'}
                color="inherit"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenMenu(event, row);
                }}
              >
                <Iconify
                  icon="eva:more-vertical-fill"
                  sx={{
                    width: 20,
                    height: isSmallScreen ? 16 : 20,
                  }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  );
}
