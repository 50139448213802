import { useTranslation } from 'react-i18next';

import { Box, Typography, LinearProgress } from '@mui/material';

export default function Loader() {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <LinearProgress sx={{ width: '80%', mb: 2 }} />
      <Typography variant="body2">{t('notifications.loading')}</Typography>
    </Box>
  );
}
