import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

import enJSON from './locale/en.json';
import itJSON from './locale/it.json';
import arJSON from './locale/ar.json';

const lang = JSON.parse(secureLocalStorage.getItem('language'));

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    it: { ...itJSON },
    ar: { ...arJSON },
  },
  lng: lang?.value || 'it',
});
