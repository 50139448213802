import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';

import TextMessage from './TextMessage';
import MediaMessage from './MediaMessage';
import UpdateMessage from './UpdateMessage';

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  messageType: PropTypes.string,
  userId: PropTypes.number,
  time: PropTypes.string,
  fetchUser: PropTypes.func,
  statusesList: PropTypes.array,
  departmentUsers: PropTypes.array,
};
export default function Message({
  message,
  messageType,
  userId,
  time,
  fetchUser,
  statusesList,
  departmentUsers,
}) {
  const [user, setUser] = useState({
    id: '',
    username: '',
    appUserRole: '',
  });
  const { id: currentUserId } = useAuth();

  useEffect(() => {
    fetchUser(userId, setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMine = useMemo(() => currentUserId === userId, [currentUserId, userId]);

  return (
    <Stack spacing={2} p={1}>
      {messageType === 'UPDATE' && (
        <UpdateMessage
          message={message}
          time={time}
          user={user}
          statusesList={statusesList}
          departmentUsers={departmentUsers}
        />
      )}
      {messageType === 'FILE' && (
        <MediaMessage message={message} time={time} user={user} isMine={isMine} />
      )}
      {messageType === 'MESSAGE' && (
        <TextMessage message={message} time={time} isMine={isMine} user={user} />
      )}
    </Stack>
  );
}
