import { useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Link,
  Stack,
  Alert,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import Iconify from '../../../components/iconify';
import { EMAIL_REGEX } from '../../../utils/regex';
import {
  emailStyles,
  inputStyles,
  labelStyles,
  buttonStyles,
  signInStyles,
} from './styles';

LoginForm.propTypes = {
  toggleForgotPassword: PropTypes.func,
};

export default function LoginForm({ toggleForgotPassword }) {
  const HoverableText = styled('p')({
    cursor: 'pointer',
  });
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = () => {
    toggleForgotPassword();
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { t } = useTranslation();

  const handleClick = () => {};

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await login(data);

      const { firstLogin, id } = jwtDecode(res.authenticationToken);

      if (firstLogin) {
        navigate('/firstLogin', {
          replace: true,
          state: { userId: id, oldPassword: data.password },
        });
      } else {
        navigate('/dashboard', { replace: true });
      }
    } catch (e) {
      if (e.response?.data === 'User account is archived and cannot sign in') {
        setError(t('pages.login.archivedUser'));
      } else {
        setError(`${t('pages.login.email/password')} ${t('pages.login.valueIncorrect')}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography alignSelf="center" sx={signInStyles}>
          {t('pages.login.signIn')}
        </Typography>
        <Typography alignSelf="center" sx={emailStyles}>
          {t('pages.login.EnterEmail')}
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.8} sx={{ mt: 0 }}>
          {error && (
            <Stack sx={{ width: '100%' }}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          )}
          {!error && <Box sx={{ height: error ? 30 : 10 }} />}

          <TextField
            name="email"
            id="login-email"
            label={t('pages.usersPage.form.emailAdress')}
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message || ''}
            {...register('email', {
              required: t('formControl.enterEmail'),
              pattern: {
                value: EMAIL_REGEX,
                message: t('formControl.invalidEmail'),
              },
            })}
            InputLabelProps={{
              sx: labelStyles,
            }}
            InputProps={{
              sx: inputStyles,
            }}
          />

          <TextField
            name="password"
            id="login-password"
            label={t('attributes.password')}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              sx: inputStyles,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password?.message || ''}
            {...register('password', {
              required: t('formControl.insertPassword'),
            })}
            InputLabelProps={{
              sx: labelStyles,
            }}
          />

          <Link variant="subtitle2">
            <HoverableText
              sx={{ width: 'fit-content' }}
              underline="hover"
              onClick={handleForgotPassword}
            >
              {t('pages.login.forgotPassword')}
            </HoverableText>
          </Link>

          <LoadingButton
            id="login-button"
            size="medium"
            type="submit"
            variant="contained"
            onClick={handleClick}
            sx={buttonStyles}
            loading={loading}
          >
            {t('buttons.login')}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
