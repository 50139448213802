import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{ color: '#212636' }}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        Algor Leap Solutions
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
