// Function to get the value of a nested property
const getNestedValue = (obj, key) =>
  key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);

export const descendingComparator = (operand1, operand2, orderBy) => {
  const value1 = getNestedValue(operand1, orderBy);
  const value2 = getNestedValue(operand2, orderBy);

  const normalizedValue1 = typeof value1 === 'string' ? value1.toLowerCase() : value1;
  const normalizedValue2 = typeof value2 === 'string' ? value2.toLowerCase() : value2;

  if (normalizedValue2 < normalizedValue1) return -1;
  if (normalizedValue2 > normalizedValue1) return 1;
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (operand1, operand2) => descendingComparator(operand1, operand2, orderBy)
    : (operand1, operand2) => -descendingComparator(operand1, operand2, orderBy);

export const applySortFilter = (array, comparator, query, filterBy) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });

  // Filter based on the selected filterBy field
  if (query) {
    const queryString = query.toString().toLowerCase(); // Convert query to a string

    return stabilizedThis
      .map((el) => el[0])
      .filter((item) => {
        // exception for the status filter and i want to check the departmentQuery not "no department" after the "|"
        if (filterBy === 'status' && queryString.includes('|')) {
          // Split query into name and department
          const [nameQuery, departmentQuery] = queryString.split('|');

          const statusValue = getNestedValue(item, 'status');
          const departmentValue = getNestedValue(item, 'department');

          return (
            statusValue &&
            departmentValue &&
            statusValue.toLowerCase() === nameQuery && // Match status name
            departmentValue.toLowerCase() === departmentQuery // Match department
          );
        }

        const value = getNestedValue(item, filterBy); // Get the value for the selected filterBy

        // Check if the value is defined before calling toString
        if (value !== undefined) {
          // Handle boolean values as strings
          if (typeof value === 'boolean') {
            return value.toString().toLowerCase().includes(queryString);
          }
          // Handle other types, assuming they can be converted to strings
          return value.toString().toLowerCase().includes(queryString);
        }
        return false; // If value is undefined, don't include it in the results
      });
  }

  return stabilizedThis.map((el) => el[0]);
};

export const transformData = (originalData) =>
  originalData.map((dep) => {
    const statusOrder = ['UNASSIGNED', 'RESOLVED', 'CLOSED', 'OPEN', 'ONHOLD']; // Define the order of statuses

    const statusCounts = {
      UNASSIGNED: 0,
      RESOLVED: 0,
      CLOSED: 0,
      OPEN: 0,
      ONHOLD: 0,
    };

    dep?.tickets?.forEach((ticket) => {
      // eslint-disable-next-line no-plusplus
      statusCounts[ticket.statusObject?.systemMappedStatus]++;
    });
    const data = statusOrder.map((status) => statusCounts[status]);
    return data;
  });

export const reorderArray = (arr, currentIndex, nextIndex) => {
  if (currentIndex === -1 || nextIndex === -1) {
    // If either section is not found in the array, return the original array.
    return arr;
  }

  const newArr = [...arr]; // Create a new array to avoid mutating the original.
  const nextSection = arr[nextIndex];
  const currentVal = newArr[currentIndex];
  // Remove the nextSection from its current position.
  newArr.splice(nextIndex, 1);
  // Get new current index after removing the nextSection.
  const newCurrentIndex = newArr.indexOf(currentVal);

  // Calculate the new index to insert the nextSection.
  const insertIndex = newCurrentIndex + 1;

  // Insert the nextSection at the new position.
  newArr.splice(insertIndex, 0, nextSection);

  return { currentIndex: newCurrentIndex, array: newArr };
};
