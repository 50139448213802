import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Chip,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from '@mui/material';

import { LANGS } from 'src/pages/shareableForms/ShareableFormLang';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import EmptyTable from 'src/components/tables/emptyTable';

import { useToast } from '../../hooks/useToast';

export const handleIconLanguageSelected = (lang) =>
  LANGS.find((l) => l.value === lang)?.icon;

function TableBodyShareableForm({
  currentPageFormsShareable,
  isSelected,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  filterName,
  onRowClick,
}) {
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();

  const handleCopyToken = (token) => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_DOMAIN_URL}/create-ticket?form=${token}`)
      .then(() => {
        showToast({
          message: t('pages.shareableFormsPage.urlCopied'),
          severity: 'success',
          props: { hideToast },
        });
      });
  };

  const handleCopyIframe = (token) => {
    navigator.clipboard
      .writeText(
        `<iframe title="iframe form" width="100%" height="680px" src="${process.env.REACT_APP_DOMAIN_URL}/create-ticket?form=${token}" allowFullScreen style="border: none;"></iframe>`
      )
      .then(() => {
        showToast({
          message: t('pages.shareableFormsPage.iframeCopied'),
          severity: 'success',
          props: { hideToast },
        });
      });
  };

  return (
    <>
      <TableBody>
        {currentPageFormsShareable.map((formShareable, index) => {
          const isItemSelected = isSelected(formShareable.id);
          return (
            <TableRow
              key={index}
              selected={isItemSelected}
              sx={{ cursor: 'pointer' }}
              hover
              onClick={() => onRowClick(formShareable)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event) => handleClick(event, formShareable.id)}
                />
              </TableCell>
              <TableCell>#{formShareable.id}</TableCell>
              <TableCell>{formShareable.form.title}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={handleIconLanguageSelected(formShareable.language)}
                    alt={formShareable.language}
                    width={28}
                    height={20}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Chip
                    label={
                      formShareable.isAnonymous
                        ? t('isMultipleSubmission.yes')
                        : t('isMultipleSubmission.no')
                    }
                    color={formShareable.isAnonymous ? 'primary' : 'default'}
                    size="small"
                  />
                </Box>
              </TableCell>
              <TableCell align="left">
                <Label color={(formShareable.isActive === false && 'error') || 'success'}>
                  {formShareable.isActive === true
                    ? t('userStatus.active')
                    : t('userStatus.inactive')}
                </Label>
              </TableCell>
              <TableCell>{formShareable.generatedBy.username}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Chip
                    label={
                      formShareable.isMultipleSubmission
                        ? t('isMultipleSubmission.yes')
                        : t('isMultipleSubmission.no')
                    }
                    color={formShareable.isMultipleSubmission ? 'primary' : 'default'}
                    size="small"
                  />
                </Box>
              </TableCell>
              <TableCell align="center">{formShareable.submissions}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCopyToken(formShareable.token);
                  }}
                >
                  <Iconify
                    icon="heroicons:clipboard-document-list"
                    width={28}
                    height={28}
                    style={{ color: '#76B0F1' }}
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCopyIframe(formShareable.token);
                  }}
                >
                  <Iconify
                    icon="heroicons:clipboard-document-list"
                    width={28}
                    height={28}
                    style={{ color: '#76B0F1' }}
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                {new Date(formShareable.generatedOn).toLocaleString()}
              </TableCell>
              <TableCell>{new Date(formShareable.expiresOn).toLocaleString()}</TableCell>
              <TableCell
                align="center"
                sx={{
                  position: 'sticky',
                  zIndex: '100',
                  right: 0,
                  backgroundColor: 'white',
                }}
              >
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenMenu(event, formShareable);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
      {currentPageFormsShareable.length === 0 && (
        <EmptyTable filterName={filterName} searchIsNotFound={isNotFound} colSpan={15} />
      )}
    </>
  );
}

TableBodyShareableForm.propTypes = {
  currentPageFormsShareable: PropTypes.array,
  isSelected: PropTypes.func,
  handleClick: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  emptyRows: PropTypes.number,
  isNotFound: PropTypes.bool,
  filterName: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default TableBodyShareableForm;
