import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, List, Button, Typography } from '@mui/material';

import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';

import UserListPermission from './UserListPermission';
import AddUsersPermissionsModal from '../modals/AddUsersPermissionsModal';
import { thirdGridStyle, cardTicketModalStyle, listTicketModalStyle } from '../styles';

Permissions.propTypes = {
  ticketId: PropTypes.string,
  usersList: PropTypes.array,
  groupList: PropTypes.array,
  hasReadPermission: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
  users: PropTypes.array,
  deletePermission: PropTypes.func,
  permissionsLoading: PropTypes.bool,
  setPermissionUsers: PropTypes.func,
  addMultiplePermissions: PropTypes.func,
};

export default function Permissions({
  ticketId,
  usersList,
  hasReadPermission,
  hasWritePermission,
  users,
  setPermissionUsers,
  deletePermission,
  permissionsLoading,
  groupList,
  addMultiplePermissions,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const { t } = useTranslation();
  const [ticketGroupsIds, setTicketGroupsIds] = useState([]);
  const { showModal, hideModal } = useModal();

  const handleShowModal = () => {
    showModal({
      title: t('pages.ticketDetails.addPermission'),
      Component: AddUsersPermissionsModal,
      props: {
        hideModal,
        ticketId,
        usersList,
        groupList,
        ticketGroupsIds,
        setTicketGroupsIds,
        setPermissionUsers,
        permissionUsers: users,
        ticketUsersIds: users.map((user) => user.id),
        addMultiplePermissions,
      },
    });
  };
  const sortedUsers = useMemo(() => users.sort((a, b) => a.default - b.default), [users]);

  return (
    <Box sx={thirdGridStyle} justifyContent="center">
      <Typography variant="h6">{t('pages.ticketDetails.users')}</Typography>
      <Card
        sx={{
          ...cardTicketModalStyle,
          height: 'auto',
          minHeight: isSmallScreen ? '50px' : '100px',
          maxHeight: '350px',
        }}
      >
        {(hasReadPermission || hasWritePermission) && (
          <List sx={listTicketModalStyle}>
            {permissionsLoading ? (
              <Loader size={isSmallScreen ? 60 : 131.25} hideText />
            ) : sortedUsers.length > 0 ? (
              sortedUsers.map((user, index) => (
                <UserListPermission
                  setPermissionUsers={setPermissionUsers}
                  hasReadPermission={hasReadPermission}
                  hasWritePermission={hasWritePermission}
                  key={user.id || index}
                  user={user}
                  deletePermission={deletePermission}
                  ticketId={ticketId}
                />
              ))
            ) : (
              <Typography
                textAlign="center"
                variant="subtitle1"
                sx={{
                  fontSize: isSmallScreen ? '0.675rem' : '1rem',
                }}
              >
                {t('pages.ticketDetails.noUserFound')}
              </Typography>
            )}
          </List>
        )}
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            mt: 3,
            fontSize: isSmallScreen ? '0.7rem' : '1rem',
          }}
          disabled={!hasWritePermission}
          onClick={handleShowModal}
          style={{ width: '100%' }}
        >
          {t('pages.ticketDetails.addUserPermission')}
        </Button>
      </Box>
    </Box>
  );
}
