import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import { useNavigate } from 'react-router-dom';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';
import arLocale from '@fullcalendar/core/locales/ar';
import multiMonthPlugin from '@fullcalendar/multimonth';
import enLocale from '@fullcalendar/core/locales/en-gb';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useMemo, useState, useEffect } from 'react';

import { Box, Select, Tooltip, MenuItem, Container, Typography } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import { encrypt } from '../../utils/hashCode';
import { useToast } from '../../hooks/useToast';
import { convertLocalTime } from '../../utils/convertTime';
import { fetchStatuses } from '../../services/status.service';
import { getSettings } from '../../services/configuration.service';
import { getUsersByDepartment } from '../../services/user.service';
import {
  fetchAgentTickets,
  fetchTicketsBydepartment,
} from '../../services/ticket.service';

const localLanguage = {
  it: itLocale,
  en: enLocale,
  ar: arLocale,
};

const colors = [
  '#3d91ff',
  '#3ecf8e',
  '#fed14c',
  '#9c27b0',
  '#aa2e25',
  '#33eaff',
  '#827717',
];

export default function AssignmentsCalendar() {
  const [tickets, setTickets] = useState([]);
  const [agent, setAgent] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState(0);
  const [options, setOptions] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const { t, i18n } = useTranslation();
  // setting work day 0 is sunday --> 6 is saturday
  const [workdays, setWorkDays] = useState([1, 2, 3, 4, 5]);
  // setting work hours first value is the starting hours, second is ending hours
  const [workHours, setWorkHours] = useState([8, 17]);
  // setting work hours first value is the starting minutes, second is ending minutes
  const [workMinutes, setWorkMinutes] = useState(['00', '00']);
  const slotMin = `${workHours[0] - 2}:${workMinutes[0]}`;
  const slotMax = `${workHours[1] + 2}:${workMinutes[1]}`;
  const { role: currentUserRole, id: currentUserId, departmentId } = useAuth();
  const role = currentUserRole.authority;
  const { showToast, hideToast } = useToast();
  const navigate = useNavigate();

  const currentLocale = useMemo(
    () => (i18n.language ? localLanguage[i18n.language] : arLocale),
    [i18n]
  );

  const getStatuses = (depId) => {
    fetchStatuses().then((res) => {
      setStatusesList(
        res.filter((status) => status.departmentId === depId || status.departmentId === 0)
      );
    });
  };

  const fetchTickets = () => {
    if (role === 'ROLE_AGENT') {
      fetchAgentTickets(currentUserId)
        .then((res) => {
          const updatedTickets = res.map((ticket) => {
            const statusObject = statusesList.find(
              (status) => status.statusId === ticket.status_id
            );
            return { ...ticket, statusObject };
          });
          setTickets(
            updatedTickets.filter(
              (ticket) =>
                ticket.statusObject?.systemMappedStatus === 'RESOLVED' ||
                ticket.statusObject?.systemMappedStatus === 'OPEN'
            )
          );
        })
        .catch(() =>
          showToast({
            message: t('errorMessages.couldntLoadTicket'),
            severity: 'error',
            props: { hideToast },
          })
        );
    } else if (role === 'ROLE_DEPARTMENTMANAGER') {
      if (selectedAgentId === 0)
        fetchTicketsBydepartment(departmentId)
          .then((res) => {
            const updatedTickets = res.map((ticket) => {
              const statusObject = statusesList.find(
                (status) => status.statusId === ticket.status_id
              );
              return { ...ticket, statusObject };
            });
            setTickets(
              updatedTickets.filter(
                (ticket) =>
                  ticket.statusObject?.systemMappedStatus === 'RESOLVED' ||
                  ticket.statusObject?.systemMappedStatus === 'OPEN'
              )
            );
          })
          .catch(() =>
            showToast({
              message: t('errorMessages.couldntLoadTicket'),
              severity: 'error',
              props: { hideToast },
            })
          );
      else
        fetchAgentTickets(selectedAgentId)
          .then((res) => {
            const updatedTickets = res.map((ticket) => {
              const statusObject = statusesList.find(
                (status) => status.statusId === ticket.status_id
              );
              return { ...ticket, statusObject };
            });
            setTickets(
              updatedTickets.filter(
                (ticket) =>
                  ticket.statusObject?.systemMappedStatus === 'RESOLVED' ||
                  ticket.statusObject?.systemMappedStatus === 'OPEN'
              )
            );
          })
          .catch(() =>
            showToast({
              message: t('errorMessages.couldntLoadTicket'),
              severity: 'error',
              props: { hideToast },
            })
          );
    }
  };

  const fetchAgents = () => {
    getUsersByDepartment(departmentId)
      .then((res) =>
        setAgentsList(res.filter((element) => element.appUserRole === 'ROLE_AGENT'))
      )
      .catch(() =>
        showToast({
          message: t('errorMessages.couldntLoadTicket'),
          severity: 'error',
          props: { hideToast },
        })
      );
  };
  useEffect(() => {
    fetchAgents();
    getStatuses(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSettings = () => {
    getSettings()
      .then((res) => {
        const start = new Date(`2023-01-01T${res.data.startTime}`);
        const end = new Date(`2023-01-01T${res.data.endTime}`);
        setWorkHours([start.getHours(), end.getHours()]);

        setWorkMinutes([
          start.getMinutes() > 9
            ? start.getMinutes().toString()
            : `0${start.getMinutes()}`,
          end.getMinutes() > 9 ? end.getMinutes().toString() : `0${end.getMinutes()}`,
        ]);
        const daysList = [
          'SUNDAY',
          'MONDAY',
          'TUESDAY',
          'WEDNESDAY',
          'THURSDAY',
          'FRIDAY',
          'SATURDAY',
        ];
        const selectdDays = res.data?.workingDays;
        setWorkDays(
          selectdDays.map((day) => daysList.indexOf(day)).filter((index) => index !== -1)
        );
      })

      .catch(() =>
        showToast({
          message: t('errorMessages.couldntLoadSettings'),
          severity: 'error',
          props: { hideToast },
        })
      );
  };

  useEffect(() => {
    fetchTickets();
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgentId, statusesList]);
  useEffect(() => {
    const opt = [{ id: 0, name: 'All ticket managers' }];
    agentsList.forEach((agent) => {
      opt.push({ id: agent.id, name: agent.username });
    });
    setOptions(opt);
  }, [agentsList]);

  useEffect(() => {
    setAgent(options[0]?.name || '');
  }, [options]);

  const handleChange = (event) => {
    setAgent(event.target.value);
    const findedOption = options.find((agent) => agent.name === event.target.value);
    if (findedOption) {
      setSelectedAgentId(findedOption.id);
    }
  };

  const events = [];

  const splitEventByWorkdays = (event) => {
    const workdayStartHour = workHours[0];
    const workdayEndHour = workHours[1];
    const workDayStartMinute = parseInt(workMinutes[0], 10);
    const workDayEndMinute = parseInt(workMinutes[1], 10);
    const eventParts = [];
    const currentDay = new Date(event.start);
    const eventEnd = new Date(event.end);
    while (currentDay <= eventEnd) {
      const dayOfWeek = currentDay.getDay();
      const currentHour = currentDay.getHours();
      const currentMinutes = currentDay.getMinutes();
      const currentSeconds = currentDay.getSeconds();
      const isLastDay =
        currentDay.getDate() === eventEnd.getDate() &&
        currentDay.getMonth() === eventEnd.getMonth() &&
        currentDay.getFullYear() === eventEnd.getFullYear();
      const isFirstDay = currentDay.getTime() === new Date(event.start).getTime();
      // Check if it's a workday and within work hours
      if (
        workdays.includes(dayOfWeek) &&
        currentHour >= workdayStartHour &&
        (isLastDay ? currentHour <= eventEnd.getHours() : currentHour < workdayEndHour)
      ) {
        const partStart = new Date(currentDay);
        partStart.setHours(Math.max(currentHour, workdayStartHour));
        if (isFirstDay) {
          if (partStart.getHours() === workdayStartHour) {
            partStart.setMinutes(Math.max(currentMinutes, workDayStartMinute));
          } else {
            partStart.setMinutes(Math.max(currentMinutes, 0));
          }
          partStart.setSeconds(Math.max(currentSeconds, 0));
        } else {
          partStart.setMinutes(workDayStartMinute);
          partStart.setSeconds(0);
        }

        const partEnd = new Date(currentDay);
        if (isLastDay) {
          // Use the exact end time of the event on the last day
          partEnd.setHours(eventEnd.getHours());
          partEnd.setMinutes(eventEnd.getMinutes());
          partEnd.setSeconds(eventEnd.getSeconds());
        } else {
          // Use the workday end time on other workdays
          partEnd.setHours(workdayEndHour);
          partEnd.setMinutes(workDayEndMinute);
          partEnd.setSeconds(0);
        }

        eventParts.push({
          statusObject: event.statusObject,
          fullStart: event.start,
          fullEnd: event.end,
          ticketId: event.id,
          start: partStart,
          agentName: event.agentName,
          end: partEnd,
          title: event.title,
          color: event.color,
          createdBy: event.createdBy,
        });

        // Move to the next day
        currentDay.setDate(currentDay.getDate() + 1);
        currentDay.setHours(workdayStartHour);
      } else {
        // Move to the next hour

        currentDay.setHours(currentHour + 1);
      }
    }

    return eventParts;
  };

  useMemo(
    () => {
      tickets.forEach((ticket, index) => {
        events.push({
          start: ticket.estimatedStartTime
            ? convertLocalTime(new Date(ticket.estimatedStartTime))
            : convertLocalTime(new Date(ticket.acceptedAt)),
          end:
            ticket.statusObject.systemMappedStatus === 'RESOLVED' && ticket.resolvedAt
              ? convertLocalTime(new Date(ticket.resolvedAt))
              : ticket.estimatedEndTime
              ? convertLocalTime(new Date(ticket.estimatedEndTime))
              : convertLocalTime(new Date()),
          title: ticket.title,
          agentName: ticket.agentName,
          color: colors[index % colors.length],
          id: ticket.id,
          statusObject: ticket.statusObject,
          createdBy: ticket.customerName,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tickets, currentLocale, t, events]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flattenedEvents = useMemo(() => events.flatMap(splitEventByWorkdays), [events]);

  return (
    <Container>
      {role === 'ROLE_DEPARTMENTMANAGER' && (
        <Select
          id="dashboard-department-select"
          value={agent}
          onChange={handleChange}
          sx={{ maxWidth: '25vh', minWidth: '20vh', mx: 6, my: 1 }}
        >
          {options?.length > 0 ? (
            options.map((optionAgent) => (
              <MenuItem key={`Agent-${optionAgent.id}`} value={optionAgent.name}>
                {optionAgent.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled value="">
              {t('infoMessages.noOptions')}
            </MenuItem>
          )}
        </Select>
      )}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
        initialView="dayGridMonth"
        height="75vh"
        locale={currentLocale}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay', // Built-in view buttons
        }}
        events={flattenedEvents}
        businessHours={{
          daysOfWeek: workdays,
          startTime: `${workHours[0]}:${workMinutes[0]}`,
          endTime: `${workHours[1]}:${workMinutes[1]}`,
        }}
        eventDisplay="block"
        slotMinTime={slotMin}
        slotMaxTime={slotMax}
        nowIndicator
        firstDay={0}
        dayMaxEventRows={3}
        eventMaxStack={3}
        eventContent={(arg) => {
          const viewType = arg.event._context.viewSpec.type;
          const { statusObject, ticketId, agentName, fullEnd, fullStart, createdBy } =
            arg.event.extendedProps;
          const statusName =
            statusObject.statusId < 6
              ? t(`status.${statusObject.name}`)
              : statusObject.name;
          const startTime = new Date(arg.event.start);
          const endTime = new Date(arg.event.end);
          const startTimeFormatted = `${startTime
            .getHours()
            .toString()
            .padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}`;
          const endTimeFormatted = `${endTime
            .getHours()
            .toString()
            .padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;

          return (
            <Tooltip
              placement="left-start"
              title={
                <div>
                  {`${startTimeFormatted} - ${endTimeFormatted}`}
                  {role === 'ROLE_DEPARTMENTMANAGER' && (
                    <div>
                      {t('pages.calendar.agentName')}: {agentName}
                    </div>
                  )}
                  <div>
                    <b>{t('pages.ticketDetails.ticketId')} :</b> {ticketId}
                  </div>
                  <div>
                    <b> {t('attributes.title')}: </b>
                    {arg.event.title}
                  </div>
                  <div>
                    <b> {t('attributes.status')}: </b>
                    {statusName}
                  </div>
                  <div>
                    <b> {t('pages.calendar.ticketStart')} :</b>
                    {fullStart.toLocaleString()}
                  </div>
                  <div>
                    <b> {t('pages.calendar.ticketEnd')} :</b>
                    {fullEnd.toLocaleString()}
                  </div>
                  <div>
                    <b>{t('attributes.createdBy')} : </b>{' '}
                    {createdBy !== 'none'
                      ? createdBy
                      : t('pages.ticketDetails.anonymous')}
                  </div>
                </div>
              }
            >
              <Box
                onClick={() => {
                  navigate(`/tickets/${encrypt(ticketId)}`);
                }}
                sx={{
                  height: '100%',
                  cursor: 'pointer',
                  overflow: viewType !== 'timeGridWeek' ? 'hidden' : 'unset',
                  whiteSpace: viewType !== 'timeGridWeek' ? 'nowrap' : 'normal',
                  textOverflow: viewType !== 'timeGridWeek' ? 'ellipsis' : 'unset',
                }}
              >
                <Typography variant="caption">
                  <b>
                    {startTimeFormatted} - {endTimeFormatted}
                  </b>
                  {role === 'ROLE_DEPARTMENTMANAGER' ? ` - ${agentName}` : null}-
                  {arg.event.title}
                </Typography>
              </Box>
            </Tooltip>
          );
        }}
        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
      />
    </Container>
  );
}
