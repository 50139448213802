import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

Loader.propTypes = {
  size: PropTypes.number,
  hideText: PropTypes.bool,
};

export default function Loader({ size = 175, hideText }) {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ width: size, mx: 'auto', my: 5 }}>
        <img src="/assets/loader_logo.gif" alt="logo" />
      </Box>
      {!hideText && (
        <Typography variant="subtitle2" fontSize={size / 12}>
          {t('timeOut.loading')}
        </Typography>
      )}
    </>
  );
}
