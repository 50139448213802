import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover, MenuItem, Typography } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import { useModal, useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import {
  deleteClientCredentials,
  activeEnactiveClientCredentials,
} from 'src/services/client.credentials.service';

import Iconify from 'src/components/iconify';

function MenuPopover({
  popoverAnchorEl,
  handleCloseMenu,
  handleOpenClientCredentialsDetails,
  selectedItem,
  handleFetchClientCredentials,
  AddUpdateClientCredentials,
  setSelectedClientCredentials,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal } = useModal();
  const { showToast, hideToast } = useToast();

  const ActiveInactiveClientCredentials = async (clientId, isActive) => {
    genericApiToaster(
      activeEnactiveClientCredentials,
      clientId,
      isActive
        ? t('successMessages.successDeactivatingClientCredentials')
        : t('successMessages.successActivatingClientCredentials'),
      isActive
        ? t('errorMessages.errorDeactivatingClientCredentials')
        : t('errorMessages.errorActivatingClientCredentials'),
      showToast,
      hideToast,
      t,
      () => {
        handleFetchClientCredentials();
        setSelectedClientCredentials([]);
      }
    );
  };

  const { showConfirmModal: showToggleActiveConfirmModal } = useConfirmModal(
    selectedItem?.enabled
      ? t('pages.clientCredentialsPage.confirmDeactivate')
      : t('pages.clientCredentialsPage.confirmActivate'),
    `${
      selectedItem?.enabled
        ? t('pages.clientCredentialsPage.areYouSureToDeactivate')
        : t('pages.clientCredentialsPage.areYouSureToActivate')
    } ${selectedItem?.id}`,
    async () =>
      ActiveInactiveClientCredentials(selectedItem?.clientId, selectedItem?.enabled)
  );

  const RemoveClientCredentials = async (id) => {
    try {
      await deleteClientCredentials(id);
      showToast({
        message: t('successMessages.successDeletingClientCredentials'),
        severity: 'success',
        props: { hideToast },
      });
      handleFetchClientCredentials();
      setSelectedClientCredentials([]);
    } catch (error) {
      showToast({
        message: t('errorMessages.errorDeletingClientCredentials'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const { showConfirmModal: showDeleteConfirmModal } = useConfirmModal(
    t('pages.clientCredentialsPage.confirmDelete'),
    `${t('pages.clientCredentialsPage.areYouSure')} ${selectedItem?.id}`,
    async () => RemoveClientCredentials(selectedItem?.id)
  );

  return (
    <Popover
      open={Boolean(popoverAnchorEl)}
      anchorEl={popoverAnchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          minwidth: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          handleOpenClientCredentialsDetails(selectedItem);
          handleCloseMenu();
        }}
      >
        <Iconify
          icon="eva:plus-circle-outline"
          sx={{
            mr: isSmallScreen ? 1.5 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.moreDetails')}
        </Typography>
      </MenuItem>

      <MenuItem
        sx={{ color: selectedItem?.enabled ? 'error.dark' : 'success.main' }}
        onClick={() => {
          showToggleActiveConfirmModal();
          handleCloseMenu();
        }}
      >
        <Iconify
          icon={selectedItem?.enabled ? 'eva:lock-outline' : 'eva:unlock-outline'}
          sx={{
            mr: isSmallScreen ? 1.5 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {selectedItem?.enabled ? t('popover.deactivate') : t('popover.activate')}
        </Typography>
      </MenuItem>

      <MenuItem
        onClick={() => {
          showModal({
            title: t('pages.clientCredentialsPage.updateClientCredentials'),
            Component: AddUpdateClientCredentials,
            props: {
              hideModal,
              handleFetchClientCredentials,
              row: selectedItem,
            },
          });
          handleCloseMenu();
        }}
      >
        <Iconify
          icon="eva:edit-fill"
          sx={{
            mr: isSmallScreen ? 1.5 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.edit')}
        </Typography>
      </MenuItem>

      <MenuItem
        sx={{ color: 'error.main' }}
        onClick={() => {
          showDeleteConfirmModal();
          handleCloseMenu();
        }}
      >
        <Iconify
          icon="eva:trash-2-outline"
          sx={{
            mr: isSmallScreen ? 1.5 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.delete')}
        </Typography>
      </MenuItem>
    </Popover>
  );
}

MenuPopover.propTypes = {
  popoverAnchorEl: PropTypes.object,
  handleCloseMenu: PropTypes.func,
  handleOpenClientCredentialsDetails: PropTypes.func,
  selectedItem: PropTypes.object,
  handleFetchClientCredentials: PropTypes.func,
  AddUpdateClientCredentials: PropTypes.func,
  setSelectedClientCredentials: PropTypes.func,
};

export default MenuPopover;
