import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';

import { nonSortableHeaders } from './nonSortableHeaders';
// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

TableHeaders.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  indeterminate: PropTypes.bool,
  showCheckBox: PropTypes.bool,
};

export default function TableHeaders({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  indeterminate,
  showCheckBox,
}) {
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {showCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount && indeterminate}
              checked={rowCount > 0 && numSelected > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            sx={
              headCell.sticky && {
                position: 'sticky',
                zIndex: '100',
                right: 0,
              }
            }
            key={headCell.id}
            align={
              headCell.alignRight ? 'right' : headCell.alignCenter ? 'center' : 'left'
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {nonSortableHeaders.includes(headCell.id) ? (
              // Render title without sorting for "options"
              t(`tableHeads.${headCell.label}`)
            ) : (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {t(`tableHeads.${headCell.label}`)}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
