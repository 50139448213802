import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Card,
  Link,
  Stack,
  Divider,
  Popover,
  Tooltip,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';

import Iconify from 'src/components/iconify/Iconify';

StatusCard.propTypes = {
  status: PropTypes.object,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
};
export default function StatusCard({ status, handleDetailsClick, deleteStatus }) {
  const { t } = useTranslation();
  const statusColor = status.statusId <= 5 ? status.statusColor : status.color;
  const statusDescription =
    status.statusId <= 5
      ? t(`statusDescription.${status.systemMappedStatus}`)
      : status.description;
  const [open, setOpen] = useState(null);
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };
  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmDelete'),
    `${t('pages.ticketDetails.areYouSure')} ${status.name}`,
    async () => deleteStatus(status.statusId)
  );
  return (
    <>
      <Card
        sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }}
        onClick={() => handleDetailsClick(status)}
      >
        <Box bgcolor={statusColor} width="100%" height="30px" />
        <Stack direction="row">
          <Stack width="100%" spacing={0.5} py={2}>
            <Stack
              px={2}
              alignItems="center"
              justifyContent={status.statusId < 6 ? 'center' : 'space-between'}
              direction="row"
            >
              <Tooltip title={status.name}>
                <Link color="inherit" underline="hover">
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: 100,
                    }}
                  >
                    <b>{status.name}</b>
                  </Typography>
                </Link>
              </Tooltip>
              {status.statusId > 5 && (
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenMenu(e);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              )}
            </Stack>
            {status.statusId > 5 ? (
              <Stack spacing={0.5} px={2}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Iconify icon="eva:email-outline" />
                  <Typography>
                    {status.correspondences?.length} {t('pages.statusPage.linkedEmails')}
                  </Typography>
                </Stack>
                <Typography noWrap>
                  {status.departmentId === 0
                    ? t('pages.statusPage.noDepartment')
                    : status.department}
                </Typography>
                <Typography noWrap>
                  <b>
                    {t('pages.statusPage.systemState')}: <br />{' '}
                    {t(`status.${status.systemMappedStatus}`)}
                  </b>
                </Typography>
              </Stack>
            ) : (
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="subtitle2">
                  <b>{t('pages.statusPage.globalStatus')}</b>
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack p={2}>
          <Typography variant="subtitle1">
            {' '}
            {t('pages.statusPage.description')} :{' '}
          </Typography>
          <Typography variant="body2" noWrap>
            {statusDescription}
          </Typography>
        </Stack>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleDetailsClick(status);
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            showConfirmModal();
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          {t('popover.delete')}
        </MenuItem>
      </Popover>
    </>
  );
}
