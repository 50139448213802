import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useReducer, createContext } from 'react';

import { Alert, Snackbar, Typography } from '@mui/material';

const SnackbarContext = createContext({
  showToast: () => undefined,
  hideToast: () => undefined,
  isVisible: false,
});

const { Provider, Consumer: SnackbarConsumer } = SnackbarContext;

const reducer = (state, action) => {
  switch (action.type) {
    case 'openToast': {
      const { message, severity, verticalPos, horizontalPos, props } = action;
      return {
        ...state,
        message,
        severity,
        verticalPos,
        horizontalPos,
        props,
        isVisible: true,
      };
    }
    case 'hideToast':
      return {
        ...state,
        message: null,
        verticalPos: null,
        horizontalPos: null,
        props: {},
      };
    default:
      throw new Error('Unspecified reducer action');
  }
};

const SnackbarProvider = ({ children }) => {
  const initialState = {
    message: null,
    severity: null,
    verticalPos: null,
    horizontalPos: null,
    props: {},
  };

  SnackbarProvider.propTypes = {
    children: PropTypes.node,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { message, severity, verticalPos, horizontalPos, props } = state;
  const isVisible = !!message;

  const showToast = (modal) => {
    dispatch({ type: 'openToast', ...modal });
  };

  const hideToast = () => {
    dispatch({ type: 'hideToast' });
    // eslint-disable-next-line no-unused-expressions, react/prop-types
    props.onClose && props.onClose();
  };

  return (
    <Provider value={{ ...state, showToast, hideToast, isVisible }}>
      {children}
      <Snackbar
        key={uuidv4()}
        open={isVisible}
        anchorOrigin={{
          vertical: verticalPos || 'bottom',
          horizontal: horizontalPos || 'center',
        }}
        autoHideDuration={5000}
        onClose={hideToast}
      >
        <Alert onClose={hideToast} severity={severity} sx={{ width: '100%' }}>
          <Typography variant="subtitle1">{message}</Typography>
        </Alert>
      </Snackbar>
    </Provider>
  );
};
const useToast = () => useContext(SnackbarContext);

export { useToast, SnackbarConsumer, SnackbarProvider };
