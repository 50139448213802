import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  List,
  Card,
  Stack,
  Select,
  Avatar,
  Button,
  ListItem,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  ListItemAvatar,
} from '@mui/material';

import {
  cardTicketModalStyle,
  listTicketModalStyle,
  listItemTicketModalStyle,
} from 'src/sections/ticketDetails/styles';

import Label from '../../../components/label';
import { useConfirmModal } from '../../../hooks/useModal';
import { convertLocalTime } from '../../../utils/convertTime';
import SearchBar from '../../../components/searchBar/SearchBar';
import { getComparator, applySortFilter } from '../../../utils/filterData';

AssignTicketModal.propTypes = {
  usersList: PropTypes.array,
  assignTicket: PropTypes.func,
  department: PropTypes.string,
  statusesList: PropTypes.array,
};

const order = 'asc';
const orderBy = 'name';

export default function AssignTicketModal({
  usersList,
  assignTicket,
  department,
  statusesList,
}) {
  const [selectedAgent, setSelectedAgent] = useState({
    id: 0,
    username: '',
  });
  const [selectedStatusId, setSelectedStatusId] = useState(2);
  const [filterName, setFilterName] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const { t } = useTranslation();

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = useMemo(() => {
    const filteredData = applySortFilter(
      usersList,
      getComparator(order, orderBy),
      filterName,
      'username'
    );
    setIsNotFound(!filteredData.length && !!filterName);
    return filteredData;
  }, [filterName, usersList]);

  const { showConfirmModal } = useConfirmModal(
    t('pages.assignTicket.confirmAssignment'),
    `${t('pages.assignTicket.areYouSure')} ${selectedAgent.username} ?`,
    async () => {
      assignTicket(selectedAgent.id, selectedStatusId);
    }
  );

  const handleStatusChange = (event) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <Stack px={2} spacing={2} my={2}>
      <Typography variant="subtitle1">
        {t('pages.assignTicket.availableAgents')} {department} :
      </Typography>
      <SearchBar
        filterName={filterName}
        handleFilterByName={handleFilterByName}
        model="agent"
      />
      <Card sx={cardTicketModalStyle}>
        <List sx={listTicketModalStyle}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => {
              const { id, username, email, phoneNumber, avatar, available, availableOn } =
                user;

              return (
                <ListItem
                  divider
                  key={id}
                  onClick={() => {
                    setSelectedAgent(user);
                  }}
                  sx={listItemTicketModalStyle(selectedAgent, user)}
                  alignItems="flex-start"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="row">
                      <ListItemAvatar>
                        <Avatar alt="" src={avatar} />
                      </ListItemAvatar>

                      <Stack spacing={0.5}>
                        <Typography variant="body2">
                          <b>{username}</b>
                        </Typography>
                        <Typography variant="body2">
                          <b>{t('attributes.email')}: </b>
                          {email}
                        </Typography>
                        <Typography variant="body2">
                          <b>{t('attributes.phoneNumber')}: </b>
                          {phoneNumber}
                        </Typography>
                        {!available && (
                          <Typography variant="body2">
                            <b>{t('attributes.availableOn')}: </b>
                            {convertLocalTime(new Date(availableOn)).toLocaleString(
                              ('en-GB',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            )}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Label color={(available && 'success') || 'error'}>
                      {available
                        ? t('attributes.available')
                        : t('attributes.unavailable')}
                    </Label>
                  </Stack>
                </ListItem>
              );
            })
          ) : (
            <Typography textAlign="center" variant="subtitle1">
              {isNotFound
                ? t('pages.assignTicket.noAgentsFound')
                : t('pages.assignTicket.noAgentsAvailable')}
            </Typography>
          )}
        </List>
      </Card>
      <FormControl>
        <InputLabel id="ticket-status-select-label">
          {t('pages.assignTicket.statusAfterAssign')}
        </InputLabel>
        <Select
          labelId="ticket-status-select-label"
          id="status-select"
          name="status_id"
          label={t('pages.assignTicket.statusAfterAssign')}
          defaultValue={selectedStatusId}
          onChange={handleStatusChange}
        >
          {statusesList?.length > 0 ? (
            statusesList.map((status) => {
              const { statusId, name } = status;
              let tName = name;
              if (statusId <= 5) tName = t(`status.${name}`);
              return (
                <MenuItem key={statusId} value={statusId}>
                  {tName}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem disabled value="">
              {t('infoMessages.noOptions')}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <Button
        variant="outlined"
        onClick={selectedAgent.id !== 0 ? showConfirmModal : null}
        disabled={selectedAgent.id === 0}
      >
        {t('pages.assignTicket.assignTicket')}
      </Button>
    </Stack>
  );
}
