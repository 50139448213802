import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import {
  Card,
  List,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
} from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { scrollbarStyle } from 'src/sections/style';

import GroupListItem from './GroupListItem';
import { cardTicketModalStyle, listTicketModalStyle } from '../../styles';
import updatePermissionListAfterAdding from '../../utils/updatePermissionListAfterAdding';

AddGroupsPermissionsForm.propTypes = {
  groupList: PropTypes.array,
  ticketId: PropTypes.string,
  ticketGroupsIds: PropTypes.array,
  availableUserIds: PropTypes.object,
  setPermissionUsers: PropTypes.func,
  permissionUsers: PropTypes.array,
  addMultiplePermissions: PropTypes.func,
};

export default function AddGroupsPermissionsForm({
  groupList,
  ticketId,
  ticketGroupsIds,
  availableUserIds,
  setPermissionUsers,
  permissionUsers,
  addMultiplePermissions,
}) {
  const [filterGroup, setFilterGroup] = useState('');
  const { isSmallScreen } = useResponsiveScreen();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isNotFoundGroup, setIsNotFoundGroup] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState('read');
  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmPermission'),
    t('pages.ticketDetails.areYouSureToAdd'),

    async () => {
      // get all user ids to add from all the selected groups
      const allUserIds = selectedGroups.flatMap((group) =>
        group.users.map((user) => user.id)
      );
      const successCallback = () => {
        // update users list
        const updateUsers = updatePermissionListAfterAdding({
          users: Array.from(
            new Map(
              selectedGroups
                .flatMap((group) => group.users)
                .map((user) => [user.id, user])
            ).values()
          ),
          permission: selectedPermission,
          availableUserIds,
          permissionUsers,
        });
        setPermissionUsers(updateUsers);
      };

      // call api to add multiple users
      addMultiplePermissions(
        ticketId,
        allUserIds,
        selectedPermission === 'read',
        selectedPermission === 'write',
        successCallback
      );
    }
  );

  const handlePermissionChange = (event) => {
    setSelectedPermission(event.target.value);
  };

  const handleFilterByGroup = (event) => {
    setFilterGroup(event.target.value);
  };

  const filteredGroups = useMemo(() => {
    const lowerCaseFilterName = filterGroup.toLowerCase();
    const availableGroups = groupList.filter(
      (group) => !ticketGroupsIds.includes(group.id)
    );
    const filteredData = availableGroups.filter((group) =>
      group.name.toLowerCase().includes(lowerCaseFilterName)
    );
    setIsNotFoundGroup(!filteredData.length && !!filterGroup);
    return filteredData;
  }, [ticketGroupsIds, groupList, filterGroup]);

  return (
    <Stack px={2} spacing={2} my={2}>
      <TextField
        value={filterGroup}
        onChange={handleFilterByGroup}
        placeholder={`${t('filters.search')}`}
        size={isSmallScreen ? 'small' : 'medium'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: 'text.disabled',
                  width: isSmallScreen ? 16 : 20,
                  height: isSmallScreen ? 16 : 20,
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          '& input': {
            fontSize: isSmallScreen ? '0.775rem' : '1rem',
          },
        }}
      />
      <Card
        sx={{
          ...cardTicketModalStyle,
          height: isSmallScreen ? '200px' : '350px',
        }}
      >
        <List sx={{ ...listTicketModalStyle, pl: 1.5 }}>
          {filteredGroups.length > 0 ? (
            filteredGroups.map((group, index) => (
              <GroupListItem
                key={index}
                group={group}
                setSelectedGroups={setSelectedGroups}
                selectedGroups={selectedGroups}
                availableUserIds={availableUserIds}
              />
            ))
          ) : (
            <Typography
              textAlign="center"
              variant="subtitle1"
              sx={{
                fontSize: isSmallScreen ? '0.675rem' : '1rem',
              }}
            >
              {isNotFoundGroup
                ? t('pages.ticketDetails.noGroupsFound')
                : t('pages.ticketDetails.noGroupsAvailable')}
            </Typography>
          )}
        </List>
      </Card>
      <FormControl>
        <InputLabel
          id="ticket-status-select-label"
          sx={{
            fontSize: isSmallScreen ? '0.675rem' : '1rem',
          }}
        >
          {t('pages.ticketDetails.permission')}
        </InputLabel>
        <Select
          labelId="ticket-status-select-label"
          id="status-select"
          name="status_id"
          label="Permission"
          value={selectedPermission}
          onChange={handlePermissionChange}
          size={isSmallScreen ? 'small' : 'medium'}
          MenuProps={{
            PaperProps: {
              sx: {
                ...scrollbarStyle,
              },
            },
          }}
        >
          <MenuItem
            value="read"
            sx={{
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.ticketDetails.read')}
            </Typography>
          </MenuItem>
          <MenuItem
            value="write"
            sx={{
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.ticketDetails.modify')}
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        onClick={selectedGroups.length !== 0 ? showConfirmModal : null}
        disabled={selectedGroups.length === 0}
        sx={{
          fontSize: isSmallScreen ? '0.675rem' : '1rem',
        }}
      >
        {t('pages.ticketDetails.addUserPermission')}
      </Button>
    </Stack>
  );
}
