import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/system';
import {
  Grid,
  Card,
  Divider,
  Typography,
  CardContent,
  SwipeableDrawer,
  CircularProgress,
} from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { convertLocalTime } from 'src/utils/convertTime';

import { genericApiToaster } from 'src/services/utils';
import { getAccessAttemptsByClientId } from 'src/services/client.credentials.service';

import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

function ClientCredentialsDetails({ setOpen, open, selectedRow }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [accessAttempts, setAccessAttempts] = useState([]);
  const { showToast, hideToast } = useToast();
  const [loading, setLoading] = useState(false);

  const fetchAccessAttempts = async (clientId) => {
    setLoading(true);
    genericApiToaster(
      getAccessAttemptsByClientId,
      clientId,
      '',
      t('errorMessages.errorFetchingAccessAttempts'),
      showToast,
      hideToast,
      t,
      setAccessAttempts,
      undefined,
      () => setLoading(false)
    );
  };

  useEffect(() => {
    fetchAccessAttempts(selectedRow?.clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, open]);

  const handleClose = () => {
    setOpen(false);
    setAccessAttempts([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: isSmallScreen ? '75%' : 500,
          padding: 3,
          backgroundColor: '#f4f6f8',
        },
      }}
    >
      <Box sx={{ textAlign: 'center', mb: isSmallScreen ? 2 : 3 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            fontSize: isSmallScreen && '0.8rem',
          }}
        >
          {t('pages.clientCredentialsPage.details.clientName')}: {selectedRow?.clientName}
        </Typography>
      </Box>

      <Divider sx={{ mb: isSmallScreen ? 2 : 3 }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.clientCredentialsPage.details.clientDomain')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow?.clientDomain}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.clientCredentialsPage.details.clientId')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow?.clientId}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              p: 2,
              backgroundColor: 'white',
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.clientCredentialsPage.details.status')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Label
                color={(selectedRow?.enabled === false && 'error') || 'success'}
                sx={{
                  fontSize: isSmallScreen && '0.65rem',
                  width: '150px',
                }}
              >
                {selectedRow?.enabled === true
                  ? t('userStatus.active')
                  : t('userStatus.inactive')}
              </Label>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.clientCredentialsPage.details.createdAt')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow?.createdAt
                ? format(
                    convertLocalTime(new Date(selectedRow.createdAt)),
                    'dd/MM/yyyy HH:mm:ss'
                  )
                : ''}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.clientCredentialsPage.details.updatedAt')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow?.updatedAt
                ? format(
                    convertLocalTime(new Date(selectedRow.updatedAt)),
                    'dd/MM/yyyy HH:mm:ss'
                  )
                : '-'}
            </Typography>
          </Box>
        </Grid>

        {/* access attempts */}
        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
                mb: 0.6,
              }}
            >
              {t('pages.clientCredentialsPage.details.accessAttempts')}
            </Typography>
            {loading ? (
              <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <>
                {accessAttempts?.length > 0 ? (
                  <Scrollbar style={{ maxHeight: 300, overflowY: 'auto' }}>
                    {accessAttempts.map((accessAttempt, index) => (
                      <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: isSmallScreen && '0.65rem',
                                }}
                              >
                                {t('pages.clientCredentialsPage.details.ipAddress')}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  fontSize: isSmallScreen && '0.75rem',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {accessAttempt?.ip}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: isSmallScreen && '0.65rem',
                                }}
                              >
                                {t('pages.clientCredentialsPage.details.status')}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  fontSize: isSmallScreen && '0.75rem',
                                }}
                              >
                                {accessAttempt?.isSuccessful ? (
                                  <Label color="success">
                                    {t('pages.clientCredentialsPage.details.success')}
                                  </Label>
                                ) : (
                                  <Label color="error">
                                    {t('pages.clientCredentialsPage.details.failure')}
                                  </Label>
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: isSmallScreen && '0.65rem',
                                }}
                              >
                                {t('pages.clientCredentialsPage.details.timestamp')}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  fontSize: isSmallScreen && '0.75rem',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {format(
                                  convertLocalTime(new Date(accessAttempt?.timestamp)),
                                  'dd/MM/yyyy HH:mm:ss'
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: isSmallScreen && '0.65rem',
                                }}
                              >
                                {t('pages.clientCredentialsPage.details.clientDomain')}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  fontSize: isSmallScreen && '0.75rem',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {accessAttempt?.clientDomain}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider />
                      </Card>
                    ))}
                  </Scrollbar>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontSize: isSmallScreen && '0.65rem',
                    }}
                  >
                    {t('pages.clientCredentialsPage.details.noAccessAttemptsYet')}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

ClientCredentialsDetails.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  selectedRow: PropTypes.object,
};

export default ClientCredentialsDetails;
