import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Stack, TableRow, TableCell, Typography, IconButton } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify/Iconify';

import DepartmentDetails from 'src/sections/department/modal/DepartmentDetailsModal';

import DataCell from './DataCell';

DepartmentRow.propTypes = {
  row: PropTypes.object,
  handleOpenMenu: PropTypes.func,
  setSelected: PropTypes.func,
  fetchTickets: PropTypes.func,
  fetchUsers: PropTypes.func,
};

export default function DepartmentRow({
  row,
  handleOpenMenu,
  setSelected,
  fetchTickets,
  fetchUsers,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const { id, name, description } = row;
  const [openDepartmentDetails, setOpenDepartmentDetails] = useState(false);
  const [users, setUsersData] = useState([]);
  const [tickets, setTicketsData] = useState([]);

  useEffect(() => {
    fetchTickets(id, (res) => setTicketsData(res));
    fetchUsers(id, (res) => {
      setUsersData(res.filter((user) => user.appUserRole === 'ROLE_AGENT'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <DepartmentDetails
        open={openDepartmentDetails}
        setOpen={setOpenDepartmentDetails}
        department={row}
        tickets={tickets.length}
        users={users.length}
      />
      <TableRow
        hover
        sx={{ cursor: 'pointer' }}
        onClick={() => setOpenDepartmentDetails(true)}
      >
        <TableCell component="th" scope="row">
          <Stack alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {id}
            </Typography>
          </Stack>
        </TableCell>
        <DataCell value={name} />
        <DataCell value={description} />
        <DataCell value={users.length} />
        <DataCell value={tickets.length} />

        <TableCell
          align="center"
          sx={{
            position: 'sticky',
            zIndex: '100',
            right: 0,
            backgroundColor: 'white',
          }}
        >
          <IconButton
            size={isSmallScreen ? 'small' : 'large'}
            color="inherit"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenMenu(event, row);
              setSelected(row);
            }}
          >
            <Iconify
              icon="eva:more-vertical-fill"
              sx={{
                width: 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
