import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import AddUpdateGroupUsers from '../modal/AddUpdateGroupUsers';

function GroupUserMenu({
  anchorEl,
  onClose,
  showModal,
  hideModal,
  selectedGroup,
  onEditGroupUsers,
  handleDeleteClick,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem
        onClick={() => {
          showModal({
            title: t('pages.groupUsersPage.updateGroupUsers'),
            Component: AddUpdateGroupUsers,
            props: {
              hideModal,
              updateGroupUsers: onEditGroupUsers,
              groupUsers: selectedGroup,
            },
          });
          onClose();
        }}
      >
        <Iconify
          icon="eva:edit-fill"
          sx={{
            mr: 1,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.edit')}
        </Typography>
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClick}>
        <Iconify
          icon="eva:trash-2-outline"
          sx={{
            mr: 1,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.delete')}
        </Typography>
      </MenuItem>
    </Menu>
  );
}

GroupUserMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  selectedGroup: PropTypes.object,
  onEditGroupUsers: PropTypes.func,
  handleDeleteClick: PropTypes.func,
};

export default GroupUserMenu;
