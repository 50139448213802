export const boxStyleFormShareaableSubmitted = {
  height: '100vh',
  backgroundImage: 'url("/assets/images/covers/spaceCoverv1.gif")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
};

export const containerStyleFormSharableSubmitted = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: 3,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '400px',
};

export const TypographyStyleFormSharableSubmitted = {
  marginBottom: '20px',
  color: 'primary.main',
  cursor: 'pointer',
  textDecoration: 'underline',
};

export const boxFormUnavailable = {
  height: '100vh',
  backgroundImage: 'url("/assets/images/covers/spaceCoverv1.gif")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
};

export const containerFormUnavailable = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: 3,
  textAlign: 'center',
};
