import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Paper } from '@mui/material';

import { useModal, useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';
import EmptyGrid from 'src/components/emptyGrid/emptyGrid';

import { paperStyle } from '../style';
import GroupUsersCard from './GroupUsersCard';
import GroupUserMenu from '../popovers/MenuPopover';
import GroupUsersDetails from '../modal/GroupUsersDetails';

function GroupUsersList({
  groupUsers,
  onEditGroupUsers,
  handleDelete,
  itemsPerPage,
  page,
  isLoading,
  isNotFound,
  searchIsNotFound,
  filterName,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleMenuOpen = (event, group) => {
    setAnchorEl(event.currentTarget);
    setSelectedGroup(group);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedGroup(null);
  };

  const handleDeleteClick = async () => {
    showConfirmModal();
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.groupUsersPage.confirmDelete'),
    `${t('pages.groupUsersPage.areYouSure')} ${selectedGroup?.name}`,
    async () => {
      handleDelete(selectedGroup?.id);
      handleMenuClose();
    }
  );

  const handleCardClick = (group) => {
    setSelectedGroup(group);
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    setSelectedGroup(null);
  };

  return (
    <>
      <Grid container spacing={3} px={3} pt={2}>
        {!isLoading ? (
          <>
            {groupUsers
              .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              .map((group) => (
                <Grid key={group.id} item xs={12} sm={6} md={4} lg={3}>
                  <GroupUsersCard
                    group={group}
                    onClick={() => handleCardClick(group)}
                    onMenuOpen={handleMenuOpen}
                    isSmallScreen={isSmallScreen}
                  />
                </Grid>
              ))}
            {isNotFound && (
              <EmptyGrid searchIsNotFound={searchIsNotFound} filterName={filterName} />
            )}
          </>
        ) : (
          <Paper sx={paperStyle}>
            <Loader size={175} />
          </Paper>
        )}
      </Grid>

      {selectedGroup && (
        <GroupUsersDetails
          group={selectedGroup}
          open={detailsOpen}
          onClose={handleDetailsClose}
          onOpen={() => setDetailsOpen(true)}
          isSmallScreen={isSmallScreen}
        />
      )}

      <GroupUserMenu
        anchorEl={anchorEl}
        isSmallScreen={isSmallScreen}
        onClose={handleMenuClose}
        showModal={showModal}
        hideModal={hideModal}
        selectedGroup={selectedGroup}
        onEditGroupUsers={onEditGroupUsers}
        handleDeleteClick={handleDeleteClick}
      />
    </>
  );
}

GroupUsersList.propTypes = {
  groupUsers: PropTypes.array,
  onEditGroupUsers: PropTypes.func,
  handleDelete: PropTypes.func,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  isLoading: PropTypes.bool,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};

export default GroupUsersList;
