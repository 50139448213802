import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  List,
  Divider,
  Tooltip,
  ListItem,
  Typography,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { encrypt } from 'src/utils/hashCode';

import { genericApiToaster } from 'src/services/utils';

import Scrollbar from 'src/components/scrollbar/Scrollbar';
import ActionButton from 'src/components/action-button/action-button';

import { useToast } from '../../hooks/useToast';
import { getTicketsByIds } from '../../services/ticket.service';
import { handleIconLanguageSelected } from './TableBodyShareableForm';
import { clickedTicketStyle } from './shareableFormOptions/sections/style';

function ShareableFormDetails({ setOpen, open, selectedRow }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [ticketsStatuses, setTicketsStatuses] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const fetchTicketsStatus = async () => {
      genericApiToaster(
        getTicketsByIds,
        [selectedRow.tickets],
        '',
        t('errorMessages.ticketsStatusError'),
        showToast,
        hideToast,
        t,
        (tickets) => {
          const ticketStatuses = selectedRow.tickets.reduce((acc, ticket) => {
            // Mark ticket as "exists" or "deleted" based on the response
            const ticketExists = tickets.some((t) => t.id === ticket); // Check if ticket exists
            acc[ticket] = ticketExists ? 'exists' : 'deleted';
            return acc;
          }, {});
          setTicketsStatuses(ticketStatuses);
        }
      );
    };

    if (selectedRow?.tickets.length > 0) {
      fetchTicketsStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow?.tickets]);

  const handleDownloadCSV = async () => {
    try {
      const tickets = await getTicketsByIds(selectedRow.tickets);
      // Gather all unique question texts
      const allQuestionTexts = new Set(
        tickets.flatMap((ticket) =>
          (ticket.data || []).flatMap((section) =>
            section.questions.map((question) => question.text)
          )
        )
      );
      const ticketAttributes = [];
      Object.keys(tickets[0] || [])?.forEach((key) => {
        if (key !== 'data') {
          ticketAttributes.push(key);
        }
      });

      // Create CSV data
      const csvData = Papa.unparse({
        fields: [...ticketAttributes, ...allQuestionTexts],
        data: tickets.map((ticket) => {
          const rowData = {};
          // Add ticket attributes
          Object.keys(ticket).forEach((key) => {
            if (key !== 'data') {
              rowData[key] = ticket[key];
            }
          });
          // Add questions and answers as columns
          allQuestionTexts.forEach((questionText) => {
            rowData[questionText] = ''; // Initialize with empty string
          });
          (ticket.data || []).forEach((section) => {
            section.questions.forEach((question) => {
              rowData[question.text] = question.inputValue;
            });
          });
          return rowData;
        }),
      });

      // Trigger CSV download
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = t('pages.shareableFormsPage.details.downloadFileName', {
        id: selectedRow.id,
      });
      link.click();

      showToast({
        message: t('successMessages.csvDownloaded'),
        severity: 'success',
        props: { hideToast },
      });
    } catch (error) {
      showToast({
        message: t('errorMessages.csvDownloadError'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  if (!selectedRow) return null; // Avoid rendering if no row is selected

  function navigateToTicket(ticket) {
    window.open(`/tickets/${ticket}`, '_blank');
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: isSmallScreen ? '75%' : 450,
          padding: 3,
          backgroundColor: '#f4f6f8',
        },
      }}
    >
      <Box sx={{ textAlign: 'center', mb: isSmallScreen ? 2 : 3 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            fontSize: isSmallScreen && '0.8rem',
          }}
        >
          {t('pages.shareableFormsPage.details.formTitle')}: {selectedRow.form.title}
        </Typography>
      </Box>

      <Divider sx={{ mb: isSmallScreen ? 2 : 3 }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.generatedBy')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow.generatedBy.username}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.status')}
            </Typography>
            <Typography
              variant="body1"
              color={selectedRow.isActive ? 'success.main' : 'error.main'}
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow.isActive ? t('userStatus.active') : t('userStatus.inactive')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.isAnonymous')}
            </Typography>
            <Typography
              variant="body1"
              color={selectedRow.isAnonymous ? 'info.main' : 'text.primary'}
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow.isAnonymous
                ? t('isMultipleSubmission.yes')
                : t('isMultipleSubmission.no')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.language')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                src={handleIconLanguageSelected(selectedRow.language)}
                alt={selectedRow.language}
                width={isSmallScreen ? 20 : 28}
                height={isSmallScreen ? 16 : 20}
              />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.generatedOn')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {new Date(selectedRow.generatedOn).toLocaleString()}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.expiresOn')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {new Date(selectedRow.expiresOn).toLocaleString()}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.isMultipleSubmission')}
            </Typography>
            <Typography
              variant="body1"
              color={selectedRow.isMultipleSubmission ? 'info.main' : 'text.primary'}
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow.isMultipleSubmission
                ? t('isMultipleSubmission.yes')
                : t('isMultipleSubmission.no')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.submissions')}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                fontSize: isSmallScreen && '0.65rem',
              }}
            >
              {selectedRow.submissions}
            </Typography>
          </Box>
        </Grid>

        {/* Email Submissions */}
        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.mailSubmissions')}
            </Typography>

            {selectedRow.submitters.length > 0 ? (
              <Scrollbar sx={{ maxHeight: 300, overflowY: 'auto' }}>
                <List>
                  {selectedRow.submitters.map((email, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemText
                        primary={email}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: isSmallScreen && '0.65rem',
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Scrollbar>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: isSmallScreen && '0.65rem',
                }}
              >
                {t('pages.shareableFormsPage.details.noEmailsYet')}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 1 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('pages.shareableFormsPage.details.ticketsCreated')}
            </Typography>

            {selectedRow.submitters.length > 0 ? (
              <>
                <Scrollbar sx={{ maxHeight: 100, overflowY: 'auto', mt: 1 }}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    sx={{
                      whiteSpace: 'break-spaces',
                      fontSize: isSmallScreen && '0.65rem',
                    }}
                  >
                    {selectedRow.tickets.map((ticket, index) => {
                      const status = ticketsStatuses[ticket];

                      return status === 'exists' ? (
                        // Ticket exists, make it clickable
                        <span
                          key={ticket}
                          onClick={() => navigateToTicket(encrypt(ticket))}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              navigateToTicket(encrypt(ticket));
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          style={{
                            ...clickedTicketStyle,
                            display: 'inline-block',
                            marginRight:
                              index < selectedRow.tickets.length - 1 ? '12px' : '0',
                          }}
                        >
                          #{ticket}
                        </span>
                      ) : (
                        // Ticket is deleted, show deleted label
                        <span
                          key={ticket}
                          style={{
                            color: 'gray',
                            textDecoration: 'line-through',
                            display: 'inline-block',
                            marginRight:
                              index < selectedRow.tickets.length - 1 ? '12px' : '0',
                          }}
                        >
                          <Tooltip
                            title={t('pages.shareableFormsPage.details.ticketWasDeleted')}
                          >
                            <span>#{ticket}</span>
                          </Tooltip>
                        </span>
                      );
                    })}
                  </Typography>
                </Scrollbar>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }}>
                  <ActionButton
                    id="download-csv-small-screen"
                    onClick={handleDownloadCSV}
                    icon="material-symbols:download"
                    text={t('buttons.download')}
                    title={t('toolbar.downloadCSV')}
                  />
                </Box>
              </>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: isSmallScreen && '0.65rem',
                }}
              >
                {t('pages.shareableFormsPage.details.noTicketsYet')}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

ShareableFormDetails.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  selectedRow: PropTypes.object,
};

export default ShareableFormDetails;
