import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

import authService from 'src/services/auth.service';
import { updateUser, getUserById } from 'src/services/user.service';

const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(secureLocalStorage.getItem('user') || null);
  const [user, setUser] = useState(secureLocalStorage.getItem('user_data') || null);

  const fetchUser = async (token) => {
    if (token) {
      const decodedUser = jwtDecode(token);
      const user = await getUserById(decodedUser.id);
      setUser({ ...user, role: decodedUser.auth, firstLogin: decodedUser.firstLogin });
      secureLocalStorage.setItem('user_data', {
        ...user,
        role: decodedUser.auth,
        firstLogin: decodedUser.firstLogin,
      });
    }
  };

  const login = async (accessToken) => {
    const res = await authService.login(accessToken);
    secureLocalStorage.setItem('user', res.authenticationToken);
    setToken(res.authenticationToken);
    await fetchUser(res.authenticationToken);

    return res;
  };

  const updateCurrentUser = async (data) => {
    await updateUser(data);
    await fetchUser(token);
  };

  const contextValue = useMemo(
    () => ({
      id: user?.id,
      role: user?.role,
      isAuthenticated: !!token,
      departmentId: user?.departmentId,
      username: user?.username,
      firstLogin: user?.firstLogin,
      email: user?.email,
      token,
      login,
      user,
      updateCurrentUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, user]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
