import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const encrypt = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(String(data), SECRET_KEY).toString();
  const urlSafeCiphertext = encodeURIComponent(ciphertext); // Make it URL-safe
  return urlSafeCiphertext;
};

export const decrypt = (ciphertext) => {
  const decodedCiphertext = decodeURIComponent(ciphertext); // Decode URL-safe string
  const bytes = CryptoJS.AES.decrypt(decodedCiphertext, SECRET_KEY);
  const originalData = bytes.toString(CryptoJS.enc.Utf8);
  return originalData === '' ? undefined : originalData;
};
