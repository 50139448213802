import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  List,
  Avatar,
  Tooltip,
  ListItem,
  IconButton,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';

import { useToast } from 'src/hooks/useToast';

import Iconify from '../../../components/iconify';
import fileDownload from '../../../utils/fileDownload';
import { getFileByName } from '../../../services/file.service';

AttachmentsList.propTypes = {
  ticketFilesList: PropTypes.array,
};
export default function AttachmentsList({ ticketFilesList }) {
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();

  const handleDownloadClick = async (e) => {
    const name = e.currentTarget.value;
    try {
      const file = await getFileByName(name);
      fileDownload(file, name);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntDownloadFile'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  return (
    <List sx={{ px: 2 }}>
      {ticketFilesList.map((fileName, index) => {
        let icon = 'bx:file';
        const extensions = ['.jpeg', '.jpg', '.png', '.gif'];
        extensions.forEach((ext) => {
          if (fileName.endsWith(ext)) {
            icon = 'ph:image';
          }
        });

        return (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                value={fileName}
                onClick={handleDownloadClick}
                edge="end"
                aria-label="delete"
              >
                <Iconify icon="material-symbols:download" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Iconify icon={icon} />
              </Avatar>
            </ListItemAvatar>
            <Tooltip title={fileName}>
              <ListItemText
                primary={fileName}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              />
            </Tooltip>
          </ListItem>
        );
      })}
    </List>
  );
}
