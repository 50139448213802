import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { LoginLayout } from 'src/layouts/login';

import LoginForm from 'src/sections/auth/login';
import ForgotPassword from 'src/sections/auth/ForgotPassword';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [forgotPassword, setForgotPassword] = useState(false);

  const toggleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System Log in Page </title>
      </Helmet>

      <LoginLayout>
        {forgotPassword ? (
          <ForgotPassword toggleForgotPassword={toggleForgotPassword} />
        ) : (
          <LoginForm toggleForgotPassword={toggleForgotPassword} />
        )}
      </LoginLayout>
    </>
  );
}
