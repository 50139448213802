const countries = [
  { code: '+1', en: 'United States', it: 'Stati Uniti', ar: 'الولايات المتحدة' },
  { code: '+1', en: 'Canada', it: 'Canada', ar: 'كندا' },
  { code: '+44', en: 'United Kingdom', it: 'Regno Unito', ar: 'المملكة المتحدة' },
  { code: '+91', en: 'India', it: 'India', ar: 'الهند' },
  { code: '+1242', en: 'Bahamas', it: 'Bahamas', ar: 'الباهاما' },
  { code: '+1246', en: 'Barbados', it: 'Barbados', ar: 'بربادوس' },
  { code: '+1264', en: 'Anguilla', it: 'Anguilla', ar: 'أنغويلا' },
  {
    code: '+1268',
    en: 'Antigua and Barbuda',
    it: 'Antigua e Barbuda',
    ar: 'أنتيغوا وبربودا',
  },
  {
    code: '+1284',
    en: 'British Virgin Islands',
    it: 'Isole Vergini Britanniche',
    ar: 'جزر فيرجن البريطانية',
  },
  {
    code: '+1340',
    en: 'U.S. Virgin Islands',
    it: 'Isole Vergini Americane',
    ar: 'جزر فيرجن الأمريكية',
  },
  { code: '+1441', en: 'Bermuda', it: 'Bermuda', ar: 'برمودا' },
  { code: '+1473', en: 'Grenada', it: 'Grenada', ar: 'غرينادا' },
  {
    code: '+1649',
    en: 'Turks and Caicos Islands',
    it: 'Isole Turks e Caicos',
    ar: 'جزر الترك وجايكوس',
  },
  { code: '+1664', en: 'Montserrat', it: 'Montserrat', ar: 'مونتسرات' },
  {
    code: '+1670',
    en: 'Northern Mariana Islands',
    it: 'Isole Marianne Settentrionali',
    ar: 'جزر ماريانا الشمالية',
  },
  { code: '+1671', en: 'Guam', it: 'Guam', ar: 'غوام' },
  { code: '+1684', en: 'American Samoa', it: 'Samoa Americane', ar: 'ساموا الأمريكية' },
  { code: '+1758', en: 'Saint Lucia', it: 'Santa Lucia', ar: 'سانت لوسيا' },
  { code: '+1767', en: 'Dominica', it: 'Dominica', ar: 'دومينيكا' },
  {
    code: '+1784',
    en: 'Saint Vincent and the Grenadines',
    it: 'Saint Vincent e Grenadine',
    ar: 'سانت فينسنت والغرينادين',
  },
  {
    code: '+1849',
    en: 'Dominican Republic',
    it: 'Repubblica Dominicana',
    ar: 'جمهورية الدومينيكان',
  },
  {
    code: '+1868',
    en: 'Trinidad and Tobago',
    it: 'Trinidad e Tobago',
    ar: 'ترينيداد وتوباغو',
  },
  {
    code: '+1869',
    en: 'Saint Kitts and Nevis',
    it: 'Saint Kitts e Nevis',
    ar: 'سانت كيتس ونيفيس',
  },
  { code: '+1876', en: 'Jamaica', it: 'Giamaica', ar: 'جامايكا' },
  { code: '+1939', en: 'Puerto Rico', it: 'Porto Rico', ar: 'بورتوريكو' },
  { code: '+20', en: 'Egypt', it: 'Egitto', ar: 'مصر' },
  { code: '+211', en: 'South Sudan', it: 'Sudan del Sud', ar: 'جنوب السودان' },
  { code: '+212', en: 'Morocco', it: 'Marocco', ar: 'المغرب' },
  { code: '+213', en: 'Algeria', it: 'Algeria', ar: 'الجزائر' },
  { code: '+216', en: 'Tunisia', it: 'Tunisia', ar: 'تونس' },
  { code: '+218', en: 'Libya', it: 'Libia', ar: 'ليبيا' },
  { code: '+220', en: 'Gambia', it: 'Gambia', ar: 'غامبيا' },
  { code: '+221', en: 'Senegal', it: 'Senegal', ar: 'السنغال' },
  { code: '+222', en: 'Mauritania', it: 'Mauritania', ar: 'موريتانيا' },
  { code: '+223', en: 'Mali', it: 'Mali', ar: 'مالي' },
  { code: '+224', en: 'Guinea', it: 'Guinea', ar: 'غينيا' },
  { code: '+225', en: 'Ivory Coast', it: "Costa d'Avorio", ar: 'ساحل العاج' },
  { code: '+226', en: 'Burkina Faso', it: 'Burkina Faso', ar: 'بوركينا فاسو' },
  { code: '+227', en: 'Niger', it: 'Niger', ar: 'النيجر' },
  { code: '+228', en: 'Togo', it: 'Togo', ar: 'توغو' },
  { code: '+229', en: 'Benin', it: 'Benin', ar: 'بنين' },
  { code: '+230', en: 'Mauritius', it: 'Mauritius', ar: 'موريشيوس' },
  { code: '+231', en: 'Liberia', it: 'Liberia', ar: 'ليبيريا' },
  { code: '+232', en: 'Sierra Leone', it: 'Sierra Leone', ar: 'سيراليون' },
  { code: '+233', en: 'Ghana', it: 'Ghana', ar: 'غانا' },
  { code: '+234', en: 'Nigeria', it: 'Nigeria', ar: 'نيجيريا' },
  { code: '+235', en: 'Chad', it: 'Ciad', ar: 'تشاد' },
  {
    code: '+236',
    en: 'Central African Republic',
    it: 'Repubblica Centrafricana',
    ar: 'جمهورية افريقيا الوسطى',
  },
  { code: '+237', en: 'Cameroon', it: 'Camerun', ar: 'الكاميرون' },
  { code: '+238', en: 'Cape Verde', it: 'Capo Verde', ar: 'الرأس الأخضر' },
  {
    code: '+239',
    en: 'Sao Tome and Principe',
    it: 'Sao Tomé e Principe',
    ar: 'ساو تومي وبرينسيبي',
  },
  {
    code: '+240',
    en: 'Equatorial Guinea',
    it: 'Guinea Equatoriale',
    ar: 'غينيا الاستوائية',
  },
  { code: '+241', en: 'Gabon', it: 'Gabon', ar: 'الجابون' },
  { code: '+242', en: 'Congo', it: 'Congo', ar: 'الكونغو' },
  {
    code: '+243',
    en: 'Democratic Republic of the Congo',
    it: 'Repubblica Democratica del Congo',
    ar: 'جمهورية الكونغو الديمقراطية',
  },
  { code: '+244', en: 'Angola', it: 'Angola', ar: 'أنغولا' },
  { code: '+245', en: 'Guinea-Bissau', it: 'Guinea-Bissau', ar: 'غينيا بيساو' },
  {
    code: '+246',
    en: 'British Indian Ocean Territory',
    it: "Territorio britannico dell'oceano Indiano",
    ar: 'المحيط الهندي البريطاني',
  },
  { code: '+248', en: 'Seychelles', it: 'Seychelles', ar: 'سيشل' },
  { code: '+249', en: 'Sudan', it: 'Sudan', ar: 'السودان' },
  { code: '+250', en: 'Rwanda', it: 'Ruanda', ar: 'رواندا' },
  { code: '+251', en: 'Ethiopia', it: 'Etiopia', ar: 'اثيوبيا' },
  { code: '+252', en: 'Somalia', it: 'Somalia', ar: 'الصومال' },
  { code: '+253', en: 'Djibouti', it: 'Gibuti', ar: 'جيبوتي' },
  { code: '+254', en: 'Kenya', it: 'Kenya', ar: 'كينيا' },
  { code: '+255', en: 'Tanzania', it: 'Tanzania', ar: 'تنزانيا' },
  { code: '+256', en: 'Uganda', it: 'Uganda', ar: 'أوغندا' },
  { code: '+257', en: 'Burundi', it: 'Burundi', ar: 'بوروندي' },
  { code: '+258', en: 'Mozambique', it: 'Mozambico', ar: 'موزمبيق' },
  { code: '+260', en: 'Zambia', it: 'Zambia', ar: 'زامبيا' },
  { code: '+261', en: 'Madagascar', it: 'Madagascar', ar: 'مدغشقر' },
  {
    code: '+262',
    en: 'French Southern Territories',
    it: 'Terre Australi e Antartiche Francesi',
    ar: 'الأراضي الفرنسية الجنوبية',
  },
  { code: '+262', en: 'Mayotte', it: 'Mayotte', ar: 'مايوت' },
  { code: '+262', en: 'Réunion', it: 'Riunione', ar: 'روينيون' },
  { code: '+263', en: 'Zimbabwe', it: 'Zimbabwe', ar: 'زيمبابوي' },
  { code: '+264', en: 'Namibia', it: 'Namibia', ar: 'ناميبيا' },
  { code: '+265', en: 'Malawi', it: 'Malawi', ar: 'ملاوي' },
  { code: '+266', en: 'Lesotho', it: 'Lesotho', ar: 'ليسوتو' },
  { code: '+267', en: 'Botswana', it: 'Botswana', ar: 'بوتسوانا' },
  { code: '+268', en: 'Eswatini', it: 'Eswatini', ar: 'سوازيلاند' },
  { code: '+269', en: 'Comoros', it: 'Comore', ar: 'جزر القمر' },
  { code: '+27', en: 'South Africa', it: 'Sudafrica', ar: 'جنوب أفريقيا' },
  {
    code: '+290',
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    it: "Sant'Elena, Ascensione e Tristan da Cunha",
    ar: 'سانت هيلينا وأسينشين وتريستان دا كونا',
  },
  { code: '+291', en: 'Eritrea', it: 'Eritrea', ar: 'اريتريا' },
  { code: '+297', en: 'Aruba', it: 'Aruba', ar: 'أروبا' },
  { code: '+298', en: 'Faroe Islands', it: 'Isole Fær Øer', ar: 'جزر فارو' },
  { code: '+299', en: 'Greenland', it: 'Groenlandia', ar: 'غرينلاند' },
  { code: '+30', en: 'Greece', it: 'Grecia', ar: 'اليونان' },
  { code: '+31', en: 'Netherlands', it: 'Paesi Bassi', ar: 'هولندا' },
  { code: '+32', en: 'Belgium', it: 'Belgio', ar: 'بلجيكا' },
  { code: '+33', en: 'France', it: 'Francia', ar: 'فرنسا' },
  { code: '+34', en: 'Spain', it: 'Spagna', ar: 'إسبانيا' },
  { code: '+345', en: 'Cayman Islands', it: 'Isole Cayman', ar: 'جزر كايمان' },
  { code: '+350', en: 'Gibraltar', it: 'Gibilterra', ar: 'جبل طارق' },
  { code: '+351', en: 'Portugal', it: 'Portogallo', ar: 'البرتغال' },
  { code: '+352', en: 'Luxembourg', it: 'Lussemburgo', ar: 'لوكسمبورغ' },
  { code: '+353', en: 'Ireland', it: 'Irlanda', ar: 'جمهورية أيرلندا' },
  { code: '+354', en: 'Iceland', it: 'Islanda', ar: 'أيسلندا' },
  { code: '+355', en: 'Albania', it: 'Albania', ar: 'ألبانيا' },
  { code: '+356', en: 'Malta', it: 'Malta', ar: 'مالطا' },
  { code: '+357', en: 'Cyprus', it: 'Cipro', ar: 'قبرص' },
  { code: '+358', en: 'Finland', it: 'Finlandia', ar: 'فنلندا' },
  { code: '+358', en: 'Åland Islands', it: 'Isole Åland', ar: 'جزر أولاند' },
  { code: '+359', en: 'Bulgaria', it: 'Bulgaria', ar: 'بلغاريا' },
  { code: '+36', en: 'Hungary', it: 'Ungheria', ar: 'هنغاريا' },
  { code: '+370', en: 'Lithuania', it: 'Lituania', ar: 'ليتوانيا' },
  { code: '+371', en: 'Latvia', it: 'Lettonia', ar: 'لاتفيا' },
  { code: '+372', en: 'Estonia', it: 'Estonia', ar: 'استونيا' },
  { code: '+373', en: 'Moldova', it: 'Moldavia', ar: 'مولدوفا' },
  { code: '+374', en: 'Armenia', it: 'Armenia', ar: 'أرمينيا' },
  { code: '+375', en: 'Belarus', it: 'Bielorussia', ar: 'روسيا البيضاء' },
  { code: '+376', en: 'Andorra', it: 'Andorra', ar: 'أندورا' },
  { code: '+377', en: 'Monaco', it: 'Monaco', ar: 'موناكو' },
  { code: '+378', en: 'San Marino', it: 'San Marino', ar: 'سان مارينو' },
  { code: '+379', en: 'Vatican City', it: 'Città del Vaticano', ar: 'الفاتيكان' },
  { code: '+380', en: 'Ukraine', it: 'Ucraina', ar: 'أوكرانيا' },
  { code: '+381', en: 'Serbia', it: 'Serbia', ar: 'صربيا' },
  { code: '+382', en: 'Montenegro', it: 'Montenegro', ar: 'الجبل الأسود' },
  { code: '+383', en: 'Kosovo', it: 'Kosovo', ar: 'كوسوفو' },
  { code: '+385', en: 'Croatia', it: 'Croazia', ar: 'كرواتيا' },
  { code: '+386', en: 'Slovenia', it: 'Slovenia', ar: 'سلوفينيا' },
  {
    code: '+387',
    en: 'Bosnia and Herzegovina',
    it: 'Bosnia-Erzegovina',
    ar: 'البوسنة والهرسك',
  },
  {
    code: '+389',
    en: 'North Macedonia',
    it: 'Macedonia del Nord',
    ar: 'مقدونيا الشمالية',
  },
  { code: '+39', en: 'Italy', it: 'Italia', ar: 'إيطاليا' },
  { code: '+40', en: 'Romania', it: 'Romania', ar: 'رومانيا' },
  { code: '+41', en: 'Switzerland', it: 'Svizzera', ar: 'سويسرا' },
  { code: '+420', en: 'Czech Republic', it: 'Repubblica Ceca', ar: 'جمهورية التشيك' },
  { code: '+421', en: 'Slovakia', it: 'Slovacchia', ar: 'سلوفاكيا' },
  { code: '+423', en: 'Liechtenstein', it: 'Liechtenstein', ar: 'ليختنشتاين' },
  { code: '+43', en: 'Austria', it: 'Austria', ar: 'النمسا' },
  { code: '+44', en: 'Jersey', it: 'Jersey', ar: 'جيرنسي' },
  { code: '+44', en: 'Isle of Man', it: 'Isola di Man', ar: 'جزيرة مان' },
  { code: '+44', en: 'Guernsey', it: 'Guernsey', ar: 'جيرسي' },
  { code: '+45', en: 'Denmark', it: 'Danimarca', ar: 'الدانمرك' },
  { code: '+46', en: 'Sweden', it: 'Svezia', ar: 'السويد' },
  { code: '+47', en: 'Bouvet Island', it: 'Isola Bouvet', ar: 'جزيرة بوفيه' },
  { code: '+47', en: 'Norway', it: 'Norvegia', ar: 'النرويج' },
  {
    code: '+47',
    en: 'Svalbard and Jan Mayen',
    it: 'Svalbard e Jan Mayen',
    ar: 'سفالبارد وجان ماين',
  },
  { code: '+48', en: 'Poland', it: 'Polonia', ar: 'بولندا' },
  { code: '+49', en: 'Germany', it: 'Germania', ar: 'ألمانيا' },
  {
    code: '+500',
    en: 'Falkland Islands (Malvinas)',
    it: 'Isole Falkland (Malvine)',
    ar: 'جزر فوكلاند (مالفيناس)',
  },
  {
    code: '+500',
    en: 'South Georgia and the South Sandwich Islands',
    it: 'Georgia del Sud e Isole Sandwich Meridionali',
    ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
  },
  { code: '+501', en: 'Belize', it: 'Belize', ar: 'بليز' },
  { code: '+502', en: 'Guatemala', it: 'Guatemala', ar: 'غواتيمالا' },
  { code: '+503', en: 'El Salvador', it: 'El Salvador', ar: 'السلفادور' },
  { code: '+504', en: 'Honduras', it: 'Honduras', ar: 'هندوراس' },
  { code: '+505', en: 'Nicaragua', it: 'Nicaragua', ar: 'نيكاراغوا' },
  { code: '+506', en: 'Costa Rica', it: 'Costa Rica', ar: 'كوستاريكا' },
  { code: '+507', en: 'Panama', it: 'Panama', ar: 'بنما' },
  {
    code: '+508',
    en: 'Saint Pierre and Miquelon',
    it: 'Saint Pierre e Miquelon',
    ar: 'سان بيير وميكلون',
  },
  { code: '+509', en: 'Haiti', it: 'Haiti', ar: 'هايتي' },
  { code: '+51', en: 'Peru', it: 'Perù', ar: 'بيرو' },
  { code: '+52', en: 'Mexico', it: 'Messico', ar: 'المكسيك' },
  { code: '+53', en: 'Cuba', it: 'Cuba', ar: 'كوبا' },
  { code: '+54', en: 'Argentina', it: 'Argentina', ar: 'الأرجنتين' },
  { code: '+55', en: 'Brazil', it: 'Brasile', ar: 'البرازيل' },
  { code: '+56', en: 'Chile', it: 'Cile', ar: 'شيلي' },
  { code: '+57', en: 'Colombia', it: 'Colombia', ar: 'كولومبيا' },
  { code: '+58', en: 'Venezuela', it: 'Venezuela', ar: 'فنزويلا' },
  { code: '+590', en: 'Guadeloupe', it: 'Guadalupa', ar: 'غوادلوب' },
  { code: '+591', en: 'Bolivia', it: 'Bolivia', ar: 'بوليفيا' },
  { code: '+592', en: 'Guyana', it: 'Guyana', ar: 'غيانا' },
  { code: '+593', en: 'Ecuador', it: 'Ecuador', ar: 'الإكوادور' },
  { code: '+594', en: 'French Guiana', it: 'Guyana francese', ar: 'غويانا الفرنسية' },
  { code: '+595', en: 'Paraguay', it: 'Paraguay', ar: 'باراغواي' },
  { code: '+596', en: 'Martinique', it: 'Martinica', ar: 'مارتينيك' },
  { code: '+597', en: 'Suriname', it: 'Suriname', ar: 'سورينام' },
  { code: '+598', en: 'Uruguay', it: 'Uruguay', ar: 'أوروغواي' },
  {
    code: '+599',
    en: 'Caribbean Netherlands',
    it: 'Paesi Bassi caraibici',
    ar: 'هولندا الكاريبية',
  },
  { code: '+599', en: 'Curaçao', it: 'Curaçao', ar: 'كوراساو' },
  { code: '+60', en: 'Malaysia', it: 'Malesia', ar: 'ماليزيا' },
  { code: '+61', en: 'Australia', it: 'Australia', ar: 'أستراليا' },
  { code: '+61', en: 'Christmas Island', it: 'Isola Christmas', ar: 'جزيرة كريسماس' },
  {
    code: '+61',
    en: 'Cocos (Keeling) Islands',
    it: 'Isole Cocos (Keeling)',
    ar: 'جزر كوكوس (كيلينغ)',
  },
  { code: '+62', en: 'Indonesia', it: 'Indonesia', ar: 'إندونيسيا' },
  { code: '+63', en: 'Philippines', it: 'Filippine', ar: 'الفلبين' },
  { code: '+64', en: 'New Zealand', it: 'Nuova Zelanda', ar: 'نيوزيلندا' },
  { code: '+65', en: 'Singapore', it: 'Singapore', ar: 'سنغافورة' },
  { code: '+66', en: 'Thailand', it: 'Thailandia', ar: 'تايلاند' },
  { code: '+670', en: 'Timor-Leste', it: 'Timor Est', ar: 'تيمور الشرقية' },
  { code: '+672', en: 'Norfolk Island', it: 'Isola Norfolk', ar: 'جزيرة نورفولك' },
  {
    code: '+673',
    en: 'Brunei Darussalam',
    it: 'Brunei Darussalam',
    ar: 'بروناي دار السلام',
  },
  { code: '+674', en: 'Nauru', it: 'Nauru', ar: 'ناورو' },
  {
    code: '+675',
    en: 'Papua New Guinea',
    it: 'Papua Nuova Guinea',
    ar: 'بابوا غينيا الجديدة',
  },
  { code: '+676', en: 'Tonga', it: 'Tonga', ar: 'تونغا' },
  { code: '+677', en: 'Solomon Islands', it: 'Isole Salomone', ar: 'جزر سليمان' },
  { code: '+678', en: 'Vanuatu', it: 'Vanuatu', ar: 'فانواتو' },
  { code: '+679', en: 'Fiji', it: 'Figi', ar: 'فيجي' },
  { code: '+680', en: 'Palau', it: 'Palau', ar: 'بالاو' },
  { code: '+681', en: 'Wallis and Futuna', it: 'Wallis e Futuna', ar: 'واليس وفوتونا' },
  { code: '+682', en: 'Cook Islands', it: 'Isole Cook', ar: 'جزر كوك' },
  { code: '+683', en: 'Niue', it: 'Niue', ar: 'نيوي' },
  { code: '+685', en: 'Samoa', it: 'Samoa', ar: 'ساموا' },
  { code: '+686', en: 'Kiribati', it: 'Kiribati', ar: 'كيريباتي' },
  { code: '+687', en: 'New Caledonia', it: 'Nuova Caledonia', ar: 'كاليدونيا الجديدة' },
  { code: '+688', en: 'Tuvalu', it: 'Tuvalu', ar: 'توفالو' },
  {
    code: '+689',
    en: 'French Polynesia',
    it: 'Polinesia francese',
    ar: 'بولينيزيا الفرنسية',
  },
  { code: '+690', en: 'Tokelau', it: 'Tokelau', ar: 'توكيلاو' },
  { code: '+691', en: 'Micronesia', it: 'Micronesia', ar: 'ميكرونيزيا' },
  { code: '+692', en: 'Marshall Islands', it: 'Isole Marshall', ar: 'جزر مارشال' },
  { code: '+7', en: 'Kazakhstan', it: 'Kazakistan', ar: 'كازاخستان' },
  { code: '+7', en: 'Russia', it: 'Russia', ar: 'روسيا' },
  { code: '+76', en: 'Kazakhstan', it: 'Kazakistan', ar: 'كازاخستان' },
  { code: '+77', en: 'Kazakhstan', it: 'Kazakistan', ar: 'كازاخستان' },
  { code: '+78', en: 'Russia', it: 'Russia', ar: 'روسيا' },
  { code: '+79', en: 'Russia', it: 'Russia', ar: 'روسيا' },
  { code: '+81', en: 'Japan', it: 'Giappone', ar: 'اليابان' },
  { code: '+82', en: 'South Korea', it: 'Corea del Sud', ar: 'كوريا الجنوبية' },
  { code: '+84', en: 'Vietnam', it: 'Vietnam', ar: 'فيتنام' },
  { code: '+850', en: 'North Korea', it: 'Corea del Nord', ar: 'كوريا الشمالية' },
  { code: '+852', en: 'Hong Kong', it: 'Hong Kong', ar: 'هونغ كونغ' },
  { code: '+853', en: 'Macau', it: 'Macao', ar: 'ماكاو' },
  { code: '+855', en: 'Cambodia', it: 'Cambogia', ar: 'كمبوديا' },
  { code: '+856', en: 'Laos', it: 'Laos', ar: 'لاوس' },
  { code: '+86', en: 'China', it: 'Cina', ar: 'الصين' },
  { code: '+870', en: 'Inmarsat SNAC', it: 'Inmarsat SNAC', ar: 'إنمارسات SNAC' },
  { code: '+880', en: 'Bangladesh', it: 'Bangladesh', ar: 'بنغلاديش' },
  { code: '+886', en: 'Taiwan', it: 'Taiwan', ar: 'تايوان' },
  { code: '+90', en: 'Turkey', it: 'Turchia', ar: 'تركيا' },
  { code: '+91', en: 'India', it: 'India', ar: 'الهند' },
  { code: '+92', en: 'Pakistan', it: 'Pakistan', ar: 'باكستان' },
  { code: '+93', en: 'Afghanistan', it: 'Afghanistan', ar: 'أفغانستان' },
  { code: '+94', en: 'Sri Lanka', it: 'Sri Lanka', ar: 'سريلانكا' },
  { code: '+95', en: 'Myanmar', it: 'Myanmar', ar: 'ميانمار' },
  { code: '+960', en: 'Maldives', it: 'Maldive', ar: 'جزر المالديف' },
  { code: '+961', en: 'Lebanon', it: 'Libano', ar: 'لبنان' },
  { code: '+962', en: 'Jordan', it: 'Giordania', ar: 'الأردن' },
  { code: '+963', en: 'Syria', it: 'Siria', ar: 'سوريا' },
  { code: '+964', en: 'Iraq', it: 'Iraq', ar: 'العراق' },
  { code: '+965', en: 'Kuwait', it: 'Kuwait', ar: 'الكويت' },
  {
    code: '+966',
    en: 'Saudi Arabia',
    it: 'Arabia Saudita',
    ar: 'المملكة العربية السعودية',
  },
  { code: '+967', en: 'Yemen', it: 'Yemen', ar: 'اليمن' },
  { code: '+968', en: 'Oman', it: 'Oman', ar: 'عمان' },
  { code: '+970', en: 'State of Palestine', it: 'Stato di Palestina', ar: 'دولة فلسطين' },
  {
    code: '+971',
    en: 'United Arab Emirates',
    it: 'Emirati Arabi Uniti',
    ar: 'الإمارات العربية المتحدة',
  },
  { code: '+972', en: 'Israel', it: 'Israele', ar: 'إسرائيل' },
  { code: '+973', en: 'Bahrain', it: 'Bahrein', ar: 'البحرين' },
  { code: '+974', en: 'Qatar', it: 'Qatar', ar: 'قطر' },
  { code: '+975', en: 'Bhutan', it: 'Bhutan', ar: 'بوتان' },
  { code: '+976', en: 'Mongolia', it: 'Mongolia', ar: 'منغوليا' },
  { code: '+977', en: 'Nepal', it: 'Nepal', ar: 'نيبال' },
  { code: '+98', en: 'Iran', it: 'Iran', ar: 'إيران' },
  { code: '+992', en: 'Tajikistan', it: 'Tagikistan', ar: 'طاجيكستان' },
  { code: '+993', en: 'Turkmenistan', it: 'Turkmenistan', ar: 'تركمانستان' },
  { code: '+994', en: 'Azerbaijan', it: 'Azerbaigian', ar: 'أذربيجان' },
  { code: '+995', en: 'Georgia', it: 'Georgia', ar: 'جورجيا' },
  { code: '+996', en: 'Kyrgyzstan', it: 'Kirghizistan', ar: 'قيرغيزستان' },
  { code: '+998', en: 'Uzbekistan', it: 'Uzbekistan', ar: 'أوزبكستان' },
];
export default countries;
