import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import {
  Avatar,
  Tooltip,
  ListItem,
  IconButton,
  Typography,
  ListItemAvatar,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import { useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { updateUserPermissions } from 'src/services/ticket.permissions.service';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

UserListPermission.propTypes = {
  user: PropTypes.object,
  deletePermission: PropTypes.func,
  ticketId: PropTypes.string,
  hasWritePermission: PropTypes.bool.isRequired,
  setPermissionUsers: PropTypes.func,
};

function UserListPermission({
  ticketId,
  user,
  deletePermission,
  hasWritePermission,
  setPermissionUsers,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(user?.permissions || [false, false]);
  const [toggled, setToggled] = useState(false);
  const { showToast, hideToast } = useToast();
  const { id: currentUserId } = useAuth();
  useEffect(() => {
    if (user.permissions && user.permissions.length === 2) {
      setPermissions(user.permissions);
    }
  }, [user.permissions]);

  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmDelete'),
    `${t('pages.ticketDetails.areYouSure')} ${user?.givenName}`,
    async () => {
      deletePermission(ticketId, user?.id);
    }
  );

  const handleUpdatePermissions = async () => {
    if (!permissions[0] && permissions[1]) {
      setPermissions([true, permissions[1]]);
      setToggled(false);
      showToast({
        message: t('errorMessages.cannotRevokeReadPermission'),
        severity: 'warning',
        props: { hideToast },
      });
      return;
    }
    if (!permissions[0] && !permissions[1]) {
      showConfirmModal();
      return;
    }
    try {
      setToggled(false);
      await updateUserPermissions(ticketId, user.id, permissions[0], permissions[1]);
      setPermissionUsers((prevUsers) =>
        prevUsers.map((prevUser) =>
          prevUser.id === user.id ? { ...prevUser, permissions } : prevUser
        )
      );
      showToast({
        message: t('successMessages.userPermissionsUpdated'),
        severity: 'success',
        props: { hideToast },
      });
    } catch (error) {
      showToast({
        message: t('errorMessages.errorUpdatingPermissions'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const toggleReadPermission = () => {
    setPermissions([!permissions[0], permissions[1]]);
    setToggled(true);
  };

  const toggleWritePermission = () => {
    setPermissions([true, !permissions[1]]);
    setToggled(true);
  };

  const cancelUpdate = () => {
    setPermissions(user.permissions || [false, false]);
    setToggled(false);
  };

  return (
    <ListItem divider alignItems="flex-start">
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <ListItemAvatar>
            <Avatar
              alt={user.givenName}
              src={user.avatarUrl || ''}
              sx={{
                width: isSmallScreen ? 30 : 40,
                height: isSmallScreen ? 30 : 40,
              }}
            />
          </ListItemAvatar>
          <Stack spacing={0.5}>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{`${user.givenName} ${user.familyName}`}</b>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{t('attributes.email')}: </b>
              {user.email}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
              }}
            >
              <b>{t('tableHeads.user.role')}: </b>
              {t(`roles.${user.appUserRole}`)}
            </Typography>
            {user.departmentName && user.departmentName !== 'none' && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen ? '0.65rem' : '0.85rem',
                }}
              >
                <b>{t('attributes.department')}: </b>
                {user.departmentName}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.25}>
          {/* Permissions */}
          <Stack direction="row" spacing={0.5}>
            <Tooltip title={t('pages.ticketDetails.read')}>
              <Label
                color={permissions[0] ? 'success' : 'error'}
                onClick={
                  hasWritePermission && !user.default && currentUserId !== user.id
                    ? toggleReadPermission
                    : undefined
                }
                sx={{
                  fontSize: isSmallScreen ? '0.55rem' : '0.65rem',
                  cursor:
                    hasWritePermission && !user.default && currentUserId !== user.id
                      ? 'pointer'
                      : 'not-allowed',
                }}
              >
                {t('pages.ticketDetails.r')}
              </Label>
            </Tooltip>
            <Tooltip title={t('pages.ticketDetails.modify')}>
              <Label
                color={permissions[1] ? 'success' : 'error'}
                onClick={
                  hasWritePermission && !user.default && currentUserId !== user.id
                    ? toggleWritePermission
                    : undefined
                }
                sx={{
                  fontSize: isSmallScreen ? '0.55rem' : '0.65rem',
                  cursor:
                    hasWritePermission && !user.default && currentUserId !== user.id
                      ? 'pointer'
                      : 'not-allowed',
                }}
              >
                {t('pages.ticketDetails.m')}
              </Label>
            </Tooltip>
          </Stack>

          {toggled && (
            <>
              {/* Update Icon */}
              <Tooltip title={t('pages.ticketDetails.confirm')}>
                <IconButton
                  color="primary"
                  onClick={handleUpdatePermissions}
                  disabled={!hasWritePermission || user.default}
                >
                  <Iconify icon="eva:checkmark-outline" sx={{ width: 17, height: 17 }} />
                </IconButton>
              </Tooltip>
              {/* Cancel Icon */}
              <Tooltip title={t('pages.ticketDetails.cancel')}>
                <IconButton color="warning" onClick={cancelUpdate}>
                  <Iconify icon="eva:close-outline" sx={{ width: 17, height: 17 }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {/* Delete Icon */}
          {!user.default ? (
            <Tooltip title={t('pages.ticketDetails.delete')}>
              <IconButton
                color="error"
                disabled={!hasWritePermission || currentUserId === user.id}
                onClick={() => showConfirmModal()}
              >
                <Iconify icon="eva:trash-2-outline" sx={{ width: 17, height: 17 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Label
              variant="outlined"
              sx={{ fontSize: isSmallScreen ? '0.55rem' : '0.65rem' }}
            >
              {t('pages.ticketDetails.default')}
            </Label>
          )}
        </Stack>
      </Stack>
    </ListItem>
  );
}

export default UserListPermission;
