import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';

import generateUpdateMessage from 'src/utils/generateUpdateMessage';

UpdateMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  time: PropTypes.string,
  statusesList: PropTypes.array,
  user: PropTypes.object,
  departmentUsers: PropTypes.array,
};
export default function UpdateMessage({
  time,
  statusesList,
  user,
  message,
  departmentUsers,
}) {
  const { t } = useTranslation();
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    if (message) {
      const updatesList = generateUpdateMessage({
        message,
        statusesList,
        username: user?.username || '',
        t,
        usersList: departmentUsers,
      });
      setUpdates(updatesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, statusesList, t]);

  return (
    <Stack justifyContent="space-between">
      <Box textAlign="center">
        {!(updates.length === 0) && (
          <Typography color="#4a4a4a" variant="caption" textAlign="center">
            {time}
          </Typography>
        )}
        {updates &&
          updates.map((updateMessage, index) => (
            <div key={index}>
              <Typography variant="caption">{updateMessage}</Typography>
            </div>
          ))}
      </Box>
    </Stack>
  );
}
