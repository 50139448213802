import { Outlet, Navigate } from 'react-router-dom';

import ProfilePage from 'src/pages/profile/ProfilePage';
import TicketDetailsPage from 'src/pages/ticketDetails/TicketDetailsPage';

import Page404 from '../pages/404/Page404';
import SimpleLayout from '../layouts/simple';
import Status from '../pages/statuses/Status';
import FormsPage from '../pages/forms/FormsPage';
import UsersPage from '../pages/users/UsersPage';
import LoginPage from '../pages/Login/loginPage';
// layouts
import DashboardLayout from '../layouts/dashboard';
import NewFormPage from '../pages/forms/NewFormPage';
import SettingsPage from '../pages/settings/Settings';
import TicketsPage from '../pages/tickets/TicketsPage';
import AdminDashboard from '../pages/dashboards/AdminDashboard';
import DepartmentsPage from '../pages/departments/DepartmentsPage';
import CreateTicketPage from '../pages/ticketCreation/CreateTicketPage';
import ShareableFormsPage from '../pages/shareableForms/ShareableFormsPage';
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute';

const AdminRoutes = {
  path: '/',
  element: <Outlet />,
  children: [
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <AdminDashboard /> },
        { path: 'user', element: <UsersPage title="users" /> },
        { path: 'departments', element: <DepartmentsPage /> },
        { path: 'forms', element: <FormsPage /> },
        { path: 'NewForm', element: <NewFormPage /> },
        { path: 'tickets', element: <TicketsPage /> },
        { path: 'newTicket', element: <CreateTicketPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'Status', element: <Status /> },
        { path: 'shareableForms', element: <ShareableFormsPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    { path: 'tickets/:ticketId', element: <TicketDetailsPage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ],
};

export default AdminRoutes;
