import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/comments/ticket`;

// fetch comments

export const fetchCommentsPerTicketId = async (id) => {
  const res = await axios.get(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return res;
};

// fetch messages
export const fetchMessagesByTicketId = async (id) => {
  const res = await axios.get(`${API_URL}/chat/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return res;
};
