import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FormFooter from './FormFooter';
import { boxStyleFormShareaableSubmitted, containerStyleFormSharableSubmitted, TypographyStyleFormSharableSubmitted } from './style';

const FormSharableSubmitted = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isMultipleSubmission, isAnonymous } = state || {};

  const handleSendAnotherResponse = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={boxStyleFormShareaableSubmitted}
    >
      <Container
        maxWidth="sm"
        sx={containerStyleFormSharableSubmitted}
      >
        <img src="/assets/icons/ic_form_submitted.svg" alt="Submission Success" />

        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {t('pages.formSharableSubmitted.title')}
        </Typography>

        {!isAnonymous && (
          <Typography variant="h5" paragraph sx={{ marginBottom: '20px' }}>
            {t('pages.formSharableSubmitted.checkEmail')}
          </Typography>
        )}

        <Typography variant="body1" paragraph sx={{ marginBottom: '20px' }}>
          {t('pages.formSharableSubmitted.message')}
        </Typography>
        {isMultipleSubmission && (
          <Typography
            variant="body2"
            onClick={handleSendAnotherResponse}
            paragraph
            sx={TypographyStyleFormSharableSubmitted}
          >
            {t('pages.formSharableSubmitted.sendAnotherResponse')}
          </Typography>
        )}
        <FormFooter />
      </Container>
    </Box>
  );
};

export default FormSharableSubmitted;
