import format from 'date-fns/format';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Stack } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { getDepartmentById } from 'src/services/department.service';

import { styles } from './styles';
import UserDetails from './subSections/UserDetails';
import UserInfoForm from './subSections/UserInfoForm';
import AvatarSection from './subSections/AvatarSection';
import { convertLocalTime } from '../../utils/convertTime';
import ChangePasswordSection from './subSections/ChangePasswordSection';
import UpdateProfilePictureDialog from './dialog/UpdateProfilePictureDialog';

export default function ProfileView() {
  const { user, updateCurrentUser } = useAuth();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [department, setDepartment] = useState('');
  const [startDate, setStartDate] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (user.departmentId) getUserDepartment();

    if (user.createdAt) {
      setStartDate(
        format(convertLocalTime(new Date(user.createdAt)), 'dd/MM/yyyy HH:mm:ss')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // update user
  const modifyUser = async (newData) => {
    await updateCurrentUser({
      id: newData.id,
      givenName: newData.givenName,
      familyName: newData.familyName,
      username: newData.username,
      email: newData.email,
      departmentId: newData.departmentId,
      appUserRole: newData.appUserRole,
      phoneNumber: newData.phoneNumber,
      avatar: newData.avatar,
    });
  };

  // fetch department
  const getUserDepartment = async () => {
    genericApiToaster(
      getDepartmentById,
      user.departmentId,
      '',
      t('errorMessages.couldntLoadUser'),
      showToast,
      hideToast,
      t,
      (res) => {
        setDepartment(res.name);
      }
    );
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <UpdateProfilePictureDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        modifyUser={modifyUser}
        userData={user}
      />
      <Grid container flexGrow={1} justifyContent="center">
        <Grid
          item
          xs={10.7}
          sm={7.7}
          sx={{ ...styles.infoBox, p: isSmallScreen ? 1 : 2 }}
        >
          <Stack spacing={2}>
            <AvatarSection setDialogOpen={setDialogOpen} />
            <UserInfoForm modifyUser={modifyUser} />
          </Stack>
        </Grid>

        <Grid
          item
          xs={10.7}
          sm={3.6}
          sx={{ ...styles.infoBox, p: isSmallScreen ? 2 : 3 }}
        >
          <Stack spacing={3}>
            <UserDetails department={department} startDate={startDate} />
            <ChangePasswordSection userId={user.id} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
