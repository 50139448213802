import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  ClickAwayListener,
  Box,
  Link,
} from '@mui/material';
import { useConfirmModal } from '../../../hooks/useModal';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterBy: PropTypes.string,
  model: PropTypes.string,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  deleteFunc: PropTypes.func,
  selected: PropTypes.array,
  handleRemoveSelected: PropTypes.func,
};

export default function ListToolbar({
  numSelected,
  filterName,
  filterBy,
  onFilterName,
  model,
  handleOpenFilter,
  handleCloseFilter,
  deleteFunc,
  selected,
  handleRemoveSelected,
}) {
  const { t } = useTranslation();
  const { showConfirmModal } = useConfirmModal(
    t('pages.deletePage.confirmDelete'),
    `${t('pages.deletePage.areYouSure')}`,
    async () => handleDelete()
  );
  const handleDelete = () => {
    selected.forEach((id) => deleteFunc(id));
  };
  const isDisabled = filterBy === 'byStatus' || filterBy === 'byRole' || filterBy === 'byPriority';
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box>
          <Typography component="div" variant="subtitle1">
            {numSelected} {t('filters.selected')}
          </Typography>
          <Link sx={{ cursor: 'pointer' }} variant="caption" onClick={handleRemoveSelected}>
            {t('buttons.removeSelected')}
          </Link>
        </Box>
      ) : (
        <StyledSearch
          name="searchBar"
          id="search-bar"
          value={filterName}
          onChange={onFilterName}
          disabled={isDisabled}
          placeholder={`${t('filters.search')} ${model}...`}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={showConfirmModal}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Stack direction="row" alignItems="center" spacing={2}>
          {filterBy && (
            <Typography>
              {t('filters.search')} {t(`filters.${filterBy}`)}
            </Typography>
          )}

          <ClickAwayListener
            onClickAway={() => {
              if (handleCloseFilter) handleCloseFilter();
            }}
          >
            <div>
              <Tooltip title="Filter list">
                <IconButton
                  onClick={(e) => {
                    if (handleOpenFilter) handleOpenFilter(e);
                  }}
                >
                  <Iconify icon="ic:round-filter-list" />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}
    </StyledRoot>
  );
}
