export const linkBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 2,
  border: '1px solid',
  borderColor: 'grey.300',
  borderRadius: 1,
  p: 1,
  backgroundColor: 'grey.100',
  width: '100%',
  maxWidth: '400px',
};

export const TypographyStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  flexGrow: 1,
};

export const TypographyStyleShowMoreOptions = {
  mt: 2,
  mb: 2,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: 'primary.main',
};

export const clickedTicketStyle = {
  cursor: 'pointer',
  color: '#1976d2',
  textDecoration: 'underline',
};
