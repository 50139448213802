import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Table,
  TableContainer,
  Paper,
  Button,
  Stack,
  Container,
  Card,
  TablePagination,
  Popover,
  MenuItem,
  Popper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import Iconify from '../../components/iconify';
import { useAuth } from '../../hooks/useAuth';
import {
  activateFormLink,
  deleteFormLink,
  fetchAllFormsSharable,
  fetchFormsSharableByGeneratedBy,
} from '../../services/form.link.service';
import { applySortFilter, getComparator } from '../../utils/filterData';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { useModal, useConfirmModal } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast';
import AddShareableForm from '../../components/shareableForm/AddShareableForm';
import TableBodyShareableForm from '../../components/shareableForm/TableBodyShareableForm';
import ShareableFormDetails from '../../components/shareableForm/ShareableFormDetails';

export const LANGS = [
  {
    value: 'it',
    label: 'Italian',
    icon: '/assets/icons/ic_flag_it.webp',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'ar',
    label: 'Arabic',
    icon: '/assets/icons/ic_flag_tn.webp',
  },
];

const TABLE_HEAD_FORMS_SHAREABLE = [
  { id: 'id', name: 'id', label: 'formShareable.id', alignRight: false },
  { id: 'form.title', name: 'form', label: 'formShareable.form', alignRight: false },
  { id: 'language', name: 'language', label: 'formShareable.language', alignRight: false },
  { id: 'isAnonymous', name: 'isAnonymous', label: 'formShareable.isAnonymous', alignRight: false },
  { id: 'isActive', name: 'isActive', label: 'formShareable.status', alignRight: false },
  { id: 'generatedBy.username', name: 'generatedBy', label: 'formShareable.generatedBy', alignRight: false },
  {
    id: 'isMultipleSubmission',
    name: 'isMultipleSubmission',
    label: 'formShareable.isMultipleSubmission',
    alignRight: false,
  },
  { id: 'submissions', name: 'submissions', label: 'formShareable.submissions', alignRight: false },
  { id: 'token', name: 'token', label: 'formShareable.token', alignRight: false },
  { id: 'iframe', name: 'token', label: 'formShareable.iframe', alignRight: false },
  { id: 'generatedOn', name: 'generatedOn', label: 'formShareable.generatedOn', alignRight: false },
  { id: 'expiresOn', name: 'expiresOn', label: 'formShareable.expiresOn', alignRight: false },
  { id: 'actions', label: 'formShareable.actions' },
];

function ShareableFormsPage() {
  const [formsShareable, setFormsShareable] = useState([]);
  const [selectedFormsShareable, setSelectedFormsShareable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageFormsShareable, setCurrentPageFormsShareable] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterBy, setFilterBy] = useState('id');
  const [searchBy, setSearchBy] = useState('byId');
  const [selectedFilter, setSelectedFilter] = useState('id');
  const [filterPopover, setFilterPopover] = useState(null);
  const [subFilterPopover, setSubFilterPopover] = useState({
    status: null,
    isMultiple: null,
    language: null,
    isAnonymous: null,
  });
  const [openShareableFormDetails, setOpenShareableFormDetails] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const { showToast, hideToast } = useToast();
  const { showModal, hideModal } = useModal();
  const { id, role } = useAuth();
  const { t } = useTranslation();
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formsShareable.length) : 0),
    [page, rowsPerPage, formsShareable.length]
  );
  const isSelected = (id) => selectedFormsShareable.indexOf(id) !== -1;

  const handleFetchFormsSharableByGeneratedBy = async () => {
    const data =
      role?.authority === 'ROLE_ADMIN' ? await fetchAllFormsSharable() : await fetchFormsSharableByGeneratedBy(id);
    setFormsShareable(data);
  };

  useEffect(() => {
    handleFetchFormsSharableByGeneratedBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenMenu = (event, row) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedItem(row);
  };

  const handleCloseMenu = () => {
    setPopoverAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = currentPageFormsShareable.map((form) => form.id);
      setSelectedFormsShareable(newSelected);
    } else {
      setSelectedFormsShareable([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedFormsShareable.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFormsShareable, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFormsShareable.slice(1));
    } else if (selectedIndex === selectedFormsShareable.length - 1) {
      newSelected = newSelected.concat(selectedFormsShareable.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFormsShareable.slice(0, selectedIndex),
        selectedFormsShareable.slice(selectedIndex + 1)
      );
    }

    setSelectedFormsShareable(newSelected);
  };

  const filteredFormsShareable = useMemo(() => {
    const filteredData = applySortFilter(formsShareable, getComparator(order, orderBy), filterName, filterBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsNotFound(!filteredData.length && !!filterName);

    return filteredData;
  }, [filterName, order, orderBy, filterBy, formsShareable]);

  useEffect(() => {
    // Update current page forms shareable when page, rowsPerPage, or formsShareable changes
    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    setCurrentPageFormsShareable(filteredFormsShareable.slice(startIdx, endIdx));
  }, [page, rowsPerPage, filteredFormsShareable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 whenever rows per page changes
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Adds error handling and toast on success/error
  const genericApiToaster = (apiCall, args, successMessage, errorMessage, successCallback, errorCallback) => {
    const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

    apiCall(...apiArguments)
      .then((res) => {
        showToast({ message: successMessage, severity: 'success', props: { hideToast } });
        if (successCallback) {
          successCallback(res);
        }
      })
      .catch((e) => {
        showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
        if (errorCallback) {
          errorCallback(e);
        }
      });
  };

  const RemoveFormShareable = async (id) => {
    genericApiToaster(
      deleteFormLink,
      id,
      t('successMessages.deleteShareableFormSuccess'),
      t('errorMessages.deleteShareableFormError'),
      () => {
        handleFetchFormsSharableByGeneratedBy();
        setSelectedFormsShareable([]);
      },
      () => {}
    );
  };

  const { showConfirmModal: showDeleteConfirmModal } = useConfirmModal(
    t('pages.shareableFormsPage.confirmDelete'),
    `${t('pages.shareableFormsPage.areYouSure')} ${selectedItem?.id}`,
    async () => RemoveFormShareable(selectedItem.id)
  );

  const ActiveInactiveFormShareable = async (id, isActive) => {
    genericApiToaster(
      activateFormLink,
      id,
      !isActive
        ? t('successMessages.activateShareableFormSuccess')
        : t('successMessages.deactivateShareableFormSuccess'),
      !isActive ? t('errorMessages.activateShareableFormError') : t('errorMessages.deactivateShareableFormError'),
      () => {
        handleFetchFormsSharableByGeneratedBy();
        setSelectedFormsShareable([]);
      },
      () => {}
    );
  };

  const { showConfirmModal: showToggleActiveConfirmModal } = useConfirmModal(
    selectedItem?.isActive
      ? t('pages.shareableFormsPage.confirmDeactivate')
      : t('pages.shareableFormsPage.confirmActivate'),
    `${
      selectedItem?.isActive
        ? t('pages.shareableFormsPage.areYouSureToDeactivate')
        : t('pages.shareableFormsPage.areYouSureToActivate')
    } ${selectedItem?.id}`,
    async () => ActiveInactiveFormShareable(selectedItem.id, selectedItem.isActive)
  );

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, isMultiple: null, language: null, isAnonymous: null });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleRemoveSelected = () => {
    setSelectedFormsShareable([]);
  };

  const handleOpenSubFilter = (event, category) => {
    if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'isMultipleSubmission') {
      setSubFilterPopover({ isMultiple: event.currentTarget });
    } else if (category === 'language') {
      setSubFilterPopover({ language: event.currentTarget });
    } else if (category === 'isAnonymous') {
      setSubFilterPopover({ isAnonymous: event.currentTarget });
    }
  };

  const handleOpenShareableFormDetails = (row) => {
    setSelectedItem(row);
    setOpenShareableFormDetails(true);
  };

  useEffect(() => {
    const newSelectdsId = currentPageFormsShareable.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedFormsShareable.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormsShareable]);

  return (
    <>
      <Helmet>
        <title> {t('nav.ShareableForms')} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('nav.ShareableForms')}
          </Typography>
          <Button
            variant="contained"
            id="add-user-btn"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() =>
              showModal({
                title: t('pages.shareableFormsPage.addNewShareableForm'),
                Component: AddShareableForm,
                props: {
                  hideModal,
                  handleFetchFormsSharableByGeneratedBy,
                },
              })
            }
            sx={{ marginRight: '1vh' }}
          >
            {t('buttons.addNewShareableForm')}
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selectedFormsShareable.length}
            filterName={filterName}
            filterBy={searchBy}
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            onFilterName={handleFilterByName}
            selected={selectedFormsShareable}
            handleRemoveSelected={handleRemoveSelected}
            deleteFunc={RemoveFormShareable}
            model={t(`nav.ShareableForms`)}
          />

          <ShareableFormDetails
            open={openShareableFormDetails}
            setOpen={setOpenShareableFormDetails}
            selectedRow={selectedItem}
          />

          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD_FORMS_SHAREABLE}
                rowCount={filteredFormsShareable.length}
                numSelected={selectedFormsShareable.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                showCheckBox
                indeterminate={indeterminateCheckbox}
              />

              <TableBodyShareableForm
                currentPageFormsShareable={currentPageFormsShareable}
                isSelected={isSelected}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRows}
                isNotFound={isNotFound}
                filterName={filterName}
                onRowClick={handleOpenShareableFormDetails}
              />
            </Table>
          </TableContainer>

          {/* Table Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredFormsShareable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('filters.rowsPerPage')}
          />
        </Card>

        {/* menu popover */}
        <Popover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenShareableFormDetails(true);
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:plus-circle-outline'} sx={{ mr: 2 }} />
            {t('popover.moreDetails')}
          </MenuItem>

          <MenuItem
            sx={{ color: selectedItem?.isActive ? 'error.dark' : 'success.main' }}
            onClick={() => {
              showToggleActiveConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify icon={selectedItem?.isActive ? 'eva:lock-outline' : 'eva:unlock-outline'} sx={{ mr: 2 }} />
            {selectedItem?.isActive ? t('popover.deactivate') : t('popover.activate')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              showModal({
                title: t('pages.shareableFormsPage.addNewShareableForm'),
                Component: AddShareableForm,
                props: {
                  hideModal,
                  handleFetchFormsSharableByGeneratedBy,
                  row: selectedItem,
                },
              });
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('popover.edit')}
          </MenuItem>

          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              showDeleteConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('popover.delete')}
          </MenuItem>
        </Popover>

        {/* main popper */}
        <Popper
          open={Boolean(filterPopover)}
          anchorEl={filterPopover}
          placement="left-start"
          onClose={handleCloseFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {TABLE_HEAD_FORMS_SHAREABLE.filter(({ name }) => !['token', 'generatedOn', 'expiresOn'].includes(name)) // Exclude unwanted filter names
              .map(({ id, name }) =>
                name === 'isActive' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'status'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
                  >
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2">{t('filters.byStatus')}</Typography>
                  </MenuItem>
                ) : name === 'isMultipleSubmission' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'isMultipleSubmission'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'isMultipleSubmission')}
                  >
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2">{t('filters.byMultipleSubmission')}</Typography>
                  </MenuItem>
                ) : name === 'language' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'language'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'language')}
                  >
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2">{t('filters.byLanguage')}</Typography>
                  </MenuItem>
                ) : name === 'isAnonymous' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'isAnonymous'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'isAnonymous')}
                  >
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2">{t('filters.byIsAnonymous')}</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === name}
                    onMouseEnter={handleCloseSubFilter}
                    onClick={() => {
                      setFilterName('');
                      setFilterBy(
                        name === 'form' ? 'form.title' : name === 'generatedBy' ? 'generatedBy.username' : name
                      );
                      setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                      setSelectedFilter(name);
                      handleCloseFilter();
                      setPage(0);
                    }}
                  >
                    <Typography variant="body2">
                      {name && t(`filters.by${name.charAt(0).toUpperCase() + name.slice(1)}`)}
                    </Typography>
                  </MenuItem>
                )
              )}
          </Card>
        </Popper>

        {/* sub popper */}
        <Popper
          open={Boolean(subFilterPopover.status)}
          anchorEl={subFilterPopover.status}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isActive, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isActive');
                  setSearchBy('byStatus');
                  setSelectedFilter('isActive');
                  setFilterName(isActive);
                  handleCloseFilter();
                  setPage(0);
                }}
              >
                <Typography>{isActive === 'true' ? t('userStatus.active') : t('userStatus.inactive')}</Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* sub popper */}
        <Popper
          open={Boolean(subFilterPopover.isMultiple)}
          anchorEl={subFilterPopover.isMultiple}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isMultipleSubmission, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isMultipleSubmission');
                  setSearchBy('byMultipleSubmission');
                  setSelectedFilter('isMultipleSubmission');
                  setFilterName(isMultipleSubmission);
                  handleCloseFilter();
                  setPage(0);
                }}
              >
                <Typography>
                  {isMultipleSubmission === 'true' ? t('isMultipleSubmission.yes') : t('isMultipleSubmission.no')}
                </Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* language sub popover */}
        <Popper
          open={Boolean(subFilterPopover.language)}
          anchorEl={subFilterPopover.language}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {LANGS.map((lang, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('language');
                  setSearchBy('byLanguage');
                  setSelectedFilter('language');
                  setFilterName(lang.value);
                  handleCloseFilter();
                  setPage(0);
                }}
              >
                <Stack direction="row" spacing={1}>
                  <img src={lang.icon} alt={lang.value} width={28} height={20} />
                </Stack>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* sub popper is anonymous */}
        <Popper
          open={Boolean(subFilterPopover.isAnonymous)}
          anchorEl={subFilterPopover.isAnonymous}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isAnonymous, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isAnonymous');
                  setSearchBy('byIsAnonymous');
                  setSelectedFilter('isAnonymous');
                  setFilterName(isAnonymous);
                  handleCloseFilter();
                  setPage(0);
                }}
              >
                <Typography>
                  {isAnonymous === 'true' ? t('isMultipleSubmission.yes') : t('isMultipleSubmission.no')}
                </Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>
      </Container>
    </>
  );
}

export default ShareableFormsPage;
