import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import convertToBase64 from 'src/utils/convertBase64';
import compressImage from 'src/utils/imageCompressor';

import Iconify from 'src/components/iconify';
import ImageInput from 'src/components/inputs/ImageInput';

import { avatarDialogStyle } from '../styles';

const maxSize = 1024 * 1024 * 2;
const allowedTypes = process.env.REACT_APP_ALLOWED_IMAGE_MIME_TYPES.split(',').map(
  (type) => type.trim()
) || ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];

export default function UpdateProfilePictureDialog({
  dialogOpen,
  handleDialogClose,
  modifyUser,
}) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdateUser = async (compressedDataURL) => {
    const updatedData = {
      ...user,
      avatar: compressedDataURL,
    };

    try {
      await modifyUser(updatedData);
      showToast({
        message: t('successMessages.successProfilePicUpdate'),
        severity: 'success',
        props: { hideToast },
      });
      handleDialogClose();
    } catch (error) {
      showToast({
        message: t('errorMessages.errorChangingProfilePic'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  // update avatar
  const changeAvatar = async () => {
    setLoading(true);
    if (selectedFile) {
      if (selectedFile.type === 'image/gif') {
        const base64Image = await convertToBase64(selectedFile);
        await handleUpdateUser(base64Image);
      } else {
        await compressImage(selectedFile, handleUpdateUser);
      }
    }
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
        setIsChanged(true);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize', { size: maxSize / 1024 / 1024 }),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const handleCancelChange = () => {
    setSelectedFile(null);
    setIsChanged(false);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle id="modal--title" component="h2">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            sx={{
              fontSize: isSmallScreen && '0.85rem',
            }}
          >
            {t('pages.profilePage.modifyProfilePic')}
          </Typography>
          <IconButton onClick={() => handleDialogClose()} sx={{ mr: -1.5 }}>
            <Iconify
              sx={avatarDialogStyle.dialogIcon}
              width={isSmallScreen ? 25 : 30}
              icon="eva:close-square-fill"
            />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <ImageInput
          handleChange={handleFileChange}
          selectedFile={selectedFile}
          existingFile={user?.avatar}
          defaultImage="/assets/images/user/blank-user.svg"
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={avatarDialogStyle.dialogButton}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#d21426',
              color: '#fff',
              fontSize: isSmallScreen && '0.65rem',
            }}
            color="error"
            onClick={handleCancelChange}
            disabled={!isChanged}
            size={isSmallScreen ? 'small' : 'large'}
          >
            {t('buttons.cancelChanges')}
          </LoadingButton>
          <LoadingButton
            fullWidth
            id="confirm-profile-pic"
            variant="contained"
            sx={{
              backgroundColor: 'success.dark',
              color: '#fff',
              ':hover': { backgroundColor: 'success.darker' },
              fontSize: isSmallScreen && '0.65rem',
            }}
            disabled={!isChanged}
            onClick={changeAvatar}
            loading={loading}
            size={isSmallScreen ? 'small' : 'large'}
          >
            {t('buttons.confirm')}
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

UpdateProfilePictureDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  modifyUser: PropTypes.func.isRequired,
};
