import PropTypes from 'prop-types';

import { Avatar, Typography, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
import { noCase } from 'change-case';
import { fToNow } from '../../../../utils/formatTime';
import { convertLocalTime } from '../../../../utils/convertTime';
import Iconify from '../../../../components/iconify';

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    triggerId: PropTypes.number,
    triggeredBy: PropTypes.string,
  }),
  handleNotificationClick: PropTypes.func,
};

export default function NotificationItem({ notification, handleNotificationClick }) {
  const { avatar, title } = renderContent(notification);
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        handleNotificationClick(notification.id, notification.triggerId, notification.triggeredBy, notification.type);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(Date.parse(convertLocalTime(notification.createdAt)))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.subject)}
      </Typography>
    </Typography>
  );

  // svg links
  const typeToAvatar = {
    TICKET: '/assets/icons/ic_notification_ticket.svg',
    INFORMATION: '/assets/icons/ic_notification_information.svg',
    FORM: '/assets/icons/ic_notification_form.svg',
    DEPARTMENT: '/assets/icons/ic_notification_department.svg',
    MAIL: '/assets/icons/ic_notification_mail.svg',
    MESSAGE: '/assets/icons/ic_notification_chat.svg',
  };
  const avatarUrl = typeToAvatar[notification.type] || typeToAvatar.INFORMATION;
  const avatar = avatarUrl ? <img alt={notification.type} src={avatarUrl} /> : null;

  return {
    avatar,
    title,
  };
}
