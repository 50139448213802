import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Popper, MenuItem, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { TABLE_HEAD_CLIENT_CREDENTIALS } from '../table/ClientCredentialsTableHeaders';

function MainFilterPopper({
  filterPopover,
  setFilterName,
  setFilterBy,
  setSearchBy,
  selectedFilter,
  setSelectedFilter,
  handleCloseFilter,
  handleCloseSubFilter,
  setSubFilterPopover,
  setPage,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const handleOpenSubFilter = (event, category) => {
    if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    }
  };
  return (
    <Popper
      open={Boolean(filterPopover)}
      anchorEl={filterPopover}
      placement="left-start"
      onClose={handleCloseFilter}
    >
      <Card
        sx={{
          p: 1,
          minwidth: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            boxShadow: '0.5px 0.5px 2 px',
          },
        }}
      >
        {TABLE_HEAD_CLIENT_CREDENTIALS.filter(
          ({ name }) =>
            !['clientId', 'clientSecret', 'createdAt', 'updatedAt', 'actions'].includes(
              name
            )
        ) // Exclude unwanted filter names
          .map(({ id, name }) =>
            name === 'enabled' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'enabled'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
                onTouchStart={(e) => handleOpenSubFilter(e, 'status')}
                onClick={() => handleCloseSubFilter()}
              >
                <Iconify
                  icon="eva:arrow-ios-back-outline"
                  sx={{
                    mr: 1,
                    width: isSmallScreen ? 16 : 20,
                    height: isSmallScreen ? 16 : 20,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {t('filters.byStatus')}
                </Typography>
              </MenuItem>
            ) : (
              <MenuItem
                key={id}
                selected={selectedFilter === name}
                onMouseEnter={handleCloseSubFilter}
                onClick={() => {
                  setFilterName('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterName('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {name && t(`filters.by${name.charAt(0).toUpperCase() + name.slice(1)}`)}
                </Typography>
              </MenuItem>
            )
          )}
      </Card>
    </Popper>
  );
}

MainFilterPopper.propTypes = {
  filterPopover: PropTypes.object,
  setFilterName: PropTypes.func,
  setFilterBy: PropTypes.func,
  setSearchBy: PropTypes.func,
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  handleCloseSubFilter: PropTypes.func,
  setSubFilterPopover: PropTypes.func,
  setPage: PropTypes.func,
};

export default MainFilterPopper;
