export const genericApiToaster = async (
  apiCall,
  args,
  successMessage,
  errorMessage,
  showToast,
  hideToast,
  t,
  successCallback,
  errorCallback,
  finallyCallback
) => {
  const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array
  // eslint-disable-next-line react-hooks/rules-of-hooks

  try {
    const res = await apiCall(...apiArguments);
    if (successMessage !== '') {
      showToast({ message: successMessage, severity: 'success', props: { hideToast } });
    }
    if (successCallback) {
      successCallback(res);
    }
  } catch (e) {
    if (errorMessage !== '') {
      showToast({
        message:
          e?.response?.status === 401 ? t('errorMessages.unauthorized') : errorMessage,
        severity: 'error',
        props: { hideToast },
      });
    }
    if (errorCallback) {
      errorCallback(e);
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};
