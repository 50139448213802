import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  Select,
  Button,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';

import { useConfirmModal } from '../../../hooks/useModal';

AssignTicketToSelfModal.propTypes = {
  assignTicket: PropTypes.func,
  statusesList: PropTypes.array,
  currentUserId: PropTypes.number,
};

export default function AssignTicketToSelfModal({
  assignTicket,
  statusesList,
  currentUserId,
}) {
  const [selectedStatusId, setSelectedStatusId] = useState(2);
  const { t } = useTranslation();
  const { showConfirmModal } = useConfirmModal(
    t('pages.assignTicket.confirmAssignment'),
    `${t('pages.assignTicket.areYouSureSelf')} `,
    async () => {
      assignTicket(currentUserId, selectedStatusId);
    }
  );

  const handleStatusChange = (event) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <Stack px={2} spacing={2} my={2}>
      <Typography variant="subtitle1">
        {t('pages.assignTicket.assignTicketToSelf')}:
      </Typography>

      <FormControl>
        <InputLabel id="ticket-status-select-label">
          {t('pages.assignTicket.statusAfterAssign')}
        </InputLabel>
        <Select
          labelId="ticket-status-select-label"
          id="status-select"
          name="status_id"
          label={t('pages.assignTicket.statusAfterAssign')}
          defaultValue={selectedStatusId}
          onChange={handleStatusChange}
        >
          {statusesList?.length > 0 ? (
            statusesList.map((status) => {
              const { statusId, name } = status;
              let tName = name;
              if (statusId <= 5) tName = t(`status.${name}`);
              return (
                <MenuItem key={statusId} value={statusId}>
                  {tName}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem disabled value="">
              {t('infoMessages.noOptions')}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Button variant="outlined" onClick={showConfirmModal}>
        {t('pages.assignTicket.assignTicket')}
      </Button>
    </Stack>
  );
}
