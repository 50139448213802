import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Alert, Button, Container, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { formatTimeStamp } from 'src/utils/formatTime';

import Iconify from '../iconify/Iconify';
import { useToast } from '../../hooks/useToast';

FileInput.propTypes = {
  setFilesList: PropTypes.func,
  setFormData: PropTypes.func,
  disabled: PropTypes.bool,
  removeAttachements: PropTypes.func,
};

const maxSize = 1024 * 1024 * process.env.REACT_APP_MAX_FILE_SIZE_IN_MB || 100;
const allowedTypes = process.env.REACT_APP_ALLOWED_FILE_MIME_TYPES.split(',').map(
  (type) => type.trim()
) || [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'application/pdf', // PDF files
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx files
  'application/vnd.ms-excel', // xls files
  'text/csv', // CSV files
  'text/plain', // Text files
  'application/msword', // Word files (doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word files (docx)
  'application/vnd.ms-powerpoint', // PowerPoint files (ppt)
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint files (pptx)
  'application/json',
  'application/gzip',
  'application/x-7z-compressed',
  'application/zip',
  'application/x-rar-compressed',
  'application/vnd.rar',
];

export default function FileInput({
  setFilesList,
  setFormData,
  disabled,
  removeAttachements,
}) {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [valid, setValid] = useState(true);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const { showToast, hideToast } = useToast();
  let attachementData = new FormData();
  const [attachementId, setAttachementId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const onDrop = useCallback((acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter(
      (file) =>
        (allowedTypes.includes(file.type) || file.path.split('.').pop() === 'cnv') &&
        file.size <= maxSize
    );
    const unsupportedFiles = acceptedFiles.filter(
      (file) =>
        !(allowedTypes.includes(file.type) || file.path.split('.').pop() === 'cnv') &&
        file.size <= maxSize
    );
    // Handle the dropped files here (e.g., upload to a server, display them, etc.)
    if (!unsupportedFiles || unsupportedFiles.length === 0) {
      setValid(true);
      setFiles(filteredFiles);
      setIsUploading(false);

      const filePreview = filteredFiles.map((file) => {
        let url = '';
        if (file.type.startsWith('image/')) {
          url = URL.createObjectURL(file);
        } else {
          url = '/assets/images/files/fileDefault.png';
        }
        return (
          <Box
            key={file.name}
            p={2}
            border={1}
            borderColor="grey"
            borderRadius={3}
            width={200}
          >
            <Stack spacing={2}>
              <img
                src={url}
                alt={file.name}
                style={{
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  maxHeight: 100,
                  maxWidth: 100,
                }}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                variant="caption"
              >
                {file.name}
              </Typography>
            </Stack>
          </Box>
        );
      });
      setFilePreviews(filePreview);
    } else {
      const unsupportedNames = unsupportedFiles?.map((item) => item.name);
      setInvalidFiles(unsupportedNames);
      setValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFiles = async () => {
    const fileNames = [];
    files.forEach((file) => {
      const id = uuidv4();
      setAttachementId(id);
      const timeStamp = formatTimeStamp(Date.now());
      const newFileName = `${file.name.replace(/\.[^.]+$/, '')}_${timeStamp}.${
        file.name.match(/\.([^.]+)$/)[1]
      }`;
      const updatedFile = new File([file], newFileName, { type: file.type });
      attachementData.append(id, updatedFile);
      fileNames.push(newFileName);
    });
    setFormData(attachementData);
    setFilesList(fileNames);
    showToast({
      message: t('successMessages.fileAddedSuccessfully'),
      severity: 'success',
      props: { hideToast },
    });
    setIsUploading(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disabled || isUploading,
    multiple: false,
  });

  return (
    <>
      {!valid && (
        <div title={JSON.stringify(invalidFiles)}>
          <Alert severity="error">
            {t('inputs.file.cannotRead')}
            <br /> {invalidFiles[0]}, {invalidFiles[1]}... <br />
            {t('inputs.file.verifyExtensionAndSize', { size: maxSize / 1024 / 1024 })}
          </Alert>
        </div>
      )}
      <Container
        sx={{
          cursor: disabled || isUploading ? 'default' : 'pointer',
          overflow: 'auto',
          p: 3,
          mt: 3,
          height: isSmallScreen ? 200 : 300,
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 3,
          border: 1.5,
          borderColor: disabled || isUploading ? 'grey.400' : 'grey',
          mb: 3,
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
        {...getRootProps()}
        {...((disabled || isUploading) && {
          onDrop: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })}
      >
        <input {...getInputProps()} />

        {filePreviews.length > 0 ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen && '0.675rem',
              }}
            >
              {t('inputs.file.filesPreview')}:
            </Typography>
            <Stack maxWidth={isSmallScreen ? 140 : 200} direction="row" spacing={1.5}>
              {filePreviews}
            </Stack>
          </Stack>
        ) : (
          <Stack
            alignItems="center"
            sx={{
              color: disabled || isUploading ? 'text.disabled' : 'inherit',
            }}
            spacing={2}
            justifyContent="center"
          >
            <Iconify
              icon="material-symbols:upload"
              height={isSmallScreen ? 40 : 60}
              width={isSmallScreen ? 40 : 60}
              style={{
                color: disabled || isUploading ? 'grey' : 'inherit',
              }}
            />
            <Typography variant="h6" sx={{ fontSize: isSmallScreen && '0.675rem' }}>
              {t('inputs.file.dragHere')}
            </Typography>
            <Button
              variant="outlined"
              size={isSmallScreen ? 'small' : 'medium'}
              disabled={disabled || isUploading}
              sx={{ fontSize: isSmallScreen && '0.675rem' }}
            >
              {t('buttons.chooseFile')}
            </Button>
          </Stack>
        )}
      </Container>
      {filePreviews.length > 0 && (
        <Stack justifyContent="center" direction="row" spacing={2}>
          <Button
            variant="outlined"
            size={isSmallScreen ? 'small' : 'medium'}
            onClick={uploadFiles}
            disabled={isUploading}
            sx={{ fontSize: isSmallScreen && '0.675rem' }}
          >
            {t('buttons.upload')}
          </Button>
          <Button
            variant="outlined"
            size={isSmallScreen ? 'small' : 'medium'}
            onClick={() => {
              removeAttachements(attachementId);
              setFiles([]);
              setFilesList(null);
              attachementData = new FormData();
              setFilePreviews([]);
              setIsUploading(false);
            }}
            sx={{ fontSize: isSmallScreen && '0.675rem' }}
          >
            {t('buttons.reset')}
          </Button>
        </Stack>
      )}
    </>
  );
}
