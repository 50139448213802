export const ALL_TICKET_TABLE_HEAD = [
  { id: 'id', name: 'id', label: 'ticket.id', alignRight: false },
  { id: 'title', name: 'title', label: 'ticket.title', alignRight: false },
  { id: 'department', name: 'department', label: 'ticket.department', alignRight: false },
  { id: 'priority', name: 'priority', label: 'ticket.priority', alignRight: false },
  { id: 'status', name: 'status', label: 'ticket.status', alignRight: false },
  { id: 'category', name: 'category', label: 'ticket.category', alignRight: false },
  { id: 'createdBy', name: 'customerName', label: 'ticket.createdBy', alignRight: false },
  { id: 'createdAt', name: 'createdAt', label: 'ticket.createdAt', alignRight: false },
  { id: 'updatedAt', name: 'updatedAt', label: 'ticket.updatedAt', alignCenter: true },
  { id: 'agentName', name: 'agentName', label: 'ticket.agent', alignRight: false },
  { id: 'formName', name: 'formName', label: 'ticket.formName', alignRight: false },
  { id: 'options', label: 'ticket.options', sticky: true },
];
