import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import authService from '../../services/auth.service';

export const ProtectedRoute = ({ children }) => {
  const auth = authService.authVerify();
  if (!auth) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
