export const styles = {
  dialogIcon: {
    height: 40,
    width: 40,
    color: 'grey',
    '&:hover': {
      color: '#B72136',
    },
  },
  dialogButton: {
    my: 2,
  },
  avatar: {
    height: '75px',
    width: '75px',
  },
  infoBox: {
    bgcolor: '#F1F1F1',
    borderRadius: 2,
    p: 2,
    m: 1,
    '@media (max-width: 600px)': {
      mt: 2,
    },
  },
  iconBox: {
    px: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  formCard: {
    p: 3,
  },
  userInfoCard: {
    p: 3,
  },
  styleButton: {
    backgroundColor: '#2065D1',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2065B3',
      color: ' #ffffff',
    },
  },
};

export const avatarDialogStyle = {
  dialogIcon: {
    color: 'text.secondary',
    '&:hover': {
      color: '#B72136',
    },
  },
  dialogButton: {
    mt: 2,
  },
};
