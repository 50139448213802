import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Backdrop, Container, Typography } from '@mui/material';

import { useToast } from 'src/hooks/useToast';

import Loader from 'src/components/loaders/Loader';
import DashboardToolbar from 'src/components/ToolBars/DashboardToolbar';

import { useAuth } from '../../hooks/useAuth';
import { transformData } from '../../utils/filterData';
import { fetchStatuses } from '../../services/status.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { fetchDepartments } from '../../services/department.service';
import { fetchUsers, getUserCountByRole } from '../../services/user.service';
import { FloatingButton } from '../../components/floatingButton/FloatingButton';
import DepartmentSelect from '../../components/departmentSelect/DepartmentSelect';
import {
  PieChart,
  BarChart,
  LineChart,
  AppNewsUpdate,
  AppWidgetSummary,
  AppCurrentSubject,
} from '../../components/charts';
// ----------------------------------------------------------------------

export default function AdminDashboard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState({ customers: 0, agents: 0 });
  const [departmentList, setDepartmentList] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [ticketCountByDeps, setTicketCountByDeps] = useState([]);
  const [ticketCountByStat, setTicketCountByStat] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { showToast, hideToast } = useToast();
  const navigate = useNavigate();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => setStatusesList(res));
    } else {
      fetchStatuses().then((res) =>
        setStatusesList(
          res.filter(
            (status) => status.departmentId === depId || status.departmentId === 0
          )
        )
      );
    }
  };
  const getUserCount = async () => {
    const userInfo = {};
    userInfo.customers = await getUserCountByRole('ROLE_CUSTOMER');
    userInfo.agents = await getUserCountByRole('ROLE_AGENT');
  };

  //  ### API Calls ###

  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const startingDate = new Date(startDate).setHours(0, 0, 0);
    const endingDate = new Date(endDate).setHours(23, 59, 0);

    const ticketsByDate = await fetchTicketsByDate({
      startDate: new Date(startingDate),
      endDate: new Date(endingDate),
      attributeName,
    });
    const updatedTickets = ticketsByDate.map((ticket) => {
      const user = users.find((user) => user.id === ticket.createdBy);
      const statusObject = statusesList.find(
        (status) => status.statusId === ticket.status_id
      );

      return {
        ...ticket,
        statusObject,
        avatar: user?.avatar || '',
      };
    });

    const filteredTickets =
      selectedDepartmentId === 0
        ? updatedTickets
        : updatedTickets.filter((ticket) => ticket.departmentId === selectedDepartmentId);
    setTickets(filteredTickets);
    setIsLoading(false);
  };

  const getDepartMents = async () => {
    try {
      const departments = await fetchDepartments();
      setDepartmentList(departments);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDep'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const getUsers = async () => {
    try {
      const allUsers = await fetchUsers();
      setUsers(allUsers);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const activeUsersCount = async () => {
    if (!users) return;

    const userInfo = {
      customers:
        users.filter((user) => user.appUserRole === 'ROLE_CUSTOMER' && user.status)
          .length || 0,
      agents:
        users.filter(
          (user) =>
            user.appUserRole === 'ROLE_AGENT' &&
            user.status &&
            (selectedDepartmentId === 0 || user.departmentId === selectedDepartmentId) &&
            !user.archived
        ).length || 0,
    };

    setActiveUsers(userInfo);
  };

  useEffect(() => {
    activeUsersCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedDepartmentId]);

  const {
    ticketCount,
    ticketsByDep,
    ResolvedTicketCategories,
    ticketsByAgent,
    ticketsWithAvatar,
  } = useMemo(() => {
    const countByStatus = (status) =>
      tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === status)
        .length || 0;

    const ticketCount = {
      open: countByStatus('OPEN'),
      onhold: countByStatus('ONHOLD'),
      resolved: countByStatus('RESOLVED'),
      closed: countByStatus('CLOSED'),
      unassigned: countByStatus('UNASSIGNED'),
    };

    const departmentAccumulator = tickets.reduce((acc, ticket) => {
      const { department, departmentId: deptId } = ticket;
      acc[department] = acc[department] || { department, deptId, tickets: [] };
      acc[department].tickets.push(ticket);
      return acc;
    }, {});

    const ticketsByDep = Object.values(departmentAccumulator);

    const ticketcountByDep = ticketsByDep.map(({ department, tickets }) => ({
      label: department,
      value: tickets.length,
    }));

    const ticketcountByStatus = ticketsByDep.map(({ department, tickets }) => ({
      name: department,
      data: ['OPEN', 'RESOLVED', 'UNASSIGNED', 'CLOSED', 'ONHOLD'].map(
        (status) =>
          tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === status)
            .length || 0
      ),
    }));

    setTicketCountByDeps(ticketcountByDep);
    setTicketCountByStat(ticketcountByStatus);

    const ResolvedTicketCategories = tickets.reduce((acc, ticket) => {
      if (
        ticket.statusObject?.systemMappedStatus === 'RESOLVED' &&
        ticket.acceptedAt &&
        ticket.resolvedAt &&
        ticket.category
      ) {
        const { category } = ticket;
        acc[category] = acc[category] || [];
        acc[category].push({
          ...ticket,
          resolutionTime:
            Math.abs(new Date(ticket.resolvedAt) - new Date(ticket.acceptedAt)) / 36e5,
        });
      }
      return acc;
    }, {});

    const ticketsByAgent = Object.values(
      tickets.reduce((acc, ticket) => {
        const { agentName: agent, assignedTo: agentId } = ticket;
        if (agent !== 'none') {
          acc[agentId] = acc[agentId] || { agent, agentId, tickets: [] };
          acc[agentId].tickets.push(ticket);
        }
        return acc;
      }, {})
    );
    const ticketsWithAvatar = tickets
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 5)
      .map((ticket) => ({
        id: ticket.id,
        title: ticket.title,
        description: ticket.description,
        postedAt: ticket.createdAt,
        avatar: ticket.avatar,
        createdBy: ticket.customerName,
        department: ticket.department,
      }));

    return {
      ticketCount,
      ticketsByDep,
      ResolvedTicketCategories,
      ticketsByAgent,
      ticketsWithAvatar,
    };
  }, [tickets]);

  const averageResolutionTimes = useMemo(() => {
    const averageResolutions = {};

    Object.keys(ResolvedTicketCategories).forEach((category) => {
      const ticketsInCategory = ResolvedTicketCategories[category];
      const totalResolutionTime = ticketsInCategory.reduce(
        (sum, ticket) => sum + ticket.resolutionTime,
        0
      );

      averageResolutions[category] = totalResolutionTime / ticketsInCategory.length;
    });

    return averageResolutions;
  }, [ResolvedTicketCategories]);

  const ticketStatusByDep = useMemo(() => transformData(ticketsByDep), [ticketsByDep]);
  useEffect(() => {
    getUserCount();
    getUsers();
    getDepartMents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStatuses(selectedDepartmentId);
  }, [selectedDepartmentId]);

  useEffect(() => {
    setIsLoading(true);
    if (statusesList.length > 0 && users.length > 0)
      getTicketsByDate({
        startDate: start,
        endDate: end,
        attributeName: 'created',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList, users]);

  useEffect(() => {
    const opt = [{ id: 0, name: 'All Departments' }];
    departmentList.forEach((department) => {
      opt.push({ id: department.id, name: department.name });
    });
    setOptions(opt);
  }, [departmentList]);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>
      <Container maxWidth="xl">
        <Backdrop
          sx={{
            bgcolor: 'rgb(121 121 121 / 32%)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Loader size={175} hideText />
          </Box>
        </Backdrop>
        <FloatingButton />
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {user.username || ''}!
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item xs={10}>
            <DashboardToolbar
              getTicketsByDate={getTicketsByDate}
              start={start}
              end={end}
              setIsLoading={setIsLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <DepartmentSelect
              options={options}
              setSelectedDepId={setSelectedDepartmentId}
            />
          </Grid>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} height="100%">
              <Grid item xs={12} md={6} lg={6} height="100%">
                <AppNewsUpdate
                  isLoading={isLoading}
                  title={t('dashboard.latestUpdates')}
                  onViewAllClick={() => navigate('/dashboard/tickets')}
                  list={ticketsWithAvatar}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.activeUsers')}
                      total={activeUsers.customers}
                      icon="raphael:customer"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.activeAgents')}
                      total={activeUsers.agents}
                      color="info"
                      icon="mdi:face-agent"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.closureRate')}
                      total={(ticketCount.resolved / tickets.length) * 100 || 0}
                      color="warning"
                      icon="material-symbols:percent"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.openingRate')}
                      total={
                        ((ticketCount.unassigned +
                          ticketCount.onhold +
                          ticketCount.open) /
                          tickets.length) *
                          100 || 0
                      }
                      color="error"
                      icon="material-symbols:percent"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {selectedDepartmentId === 0 && (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <LineChart
                  title={t('dashboard.ticketStatusByDep')}
                  subheader=""
                  chartLabels={[
                    t('status.UNASSIGNED'),
                    t('status.RESOLVED'),
                    t('status.CLOSED'),
                    t('status.OPEN'),
                    t('status.ONHOLD'),
                  ]}
                  chartData={departmentList.map((dep, index) => ({
                    name: dep.name,
                    type: 'line',
                    fill: 'solid',
                    data: ticketStatusByDep[index] ?? [0, 0, 0, 0],
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PieChart
                  title={t('dashboard.totalTicketsByDep')}
                  chartData={ticketCountByDeps ?? []}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.error.main,
                    theme.palette.warning.main,
                    theme.palette.success.main,
                  ]}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <PieChart
              title={t('dashboard.ticketByStatus')}
              chartData={[
                { label: t('status.UNASSIGNED'), value: ticketCount.unassigned ?? 0 },
                { label: t('status.RESOLVED'), value: ticketCount.resolved ?? 0 },
                { label: t('status.CLOSED'), value: ticketCount.closed ?? 0 },
                { label: t('status.OPEN'), value: ticketCount.open ?? 0 },
                { label: t('status.ONHOLD'), value: ticketCount.onhold ?? 0 },
              ]}
              chartColors={['#d32f2f', '#1976d2', '#9c27b0', '#4caf50', '#ed6c02']}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <BarChart
              title={t('dashboard.bestPerAgents')}
              subheader=""
              chartData={ticketsByAgent
                .filter((agent) =>
                  agent.tickets.some(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  )
                )
                .sort(
                  (a, b) =>
                    b.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length -
                    a.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length
                )
                .map((agent) => ({
                  label: agent.agent,
                  value: agent.tickets.filter(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  ).length,
                }))}
            />
          </Grid>

          {selectedDepartmentId === 0 ? (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentSubject
                title={t('dashboard.statByDep')}
                chartLabels={[
                  t('status.OPEN'),
                  t('status.RESOLVED'),
                  t('status.UNASSIGNED'),
                  t('status.CLOSED'),
                  t('status.ONHOLD'),
                ]}
                chartData={ticketCountByStat || []}
                chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
              />
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            md={selectedDepartmentId === 0 ? 6 : 12}
            lg={selectedDepartmentId === 0 ? 8 : 12}
          >
            <BarChart
              title={t('dashboard.averageResolutionTime')}
              subheader={t('pages.formPage.inHours')}
              chartData={Object.keys(averageResolutionTimes).map((key) => ({
                label: key,
                value: averageResolutionTimes[key],
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
