import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Avatar, Typography } from '@mui/material';

import convertLineReturnToHTML from 'src/utils/convertLineReturn';

import { avatarMessageStyle } from '../../styles';

TextMessage.propTypes = {
  time: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMine: PropTypes.bool,
  user: PropTypes.object,
};
export default function TextMessage({ time, message, isMine, user }) {
  const { t } = useTranslation();
  return (
    <Stack justifyContent="space-between">
      <Typography color="#4a4a4a" variant="caption" textAlign="center">
        {time}
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {!isMine && (
          <Avatar src={user.avatar ?? ''} alt="avatarUrl" sx={avatarMessageStyle} />
        )}

        <Stack sx={{ width: '80%' }} spacing={1}>
          <Typography
            variant="body1"
            color="#4a4a4a"
            sx={{ textAlign: isMine ? 'right' : 'left' }}
          >
            {user.username || t('pages.ticketDetails.anonymous')}
          </Typography>
          <Card
            sx={{
              bgcolor: isMine ? '#237874' : 'white',
              alignSelf: isMine ? 'flex-end' : 'flex-start',
              width: '70%',
              wordWrap: 'break-word',
              p: 2,
              borderRadius: 3,
            }}
          >
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: isMine ? 'white' : '#4a4a4a' }}
            >
              {typeof message === 'string' ? convertLineReturnToHTML(message) : message}
            </Typography>
          </Card>
        </Stack>
        {isMine && (
          <Avatar
            src={user.avatar ?? ''}
            alt="avatarUrl"
            sx={{ width: 30, height: 30 }}
          />
        )}
      </Stack>
    </Stack>
  );
}
