import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import React, { useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Grid, Paper, TextField, Typography, Autocomplete } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import convertToBase64 from 'src/utils/convertBase64';
import compressImage from 'src/utils/imageCompressor';

import { fetchUsers } from 'src/services/user.service';
import { genericApiToaster } from 'src/services/utils';

import ImageInput from 'src/components/inputs/ImageInput';

const maxSize = 1024 * 1024 * 2;
const allowedTypes = process.env.REACT_APP_ALLOWED_IMAGE_MIME_TYPES.split(',').map(
  (type) => type.trim()
) || ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];

function AddUpdateGroupUsers({ hideModal, addGroupUsers, updateGroupUsers, groupUsers }) {
  const { isSmallScreen } = useResponsiveScreen();
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const [users, setUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: groupUsers?.id || '',
      name: groupUsers?.name || '',
      users: groupUsers?.users || [],
      image: groupUsers?.image || '',
      createdBy: groupUsers?.createdBy || '',
    },
  });

  const watchedValues = watch();

  const isFormDirty = useMemo(
    () =>
      watchedValues.name !== groupUsers?.name ||
      watchedValues.users !== groupUsers?.users ||
      selectedFile !== null,
    [watchedValues, groupUsers, selectedFile]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize', { size: maxSize / 1024 / 1024 }),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const handleReset = () => {
    const defaultValues = {
      id: groupUsers?.id || '',
      name: groupUsers?.name || '',
      users: groupUsers?.users || [],
      image: groupUsers?.image || '',
      createdBy: groupUsers?.createdBy || '',
    };
    reset(defaultValues);
    setSelectedFile(null);
  };

  const getUsers = async () => {
    genericApiToaster(
      fetchUsers,
      [],
      '',
      t('errorMessages.errorFetchUsers'),
      showToast,
      hideToast,
      t,
      (res) => {
        setUsers(res.filter((user) => !user.archived));
        setAvailableUsers(res.filter((user) => !user.archived));
      }
    );
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUsersChange = (_event, data, field) => {
    field.onChange(data);
    const selectedUserIds = data.map((user) => user.id);
    setAvailableUsers(users.filter((user) => !selectedUserIds.includes(user.id)));
  };

  const onSubmit = async (data) => {
    const callBackFunc = (convertedFile) => {
      const fullData = {
        ...data,
        image: convertedFile || groupUsers?.image || '',
      };

      if (groupUsers) {
        updateGroupUsers(fullData);
      } else {
        addGroupUsers(fullData);
      }
      hideModal();
      reset();
      setAvailableUsers(users);
    };

    if (selectedFile) {
      if (selectedFile.type === 'image/gif') {
        convertToBase64(selectedFile).then(callBackFunc);
      } else {
        compressImage(selectedFile, callBackFunc);
      }
    } else {
      callBackFunc(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack my={isSmallScreen ? 1 : 2} spacing={isSmallScreen ? 2 : 3}>
        <TextField
          name="name"
          label={t('attributes.name')}
          variant="outlined"
          fullWidth
          error={!!errors.name}
          helperText={errors.name?.message || ''}
          {...register('name', {
            required: t('formControl.enterName'),
            minLength: {
              value: 4,
              message: t('formControl.minName'),
            },
            maxLength: {
              value: 50,
              message: t('formControl.maxName'),
            },
          })}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
            '& .MuiFormLabel-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
            '& .MuiFormHelperText-root': {
              fontSize: isSmallScreen ? '0.6rem' : '0.875rem',
            },
          }}
        />
        <Controller
          name="users"
          control={control}
          rules={{
            required: t('formControl.selectUsers'),
            validate: (value) => value.length >= 2 || t('formControl.minUsers'),
          }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={availableUsers}
              getOptionLabel={(option) => option.email}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    '& .MuiAutocomplete-listbox': {
                      maxHeight: '200px',
                      overflowY: 'auto',
                      fontSize: isSmallScreen ? '0.6rem' : '1rem',
                    },
                  }}
                />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('attributes.users')}
                  variant="outlined"
                  fullWidth
                  error={!!errors.users}
                  helperText={errors.users?.message || ''}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.7rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.7rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.6rem' : '0.875rem',
                    },
                  }}
                />
              )}
              onChange={(event, data) => handleUsersChange(event, data, field)}
            />
          )}
        />

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              fontSize: isSmallScreen && '0.8rem',
              mt: 2,
            }}
          >
            {t('pages.groupUsersPage.selectGroupImage')} :
          </Typography>
          <ImageInput
            handleChange={handleFileChange}
            selectedFile={selectedFile}
            existingFile={groupUsers?.image || undefined}
            defaultImage="/assets/images/group-users/group-users.jpg"
          />
        </Grid>

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {groupUsers && (
            <LoadingButton
              sx={{
                backgroundColor: '#d21426',
                color: '#fff',
                fontSize: isSmallScreen ? '0.65rem' : '1rem',
              }}
              fullWidth
              size={isSmallScreen ? 'small' : 'medium'}
              variant="contained"
              color="error"
              disabled={!isFormDirty}
              onClick={handleReset}
            >
              {t('buttons.cancelChanges')}
            </LoadingButton>
          )}
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!isValid}
            sx={{ fontSize: isSmallScreen ? '0.7rem' : '1rem' }}
          >
            {groupUsers ? t('buttons.updateGroupUsers') : t('buttons.addGroupUsers')}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}

AddUpdateGroupUsers.propTypes = {
  hideModal: PropTypes.func,
  addGroupUsers: PropTypes.func,
  updateGroupUsers: PropTypes.func,
  groupUsers: PropTypes.object,
};

export default AddUpdateGroupUsers;
