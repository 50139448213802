import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, Select, Button, MenuItem, InputLabel, FormControl } from '@mui/material';

import { useAuth } from '../../../../hooks/useAuth';
import { useToast } from '../../../../hooks/useToast';
import { createFavorite } from '../../../../services/favorite.service';

function AddFavoriteModal({ onClose, departmentsList, forms, loadFavorites }) {
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [formsList, setFormsList] = useState([]);

  const { t } = useTranslation();
  const { id: currentUserId } = useAuth();
  const { showToast, hideToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues: {
      type: 'form',
      typeId: 0,
      userId: currentUserId,
    },
  });

  const selectedType = watch('type');

  const handleChangeDepartment = (e) => {
    setSelectedDepartment(e.target.value);
    setFormsList(forms.filter((ticket) => ticket.departmentId === e.target.value));
  };

  const onSubmit = async (data) => {
    if (data.type === 'form') {
      try {
        await createFavorite(data);
        loadFavorites();
        showToast({
          message: t('successMessages.successfavoriteAdd'),
          severity: 'success',
          props: { hideToast },
        });
      } catch (error) {
        showToast({
          message:
            error.response?.data === 'Favorite exists already'
              ? t('errorMessages.alreadyFavorite')
              : t('errorMessages.cannotAddFavorite'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack py={2} spacing={2} width="100%">
        <FormControl fullWidth>
          <InputLabel id="type-select-label">{t('favorites.selectType')}</InputLabel>
          <Select
            labelId="type-select-label"
            label={t('favorites.selectType')}
            defaultValue="form"
            value={watch('type') || ''}
            disabled
            {...register('type', {
              required: t('formControl.typeRequired'),
            })}
          >
            {['form', 'ticket', 'user'].map((type, index) => (
              <MenuItem key={index} value={type}>
                {t(`favorites.${type}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedType === 'form' && (
          <FormControl fullWidth>
            <InputLabel id="department-select-label">
              {t('favorites.selectDepartment')}
            </InputLabel>
            <Select
              labelId="department-select-label"
              onChange={handleChangeDepartment}
              value={selectedDepartment || ''}
              label={t('favorites.selectDepartment')}
            >
              {departmentsList.length > 0 ? (
                departmentsList.map((department) => {
                  const { id, name } = department;
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  {t('infoMessages.noOptions')}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {selectedDepartment && (
          <FormControl fullWidth>
            <InputLabel id="form-select-label">{t('favorites.selectForm')}</InputLabel>
            <Select
              labelId="form-select-label"
              label={t('favorites.selectForm')}
              value={watch('typeId') || ''}
              {...register('typeId', { required: t('formControl.formRequired') })}
            >
              {formsList.length > 0 ? (
                formsList.map((form) => {
                  const { id, title } = form;
                  return (
                    <MenuItem key={id} value={id}>
                      {title}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  {t('infoMessages.noOptions')}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <Button variant="contained" color="primary" disabled={!isValid} type="submit">
          {t('favorites.addNewFavorite', { type: t(`favorites.${selectedType}`) })}
        </Button>
      </Stack>
    </form>
  );
}

AddFavoriteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  departmentsList: PropTypes.array,
  forms: PropTypes.array,
  loadFavorites: PropTypes.func,
};

export default AddFavoriteModal;
