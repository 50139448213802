import PropTypes from 'prop-types';

import UsersView from 'src/sections/users';

UsersPage.propTypes = {
  title: PropTypes.string,
};

export default function UsersPage({ title }) {
  return <UsersView title={title} />;
}
