import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { boxFormUnavailable, containerFormUnavailable } from './style';
import FormFooter from './FormFooter';

const FormUnavailable = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={boxFormUnavailable}
    >
      <Container
        maxWidth="sm"
        sx={containerFormUnavailable}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {t('pages.formUnavailable.title')}
        </Typography>
        <Typography variant="body1" paragraph sx={{ marginBottom: '20px' }}>
          {t('pages.formUnavailable.message')}
        </Typography>
        <FormFooter />
      </Container>
    </Box>
  );
};

export default FormUnavailable;
