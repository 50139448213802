import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import Label from 'src/components/label/Label';
import Iconify from 'src/components/iconify/Iconify';

UsersTableBody.propTypes = {
  currentPageData: PropTypes.array,
  role: PropTypes.string,
  handleClick: PropTypes.func,
  fullFilteredData: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  selected: PropTypes.array,
  handleOpenMenu: PropTypes.func,
  handleClickRow: PropTypes.func,
  activeTab: PropTypes.number,
};

export default function UsersTableBody({
  activeTab,
  currentPageData,
  role,
  handleClick,
  fullFilteredData,
  handleClickRow,
  rowsPerPage,
  page,
  selected,
  handleOpenMenu,
}) {
  const emptyRows = useMemo(
    () =>
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fullFilteredData.length) : 0,
    [page, rowsPerPage, fullFilteredData]
  );
  const { t } = useTranslation();

  return (
    <TableBody>
      {currentPageData.map((row, idx) => {
        const { fullName, appUserRole, email, phoneNumber, status, departmentName, id } =
          row;
        const selectedUser = selected.indexOf(email) !== -1;

        return (
          <TableRow
            hover
            key={idx}
            tabIndex={-1}
            role="checkbox"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClickRow(row)}
            selected={selectedUser}
          >
            {activeTab === 0 && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedUser}
                  onChange={(event) => handleClick(event, email, id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </TableCell>
            )}

            <TableCell component="th" scope="row" padding="none">
              <Typography variant="subtitle2" mx={2} noWrap>
                {fullName}
              </Typography>
            </TableCell>

            <TableCell align="left">{email}</TableCell>
            {role === 'ROLE_ADMIN' && (
              <TableCell align="left">
                {appUserRole === 'ROLE_ADMIN' || appUserRole === 'ROLE_CUSTOMER'
                  ? ''
                  : departmentName}
              </TableCell>
            )}

            <TableCell align="left">{t(`roles.${appUserRole}`)}</TableCell>
            <TableCell align="left">{phoneNumber}</TableCell>
            <TableCell align="left">
              <Label color={(status === 'false' && 'error') || 'success'}>
                {status === 'true' ? t('userStatus.active') : t('userStatus.inactive')}
              </Label>
            </TableCell>

            <TableCell
              align="center"
              sx={{
                position: 'sticky',
                zIndex: '100',
                right: 0,
                backgroundColor: 'white',
              }}
            >
              <IconButton
                size="large"
                id="user-options-btn"
                color="inherit"
                onClick={(e) => handleOpenMenu(e, row)}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}
