import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { renderTimeViewClock } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  ToggleButton,
  InputAdornment,
  ToggleButtonGroup,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';

import Iconify from '../../components/iconify';
import { useToast } from '../../hooks/useToast';
import { EMAIL_REGEX } from '../../utils/regex';
import { getSettings, updateSettings } from '../../services/configuration.service';

export default function Settings() {
  const [startHours, setStartHours] = useState(dayjs('06:00:00', 'HH:mm:ss'));
  const [endHours, setEndHours] = useState(dayjs('18:00:00', 'HH:mm:ss'));
  const [breakStart, setBreakStart] = useState(dayjs('13:00:00', 'HH:mm:ss'));
  const [breakEnd, setBreakEnd] = useState(dayjs('14:00:00', 'HH:mm:ss'));
  const [workDays, setWorkDays] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast, hideToast } = useToast();
  const [isTimeDirty, setIsTimeDirty] = useState(false);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      emailSecret: '',
      endTime: 0,
      startTime: 0,
      workingDays: [],
    },
  });

  const numberOfWorkHours = useMemo(
    () =>
      Number(
        (endHours.diff(startHours, 'minute') - breakEnd.diff(breakStart, 'minute')) / 60
      ).toFixed(1),
    [startHours, endHours, breakStart, breakEnd]
  );

  // fetch stettings

  const fetchSettingsSuccessCallback = (res) => {
    const defaultValues = {
      email: res.data?.email || '',
      emailSecret: res.data?.emailSecret || '',
      endTime: res.data?.endTime || '18:00:00',
      startTime: res.data?.startTime || '09:00:00',
      breakStart: res.data?.endTime || '13:00:00',
      breakEnd: res.data?.startTime || '14:00:00',
      workingDays: res.data?.workingDays || [],
    };
    reset({ ...defaultValues });
    setWorkDays(res.data?.workingDays);
    setStartHours(dayjs(res.data.startTime, 'HH:mm:ss'));
    setEndHours(dayjs(res.data.endTime, 'HH:mm:ss'));
    setBreakStart(dayjs(res.data.breakStart, 'HH:mm:ss'));
    setBreakEnd(dayjs(res.data.breakEnd, 'HH:mm:ss'));
  };

  const fetchSettings = () => {
    genericApiToaster(
      getSettings,
      [],
      '',
      t('errorMessages.couldntLoadSettings'),
      showToast,
      hideToast,
      t,
      fetchSettingsSuccessCallback
    );
  };

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    data.startTime = startHours.format('HH:mm:ss');
    data.endTime = endHours.format('HH:mm:ss');
    data.workingHours = Number(numberOfWorkHours) * 60 * 60;
    data.workingDays = workDays;
    data.breakStart = breakStart.format('HH:mm:ss');
    data.breakEnd = breakEnd.format('HH:mm:ss');
    genericApiToaster(
      updateSettings,
      data,
      t('successMessages.successSettingsUpdate'),
      t('errorMessages.couldntUpdateInfo'),
      showToast,
      hideToast,
      t,
      () => {
        setIsTimeDirty(false);
        fetchSettings();
      }
    );
  };

  const startHoursChange = (newValue) => {
    setStartHours(newValue);
    setIsTimeDirty(true);
  };

  const endHoursChange = (newValue) => {
    setEndHours(newValue);
    setIsTimeDirty(true);
  };

  const breakStartChange = (newValue) => {
    setBreakStart(newValue);
    setIsTimeDirty(true);
  };

  const breakEndChange = (newValue) => {
    setBreakEnd(newValue);
    setIsTimeDirty(true);
  };

  const handleChange = (event, day) => {
    setWorkDays(day);
    setIsTimeDirty(true);
  };

  const handleCancel = () => {
    fetchSettings();
    setIsTimeDirty(false);
  };

  return (
    <Box bgcolor="#f1f1f1" borderRadius={5} p={3} mx={isSmallScreen && 2}>
      <Typography
        variant="h4"
        mb={isSmallScreen ? 1.5 : 3}
        sx={{
          fontSize: isSmallScreen && '0.85em',
        }}
      >
        {t('pages.settings.applicationSettings')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={isSmallScreen ? 3 : 5}>
          <Box bgcolor="white" borderRadius={5} p={isSmallScreen ? 3 : 4}>
            <Stack spacing={2}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: isSmallScreen && '0.8em',
                }}
              >
                {t('pages.settings.workingHours')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={3}>
                  <TimePicker
                    label={t('attributes.start')}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={startHours}
                    onChange={startHoursChange}
                    sx={{
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.65rem' : '1rem',
                        maxHeight: isSmallScreen ? '35px' : {},
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: isSmallScreen ? '0.85rem' : '1rem',
                      },
                    }}
                  />
                  <TimePicker
                    label={t('attributes.end')}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={endHours}
                    onChange={endHoursChange}
                    sx={{
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.65rem' : '1rem',
                        maxHeight: isSmallScreen ? '35px' : {},
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: isSmallScreen ? '0.85rem' : '1rem',
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={3}>
                  <TimePicker
                    label={t('pages.settings.breakStart')}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={breakStart}
                    onChange={breakStartChange}
                    sx={{
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.65rem' : '1rem',
                        maxHeight: isSmallScreen ? '35px' : {},
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: isSmallScreen ? '0.85rem' : '1rem',
                      },
                    }}
                  />
                  <TimePicker
                    label={t('pages.settings.breakEnd')}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={breakEnd}
                    onChange={breakEndChange}
                    sx={{
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.65rem' : '1rem',
                        maxHeight: isSmallScreen ? '35px' : {},
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: isSmallScreen ? '0.85rem' : '1rem',
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <Typography
                variant="h6"
                sx={{
                  fontSize: isSmallScreen && '0.8em',
                }}
              >
                {t('pages.settings.numberOfWorkHours')} : {numberOfWorkHours}h
              </Typography>

              <Typography
                variant="h6"
                mb={2}
                sx={{
                  fontSize: isSmallScreen && '0.8em',
                }}
              >
                {t('pages.settings.workingDays')} :
              </Typography>
              <Stack direction="row" spacing={2}>
                <ToggleButtonGroup
                  color="primary"
                  onChange={handleChange}
                  value={workDays}
                  sx={{ overflow: 'auto' }}
                  size={isSmallScreen ? 'small' : 'medium'}
                >
                  {[
                    { value: 'SUNDAY', day: t('days.sunday') },
                    { value: 'MONDAY', day: t('days.monday') },
                    { value: 'TUESDAY', day: t('days.tuesday') },
                    { value: 'WEDNESDAY', day: t('days.wednesday') },
                    { value: 'THURSDAY', day: t('days.thursday') },
                    { value: 'FRIDAY', day: t('days.friday') },
                    { value: 'SATURDAY', day: t('days.saturday') },
                  ].map((item, index) => (
                    <ToggleButton key={index} value={item.value}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: isSmallScreen && '0.8em',
                        }}
                      >
                        {item.day}
                      </Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          </Box>
          <Box bgcolor="white" borderRadius={5} p={isSmallScreen ? 3 : 4}>
            <Stack spacing={2}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: isSmallScreen && '0.8em',
                }}
              >
                {t('pages.settings.applicationEmail')}
              </Typography>
              <TextField
                name="email"
                label={t('attributes.email')}
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('email', {
                  required: t('formControl.enterEmail'),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t('formControl.invalidEmail'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  },
                }}
              />
              <TextField
                name="password"
                autoComplete="current-password"
                label={t('pages.settings.emailSecret')}
                error={!!errors.emailSecret}
                helperText={errors.emailSecret?.message || ''}
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('emailSecret', {
                  required: t('formControl.provideEmailSecret'),
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                          sx={{
                            width: isSmallScreen ? 15 : 20,
                            height: isSmallScreen ? 15 : 20,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  },
                }}
              />
            </Stack>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="center">
            <Button
              sx={{
                backgroundColor: '#d21426',
                color: '#fff',
                fontSize: isSmallScreen ? '0.7rem' : '1rem',
              }}
              type="button"
              variant="contained"
              color="error"
              disabled={!isTimeDirty && !isDirty}
              onClick={handleCancel}
              size={isSmallScreen ? 'small' : 'medium'}
            >
              {t('buttons.cancelChanges')}
            </Button>
            <Button
              sx={{
                backgroundColor: 'success.dark',
                color: '#fff',
                ':hover': {
                  backgroundColor: 'success.darker',
                },
                fontSize: isSmallScreen ? '0.7rem' : '1rem',
              }}
              type="submit"
              variant="contained"
              disabled={!isValid || (!isTimeDirty && !isDirty)}
              size={isSmallScreen ? 'small' : 'medium'}
            >
              {t('buttons.confirmChanges')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}
