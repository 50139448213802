import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/client-credentials`;

export const fetchAllClientCredentials = async () => {
  const result = await axios.get(url, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const addClientCredentials = async (data) => {
  const result = await axios.post(url, data, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const updateClientCredentials = async (id, data) => {
  const result = await axios.put(`${url}/${id}`, data, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const deleteClientCredentials = async (id) => {
  const result = await axios.delete(`${url}/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const deleteMultipleClientCredentials = async (ids) => {
  const queryString = ids.map((id) => `ids=${id}`).join('&');
  const result = await axios.delete(`${url}/delete-multiple?${queryString}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const activeEnactiveClientCredentials = async (clientId) => {
  // pass the client id in x-www-form-urlencoded format
  const result = await axios.put(`${url}/status`, `clientId=${clientId}`, {
    headers: {
      ...getAuthorizationHeader(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return result.data;
};

export const getAccessAttemptsByClientId = async (clientId) => {
  const result = await axios.post(`${url}/access-attempts`, `clientId=${clientId}`, {
    headers: {
      ...getAuthorizationHeader(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return result.data;
};
