import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
// @mui
import {
  Box,
  Card,
  Link,
  Stack,
  Popover,
  Tooltip,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';

import { useAuth } from '../../../hooks/useAuth';
import Iconify from '../../../components/iconify/Iconify';
import { useConfirmModal } from '../../../hooks/useModal';
import {
  createFavorite,
  deleteFavorite,
  fetchFavoritesByTypeAndTypeId,
} from '../../../services/favorite.service';
// ----------------------------------------------------------------------

const StyledFormImage = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  padding: '24px',
});

// ----------------------------------------------------------------------

FormCard.propTypes = {
  form: PropTypes.object,
  removeForm: PropTypes.func,
  setSelectedForm: PropTypes.func,
  setOpenDetailedView: PropTypes.func,
};

export default function FormCard({
  form,
  removeForm,
  setSelectedForm,
  setOpenDetailedView,
}) {
  const [open, setOpen] = useState(null);
  const [favorite, setFavorite] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id: currentUserId } = useAuth();
  const { showToast, hideToast } = useToast();

  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.formPage.confirmDelete'),
    `${t('pages.formPage.areYouSure')} ${form.title} ?`,
    () => {
      removeForm(form.id);
      setOpen(false);
    }
  );

  const checkFavorite = async ({ type, typeId }) => {
    const res = await fetchFavoritesByTypeAndTypeId({ type, typeId });
    const favorite = res.filter((fav) => fav.userId === currentUserId);
    if (favorite.length > 0) setFavorite(favorite[0]);
  };

  const handleDeletFavorite = async () => {
    genericApiToaster(
      deleteFavorite,
      favorite.id,
      '',
      t('errorMessages.errorDeletingFavorite'),
      showToast,
      hideToast,
      t,
      () => setFavorite(false)
    );
  };

  const handleAddFavorite = async () => {
    genericApiToaster(
      createFavorite,
      {
        type: 'form',
        typeId: form.id,
        userId: currentUserId,
      },
      '',
      t('errorMessages.cannotAddFavorite'),
      showToast,
      hideToast,
      t,
      setFavorite
    );
  };

  const handleFavoriteClick = () => {
    if (!isDebouncing) {
      setIsDebouncing(true);
      if (favorite) {
        handleDeletFavorite();
      } else {
        handleAddFavorite();
      }
      setTimeout(() => {
        setIsDebouncing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setFavorite();
    checkFavorite({ type: 'form', typeId: form.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <Tooltip title={t('favorites.addToFavorites')}>
          <IconButton
            disabled={isDebouncing}
            onClick={handleFavoriteClick}
            sx={{
              position: 'absolute',
              cursor: isDebouncing ? 'default' : 'pointer',
              right: 5,
              top: 5,
              zIndex: 2,
              padding: isSmallScreen ? '2px' : '8px',
            }}
          >
            <Iconify
              width={isSmallScreen ? 20 : 25}
              color={isDebouncing ? '' : '#db0000'}
              icon={favorite ? 'carbon:favorite-filled' : 'carbon:favorite'}
            />
          </IconButton>
        </Tooltip>

        <Box
          sx={{
            pt: '100%',
            position: 'relative',
            cursor: 'pointer',
            zIndex: 1,
          }}
          onClick={() => {
            if (!isDebouncing) {
              setSelectedForm(form);
              setOpenDetailedView(true);
            }
          }}
        >
          <StyledFormImage alt="form" src="/assets/icons/form.png" />
        </Box>

        <Stack spacing={isSmallScreen ? 0 : 2} sx={{ p: 2 }}>
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Link
              color="inherit"
              underline="hover"
              onClick={() => {
                setSelectedForm(form);
                setOpenDetailedView(true);
              }}
              sx={{
                maxWidth: '100%', // Set the maximum width for the title
                overflow: 'hidden', // Hide any overflowing text
              }}
              title={form.title} // Set the title attribute to the full name
            >
              <Typography
                variant="h6"
                sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
              >
                {form.title}
              </Typography>
            </Link>
            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e)}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
          <Typography
            variant="body1"
            noWrap
            sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
          >
            {form.description}
          </Typography>
        </Stack>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: isSmallScreen ? 110 : 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              fontSize: isSmallScreen ? '0.775rem' : {},
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setSelectedForm(form);
            setOpenDetailedView(true);
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:edit-fill"
            sx={{
              mr: isSmallScreen ? 1 : 2,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.edit')}
          </Typography>
        </MenuItem>

        <MenuItem onClick={showConfirmModal} sx={{ color: 'error.main' }}>
          <Iconify
            icon="eva:trash-2-outline"
            sx={{
              mr: isSmallScreen ? 1 : 2,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.delete')}
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
