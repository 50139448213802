import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Stack, Tooltip, MenuItem, Typography, IconButton } from '@mui/material';

import Iconify from '../../../../components/iconify';

FavoriteItem.propTypes = {
  favorite: PropTypes.object,
  forms: PropTypes.array,
  departments: PropTypes.array,
  handleFavoriteClick: PropTypes.func,
  handleDeletFavorite: PropTypes.func,
};
export default function FavoriteItem({
  favorite,
  forms,
  departments,
  handleFavoriteClick,
  handleDeletFavorite,
}) {
  const { id, typeId } = favorite;
  const form = useMemo(() => forms.find((form) => form.id === typeId), [forms, typeId]);
  const department = useMemo(
    () => departments.find((department) => department.id === form.departmentId),
    [departments, form]
  );
  const itemText = useMemo(
    () => `${form?.title} - ${department?.name}`,
    [department, form]
  );

  return (
    <Tooltip key={id} title={itemText}>
      <MenuItem onClick={() => handleFavoriteClick(typeId)}>
        <Stack
          direction="row"
          spacing={2}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography noWrap>{itemText}</Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Iconify
              icon="eva:trash-2-outline"
              width={20}
              color="error.main"
              onClick={() => handleDeletFavorite(id)}
            />
          </IconButton>
        </Stack>
      </MenuItem>
    </Tooltip>
  );
}
