import { useState, useEffect } from 'react';
import { Box, Stack, IconButton, Popover, Button, Tooltip, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import { fetchFavoritesByUserId, deleteFavorite } from '../../../services/favorite.service';
import { useModal } from '../../../hooks/useModal';
import AddFavoriteModal from './AddFavoriteModal';
import { useAuth } from '../../../hooks/useAuth';
import { fetchDepartments } from '../../../services/department.service';
import { fetchForms } from '../../../services/form.service';
import { useToast } from '../../../hooks/useToast';
import FavoriteItem from './favoriteItem';

export default function FavoritePopover() {
  const [open, setOpen] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [iconFill, setIconFill] = useState(false);
  const [forms, setForms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { showModal, hideModal } = useModal();
  const { id: currentUserId } = useAuth();
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setIconFill(true);
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIconFill(false);
    setOpen(null);
  };

  const handleFavoriteClick = (typeId) => {
    navigate('/dashboard/newTicket', { state: { formId: typeId } });
    handleClose();
  };

  const handleAddFavoriteOpen = () => {
    showModal({
      title: t('favorites.addNewFavorite', { type: '' }),
      Component: AddFavoriteModal,
      props: { onClose: hideModal, departmentsList: departments, forms, loadFavorites },
    });
  };

  const getDepartments = async () => {
    try {
      const departments = await fetchDepartments();
      setDepartments(departments);
    } catch (error) {
      showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
    }
  };

  const getForms = async () => {
    try {
      const forms = await fetchForms();
      setForms(forms);
    } catch (error) {
      showToast({ message: t('errorMessages.cannotLoadForms'), severity: 'error', props: { hideToast } });
    }
  };

  const handleDeletFavorite = async (id) => {
    await deleteFavorite(id);
    loadFavorites();
  };

  const loadFavorites = async () => {
    const data = await fetchFavoritesByUserId(currentUserId);
    setFavorites(data);
  };
  useEffect(() => {
    loadFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, open]);

  useEffect(() => {
    getDepartments();
    getForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Tooltip title={t('navbar.favorites')}>
        <IconButton onClick={handleOpen}>
          <Iconify icon={iconFill ? 'carbon:favorite-filled' : 'carbon:favorite'} color="#db0000" width={30} />
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            maxWidth: '30vw',
            maxHeight: '50vw',
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {t('favorites.favoriteForms')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('favorites.favoriteFormsCount', { count: favorites.length })}
          </Typography>
        </Box>

        {favorites.length > 0 && <Divider sx={{ borderStyle: 'dashed' }} />}
        <Stack spacing={0.75} my={1} px={1}>
          {favorites.map((favorite, index) => (
            <FavoriteItem
              key={index}
              favorite={favorite}
              handleDeletFavorite={handleDeletFavorite}
              handleFavoriteClick={handleFavoriteClick}
              forms={forms}
              departments={departments}
            />
          ))}
        </Stack>
        <Box sx={{ position: 'sticky', bottom: 0, bgcolor: 'white' }}>
          <Divider />
          <Button
            sx={{ width: '100%', py: 1.5 }}
            startIcon={<Iconify icon="ic:outline-plus" />}
            onClick={handleAddFavoriteOpen}
          >
            {t('favorites.addNewFavorite', { type: '' })}{' '}
          </Button>
        </Box>
      </Popover>
    </>
  );
}
