import PropTypes from 'prop-types';

// @mui
import { Grid, Paper } from '@mui/material';

import Loader from 'src/components/loaders/Loader';
import EmptyGrid from 'src/components/emptyGrid/emptyGrid';

import DepartmentCard from './DepartmentCard';

// ----------------------------------------------------------------------

DepartmentsList.propTypes = {
  departments: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  editDepartment: PropTypes.func,
  filterName: PropTypes.string,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  isLoading: PropTypes.bool,
  fetchTickets: PropTypes.func,
  fetchUsers: PropTypes.func,
};

export default function DepartmentsList({
  fetchTickets,
  fetchUsers,
  departments,
  handleDelete,
  itemsPerPage,
  page,
  filterName,
  isNotFound,
  searchIsNotFound,
  isLoading,
  editDepartment,
  ...other
}) {
  return (
    <Grid container spacing={3} {...other}>
      {!isLoading ? (
        <>
          {departments
            .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
            .map((department) => (
              <Grid key={department.id} item xs={6} sm={4} md={3}>
                <DepartmentCard
                  department={department}
                  handleDelete={handleDelete}
                  editDepartment={editDepartment}
                  fetchTickets={fetchTickets}
                  fetchUsers={fetchUsers}
                />
              </Grid>
            ))}
          {isNotFound && (
            <EmptyGrid searchIsNotFound={searchIsNotFound} filterName={filterName} />
          )}
        </>
      ) : (
        <Paper
          sx={{
            width: '100%',
            py: 5,
            my: 5,
            textAlign: 'center',
          }}
        >
          <Loader size={175} />
        </Paper>
      )}
    </Grid>
  );
}
