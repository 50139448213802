import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import { Container, Stack, Typography, Button, Pagination, Paper, Popper, Card, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useToast } from '../hooks/useToast';
import FormList from '../components/Form/formList/FormList';
import FormDetails from '../components/Form/formDetails/FormDetails';
import Iconify from '../components/iconify';
// ----------------------------------------------------------------------
import { applySortFilter, getComparator } from '../utils/filterData';
import { deleteForm, fetchForms } from '../services/form.service';
import { UserListToolbar } from '../sections/@dashboard/user';
import { fetchDepartments } from '../services/department.service';

const itemsPerPage = 8;
const order = 'asc';
const orderBy = 'name';

export default function FormPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const [page, setPage] = useState(0);
  const [openDetailedView, setOpenDetailedView] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [formData, setFormData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  // Popover states
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [filterBy, setFilterBy] = useState('title');
  const [searchBy, setSearchBy] = useState('byTitle');
  const [subFilterPopover, setSubFilterPopover] = useState({ department: null });

  // Filter methods
  const handleOpenFilter = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const { departmentId, role } = useAuth();
  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setPopoverAnchorEl(null);
  };
  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    }
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ department: null });
  };
  // Fetch forms
  const getForms = () => {
    fetchForms().then((res) => {
      if (role.authority === 'ROLE_ADMIN') setFormData(res);
      else if (role.authority === 'ROLE_DEPARTMENTMANAGER')
        setFormData(res.filter((form) => form.departmentId === departmentId));
    });
  };

  // fetch departments

  const fetchDepartmentData = () => {
    fetchDepartments().then((res) => {
      setDepartmentData(res);
    });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    getForms();
    fetchDepartmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetailedView]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const removeForm = (id) => {
    deleteForm(id)
      .then(() => {
        showToast({ message: t('successMessages.successFormRemove'), severity: 'success', props: { hideToast } });
        const newFormList = formData.filter((form) => form.id !== id);
        setFormData(newFormList);
        setPage(0);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntRemoveForm'), severity: 'error', props: { hideToast } });
      });
  };

  const filteredForms = useMemo(
    () => applySortFilter(formData, getComparator(order, orderBy), filterName, filterBy),
    [filterBy, filterName, formData]
  );

  const { totalPages, isNotFound, paginatedForms } = useMemo(() => {
    const totalPages = Math.ceil(filteredForms.length / itemsPerPage);
    const isNotFound = !filteredForms.length && !!filterName;
    const paginatedForms = filteredForms.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);
    return { totalPages, isNotFound, paginatedForms };
  }, [filteredForms, page, filterName]);

  return (
    <>
      <Helmet>
        <title> {t('nav.forms')}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">{t('nav.forms')}</Typography>
          <Button
            id="create-new-form-btn"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate('/dashboard/NewForm')}
          >
            {t('buttons.newForm')}
          </Button>
        </Stack>
        <UserListToolbar
          numSelected={0}
          filterName={filterName.toString()}
          filterBy={searchBy}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByName}
          model={t('nav.forms')}
        />
        <FormDetails
          key={selectedForm.id}
          setOpen={setOpenDetailedView}
          open={openDetailedView}
          formId={selectedForm.id}
          initialData={selectedForm.formData}
        />

        <FormList
          forms={paginatedForms}
          removeForm={removeForm}
          setOpenDetailedView={setOpenDetailedView}
          setSelectedForm={setSelectedForm}
        />
        {isNotFound && (
          <Paper
            sx={{
              my: 5,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" paragraph>
              {t('filters.notFound')}
            </Typography>

            <Typography variant="body2">{t('filters.noResultsFound', { filterName })}</Typography>
          </Paper>
        )}
        {/* main filter popover */}
        <Popper
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          placement="left-start"
          onClose={handleCloseFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,

                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            <MenuItem
              selected={filterBy === 'title'}
              onMouseEnter={handleCloseSubFilter}
              onClick={() => {
                setFilterName('');
                setFilterBy('title');
                setSearchBy('byTitle');
                handleCloseFilter();
              }}
            >
              <Typography variant="body2"> {t('filters.byTitle')}</Typography>
            </MenuItem>
            <MenuItem
              selected={filterBy === 'id'}
              onMouseEnter={handleCloseSubFilter}
              onClick={() => {
                setFilterName('');
                setFilterBy('id');
                setSearchBy('byId');
                handleCloseFilter();
              }}
            >
              <Typography variant="body2"> {t('filters.byId')}</Typography>
            </MenuItem>
            {role.authority !== 'ROLE_DEPARTMENTMANAGER' && (
              <MenuItem selected={filterBy === 'department'} onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="body2"> {t('filters.byDepartment')}</Typography>
                </Stack>
              </MenuItem>
            )}
          </Card>
        </Popper>
        {/* sub filter popover */}

        <Popper
          open={Boolean(subFilterPopover.department)}
          anchorEl={subFilterPopover.department}
          placement="left-start"
          onClose={handleCloseSubFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {departmentData.map((department) => (
              <MenuItem
                key={department.id}
                onClick={() => {
                  setFilterBy('departmentId');
                  setSearchBy('byDepartment');
                  setFilterName(department.id);
                  handleCloseFilter();
                  setPage(0);
                }}
              >
                {department.name}
              </MenuItem>
            ))}
          </Card>
        </Popper>
        {filteredForms.length > 0 && (
          <Stack alignItems="center" sx={{ mt: 5 }}>
            <Pagination
              component="div"
              color="primary"
              count={totalPages}
              size="large"
              page={page + 1}
              onChange={handleChangePage}
            />
          </Stack>
        )}
      </Container>
    </>
  );
}
