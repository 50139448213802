import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TicketForm from '../../components/ticket/TicketForm';
import { fetchDepartments } from '../../services/department.service';
import { fetchFormById } from '../../services/form.service';
import { useAuth } from '../../hooks/useAuth';

export default function CreateTicketPage() {
  const [departments, setDepartments] = useState();
  const [favoriteFormData, setFavoriteFormData] = useState();
  const { state } = useLocation();
  const { isAuthenticated } = useAuth();

  const fetchDerpartmentsData = async () => {
    const data = await fetchDepartments();
    setDepartments(data);
  };

  const fetchFavoriteFormData = async (id) => {
    const data = await fetchFormById(id);
    setFavoriteFormData(data);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchDerpartmentsData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.formId) {
      fetchFavoriteFormData(state.formId);
    }
  }, [state]);

  return <TicketForm departments={departments} favoriteForm={favoriteFormData} />;
}
