import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';

import { genericApiToaster } from 'src/services/utils';
import {
  deleteUser,
  unarchiveUser,
  multipleUsersDelete,
} from 'src/services/user.service';

import TableLoader from 'src/components/loaders/Loader';
import EmptyTable from 'src/components/tables/emptyTable';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import TableToolbar from 'src/components/ToolBars/TableToolbar';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';

import UsersTableBody from './tableBody';
import FilterPopover from '../popovers/filterPopover';
import OptionsPopover from '../popovers/optionsPopover';
import { TABLE_HEAD_ADMIN, TABLE_HEAD_DEPMANAGER } from '../tableHeads';

UsersTable.propTypes = {
  currentPageData: PropTypes.array,
  fullFilteredData: PropTypes.array,
  role: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  noArchivedUsers: PropTypes.bool,
  noActiveUsers: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
  setPage: PropTypes.func,
  title: PropTypes.string,
  departmentsData: PropTypes.array,
  setRowsPerPage: PropTypes.func,
  setSelectedRow: PropTypes.func,
  selectedRow: PropTypes.object,
  setFilterName: PropTypes.func,
  setModifyUserModal: PropTypes.func,
  setFilterBy: PropTypes.func,
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
  activeTab: PropTypes.number,
  usersData: PropTypes.array,
};

export default function UsersTable({
  currentPageData,
  filterName,
  fullFilteredData,
  role,
  setRowsPerPage,
  selected,
  setSelected,
  order,
  setOrder,
  setOrderBy,
  orderBy,
  rowsPerPage,
  page,
  setPage,
  noArchivedUsers,
  noActiveUsers,
  searchIsNotFound,
  title,
  departmentsData,
  setSelectedRow,
  selectedRow,
  setFilterName,
  setModifyUserModal,
  setFilterBy,
  fetchData,
  isLoading,
  activeTab,
  usersData,
}) {
  const [searchBy, setSearchBy] = useState('byName');
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const [filterPopover, setFilterPopover] = useState(null);
  const [open, setOpen] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [showDelete, setShowDelete] = useState(true);

  const { t } = useTranslation();
  const { id: currentUserId } = useAuth();
  const { showToast, hideToast } = useToast();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenMenu = (event, row) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
    setSelectedRow(row);
    if (role === 'ROLE_ADMIN') {
      setShowDelete(row.id !== currentUserId);
    } else {
      setShowDelete(false);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClick = (event, email, id) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];
    let newSelectedId = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
      newSelectedId = newSelectedId.concat(selectedId, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedId = newSelectedId.concat(selectedId.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedId = newSelectedId.concat(
        selectedId.slice(0, selectedIndex),
        selectedId.slice(selectedIndex + 1)
      );
    }
    setSelectedId(newSelectedId);
    setSelected(newSelected);
  };

  const removeUser = async (id, archive) => {
    if (id !== currentUserId) {
      genericApiToaster(
        deleteUser,
        id,
        archive
          ? t('successMessages.successUserArchived')
          : t('successMessages.successUserDelete'),
        t('errorMessages.couldntDeleteUser'),
        showToast,
        hideToast,
        () => {
          fetchData();
        }
      );
    } else {
      showToast({
        message: t('errorMessages.cannotDeleteUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const unarchive = async (id) => {
    if (id !== currentUserId) {
      genericApiToaster(
        unarchiveUser,
        id,
        t('successMessages.successUserUnarchive'),
        t('errorMessages.couldntUnarchiveUser'),
        showToast,
        hideToast,
        () => {
          fetchData();
        }
      );
    } else {
      showToast({
        message: t('errorMessages.cannotUnarchiveUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const deleteMultipleUsers = async (usersIDs) => {
    try {
      if (!usersIDs.includes(currentUserId)) {
        const res = await multipleUsersDelete(usersIDs);
        showToast({
          message: t('successMessages.successMultipleUsersDelete', {
            usersDeleted: res.deletedCount,
            totalUsers: res.totalIds,
            usersArchived: res.archivedCount,
          }),
          severity: 'success',
          props: { hideToast },
        });
        setSelectedId([]);
        setSelected([]);
        fetchData();
      } else {
        showToast({
          message: t('errorMessages.cannotDeleteUser'),
          severity: 'error',
          props: { hideToast },
        });
      }
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntDeleteUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageData.map((n) => n.email);
    const newSelectdsId = currentPageData.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      // remove selected ids and email of this page if all are already selected
      setSelectedId(selectedId.filter((id) => !newSelectdsId.includes(id)));
      setSelected(selected.filter((email) => !newSelecteds.includes(email)));
      return;
    }
    // else select ids and emails of this page and add it to the selected list
    setSelected([...new Set([...selected, ...newSelecteds])]);
    setSelectedId([...new Set([...selectedId, ...newSelectdsId])]);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRemoveSelected = () => {
    setSelected([]);
    setSelectedId([]);
  };

  const handleCloseFilter = () => {
    setFilterPopover(null);
  };

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleClickRow = (row) => {
    setSelectedRow(row);
    setModifyUserModal(true);
  };

  const filteredData =
    activeTab === 0
      ? fullFilteredData.filter((user) => !user.archived)
      : fullFilteredData.filter((user) => user.archived);
  useEffect(() => {
    const newSelectdsId = currentPageData.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
  }, [page, currentPageData, selectedId]);
  return (
    <>
      <Card>
        <TableToolbar
          numSelected={selected.length}
          filterName={filterName}
          setFilterName={setFilterName}
          filterBy={searchBy}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByName}
          selected={selectedId}
          handleRemoveSelected={handleRemoveSelected}
          deleteFunc={deleteMultipleUsers}
          model={t(`nav.${title}`)}
          data={usersData}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHeaders
                order={order}
                orderBy={orderBy}
                headLabel={
                  role === 'ROLE_ADMIN' ? TABLE_HEAD_ADMIN : TABLE_HEAD_DEPMANAGER
                }
                showCheckBox={activeTab === 0}
                rowCount={filteredData.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                indeterminate={indeterminateCheckbox}
              />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                      <TableLoader size={150} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <UsersTableBody
                    currentPageData={filteredData.slice(
                      page * rowsPerPage,
                      (page + 1) * rowsPerPage
                    )}
                    role={role}
                    handleClick={handleClick}
                    fullFilteredData={filteredData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    selected={selected}
                    handleOpenMenu={handleOpenMenu}
                    handleClickRow={handleClickRow}
                    activeTab={activeTab}
                  />
                  {((noArchivedUsers && activeTab === 1) ||
                    (noActiveUsers && activeTab === 0)) && (
                    <EmptyTable
                      filterName={filterName}
                      searchIsNotFound={searchIsNotFound}
                      colSpan={8}
                    />
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage={t('filters.rowsPerPage')}
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <OptionsPopover
        open={open}
        handleCloseMenu={handleCloseMenu}
        setModifyUserModal={setModifyUserModal}
        selectedRow={selectedRow}
        removeUser={removeUser}
        showDelete={showDelete}
        unarchive={unarchive}
      />
      <FilterPopover
        setFilterBy={setFilterBy}
        setFilterName={setFilterName}
        setSearchBy={setSearchBy}
        setPage={setPage}
        departmentsData={departmentsData}
        handleCloseFilter={handleCloseFilter}
        filterPopover={filterPopover}
      />
    </>
  );
}
