import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import GroupUsersView from 'src/sections/groupUsers';

function GroupUsersPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title> {t('nav.MyGroups')} </title>
      </Helmet>
      <GroupUsersView />
    </>
  );
}

export default GroupUsersPage;
