import dayjs from 'dayjs';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

DateTimePickerInput.propTypes = {
  setSelectedVals: PropTypes.func,
};

export default function DateTimePickerInput({ setSelectedVals, ...other }) {
  const [value, setValue] = React.useState(dayjs('2023-08-18T21:11:54'));
  const { t } = useTranslation();
  const handleChange = (newValue) => {
    setValue(newValue);
    setSelectedVals(dayjs(newValue).format('DD/MM/YYYY'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DatePicker
          label={t('formInputs.datePicker')}
          value={value}
          onChange={handleChange}
          {...other}
        />
      </Stack>
    </LocalizationProvider>
  );
}
