import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useModal } from 'src/hooks/useModal';

import Iconify from '../../../components/iconify';
import DelegateTicketModal from '../modals/DelegateTicketModal';
import AssignTicketToSelfModal from '../modals/AssignToSelfModal';
import { secondButtonStyle, loadingButtonStyle, typographyContentStyle } from '../styles';

export default function TicketDelegation({
  currentStatus,
  ticketData,
  assignTicket,
  departmentUsers,
  ticketId,
  statusesList,
  delegateTicket,
}) {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const { id: currentUserId, role: currentUserRole } = useAuth();

  const handleDelegate = () => {
    showModal({
      title:
        currentUserRole.authority === 'ROLE_AGENT' &&
        ticketData?.assignedTo === currentUserId
          ? t('pages.delegateTicket.delegateTicketTo')
          : t('pages.assignTicket.reassignTicket'),
      Component: DelegateTicketModal,
      props: {
        delegateTicket,
        usersList: departmentUsers.filter(
          (user) => user.id !== currentUserId && user.id !== ticketData?.assignedTo
        ),
        department: ticketData.department,
        ticketData,
        isDelegate:
          currentUserRole.authority === 'ROLE_AGENT' &&
          ticketData?.assignedTo === currentUserId,
      },
    });
  };

  return (
    <Stack pt={2} height="15%" justifyContent="center" alignItems="center">
      {(currentStatus &&
        departmentUsers.length > 0 &&
        currentStatus.systemMappedStatus !== 'UNASSIGNED' &&
        (currentUserRole.authority === 'ROLE_ADMIN' ||
          currentUserRole.authority === 'ROLE_DEPARTMENTMANAGER')) ||
      (currentUserRole.authority === 'ROLE_AGENT' &&
        ticketData?.assignedTo === currentUserId) ? (
        <LoadingButton
          data-testid="accept-ticket-btn"
          size="small"
          sx={loadingButtonStyle}
          variant="contained"
          onClick={handleDelegate}
        >
          {currentUserRole.authority === 'ROLE_AGENT' &&
          ticketData?.assignedTo === currentUserId
            ? t('pages.delegateTicket.delegateTicketTo')
            : t('pages.assignTicket.reassignTicket')}{' '}
        </LoadingButton>
      ) : ticketData &&
        currentStatus?.systemMappedStatus === 'UNASSIGNED' &&
        currentUserRole.authority === 'ROLE_AGENT' &&
        ticketData.createdBy !== currentUserId ? (
        <>
          <Typography sx={typographyContentStyle}>
            {t('pages.assignTicket.assignByYourself')}
          </Typography>
          <LoadingButton
            onClick={() => {
              showModal({
                title: t('pages.assignTicket.assignTicketToSelf'),
                Component: AssignTicketToSelfModal,
                props: {
                  hideModal,
                  assignTicket,
                  ticketId: parseInt(ticketId, 10),
                  statusesList: statusesList.filter(
                    (status) => status.systemMappedStatus === 'OPEN'
                  ),
                  currentUserId,
                },
              });
            }}
            size="small"
            sx={secondButtonStyle}
            variant="contained"
            startIcon={<Iconify icon="@mui/icons-material/ArrowForward" />}
          >
            {t('pages.assignTicket.assignTicketToSelf')}
          </LoadingButton>
        </>
      ) : null}
    </Stack>
  );
}

TicketDelegation.propTypes = {
  currentStatus: PropTypes.object,
  ticketData: PropTypes.object,
  assignTicket: PropTypes.func,
  departmentUsers: PropTypes.array,
  ticketId: PropTypes.string,
  statusesList: PropTypes.array,
  delegateTicket: PropTypes.func,
};
