import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Avatar,
  Tooltip,
  CardMedia,
  Typography,
  IconButton,
  CardContent,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

import { cartStyle, avatarStyle, avatarColors, componentPropsStyle } from '../style';

function GroupUsersCard({ group, onClick, onMenuOpen }) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Tooltip
      title={
        <Scrollbar sx={{ maxHeight: 150, overflowY: 'auto', m: 0.5 }}>
          {group.users.map((user, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              <Typography key={index} variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
                {user.email}
              </Typography>
            </Box>
          ))}
        </Scrollbar>
      }
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            componentPropsStyle,
          },
        },
        arrow: {
          sx: {
            color: 'white',
          },
        },
      }}
    >
      <Card sx={cartStyle} onClick={onClick}>
        <CardMedia
          component="img"
          height="140"
          image={group.image || '/assets/images/group-users/group-users.jpg'}
          alt="Group"
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: isSmallScreen ? '0.9rem' : '1rem' }}
            >
              {group.name}
            </Typography>
            <IconButton
              aria-label="settings"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation();
                onMenuOpen(e, group);
              }}
            >
              <Iconify icon="mdi:dots-vertical" />
            </IconButton>
          </Box>
          <Box display="flex" mt={1}>
            {group.users.slice(0, 4).map((user, index) => (
              <Tooltip
                key={index}
                title={user.email}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      componentPropsStyle,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <Avatar
                  key={index}
                  src={user.avatar}
                  sx={{
                    ...avatarStyle,
                    backgroundColor: avatarColors[index % avatarColors.length],
                    mr: 0.5,
                  }}
                >
                  {user.username.charAt(0).toUpperCase()}
                  {user.username.split(' ')[1]?.charAt(0).toUpperCase() || ''}
                </Avatar>
              </Tooltip>
            ))}
            {group.users.length > 4 && (
              <Tooltip
                title={
                  <Scrollbar sx={{ maxHeight: 150, overflowY: 'auto', m: 0.5 }}>
                    {group.users.slice(4).map((user, index) => (
                      <Box key={index} display="flex" alignItems="center" mb={1}>
                        <Typography
                          key={index}
                          variant="subtitle1"
                          sx={{ fontSize: '0.65rem' }}
                        >
                          {user.email}
                        </Typography>
                      </Box>
                    ))}
                  </Scrollbar>
                }
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      componentPropsStyle,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <Avatar
                  sx={{
                    ...avatarStyle,
                    backgroundColor: avatarColors[4],
                  }}
                >
                  +{group.users.length - 4}
                </Avatar>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>
    </Tooltip>
  );
}

GroupUsersCard.propTypes = {
  group: PropTypes.object,
  onClick: PropTypes.func,
  onMenuOpen: PropTypes.func,
};

export default GroupUsersCard;
