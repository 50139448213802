import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';

import {
  List,
  Card,
  Stack,
  Avatar,
  Button,
  ListItem,
  Typography,
  ListItemAvatar,
} from '@mui/material';

import {
  cardTicketModalStyle,
  listTicketModalStyle,
  listItemTicketModalStyle,
} from 'src/sections/ticketDetails/styles';

import Label from '../../../components/label';
import { useConfirmModal } from '../../../hooks/useModal';
import { convertLocalTime } from '../../../utils/convertTime';
import SearchBar from '../../../components/searchBar/SearchBar';
import { getComparator, applySortFilter } from '../../../utils/filterData';

DelegateTicketModal.propTypes = {
  delegateTicket: PropTypes.func,
  usersList: PropTypes.array,
  department: PropTypes.string,
  ticketData: PropTypes.object,
  isDelegate: PropTypes.bool,
};

const order = 'asc';
const orderBy = 'name';

export default function DelegateTicketModal({
  delegateTicket,
  usersList,
  department,
  ticketData,
  isDelegate,
}) {
  const [selectedAgent, setSelectedAgent] = useState({
    id: 0,
    username: '',
  });
  const [filterName, setFilterName] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const { t } = useTranslation();

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const filteredUsers = useMemo(() => {
    const filteredData = applySortFilter(
      usersList,
      getComparator(order, orderBy),
      filterName,
      'username'
    );
    setIsNotFound(!filteredData.length && !!filterName);
    return filteredData;
  }, [filterName, usersList]);

  const { showConfirmModal } = useConfirmModal(
    isDelegate
      ? t('pages.delegateTicket.confirmDelegation')
      : t('pages.delegateTicket.confirmReassign'),
    isDelegate
      ? t('pages.delegateTicket.areYouSureDelegate', {
          agentName: selectedAgent.username,
        })
      : t('pages.delegateTicket.areYouSureReassign', {
          agentName: selectedAgent.username,
        }),
    async () => {
      const delegatedTicketData = { ...ticketData, assignedTo: selectedAgent.id };
      delegateTicket(delegatedTicketData);
    }
  );

  return (
    <Stack px={2} spacing={2} my={2}>
      <Typography variant="subtitle1">
        {t('pages.assignTicket.availableAgents')} {department} :
      </Typography>
      <SearchBar
        filterName={filterName}
        handleFilterByName={handleFilterByName}
        model="agent"
      />
      <Card
        sx={{ ...cardTicketModalStyle, height: filteredUsers.length > 1 ? 450 : 150 }}
      >
        <List sx={listTicketModalStyle}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => {
              const { id, username, email, phoneNumber, avatar, available, availableOn } =
                user;

              return (
                <ListItem
                  divider
                  key={id}
                  onClick={() => {
                    setSelectedAgent(user);
                  }}
                  sx={listItemTicketModalStyle(selectedAgent, user)}
                  alignItems="flex-start"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="row">
                      <ListItemAvatar>
                        <Avatar alt="" src={avatar} />
                      </ListItemAvatar>

                      <Stack spacing={0.5}>
                        <Typography variant="body2">
                          <b>{username}</b>
                        </Typography>
                        <Typography variant="body2">
                          <b>{t('attributes.email')}: </b>
                          {email}
                        </Typography>
                        <Typography variant="body2">
                          <b>{t('attributes.phoneNumber')}: </b>
                          {phoneNumber}
                        </Typography>
                        {!available && (
                          <Typography variant="body2">
                            <b>{t('attributes.availableOn')}: </b>
                            {convertLocalTime(new Date(availableOn)).toLocaleString(
                              ('en-GB',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            )}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Label color={(available && 'success') || 'error'}>
                      {available
                        ? t('attributes.available')
                        : t('attributes.unavailable')}
                    </Label>
                  </Stack>
                </ListItem>
              );
            })
          ) : (
            <Typography textAlign="center" variant="subtitle1">
              {isNotFound
                ? t('pages.assignTicket.noAgentsFound')
                : t('pages.assignTicket.noAgentsAvailable')}
            </Typography>
          )}
        </List>
      </Card>
      <Button
        variant="outlined"
        onClick={selectedAgent.id !== 0 ? showConfirmModal : null}
        disabled={selectedAgent.id === 0}
      >
        {isDelegate
          ? t('pages.delegateTicket.delegateTicket')
          : t('buttons.reassignTicket')}
      </Button>
    </Stack>
  );
}
