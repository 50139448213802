import React from 'react';
import { TextField, Stack, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DurationSelector = ({ days, setDays, hours, setHours, minutes, setMinutes }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2} mb={2} mt={2}>
      <TextField
        select
        label={t('pages.formPage.inDays')}
        value={days}
        onChange={(e) => setDays(Number(e.target.value))}
        sx={{ width: '30%' }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '300px',
                overflowY: 'auto',
              },
            },
          },
        }}
      >
        {[...Array(31).keys()].map((day) => (
          <MenuItem key={day} value={day}>
            {day}
          </MenuItem>
        ))}
         {days > 30 && (
          <MenuItem value={days} disabled>
            {days}
          </MenuItem>
        )}
      </TextField>

      <TextField
        select
        label={t('pages.formPage.inHours')}
        value={hours}
        onChange={(e) => setHours(Number(e.target.value))}
        sx={{ width: '30%' }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '300px',
                overflowY: 'auto',
              },
            },
          },
        }}
      >
        {[...Array(24).keys()].map((hour) => (
          <MenuItem key={hour} value={hour}>
            {hour}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label={t('pages.formPage.inMinutes')}
        value={minutes}
        onChange={(e) => setMinutes(Number(e.target.value))}
        sx={{ width: '30%' }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '300px',
                overflowY: 'auto',
              },
            },
          },
        }}
      >
        {[...Array(60).keys()].map((minute) => (
          <MenuItem key={minute} value={minute}>
            {minute}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

DurationSelector.propTypes = {
  days: PropTypes.number,
  setDays: PropTypes.func,
  hours: PropTypes.number,
  setHours: PropTypes.func,
  minutes: PropTypes.number,
  setMinutes: PropTypes.func,
};

export default DurationSelector;
