import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Stack,
  Avatar,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogContent,
} from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import fileDownload from 'src/utils/fileDownload';
import convertLineReturnToHTML from 'src/utils/convertLineReturn';

import { genericApiToaster } from 'src/services/utils';
import { getFileByName } from 'src/services/file.service';

import Iconify from 'src/components/iconify/Iconify';

import { iconifyStyle, avatarMessageStyle, dialogContentStyle } from '../../styles';

MediaMessage.propTypes = {
  message: PropTypes.string,
  time: PropTypes.string,
  user: PropTypes.object,
  isMine: PropTypes.bool,
};

const extensions = ['.jpeg', '.jpg', '.png', '.gif', '.webp'];

export default function MediaMessage({ message, time, user, isMine }) {
  const [fileData, setFileData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('/assets/images/files/fileDefault.png');
  const [isImage, setIsImage] = useState(false);

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();

  const getFile = async () => {
    genericApiToaster(
      getFileByName,
      message,
      '',
      t('errorMessages.cannotGetFile'),
      showToast,
      hideToast,
      t,
      setFileData
    );
  };

  const handleClickFile = () => {
    if (isImage) setModalOpen(true);
  };

  useEffect(() => {
    if (fileData) {
      extensions.forEach((ext) => {
        if (message.endsWith(ext)) {
          setIsImage(true);
          setImgUrl(URL.createObjectURL(fileData));
        }
      });
    }
  }, [fileData, message]);

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md">
        <IconButton
          onClick={() => setModalOpen(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <Iconify sx={iconifyStyle} icon="eva:close-square-fill" />
        </IconButton>
        <DialogContent sx={dialogContentStyle}>
          <img
            src={imgUrl}
            alt="Preview"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: isSmallScreen ? '70vh' : '90vh',
              objectFit: 'contain',
              display: 'block',
            }}
          />
        </DialogContent>
      </Dialog>
      <Stack justifyContent="space-between">
        <Typography
          color="#4a4a4a"
          variant="caption"
          textAlign="center"
          sx={{
            fontSize: isSmallScreen && '0.675rem',
          }}
        >
          {time}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          {!isMine && (
            <Avatar
              src={user.avatar ?? ''}
              alt="avatarUrl"
              sx={{
                ...avatarMessageStyle,
                width: isSmallScreen ? 25 : 32,
                height: isSmallScreen ? 25 : 32,
              }}
            />
          )}

          <Stack sx={{ width: '80%' }} spacing={1}>
            <Typography
              variant="body1"
              color="#4a4a4a"
              sx={{
                textAlign: isMine ? 'right' : 'left',
                fontSize: isSmallScreen && '0.9rem',
              }}
            >
              {user.username || t('pages.ticketDetails.anonymous')}
            </Typography>
            <Card
              sx={{
                bgcolor: isMine ? '#237874' : 'white',
                alignSelf: isMine ? 'flex-end' : 'flex-start',
                width: '70%',
                wordWrap: 'break-word',
                p: 2,
                borderRadius: 3,
              }}
            >
              {fileData && (
                <Stack spacing={2}>
                  <Button
                    onClick={handleClickFile}
                    disabled={!isImage}
                    sx={{ p: 0, minWidth: 0, maxWidth: 200, alignSelf: 'center' }}
                    aria-label="Open image preview"
                  >
                    <img
                      style={{
                        maxWidth: 200,
                        maxHeight: isSmallScreen ? 100 : 200,
                      }}
                      src={imgUrl}
                      alt=""
                    />
                  </Button>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: isMine ? 'white' : '#4a4a4a',
                        fontSize: isSmallScreen ? '0.675rem' : '0.9rem',
                      }}
                      textAlign="center"
                    >
                      {typeof message === 'string'
                        ? convertLineReturnToHTML(message)
                        : message}
                    </Typography>
                    <IconButton onClick={() => fileDownload(fileData, message)}>
                      <Iconify
                        sx={{
                          color: isMine ? 'white' : '#4a4a4a',
                          height: isSmallScreen ? '40' : '60',
                          width: isSmallScreen ? '40' : '60',
                        }}
                        icon="material-symbols:download"
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
            </Card>
          </Stack>
          {isMine && (
            <Avatar
              src={user.avatar ?? ''}
              alt="avatarUrl"
              sx={{ width: isSmallScreen ? 25 : 30, height: isSmallScreen ? 25 : 30 }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}
