import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Paper, Typography } from '@mui/material';

EmptyGrid.propTypes = {
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};

export default function EmptyGrid({ searchIsNotFound, filterName }) {
  const { t } = useTranslation();
  return (
    <Paper
      sx={{
        width: '100%',
        py: 5,
        my: 5,
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" paragraph>
        {t('filters.notFound')}
      </Typography>
      {searchIsNotFound && (
        <Typography variant="body2">
          {t('filters.noResultsFound', { filterName })}
        </Typography>
      )}
    </Paper>
  );
}
