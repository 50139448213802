import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

// @mui
import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import authService from 'src/services/auth.service';
import { genericApiToaster } from 'src/services/utils';
import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';

import ActionButton from 'src/components/action-button/action-button';

import filterByTab from './filterByTab';
import TicketsTable from './table/table';
// components
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import ModifyTicket from './modals/ModifyTicket';
import { fetchStatuses } from '../../services/status.service';
import { getUsersByDepartment } from '../../services/user.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { fetchDepartments } from '../../services/department.service';
import { getComparator, applySortFilter } from '../../utils/filterData';
import DashboardToolbar from '../../components/ToolBars/DashboardToolbar';
// ----------------------------------------------------------------------

TicketTab.propTypes = {
  title: PropTypes.string,
};

export default function TicketTab({ title }) {
  const [ticketsData, setTicketsData] = useState([]);
  const [openModifyTicket, setOpenModifyTicket] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderBy, setOrderBy] = useState('id');
  const [filterTitle, setFilterTitle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentData, setDepartmentData] = useState([]);
  const [filterBy, setFilterBy] = useState('id');
  const [dataInCSV, setDataInCSV] = useState('');
  const [statusesList, setStatusesList] = useState([]);
  const [ticketsWithStatus, setTicketsWithStatus] = useState([]);
  const [currentPageTickets, setCurrentPageTickets] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [searchIsNotFound, setSearchIsNotFound] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [filterByData, setFilterByData] = useState([]);
  const [isDownloadDebouncing, setIsDownloadDebouncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id: currentUserId, departmentId } = useAuth();
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const navigate = useNavigate();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));
  const currentUserRole = authService.verifyUserRole();

  const fetchTickets = async (startDate, endDate, attributeName) => {
    setIsLoading(true);
    await genericApiToaster(
      fetchTicketsByDate,
      [startDate, endDate, attributeName],
      '',
      t('errorMessages.couldntLoadTicket'),
      showToast,
      hideToast,
      t,
      (res) => {
        setTicketsData(res);
        setPage(0);
      },
      undefined,
      () =>
        setTimeout(() => {
          setIsLoading(false);
        }, 500)
    );
  };

  const getTicketsByDateRange = () => {
    fetchTickets({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
  };

  useSubscription(`/notification/sendNotif-${currentUserId}`, (message) => {
    const notif = JSON.parse(message.body);
    if (notif?.type === 'TICKET') {
      getTicketsByDateRange();
    }
  });

  // fetch departments
  const fetchDepartmentData = () => {
    genericApiToaster(
      fetchDepartments,
      [],
      '',
      t('errorMessages.couldntLoadDep'),
      showToast,
      hideToast,
      t,
      setDepartmentData
    );
  };

  const handleDownloadClick = () => {
    if (!isDownloadDebouncing) {
      setIsDownloadDebouncing(true);
      setTimeout(() => {
        setIsDownloadDebouncing(false);
      }, 1500);
    }
  };

  useEffect(() => {
    getTicketsByDateRange();
    fetchDepartmentData();
    getStatuses(0, setStatusesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatuses = (depId, setState) => {
    genericApiToaster(
      fetchStatuses,
      [],
      '',
      t('errorMessages.couldntLoadStatuses'),
      showToast,
      hideToast,
      t,
      (res) => {
        setState(
          depId === 0
            ? res
            : res.filter(
                (status) => status.departmentId === depId || status.departmentId === 0
              )
        );
      }
    );
  };

  // Function to update items with status
  const updateItemsWithStatus = async () => {
    try {
      // Create a map for statuses to avoid multiple `.find()` calls
      const statusMap = Object.fromEntries(
        statusesList.map((status) => [status.statusId, status])
      );

      // Map tickets with status information
      const updatedTickets = ticketsData.map((ticket) => ({
        ...ticket,
        statusObject: statusMap[ticket.status_id] || null,
        status: statusMap[ticket.status_id]?.name || '',
      }));

      await filterByTab(
        title,
        updatedTickets,
        currentUserId,
        currentUserRole,
        departmentId,
        setTicketsWithStatus,
        setFilterByData,
        showToast,
        hideToast,
        t
      );
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadTicket'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    updateItemsWithStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsData, statusesList, title, t]);

  const filteredTickets = useMemo(() => {
    const filtered = applySortFilter(
      filterByData,
      getComparator(order, orderBy),
      filterTitle,
      filterBy
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSearchIsNotFound(!filtered.length && !!filterTitle);
    setIsNotFound(!filtered.length);
    return filtered;
  }, [filterBy, filterTitle, order, orderBy, filterByData]);

  const generateCSVData = (tickets) => {
    // Gather all unique question texts
    const allQuestionTexts = new Set(
      tickets.flatMap((ticket) =>
        (ticket.data || []).flatMap((section) =>
          section.questions.map((question) => question.text)
        )
      )
    );

    // Extract ticket attributes (excluding 'data')
    const ticketAttributes = [];
    Object.keys(tickets[0] || []).forEach((key) => {
      if (key !== 'data') {
        ticketAttributes.push(key);
      }
    });

    // Create CSV data
    return Papa.unparse({
      fields: [...ticketAttributes, ...allQuestionTexts],
      data: tickets.map((ticket) => {
        const rowData = {};
        // Add ticket attributes
        Object.keys(ticket).forEach((key) => {
          if (key !== 'data') {
            rowData[key] = ticket[key];
          }
        });
        // Add questions and answers as columns
        allQuestionTexts.forEach((questionText) => {
          rowData[questionText] = ''; // Initialize with empty string
        });
        (ticket.data || []).forEach((section) => {
          section.questions.forEach((question) => {
            rowData[question.text] = question.inputValue;
          });
        });
        return rowData;
      }),
    });
  };

  useEffect(() => {
    const csvData = generateCSVData(filteredTickets);
    setDataInCSV(csvData);
  }, [filteredTickets]);

  useEffect(() => {
    setCurrentPageTickets(
      filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [filteredTickets, page, rowsPerPage]);

  const fetchDepAgents = (departmentId) => {
    getUsersByDepartment(departmentId)
      .then((res) => {
        setDepartmentUsers(
          res.filter((user) => user.appUserRole === 'ROLE_AGENT' && !user.archived)
        );
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.couldntLoadDepUsers'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };

  useEffect(() => {
    if (selectedItem) fetchDepAgents(selectedItem.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      {selectedItem && (
        <ModifyTicket
          open={openModifyTicket}
          setOpen={setOpenModifyTicket}
          row={selectedItem}
          fetchTicketData={getTicketsByDateRange}
          departmentData={departmentData}
          getStatuses={getStatuses}
          statusesList={statusesList}
          currentRole={currentUserRole}
          currentUserId={currentUserId}
          departmentUsers={departmentUsers}
        />
      )}

      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            gutterBottom
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
          >
            {title}
          </Typography>
          <Grid>
            <ActionButton
              id="new-ticket"
              onClick={() => navigate('/dashboard/newTicket')}
              icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
              text={t('buttons.newTicket')}
              title={t('buttons.newTicket')}
              sx={{ mr: 1 }}
            />

            <ActionButton
              id="download-ticket"
              onClick={handleDownloadClick}
              icon={isSmallScreen ? 'octicon:download-16' : 'material-symbols:download'}
              text={t('buttons.download')}
              title={t('buttons.download')}
              href={`data:text/csv;charset=utf-8,${dataInCSV}`}
              disabled={isDownloadDebouncing}
              download="tickets.csv"
              sx={{ mx: 1 }}
            />
          </Grid>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            width: '100%',
            mt: isSmallScreen && 10,
          }}
        >
          <DashboardToolbar getTicketsByDate={fetchTickets} start={start} end={end} />
          <Button
            variant="outlined"
            sx={{
              minWidth: 'unset',
              height: '37px',
              mt: isSmallScreen && 10,
              fontSize: isSmallScreen && '0.7rem',
              ml: isSmallScreen && 1,
              whiteSpace: 'nowrap',
            }}
            onClick={() => setIsAccordionOpen((state) => !state)}
            size={isSmallScreen ? 'small' : 'medium'}
          >
            {t('buttons.openFilter')}
          </Button>
        </Box>
        <TicketsTable
          isAccordionOpen={isAccordionOpen}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          searchIsNotFound={searchIsNotFound}
          isNotFound={isNotFound}
          filterTitle={filterTitle}
          setFilterTitle={setFilterTitle}
          page={page}
          currentPageTickets={currentPageTickets}
          filteredTickets={filteredTickets}
          ticketsData={ticketsData}
          ticketsWithStatus={ticketsWithStatus}
          setPage={setPage}
          setFilterByData={setFilterByData}
          departmentData={departmentData}
          statusesList={statusesList}
          setFilterBy={setFilterBy}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpenModifyTicket={setOpenModifyTicket}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          getTicketsByDateRange={getTicketsByDateRange}
          isLoading={isLoading}
          generateCSVData={generateCSVData}
        />
      </Box>
    </>
  );
}
