import axios from 'axios';
import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/forms-link`;

export const generateShareableForm = async (id, idForm, expiration, selectedLanguage, isAnonymous, isMultipleSubmission, confirmCreation = false) => {
  const result = await axios.post(
    `${url}/generate-token`,
    {},
    {
      params: { 
        generatedBy: id, 
        idForm, 
        expiration, 
        language: selectedLanguage, 
        isAnonymous, 
        isMultipleSubmission,
        confirmCreation,
      },
      headers: getAuthorizationHeader(),
    }
  );    
  return result.data;
};

export const updateShareableForm = async (id, expiration, selectedLanguage, isAnonymous, isMultipleSubmission) => {
  const body = {
    expiresOn: expiration,
    language: selectedLanguage,
    isAnonymous,
    isMultipleSubmission,
  };
  const result = await axios.put(
    `${url}/update/${id}`,
    body,
    { headers: getAuthorizationHeader() }
  );
  return result.data;
}


export const fetchFromLinkById = async (id, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };
  const result = await axios.get(`${url}/get-form-link/${id}`, { headers });
  return result.data;
};

export const fetchFormsSharableByGeneratedBy = async (id) => {
  const result = await axios.get(`${url}/generated-by/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const fetchAllFormsSharable = async () => {
  const result = await axios.get(`${url}/all`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// delete form link
export const deleteFormLink = async (id) => {
  const result = await axios.delete(`${url}/delete/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// activate inavtive form link
export const activateFormLink = async (id) => {
  const result = await axios.put(`${url}/active-inactive-form-link/${id}`, {}, { headers: getAuthorizationHeader() });
  return result.data;
};

