export const labelStyles = {
  color: '#212636',
  fontSize: '1rem',
  '&.Mui-focused': {
    color: 'darkgray',
  },
};

export const inputStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B5C0D0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.light',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
  },
};
