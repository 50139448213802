import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Box, Select, MenuItem, FormHelperText } from '@mui/material';

import { useToast } from '../../hooks/useToast';
import { fetchForms } from '../../services/form.service';
import LinkGenerationOptions from './shareableFormOptions/LinkGenerationOptions';

function AddShareableForm({ hideModal, handleFetchFormsSharableByGeneratedBy, row }) {
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(row ? row.form.id : ''); // Store the selected form ID
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();

  const handleFetchForms = async () => {
    try {
      const response = await fetchForms();
      setForms(response);
    } catch (error) {
      showToast({
        message: t('errorMessages.cannotLoadForms'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  // Fetch forms on component mount
  useEffect(() => {
    handleFetchForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle form selection change
  const handleFormChange = (event) => {
    setSelectedFormId(event.target.value);
  };

  return (
    <Box component="form" sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Form Selection */}
      <Select
        value={selectedFormId}
        onChange={handleFormChange}
        fullWidth
        required
        displayEmpty
        disabled={!!row}
      >
        <MenuItem value="" disabled>
          {t('pages.shareableFormsPage.selectForm')}
        </MenuItem>
        {forms.map((form) => (
          <MenuItem key={form.id} value={form.id}>
            {form.title}
          </MenuItem>
        ))}
      </Select>
      {!selectedFormId && (
        <FormHelperText error>{t('formControl.formRequired')}</FormHelperText>
      )}

      {/* Shareable Form Settings */}
      {selectedFormId && (
        <LinkGenerationOptions
          formId={selectedFormId}
          hideModal={hideModal}
          fetchData={handleFetchFormsSharableByGeneratedBy}
          isNewShareableForm={!row} // Pass true if creating
          isUpdate={!!row} // Pass true if updating
          existingData={row || null} // Pass existing data if updating
        />
      )}
    </Box>
  );
}

AddShareableForm.propTypes = {
  hideModal: PropTypes.func,
  handleFetchFormsSharableByGeneratedBy: PropTypes.func,
  row: PropTypes.object,
};

export default AddShareableForm;
