import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Popper, MenuItem, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

import { ALL_TICKET_TABLE_HEAD } from '../tableHeads';

FilterPopover.propTypes = {
  setFilterBy: PropTypes.func,
  setFilterTitle: PropTypes.func,
  setSearchBy: PropTypes.func,
  setPage: PropTypes.func,
  departmentData: PropTypes.array,
  handleCloseFilter: PropTypes.func,
  filterPopover: PropTypes.object,
  statusesList: PropTypes.array,
};

export default function FilterPopover({
  filterPopover,
  handleCloseFilter,
  setPage,
  setFilterTitle,
  setFilterBy,
  setSearchBy,
  departmentData,
  statusesList,
}) {
  const [selectedFilter, setSelectedFilter] = useState('id');
  const { t } = useTranslation();

  const [subFilterPopover, setSubFilterPopover] = useState({
    department: null,
    status: null,
    priority: null,
  });

  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'priority') {
      setSubFilterPopover({ priority: event.currentTarget });
    }
  };
  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  return (
    <>
      <Popper
        open={Boolean(filterPopover)}
        anchorEl={filterPopover}
        placement="left-start"
        onClose={handleCloseFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {ALL_TICKET_TABLE_HEAD.filter(({ id }) => id !== 'options').map(
            ({ id, name }) =>
              name === 'status' ? (
                <MenuItem
                  key={id}
                  selected={selectedFilter === 'status'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'status')}
                  onClick={(e) => handleOpenSubFilter(e, 'status')}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2"> {t('filters.byStatus')}</Typography>
                  </Stack>
                </MenuItem>
              ) : name === 'department' ? (
                <MenuItem
                  key={id}
                  selected={selectedFilter === 'department'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'department')}
                  onClick={(e) => handleOpenSubFilter(e, 'department')}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2">{t('filters.byDepartment')}</Typography>
                  </Stack>
                </MenuItem>
              ) : name === 'priority' ? (
                <MenuItem
                  key={id}
                  selected={selectedFilter === 'priority'}
                  onMouseEnter={(e) => handleOpenSubFilter(e, 'priority')}
                  onTouchStart={(e) => handleOpenSubFilter(e, 'priority')}
                  onClick={(e) => handleOpenSubFilter(e, 'priority')}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    <Typography variant="body2"> {t('filters.byPriority')}</Typography>
                  </Stack>
                </MenuItem>
              ) : ['createdAt', 'updatedAt'].includes(name) ? null : (
                <MenuItem
                  key={id}
                  selected={selectedFilter === name}
                  onClick={() => {
                    setFilterTitle('');
                    setFilterBy(name);
                    setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                  onTouchStart={() => {
                    setFilterTitle('');
                    setFilterBy(name);
                    setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                    setSelectedFilter(name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                  }}
                >
                  <Typography variant="body2">
                    {t(
                      `filters.by${name && name.charAt(0).toUpperCase() + name.slice(1)}`
                    )}
                  </Typography>
                </MenuItem>
              )
          )}
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.department)}
        anchorEl={subFilterPopover.department}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          <Scrollbar sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {departmentData.map((department) => (
              <MenuItem
                key={department.id}
                onClick={() => {
                  setFilterBy('department');
                  setSearchBy('byDepartment');
                  setSelectedFilter('department');
                  setFilterTitle(department.name);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('department');
                  setSearchBy('byDepartment');
                  setSelectedFilter('department');
                  setFilterTitle(department.name);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                {department.name}
              </MenuItem>
            ))}
          </Scrollbar>
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.status)}
        anchorEl={subFilterPopover.status}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          <Scrollbar sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {statusesList.map((status, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('status');
                  setSearchBy('byStatus');
                  setSelectedFilter('status');
                  setFilterTitle(
                    status.statusId < 6
                      ? status.name
                      : `${status.name}|${status.department}`
                  );
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('status');
                  setSearchBy('byStatus');
                  setSelectedFilter('status');
                  setFilterTitle(
                    status.statusId < 6
                      ? status.name
                      : `${status.name}|${status.department}`
                  );
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                {status.statusId <= 5 ? (
                  <>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {t(`status.${status.name}`)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: '0.75rem',
                      }}
                    >
                      - Global
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {status.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: '0.75rem',
                      }}
                    >
                      - {status.department}
                    </Typography>
                  </>
                )}
              </MenuItem>
            ))}
          </Scrollbar>
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.priority)}
        anchorEl={subFilterPopover.priority}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {[
            { value: 'LOW', display: t('priority.LOW') },
            { value: 'MEDIUM', display: t('priority.MEDIUM') },
            { value: 'HIGH', display: t('priority.HIGH') },
          ].map((priority, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('priority');
                setSearchBy('byPriority');
                setSelectedFilter('priority');
                setFilterTitle(priority.value);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
              onTouchStart={() => {
                setFilterBy('priority');
                setSearchBy('byPriority');
                setSelectedFilter('priority');
                setFilterTitle(priority.value);
                handleCloseFilter();
                handleCloseSubFilter();
                setPage(0);
              }}
            >
              {priority.display}
            </MenuItem>
          ))}
        </Card>
      </Popper>
    </>
  );
}
