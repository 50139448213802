import React from 'react';
import { Helmet } from 'react-helmet-async';

import DepartmentsView from 'src/sections/department';

export default function DepartmentsPage() {
  return (
    <>
      <Helmet>
        <title> Departments </title>
      </Helmet>
      <DepartmentsView />
    </>
  );
}
