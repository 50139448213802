import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

// @mui
import { Box, Grid, Backdrop, Container, Typography } from '@mui/material';

import Loader from 'src/components/loaders/Loader';
import DashboardToolbar from 'src/components/ToolBars/DashboardToolbar';

// hooks
import { useAuth } from '../../hooks/useAuth';
// sections
import { AppWidgetSummary } from '../../components/charts';
import { fetchStatuses } from '../../services/status.service';
import { fetchTicketsByDate } from '../../services/ticket.service';

// ----------------------------------------------------------------------

export default function CustomerDashboard() {
  const { t } = useTranslation();
  const { username, id } = useAuth();
  const [ticketsData, setTicketsData] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    fetchStatuses().then((res) => setStatusesList(res));
  };

  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);

    const tickets = await fetchTicketsByDate({
      startDate: start,
      endDate: end,
      attributeName,
    });

    const statusMap = statusesList.reduce((acc, status) => {
      acc[status.statusId] = status;
      return acc;
    }, {});

    const updatedTickets = tickets
      .filter((ticket) => ticket.createdBy === id)
      .map((ticket) => ({
        ...ticket,
        statusObject: statusMap[ticket.status_id],
      }));

    setTicketsData(updatedTickets);
    setIsloading(false);
  };

  useEffect(() => {
    setIsloading(true);
    if (statusesList.length > 0)
      getTicketsByDate({ startDate: start, endDate: end, attributeName: 'created' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);

  const ticketStatusCount = useMemo(() => {
    const statusCount = {
      open: 0,
      onhold: 0,
      resolved: 0,
      closed: 0,
      unassigned: 0,
    };

    ticketsData.forEach((ticket) => {
      const { systemMappedStatus } = ticket.statusObject || {};
      if (systemMappedStatus) {
        if (statusCount[systemMappedStatus] !== undefined) {
          statusCount[systemMappedStatus] += 1;
        }
      }
    });

    return statusCount;
  }, [ticketsData]);

  useEffect(() => {
    getStatuses(0);
  }, []);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Backdrop
          sx={{
            bgcolor: 'rgb(121 121 121 / 32%)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Loader size={175} hideText />
          </Box>
        </Backdrop>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || ''}!
        </Typography>
        <DashboardToolbar getTicketsByDate={getTicketsByDate} start={start} end={end} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalTickets')}
              total={ticketsData?.length || 0}
              icon="raphael:customer"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.resolvedTickets')}
              total={ticketStatusCount.resolved}
              color="info"
              icon="mdi:face-agent"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.openTickets')}
              total={ticketStatusCount.open}
              color="warning"
              icon="ph:ticket"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closedTickets')}
              total={ticketStatusCount.closed}
              color="error"
              icon="system-uicons:ticket"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
