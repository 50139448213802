import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  Avatar,
  Divider,
  Popover,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { useAuth } from '../../../hooks/useAuth';
// apis
import authService from '../../../services/auth.service';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    path: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: '/dashboard/profile',
  },
];
const ADMIN_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    path: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: '/dashboard/profile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    path: '/dashboard/settings',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const { isSmallScreen } = useResponsiveScreen();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    authService.logout(user.id);
    navigate('/login', { replace: true });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: isSmallScreen ? 0.5 : 1,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={user.avatar ?? ''}
          alt="photoURL"
          sx={{
            width: isSmallScreen ? 28 : 35,
            height: isSmallScreen ? 28 : 35,
          }}
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user.givenName} ${user.familyName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email || 'user'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {(authService.verifyUserRole() === 'ROLE_ADMIN'
            ? ADMIN_MENU_OPTIONS
            : MENU_OPTIONS
          ).map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                handleClose();
                navigate(option.path);
              }}
            >
              {t(`menu.${option.label}`)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('menu.Logout')}
        </MenuItem>
      </Popover>
    </>
  );
}
