import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import ClientCredentialsView from 'src/sections/clientCredentials';

function ClientCredentialsPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title> {t('nav.clientCredentials')} </title>
      </Helmet>
      <ClientCredentialsView />
    </>
  );
}

export default ClientCredentialsPage;
