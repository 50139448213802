import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import React, { useRef, useMemo, useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Badge,
  Stack,
  styled,
  Avatar,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { getFilesByTicket } from 'src/services/ticket.service';
import {
  fetchMessagesByTicketId,
  fetchCommentsPerTicketId,
} from 'src/services/conversation.service';

import {
  iconifyStyle,
  secondBoxtStyle,
  iconifyChatStyle,
  thirdBoxChatStyle,
  forthBoxChatStyle,
  fifthBoxChatStyle,
  secondBoxChatStyle,
} from 'src/sections/ticketDetails/styles';

import Message from './message/Message';
import AttachmentsList from './AttachmentsList';
import { useModal } from '../../../hooks/useModal';
import Iconify from '../../../components/iconify/Iconify';
import FileInput from '../../../components/inputs/fileInput';
import { convertLocalTime } from '../../../utils/convertTime';
import formatRelativeTime from '../../../utils/formatRelativeTime';
import convertLineReturnToHTML from '../../../utils/convertLineReturn';

TicketConversation.propTypes = {
  row: PropTypes.object,
  customerAvatar: PropTypes.string,
  agentAvatar: PropTypes.string,
  currentStatus: PropTypes.object,
  fetchUser: PropTypes.func,
  statusesList: PropTypes.array,
  commentsData: PropTypes.array,
  setCommentsData: PropTypes.func,
  setIsLoading: PropTypes.func,
  ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  departmentUsers: PropTypes.array,
  hasReadPermission: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
};

const CustomInputAdornment = styled(InputAdornment)(() => ({
  position: 'absolute',
  bottom: 2,
  right: 10,
  transform: 'translateY(100%)',
}));

export default function TicketConversation({
  row,
  customerAvatar,
  agentAvatar,
  currentStatus,
  fetchUser,
  statusesList,
  commentsData,
  setCommentsData,
  setIsLoading,
  ticketId,
  departmentUsers,
  hasWritePermission,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal } = useModal();
  const { showToast, hideToast } = useToast();
  const [ticketFilesList, setTikcetFilesList] = useState([]);
  const { id: currentUserId, username } = useAuth();
  const stompClient = useStompClient();
  const bottomElementRef = useRef(null);

  const [formdata, setFormData] = useState([]);

  const normalizePath = (filePath) => filePath.split(/[\\/]/).filter(Boolean);
  const { register, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues: {
      id: '',
      message: '',
      avatar: '',
      user: '',
      time: '',
    },
  });

  useEffect(() => {
    const data = row?.data.flatMap((section) =>
      section?.questions?.map((question) => ({
        question: question.text,
        answer: question.inputValue,
      }))
    );
    setFormData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row?.data]);

  const isDisabled = useMemo(
    () => row && currentStatus && currentStatus.systemMappedStatus === 'UNASSIGNED',

    [row, currentStatus]
  );

  const fetchMessages = async () => {
    try {
      const res = await fetchMessagesByTicketId(ticketId);
      const cleanedMessages = res.data.filter(
        (item) =>
          item.messageType !== 'MESSAGE' || (item.message && item.message.trim() !== '')
      );
      setCommentsData(cleanedMessages);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadComments'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    if (row) {
      setCommentsData([]);
      fetchMessages();
      getTicketFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, currentStatus]);

  useSubscription(`/queue/reply-${ticketId}`, (message) => {
    const newComment = JSON.parse(message.body);
    setCommentsData((prevList) => [...prevList, newComment]);
    getTicketFiles();
  });

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const onUpload = async (file) => {
    createComment({
      userId: currentUserId,
      message: file,
      messageType: 'FILE',
    });

    fetchCommentsPerTicketId(row.id);
    getTicketFiles();
  };

  const onSubmit = (data) => {
    if (data.message.trim() !== '') {
      createComment({
        userId: currentUserId,
        message: data.message,
        messageType: 'MESSAGE',
      });
    }
    reset();
  };

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (bottomElementRef.current) {
      bottomElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (commentsData.length > 0) {
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData]);

  // create comments
  const createComment = async (newComment) => {
    if (stompClient) {
      const receiver = row.assignedTo === currentUserId ? row.createdBy : row.assignedTo;
      stompClient.publish({
        destination: `/app/user-message-${ticketId}/${receiver}/${username}`,
        body: JSON.stringify({
          message: newComment.message,
          receiver,
          sender: currentUserId,
          messageType: newComment.messageType,
          ticketId,
        }),
      });
    }
  };

  const getTicketFiles = async () => {
    try {
      const res = await getFilesByTicket(ticketId);

      const fileNames = [];
      res.forEach((filePath) => {
        const parts = normalizePath(filePath);
        const fileName = parts[parts.length - 1];

        fileNames.push(fileName);
      });

      setTikcetFilesList(fileNames);
    } catch (err) {
      showToast({
        message: t('errorMessages.couldntLoadTicketFiles'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const handleShowAttachments = () =>
    showModal({
      title: t('pages.ticketDetails.listAttachments'),
      Component: AttachmentsList,
      props: { hideModal, ticketFilesList },
    });

  return (
    <Box
      sx={{
        ml: 2,
        pb: isSmallScreen && 3,
      }}
    >
      <Grid container>
        <Grid item xs={11.25}>
          <Box sx={secondBoxChatStyle}>
            <>
              <Box sx={thirdBoxChatStyle}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Stack spacing={1} alignItems="center" direction="row">
                      {row?.assignedTo === currentUserId ? (
                        <>
                          <Avatar
                            src={customerAvatar}
                            alt=""
                            sx={{
                              width: isSmallScreen ? 32 : 40,
                              height: isSmallScreen ? 32 : 40,
                            }}
                          />
                          <Typography
                            variant="h6"
                            color="#4a4a4a"
                            sx={{
                              fontSize: isSmallScreen && '0.9rem',
                            }}
                          >
                            {row?.customerName !== 'none'
                              ? row?.customerName || 'customer'
                              : t('pages.ticketDetails.anonymous')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Avatar
                            src={agentAvatar}
                            alt=""
                            sx={{
                              width: isSmallScreen ? 32 : 40,
                              height: isSmallScreen ? 32 : 40,
                            }}
                          />
                          <Typography
                            variant="h6"
                            color="#4a4a4a"
                            sx={{
                              fontSize: isSmallScreen && '0.9rem',
                            }}
                          >
                            {row?.agentName !== 'none'
                              ? row?.agentName || 'Agent'
                              : t('status.UNASSIGNED')}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Badge
                      badgeContent={ticketFilesList.length}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <IconButton
                        size="medium"
                        color="inherit"
                        onClick={handleShowAttachments}
                      >
                        <Iconify
                          sx={iconifyChatStyle}
                          color="#4a4a4a"
                          width={isSmallScreen ? 24 : 35}
                          icon="teenyicons:attachment-outline"
                        />
                      </IconButton>
                    </Badge>
                  </Stack>
                </Stack>
              </Box>
              <Box sx={forthBoxChatStyle}>
                {row && (
                  <Message
                    key={0}
                    message={formdata?.map((text, index) => (
                      <li key={index}>
                        <strong>{t('pages.ticketDetails.question')} : </strong>{' '}
                        {text.question}
                        <br />
                        <strong>{t('pages.ticketDetails.answer')} : </strong>
                        {typeof text.answer === 'string'
                          ? convertLineReturnToHTML(text.answer)
                          : text.answer}
                      </li>
                    ))}
                    messageType="MESSAGE"
                    userId={row.createdBy}
                    time={formatRelativeTime(
                      convertLocalTime(row.createdAt),
                      t('filters.dates.today'),
                      t('filters.dates.yesterday'),
                      t('pages.ticketDetails.at')
                    )}
                    fetchUser={fetchUser}
                  />
                )}
                <Stack>
                  {commentsData.map((message) => (
                    <Message
                      key={`message${message.id}`}
                      message={message.message}
                      messageType={message.messageType}
                      statusesList={statusesList}
                      userId={message.sender}
                      time={formatRelativeTime(
                        convertLocalTime(message.sentAt),
                        t('filters.dates.today'),
                        t('filters.dates.yesterday'),
                        t('pages.ticketDetails.at')
                      )}
                      fetchUser={fetchUser}
                      departmentUsers={departmentUsers}
                    />
                  ))}
                </Stack>
                <Box ref={bottomElementRef} />
              </Box>
              <form onSubmit={handleSubmit(onSubmit)} key="message-box">
                <Box
                  sx={{
                    ...fifthBoxChatStyle,
                    px: isSmallScreen ? 1 : 10,
                    pt: isSmallScreen ? 2 : 0,
                  }}
                >
                  <TextField
                    sx={{
                      bgcolor: isDisabled || !hasWritePermission ? '#D3D3D3' : 'white',
                      minHeight: isSmallScreen && 50,
                      borderRadius: '15px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '15px',
                        fontSize: isSmallScreen ? '0.775rem' : '1rem',
                        '&:hover fieldset': {
                          borderColor: 'gray',
                          borderRadius: '15px',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                          borderRadius: '15px',
                        },
                        '& .MuiInputBase-input': {
                          paddingRight: '56px',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                          },
                        },
                      },
                    }}
                    disabled={isDisabled || !hasWritePermission}
                    minRows={1}
                    maxRows={3}
                    fullWidth
                    multiline
                    placeholder={t('pages.ticketDetails.addMessage')}
                    onKeyDown={handleEnterKeyPress}
                    {...register('message', { required: t('formControl.enterMessage') })}
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position="end">
                          <IconButton
                            sx={{ p: 0, mb: 6 }}
                            disabled={!hasWritePermission || isDisabled}
                            size="large"
                            onClick={() =>
                              showModal({
                                title: t('inputs.file.fileInput'),
                                Component: FileInput,
                                props: { hideModal, onUpload, ticketId: row.id },
                              })
                            }
                          >
                            <Iconify color="#4a4a4a" icon="eva:attach-2-outline" />
                          </IconButton>
                          <Iconify
                            icon="radix-icons:divider-vertical"
                            mb={6}
                            color="#4a4a4a"
                          />
                          <IconButton
                            sx={{ p: 0, mb: 6 }}
                            size="small"
                            type="submit"
                            disabled={!hasWritePermission || isDisabled}
                          >
                            <Iconify icon="akar-icons:send" color="#4a4a4a" />
                          </IconButton>
                        </CustomInputAdornment>
                      ),
                    }}
                  />
                </Box>
              </form>
            </>
          </Box>
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={0.75}>
            <Box sx={secondBoxtStyle}>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    if (window.opener) {
                      window.close();
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <Iconify sx={iconifyStyle} icon="eva:close-square-fill" />
                </IconButton>
              </Stack>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
