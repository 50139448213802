import { Outlet, Navigate } from 'react-router-dom';

import ProfilePage from 'src/pages/profile/ProfilePage';
import GroupUsersPage from 'src/pages/groupUsers/GroupUsersPage';
import TicketDetailsPage from 'src/pages/ticketDetails/TicketDetailsPage';

import Page404 from '../pages/404/Page404';
import SimpleLayout from '../layouts/simple';
//
import LoginPage from '../pages/Login/loginPage';
// layouts
import DashboardLayout from '../layouts/dashboard';
import TicketsPage from '../pages/tickets/TicketsPage';
import CreateTicketPage from '../pages/ticketCreation/CreateTicketPage';
import AssignmentsCalendar from '../pages/calendar/AssignmentsCalendar';
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute';
import TicketManagerDashboard from '../pages/dashboards/TicketManagerDashboard';
// ----------------------------------------------------------------------

const AgentRoutes = {
  path: '/',
  element: <Outlet />,
  children: [
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <TicketManagerDashboard /> },
        { path: 'tickets', element: <TicketsPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'newTicket', element: <CreateTicketPage /> },
        { path: 'calendar', element: <AssignmentsCalendar /> },
        { path: 'myGroups', element: <GroupUsersPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    { path: 'tickets/:ticketId', element: <TicketDetailsPage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ],
};

export default AgentRoutes;
