import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TabList, TabContext } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';

import IFrameSection from 'src/sections/shareableForm/shareableFormOptions/sections/IFrameSection';
import LinkFormSection from 'src/sections/shareableForm/shareableFormOptions/sections/LinkFormSection';
import LinkGenerationOptions from 'src/sections/shareableForm/shareableFormOptions/LinkGenerationOptions';

FormShare.propTypes = {
  formId: PropTypes.number,
  handlePopoverClose: PropTypes.func,
  handlePopoverTabChange: PropTypes.func,
  handleRemoveToken: PropTypes.func,
  popoverTabValue: PropTypes.string,
  setToken: PropTypes.func,
  token: PropTypes.string,
};

function FormShare({
  token,
  setToken,
  popoverTabValue,
  handlePopoverTabChange,
  handlePopoverClose,
  handleRemoveToken,
  formId,
}) {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: 3.5, minWidth: 300, maxWidth: 480 }}>
      <TabContext value={popoverTabValue}>
        {/* Link Generation Options */}
        <LinkGenerationOptions token={token} setToken={setToken} formId={formId} />

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mr: 2, whiteSpace: 'nowrap' }}>
            {t('pages.formPage.sendVia')}
          </Typography>
          <TabList
            onChange={handlePopoverTabChange}
            aria-label="send-options-tablist"
            sx={{ display: 'flex', width: '100%' }}
          >
            <Tab
              label={<Iconify icon="mdi:link" width={22} height={22} />}
              value="0"
              sx={{ flex: 1 }}
            />
            <Tab
              label={<Iconify icon="mdi:code-braces" width={22} height={22} />}
              value="1"
              sx={{ flex: 1 }}
            />
          </TabList>
        </Box>

        <LinkFormSection
          token={token}
          handleRemoveToken={handleRemoveToken}
          handlePopoverClose={handlePopoverClose}
        />

        <IFrameSection
          token={token}
          handleRemoveToken={handleRemoveToken}
          handlePopoverClose={handlePopoverClose}
        />
      </TabContext>
    </Box>
  );
}

export default FormShare;
