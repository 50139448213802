import React from 'react';
import { Helmet } from 'react-helmet-async';

import TicketDetailsView from 'src/sections/ticketDetails/index';

export default function TicketDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>
      <TicketDetailsView />
    </>
  );
}
