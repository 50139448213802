import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';

import { Box, Card, Stack, Button, Avatar, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useModal } from 'src/hooks/useModal';

import TicketTimeLine from './ticketTimeLine';
import Iconify from '../../../components/iconify';
import AssignTicketModal from '../modals/AssignTicketModal';
import {
  appStyle,
  cardStyle,
  stackStyle,
  avatarStyle,
  thirdGridStyle,
  thirdTypographyStyle,
  forthTypographyStyle,
  secondTypographyStyle,
  appOrderTimelineStyle,
} from '../styles';

export default function Info({
  currentStatus,
  ticketData,
  assignTicket,
  ticketId,
  statusesList,
  departmentUsers,
  agentData,
  customerData,
  fetchUser,
  commentsData,
  departmentData,
}) {
  const { t } = useTranslation();

  const { showModal, hideModal } = useModal();

  const { id: currentUserId, role: currentUserRole } = useAuth();
  const [dataInCSV, setDataInCSV] = useState('');

  const [downloadCSVDebouncer, setDownloadCSVDebouncer] = useState(false);

  useMemo(() => {
    const csvData = [];
    const questionsheaders = [];
    const questionAnswers = [];

    (ticketData?.data || [])
      .flatMap((section) => section.questions || [])
      .forEach(({ text, inputValue }) => {
        questionsheaders.push(text);
        questionAnswers.push(inputValue);
      });

    csvData.push(questionsheaders);
    csvData.push(questionAnswers);
    setDataInCSV(Papa.unparse(csvData));
  }, [ticketData]);

  const handleDownloadClick = () => {
    if (!downloadCSVDebouncer) {
      setDownloadCSVDebouncer(true);
      setTimeout(() => {
        setDownloadCSVDebouncer(false);
      }, 1500);
    }
  };

  // Define the function outside of the component
  const handleShowModal = () => {
    showModal({
      title: t('pages.assignTicket.assignTicketTo'),
      Component: AssignTicketModal,
      props: {
        hideModal,
        assignTicket,
        usersList: departmentUsers.filter((user) => user.id !== ticketData.createdBy),
        department: ticketData.department,
        statusesList: statusesList.filter(
          (status) => status.systemMappedStatus === 'OPEN'
        ),
      },
    });
  };

  return (
    <>
      <Box sx={thirdGridStyle} justifyContent="center">
        {currentStatus && currentStatus.systemMappedStatus === 'UNASSIGNED' ? (
          <>
            <Typography sx={secondTypographyStyle} variant="h6">
              {t('pages.ticketDetails.agentInfo')}
            </Typography>
            <Card sx={cardStyle}>
              <Typography sx={thirdTypographyStyle}>
                {t('pages.ticketDetails.notAssigned')}
              </Typography>
              {(currentUserRole.authority === 'ROLE_ADMIN' ||
                currentUserRole.authority === 'ROLE_DEPARTMENTMANAGER') && (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ mt: 3 }}
                  onClick={handleShowModal}
                >
                  {t('buttons.assignManually')}
                </Button>
              )}
            </Card>
          </>
        ) : (
          <Stack sapcing={1}>
            <Typography sx={secondTypographyStyle} variant="h6">
              {ticketData && currentUserId === ticketData.assignedTo
                ? t('pages.ticketDetails.userInfo')
                : t('pages.ticketDetails.agentInfo')}
            </Typography>
            <Card sx={cardStyle}>
              <Stack direction="row" spacing={2} alignItems="center" sx={stackStyle}>
                <Avatar
                  sx={avatarStyle}
                  src={
                    ticketData && currentUserId === ticketData.assignedTo
                      ? customerData?.avatar
                      : agentData?.avatar
                  }
                  alt=""
                />
                <Typography sx={thirdTypographyStyle} variant="body1">
                  {ticketData && currentUserId === ticketData.assignedTo ? (
                    <b>{customerData.username}</b>
                  ) : (
                    <b>{agentData.username}</b>
                  )}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }} spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Typography sx={thirdTypographyStyle}>
                    <b>{t('attributes.email')} : </b>
                  </Typography>
                  <Typography sx={forthTypographyStyle}>
                    {ticketData && currentUserId === ticketData.assignedTo
                      ? customerData.email
                      : agentData.email}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography sx={thirdTypographyStyle}>
                    <b>{t('attributes.phoneNumber')} : </b>
                  </Typography>
                  <Typography sx={forthTypographyStyle}>
                    {ticketData && currentUserId === ticketData.assignedTo
                      ? customerData.phoneNumber
                      : agentData.phoneNumber || t('pages.ticketDetails.noPhone')}
                  </Typography>
                </Stack>
                {ticketData && currentUserId !== ticketData.assignedTo && (
                  <Stack direction="row" spacing={2}>
                    <Typography sx={thirdTypographyStyle}>
                      <b>{t('attributes.department')}:</b>
                    </Typography>
                    <Typography sx={forthTypographyStyle}>
                      {departmentData.name}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Card>
          </Stack>
        )}
        <TicketTimeLine
          sx={appOrderTimelineStyle}
          title={t('pages.ticketDetails.interactions')}
          list={commentsData.filter((message) => message.messageType === 'UPDATE')}
          statusesList={statusesList}
          fetchUser={fetchUser}
          ticketData={ticketData}
          departmentUsers={departmentUsers}
        />
      </Box>
      <span style={appStyle}>
        <Button
          href={`data:text/csv;charset=utf-8,${dataInCSV}`}
          download={`ticket-${ticketId}-Answers.csv`}
          disabled={downloadCSVDebouncer}
          onClick={handleDownloadClick}
          variant="contained"
          startIcon={<Iconify icon="material-symbols:download" />}
        >
          {t('buttons.downloadAnswers')}
        </Button>
      </span>
    </>
  );
}

Info.propTypes = {
  currentStatus: PropTypes.object,
  ticketData: PropTypes.object,
  assignTicket: PropTypes.func,
  ticketId: PropTypes.string,
  statusesList: PropTypes.array,
  departmentUsers: PropTypes.array,
  agentData: PropTypes.object,
  customerData: PropTypes.object,
  commentsData: PropTypes.array,
  fetchUser: PropTypes.func,
  departmentData: PropTypes.object,
};
