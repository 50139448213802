import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  FormHelperText,
} from '@mui/material';

import { EMAIL_REGEX } from '../../../utils/regex';
import { getUsersByDepartment } from '../../../services/user.service';

AddStatus.propTypes = {
  hideModal: PropTypes.func,
  emailsList: PropTypes.array,
  departmentList: PropTypes.array,
  role: PropTypes.string,
  addStatus: PropTypes.func,
  currentUserDepartment: PropTypes.number,
};
export default function AddStatus({
  hideModal,
  emailsList,
  departmentList,
  role,
  addStatus,
  currentUserDepartment,
}) {
  const [color, setColor] = useState('#000000');
  const [emails, setEmails] = useState([]);
  const [emailValid, setEmailValid] = useState(true);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const handleHtmlColorChange = (event) => {
    setColor(event.target.value);
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      description: '',
      name: '',
      correspondences: [],
      color: '',
      systemMappedStatus: '',
      departmentId: -1,
    },
  });
  const handleAddEmail = (event, newValue) => {
    let validEmails = true;
    newValue.forEach((email) => {
      if (EMAIL_REGEX.test(email) === false) {
        validEmails = false;
      }
    });
    if (validEmails) {
      setEmailValid(true);
      setEmails(newValue);
    } else if (newValue.length !== 0) {
      setEmailValid(false);
    }
  };

  const handleKeyDown = (event) => {
    if ((event.key === ' ' || event.key === ',') && event.target.value !== '') {
      event.preventDefault();
      event.stopPropagation();
      handleAddEmail(event, [...emails, event.target.value]);
    }
  };
  useEffect(() => {
    if (role !== 'ROLE_ADMIN') setEmailSuggestions(emailsList);
  }, [emailsList, role]);

  const selectedDepartment = watch('departmentId');
  useEffect(() => {
    if (selectedDepartment && role === 'ROLE_ADMIN')
      getUsersByDepartment(selectedDepartment).then((res) =>
        setEmailSuggestions(
          res.filter((user) => !user.archived).map((user) => user.email)
        )
      );
  }, [role, selectedDepartment]);

  const onSubmit = (data) => {
    data.correspondences = emails;
    data.color = color;
    if (role === 'ROLE_DEPARTMENTMANAGER') {
      data.departmentId = currentUserDepartment;
    }
    addStatus(data);
    reset();
    hideModal();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2} spacing={3}>
        <TextField
          id="status-name"
          name="name"
          label={t('tableHeads.status.name')}
          error={!!errors.name}
          helperText={errors.name?.message || ''}
          {...register('name', { required: t('formControl.enterName') })}
        />
        <TextField
          multiline
          id="status-description"
          minRows={3}
          name="description"
          label={t('tableHeads.status.description')}
          error={!!errors.description}
          helperText={errors.description?.message || ''}
          {...register('description', { required: t('formControl.enterDescription') })}
        />
        {role === 'ROLE_ADMIN' && (
          <FormControl
            fullWidth
            error={!!errors.departmentId}
            data-testid="user-department-select-control"
          >
            <InputLabel id="department-select-label">
              {t('tableHeads.status.department')}
            </InputLabel>
            <Select
              labelId="department-select-label"
              id="department-select"
              inputProps={{
                'data-testid': 'department-select',
              }}
              label={t('tableHeads.status.department')}
              defaultValue=""
              {...register('departmentId', {
                required: t('formControl.selectDepartment'),
              })}
            >
              {departmentList?.length > 0 ? (
                departmentList.map((department) => {
                  const { id, name } = department;
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  {t('infoMessages.noOptions')}
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{errors.departmentId?.message}</FormHelperText>
          </FormControl>
        )}
        <FormControl fullWidth error={!!errors.systemMappedStatus}>
          <InputLabel id="demo-simple-select-label">
            {' '}
            {t('tableHeads.status.systemState')}
          </InputLabel>

          <Select
            name="systemMappedStatus"
            labelId="demo-simple-select-label"
            id="related-state-select"
            label={t('tableHeads.status.systemState')}
            defaultValue=""
            {...register('systemMappedStatus', {
              required: t('formControl.chooseSystemStatus'),
            })}
          >
            <MenuItem value="ONHOLD">{t('status.ONHOLD')}</MenuItem>
            <MenuItem value="UNASSIGNED">{t('status.UNASSIGNED')}</MenuItem>
            <MenuItem value="OPEN">{t('status.OPEN')}</MenuItem>
            <MenuItem value="RESOLVED">{t('status.RESOLVED')}</MenuItem>
            <MenuItem value="CLOSED">{t('status.CLOSED')}</MenuItem>
          </Select>
          <FormHelperText>{errors.systemMappedStatus?.message}</FormHelperText>
        </FormControl>
        <Autocomplete
          multiple
          id="email-input"
          options={emailSuggestions}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('tableHeads.status.correspondances')}
              placeholder={t('pages.statusPage.typeEmail')}
              onKeyDown={(e) => handleKeyDown(e)}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              error={!emailValid}
              helperText={!emailValid ? t('pages.statusPage.invalidEmail') : ''}
            />
          )}
          onChange={handleAddEmail}
          clearOnBlur
          onBlur={() => setEmailValid(true)}
          value={emails}
        />

        <Stack direction="row" id="color-picker" alignItems="center" spacing={1}>
          <Typography variant="h6"> {t('tableHeads.status.color')} : </Typography>
          <input
            style={{ cursor: 'pointer' }}
            type="color"
            value={color}
            onChange={handleHtmlColorChange}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <LoadingButton
          sx={{ mt: 2 }}
          id="new-stat-btn"
          fullWidth
          size="medium"
          type="submit"
          variant="contained"
          disabled={!isValid || !emailValid}
        >
          {t('pages.statusPage.addStatus')}
        </LoadingButton>
      </Stack>
    </form>
  );
}
