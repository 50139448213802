import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/group-users`;

export const fetchAllGroups = async (id) => {
  const result = await axios.get(`${url}/all/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const fetchGroupById = async (id) => {
  const result = await axios.get(`${url}/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
}; // unused but may be useful in the future

export const createGroup = async ({ name, users, image, createdBy }) => {
  const result = await axios.post(
    `${url}/create`,
    {
      name,
      users,
      image,
      createdBy,
    },
    {
      headers: getAuthorizationHeader(),
    }
  );
  return result.data;
};

export const updateGroup = async ({ id, name, users, image, createdBy }) => {
  const result = await axios.put(
    `${url}/${id}`,
    {
      name,
      users,
      image,
      createdBy,
    },
    {
      headers: getAuthorizationHeader(),
    }
  );
  return result.data;
};

export const deleteGroup = async (id) => {
  const result = await axios.delete(`${url}/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
