import PropTypes from 'prop-types';
import { noCase } from 'change-case';

import {
  Avatar,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from '../../../../components/iconify';
import { fToNow } from '../../../../utils/formatTime';
import { convertLocalTime } from '../../../../utils/convertTime';

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    triggerId: PropTypes.number,
    triggeredBy: PropTypes.string,
  }),
  handleNotificationClick: PropTypes.func,
};

export default function NotificationItem({ notification, handleNotificationClick }) {
  const { isSmallScreen } = useResponsiveScreen();
  const { avatar, title } = renderContent(notification, isSmallScreen);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        handleNotificationClick(
          notification.id,
          notification.triggerId,
          notification.triggeredBy,
          notification.type
        );
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
              fontSize: isSmallScreen ? '11px' : '12px',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(Date.parse(convertLocalTime(notification.createdAt)))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function renderContent(notification, isSmallScreen) {
  const title = (
    <Typography>
      <Typography variant={isSmallScreen ? 'subtitle2' : 'subtitle1'} noWrap>
        {notification.title}
      </Typography>
      <Typography
        component="span"
        variant={isSmallScreen ? 'caption' : 'body2'}
        sx={{ color: 'text.secondary' }}
      >
        {noCase(notification.subject)}
      </Typography>
    </Typography>
  );

  // svg links
  const typeToAvatar = {
    TICKET: '/assets/icons/ic_notification_ticket.svg',
    INFORMATION: '/assets/icons/ic_notification_information.svg',
    FORM: '/assets/icons/ic_notification_form.svg',
    DEPARTMENT: '/assets/icons/ic_notification_department.svg',
    MAIL: '/assets/icons/ic_notification_mail.svg',
    MESSAGE: '/assets/icons/ic_notification_chat.svg',
  };
  const avatarUrl = typeToAvatar[notification.type] || typeToAvatar.INFORMATION;
  const avatar = avatarUrl ? <img alt={notification.type} src={avatarUrl} /> : null;

  return {
    avatar,
    title,
  };
}
