export const signInStyles = { fontSize: '2rem', fontWeight: '500' };

export const emailStyles = { fontSize: '0.9rem', fontWeight: '400', color: '#667085' };

export const labelStyles = {
  color: '#212636',
  fontSize: '1rem',
  '&.Mui-focused': {
    color: 'darkgray',
  },
};

export const inputStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B5C0D0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.light',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
  },
};

export const buttonStyles = {
  alignSelf: 'center',
  width: '40%',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: 'blue',
  },
};
