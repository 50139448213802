import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import {
  addClientCredentials,
  updateClientCredentials,
} from 'src/services/client.credentials.service';

function AddUpdateClientCredentials({ hideModal, handleFetchClientCredentials, row }) {
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty: isDerty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      clientName: row?.clientName || '',
      clientDomain: row?.clientDomain || '',
    },
  });

  const onSubmitErrorCallback = (error) => {
    if (error.response?.data === 'name exists') {
      showToast({
        message: t('warningMessages.clientNameExists'),
        severity: 'warning',
        props: { hideToast },
      });
    } else if (error.response?.data === 'domain exists') {
      showToast({
        message: t('warningMessages.clientDomainExists'),
        severity: 'warning',
        props: { hideToast },
      });
    } else
      showToast({
        message: row
          ? t('errorMessages.errorUpdatingClientCredentials')
          : t('errorMessages.errorAddingClientCredentials'),
        severity: 'error',
        props: { hideToast },
      });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    genericApiToaster(
      row ? updateClientCredentials : addClientCredentials,
      row ? [row.id, data] : data,
      row
        ? t('successMessages.successUpdatingClientCredentials')
        : t('successMessages.successAddingClientCredentials'),
      '',
      showToast,
      hideToast,
      t,
      () => {
        hideModal();
        handleFetchClientCredentials();
        reset();
      },
      onSubmitErrorCallback,
      setLoading(false)
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} mt={1}>
        <TextField
          fullWidth
          label={t('pages.clientCredentialsPage.clientName')}
          {...register('clientName', { required: t('formControl.fieldRequired') })}
          error={!!errors.clientName}
          helperText={errors.clientName?.message}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
            '& .MuiFormLabel-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
          }}
        />

        <TextField
          fullWidth
          label={t('pages.clientCredentialsPage.clientDomain')}
          {...register('clientDomain', { required: t('formControl.fieldRequired') })}
          error={!!errors.clientDomain}
          helperText={errors.clientDomain?.message}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
            '& .MuiFormLabel-root': {
              fontSize: isSmallScreen ? '0.7rem' : '1rem',
            },
          }}
        />

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          loading={loading}
          disabled={!isValid || !isDerty}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {row ? t('buttons.update') : t('buttons.submit')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

AddUpdateClientCredentials.propTypes = {
  hideModal: PropTypes.func,
  handleFetchClientCredentials: PropTypes.func,
  row: PropTypes.object,
};

export default AddUpdateClientCredentials;
