import { DialogTitle, DialogContent, Dialog, Alert, Button, Slide } from '@mui/material';
import React, { createContext, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Iconify from '../components/iconify';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalContext = createContext({
  showModal: () => undefined,
  hideModal: () => undefined,
  isVisible: false,
});

const { Provider, Consumer: ModalConsumer } = ModalContext;

const reducer = (state, action) => {
  switch (action.type) {
    case 'openModal': {
      const { title, Component, props } = action;
      return { ...state, title, Component, props };
    }
    case 'hideModal':
      return { ...state, title: null, Component: null, props: {} };
    default:
      throw new Error('Unspecified reducer action');
  }
};

const ModalProvider = ({ children }) => {
  const initialState = {
    title: null,
    Component: null,
    props: {},
  };

  ModalProvider.propTypes = {
    children: PropTypes.node,
    maxWidth: PropTypes.string,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { title, Component, props } = state;
  // eslint-disable-next-line react/prop-types
  const maxWidth = props.maxWidth ? 'md' : 'sm';
  const isVisible = !!Component;
  const showModal = (modal) => {
    dispatch({ type: 'openModal', ...modal });
  };

  const hideModal = () => {
    dispatch({ type: 'hideModal' });
    // eslint-disable-next-line no-unused-expressions, react/prop-types
    props.onClose && props.onClose();
  };

  return (
    <Provider value={{ ...state, showModal, hideModal, isVisible }}>
      {children}
      <Dialog open={isVisible} onClose={hideModal} TransitionComponent={Transition} fullWidth maxWidth={maxWidth}>
        {title && (
          <DialogTitle>
            {title}
            <Iconify
              icon={'carbon:close-outline'}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                cursor: 'pointer',
                color: (theme) => theme.palette.grey[500],
                transition: 'color 0.3s', // Add a smooth color transition
                '&:hover': {
                  color: (theme) => theme.palette.primary.main, // Change to the desired hover color
                },
              }}
              onClick={hideModal}
            />
          </DialogTitle>
        )}

        <DialogContent>
          {Component && (
            <div>
              <Component {...props} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Provider>
  );
};

const useModal = () => useContext(ModalContext);

const useConfirmModal = (title, message, onConfirm) => {
  const { t } = useTranslation();
  const { showModal, hideModal, isVisible } = useModal();
  const showConfirmModal = (existingId) =>
    showModal({
      title,
      Component: () => (
        <>
          <Alert severity="warning">
            {message}
            {typeof existingId === 'string' && existingId ? ` (${t('pages.shareableFormsPage.existingId')}: #${existingId})` : ''}
          </Alert>

          <div>
            <Button
              id="modal-confirm-btn"
              sx={{ color: 'red' }}
              onClick={() => {
                onConfirm();
                hideModal();
              }}
            >
              {t('buttons.confirm')}
            </Button>
            <Button id="modal-cancel-btn" type="button" onClick={hideModal}>
              {t('buttons.cancel')}
            </Button>
          </div>
        </>
      ),
      props: { onClose: hideModal },
    });

  return {
    showConfirmModal,
    hideModal,
    isVisible,
  };
};

export { ModalConsumer, ModalProvider, useModal, useConfirmModal };
